<template lang="html">
  <div class="activation-status">
    <template v-if="permissionActivationStatus">
      <MapPaymentTermsCheckbox v-if="!zoneMap.active" v-model="termsAccepted" />
      <IxButton
        v-if="zoneMap.active"
        large cancel
        :disabled="!permissionDeactivate"
        @click="startDeactivate"
      >
        <IxRes>frs.mapManagement.buttons.deactivate</IxRes>
      </IxButton>
      <IxButton
        v-else
        :disabled="!(termsAccepted && permissionActivate)"
        large colored
        @click="activate"
      >
        <IxRes>frs.mapManagement.buttons.activate</IxRes>
      </IxButton>
    </template>

    <template v-if="$can('Activate.Frs.ZoneMap.AsDemo') && !zoneMap.active">
      <IxButton large @click="activate(true)">
        <IxRes>frs.mapManagement.buttons.activateDemo</IxRes>
      </IxButton>
    </template>

    <ConfirmationModal v-model="showDeactivationModal" @confirm="deactivate">
      <template #title>
        <IxRes>frs.mapManagement.headers.deactivateZoneMap</IxRes>
      </template>

      <IxRes>frs.mapManagement.hints.deactivationCanIncurFurtherCosts</IxRes>

      <template #confirm>
        <IxRes>frs.mapManagement.buttons.confirmDeactivation</IxRes>
      </template>
      <template #cancel>
        <IxRes>frs.mapManagement.buttons.cancelDeactivation</IxRes>
      </template>
    </ConfirmationModal>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

import MapPaymentTermsCheckbox from '@components/MapPaymentTermsCheckbox'
import IxButton from '@components/IxButton'
import ConfirmationModal from '@components/modals/ConfirmationModal'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    IxButton,
    ConfirmationModal,
    MapPaymentTermsCheckbox
  },
  mixins: [
    PermissionMixin
  ],
  props: {
    zoneMap: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showDeactivationModal: false,
      termsAccepted: false
    }
  },
  computed: {
    permissionActivationStatus () {
      const zoneMapPermission = (this.$can('Create.Frs.ZoneMap') && this.zoneMap.origin === 'FieldInfo') ||
      (this.zoneMap.origin !== 'FieldInfo' && this.zoneMap.category !== 'BiomassMap' && (this.$can('Deactivate.Frs.ZoneMap') || this.$can('Activate.Frs.ZoneMap')))

      return zoneMapPermission || this.biomassPermission
    },
    permissionActivate () {
      const zoneMapPermission = this.zoneMap.category !== 'BiomassMap' && this.$can('Activate.Frs.ZoneMap')

      return zoneMapPermission || this.biomassPermission
    },
    permissionDeactivate () {
      const zoneMapPermission = this.zoneMap.category !== 'BiomassMap' && this.$can('Deactivate.Frs.ZoneMap')

      return zoneMapPermission || this.biomassPermission
    },
    biomassPermission () {
      return this.zoneMap.category === 'BiomassMap' && this.$can('Create.Frs.BiomassMap')
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'activateZoneMap',
      'deactivateZoneMap'
    ]),
    startDeactivate () {
      this.showDeactivationModal = true
    },
    async deactivate () {
      this.deactivateZoneMap({zoneMapId: this.zoneMap.id})
    },
    activate (isDemo) {
      this.activateZoneMap({zoneMapId: this.zoneMap.id, isDemo})
    }
  }
}
</script>

<style lang="scss" scoped>
.activation-status {
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 0.5em;
  }
}
</style>
