<template>
  <div class="input-group">
    <div>
      <LabelAndMessage>
        <template #label>
          <IxRes>frs.actions.seeding.SeedProduct</IxRes>
        </template>
        <FrsProductPicker
          v-model="productId"
          type="seed"
          :available-product-filter="availableProductFilter"
        />
      </LabelAndMessage>

      <LabelAndMessage v-if="mixtureComponents && mixtureComponents.length > 0">
        <template #label>
          <IxRes>frs.actions.seeding.MixtureComponents</IxRes>
        </template>
        <FrsMixtureTable :mixture-components="mixtureComponents" />
      </LabelAndMessage>

      <NumericInput
        ref="inputPlantsPerSqm" v-model="plantsPerSqm"
        :rule="plantsPerSqmRule"
        :addon="'pro qm'" hide-description
        @input="wasComputed.plantsPerSqm = false"
      >
        <template #label>
          <IxRes>frs.actions.seeding.PlantsPerSqm</IxRes>
        </template>
      </NumericInput>
      <NumericInput v-model="rowDistance" unit="cm" hide-description>
        <template #label>
          <IxRes>frs.actions.seeding.RowDistance</IxRes>
        </template>
      </NumericInput>
      <NumericInput v-model="rowSeparation" unit="cm" hide-description>
        <template #label>
          <IxRes>frs.actions.seeding.RowSeparation</IxRes>
        </template>
      </NumericInput>
      <NumericInput
        v-model="emergence" unit="%"
        percent-not-normalized
        hide-description
      >
        <template #label>
          <IxRes>frs.actions.seeding.Emergence</IxRes>
        </template>
      </NumericInput>
      <NumericInput
        v-model="germinationCapacity" unit="%"
        percent-not-normalized
        hide-description
      >
        <template #label>
          <IxRes>frs.actions.seeding.GerminationCapacity</IxRes>
        </template>
      </NumericInput>
      <text-input v-model="coating">
        <template #label>
          <IxRes>frs.actions.seeding.coating</IxRes>
        </template>
      </text-input>
      <NumericInput
        ref="inputSeedsPerHa" v-model="seedsPerHa"
        :rule="seedsPerHaRule"
        :unit="unitSeedsPerHa" hide-description
        @input="wasComputed.seedsPerHa = false"
      >
        <template #label>
          <IxRes>frs.actions.seeding.SeedsPerHa</IxRes>
        </template>
      </NumericInput>
    </div>
    <hr>
    <div>
      <strong>
        <IxRes>frs.actions.seeding.TotalSeeds</IxRes>
      </strong>
      <span class="total-seeds">{{ totalSeeds }}</span>
      <span class="total-seeds">{{ unitTotalSeeds }}</span>
    </div>
  </div>
</template>

<script>
import NumericInput from '@components/forms/NumericInput'
import TextInput from '@components/forms/TextInput'
import FrsProductPicker from '@frs/components/base/FrsProductPicker'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import FrsMixtureTable from '@frs/components/base/FrsMixtureTable'

import SeedingMixin from './SeedingMixin'

export default {
  components: {
    NumericInput,
    TextInput,
    FrsProductPicker,
    LabelAndMessage,
    FrsMixtureTable
  },
  mixins: [
    SeedingMixin
  ],
  data () {
    return {
      isCorn: true
    }
  },
  computed: {
    unitSeedsPerHa () {
      return this.$i18n.translateUnit('K/ha')
    },
    unitTotalSeeds () {
      return this.$i18n.translateUnit('K')
    }
  }
}
</script>

<style scoped>
.total-seeds {
  margin-left: 5px
}
</style>
