import { render, staticRenderFns } from "./CollapsibleCard.vue?vue&type=template&id=969f3008&scoped=true&"
import script from "./CollapsibleCard.vue?vue&type=script&lang=js&"
export * from "./CollapsibleCard.vue?vue&type=script&lang=js&"
import style0 from "./CollapsibleCard.vue?vue&type=style&index=0&id=969f3008&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "969f3008",
  null
  
)

export default component.exports