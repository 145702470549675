<template lang="html">
  <div class="action-planning" style="height: 100%">
    <PlanningWizard />
  </div>
</template>

<script>
import PlanningWizard from './wizard'

import DisableDefaultMapInteractionsMixin from '@frs/mixins/DisableDefaultMapInteractionsMixin'
import DisableDefaultMapLayersMixin from '@frs/mixins/DisableDefaultMapLayersMixin'

export default {
  components: {
    PlanningWizard
  },
  mixins: [
    DisableDefaultMapInteractionsMixin,
    DisableDefaultMapLayersMixin
  ]
}
</script>

<style lang="scss" scoped>
</style>
