<template lang="html">
  <div class="alert alert-danger">
    This should never be visible in production
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>
