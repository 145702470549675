<template lang="html">
  <span>
    <span>{{ $i18n.format(image.recordingDate, 'date') }}</span>
    (<span :title="image.dataSource" :class="$style.source">
      {{ shortSources[image.dataSource] }}
    </span>)
  </span>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      required: true
    }
  },
  computed: {
    shortSources () {
      return {
        Sentinel2AtmosphericallyCorrected: 'S2-L2A',
        Sentinel2: 'S2-L1C',
        Landsat: 'L',
        RapidEye: 'RE',
        Modis: 'M'
      }
    }
  }
}
</script>

<style lang="scss" module>
.source {
  cursor: help;
  text-decoration: underline;
  text-decoration-style: dotted;
}
</style>
