<template>
  <div class="machine-container">
    <LabelAndMessage>
      <template #label>
        <IxRes>Areas.LanePlanning.SR_LanePlanning.Machine</IxRes>:
      </template>
      <template>
        {{ vehicleName }}
      </template>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>Areas.LanePlanning.SR_LanePlanning.WorkingWidth</IxRes>:
      </template>
      <template>
        {{ plan.settings.workingWidth }}m
      </template>
    </LabelAndMessage>
    <hr>
  </div>
</template>

<script>
import IxRes from '@components/IxRes'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    LabelAndMessage,
    IxRes
  },
  props: {
    plan: {
      required: true,
      type: Object
    }
  },
  computed: {
    vehicleName () {
      return this.plan.vehicle ? this.plan.vehicle.name : this.$i18n.translate('Areas.LanePlanning.SR_LanePlanning.NotSelected')
    }
  }
}
</script>

<style lang="scss" scoped>
.machine-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
