<template>
  <div class="header-container">
    <div>
      <IxButton @click="navigateToStartPage">
        <FontAwesomeIcon :icon="icon.home" />
        <IxRes>bf.navigation.backToMainMenu</IxRes>
      </IxButton>
    </div>

    <h4>
      <template v-if="$slots.subtitle">
        <slot name="subtitle" />
      </template>
    </h4>
    <span>
      <template v-if="$slots.hint">
        <slot name="hint" />
      </template>
    </span>
    <div v-if="$slots.field" class="field">
      <h3>
        <template v-if="$slots.field">
          <slot name="field" />
        </template>
      </h3>
    </div>
    <hr>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faHome} from '@fortawesome/free-solid-svg-icons'

import IxButton from '@components/IxButton'

export default {
  components: {
    FontAwesomeIcon,
    IxButton
  },
  computed: {
    icon () {
      return {
        home: faHome
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization', [
      'navigateToStartPage'
    ])
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
  flex-direction: column;

  span {
    margin: 0;
  }
  h3, h4 {
    margin: 4px 0 0 0;
  }
  hr {
    width: 100%;
    border-bottom: 1px solid lightgrey;
    margin: 12px 0;
  }
}
</style>
