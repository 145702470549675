<template lang="html">
  <HelpBox>
    <IxRes :context="{schema: metaData.$schema}">frs.mapManagement.unsupportedMetaData</IxRes>
  </HelpBox>
</template>

<script>
import HelpBox from '@components/help/HelpBox'

export default {
  components: {
    HelpBox
  },
  props: {
    metaData: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
