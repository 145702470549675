import {cloneDeep, mergeWith, set, get, toPath} from 'lodash'

export function mapParameters (parameters, fieldIdGetter) {
  const computed = {}
  for (const pathString of parameters) {
    const path = toPath(pathString)
    const parameter = path[path.length - 1]

    const percentValue = pathString.startsWith('fertilizerNutrientContents.')

    computed[parameter] = {
      get () {
        const fieldId = (fieldIdGetter && fieldIdGetter.call(this)) || Object.keys(this.value)[0]

        const value = get(this.value[fieldId].parameters, pathString)

        return percentValue ? value * 100 : value
      },
      set (value) {
        if (percentValue) {
          value /= 100
        }

        const fieldId = fieldIdGetter ? fieldIdGetter.call(this) : undefined

        const change = {}
        set(change, pathString, value)

        this.emitParameterChange(change, fieldId)
      }
    }
  }

  return computed
}

export function mapFieldSpecificParameters (parameters, fieldIdGetter = function () {
  return this.fieldId
}) {
  return mapParameters(parameters, fieldIdGetter)
}

export function mapSingleProductQuantity (fieldIdGetter) {
  const computed = {}

  for (const propertyName of ['amount', 'productId', 'unit']) {
    computed[propertyName] = {
      get () {
        const fieldId = (fieldIdGetter && fieldIdGetter.call(this)) || Object.keys(this.value)[0]

        return this.value[fieldId].productQuantities[0]
          ? this.value[fieldId].productQuantities[0][propertyName]
          : null
      },
      set (value) {
        const fieldId = fieldIdGetter ? fieldIdGetter.call(this) : undefined

        this.emitSingleProductQuantity({[propertyName]: value}, fieldId)
      }
    }
  }

  return computed
}

export function mapFieldSpecificSingleProductQuantity (fieldIdGetter = function () {
  return this.fieldId
}) {
  return mapSingleProductQuantity(fieldIdGetter)
}

export default {
  props: {
    value: Object,
    action: Object
  },
  methods: {
    emitParameterChange (change, specificFieldId) {
      const details = {}

      for (const fieldId in this.value) {
        const {parameters: previousParameters, productQuantities} = this.value[fieldId]

        if (specificFieldId && fieldId !== specificFieldId) {
          details[fieldId] = {parameters: previousParameters, productQuantities}
          continue
        }

        const mergeCustomizer = (objValue, srcValue) => {
          if (Array.isArray(srcValue)) {
            return srcValue
          }
        }

        const parameters = mergeWith(cloneDeep(previousParameters), change, mergeCustomizer)

        details[fieldId] = {
          parameters,
          productQuantities
        }
      }

      this.$emit('input', details)
    },
    emitProductQuantities (productQuantities, specificFieldId) {
      const details = {}

      for (const fieldId in this.value) {
        const {parameters, productQuantities: previousProductQuantities} = this.value[fieldId]

        if (specificFieldId && fieldId !== specificFieldId) {
          details[fieldId] = {parameters, productQuantities: previousProductQuantities}
          continue
        }

        details[fieldId] = {
          parameters,
          productQuantities
        }
      }

      this.$emit('input', details)
    },
    mergeProductQuantities (...partialProductQuantities) {
      const defaultProductQuantity = {
        amount: null,
        productId: null,
        unit: null
      }

      return Object.assign(defaultProductQuantity, ...partialProductQuantities)
    },
    emitSingleProductQuantity (partialProductQuantity, specificFieldId) {
      const fieldId = specificFieldId || Object.keys(this.value)[0]

      this.emitProductQuantities([
        this.mergeProductQuantities({}, this.value[fieldId].productQuantities[0], partialProductQuantity)
      ], specificFieldId)
    }
  }
}
