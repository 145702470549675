<template lang="html">
  <div v-if="map">
    <CollapsibleSection v-model="showDetails">
      <div slot="header">
        Details
      </div>
      <template v-if="map">
        <LabelAndMessage>
          <template #label>
            <IxRes>Common.SR_Common.Name</IxRes>
          </template>
          <div>{{ map.name }}</div>
        </LabelAndMessage>
        <MapPaletteSelector
          v-if="map && !isLimeApplicationMap" v-model="applicationMapPalette"
          :options="paletteOptions"
          :map="map"
        />
        <Details :application-map="map" />
      </template>
    </CollapsibleSection>
    <CollapsibleSection v-model="showValues">
      <template #header>
        <IxRes>Areas.ApplicationMaps.SR_ApplicationMaps.ApplicationValue</IxRes>
      </template>
      <!-- TODO auswahl des nährstoffs anstatt nur umrechnung bei grunddüngungskarten -->
      <SimpleSwitch v-if="nutrient" v-model="applicationAmounts">
        <template #offLabel>
          <IxRes>Areas.ApplicationMaps.SR_ApplicationMaps.PureNutrientAmount</IxRes>
        </template>
        <IxRes>Areas.ApplicationMaps.SR_ApplicationMaps.ApplicationAmount</IxRes>
      </SimpleSwitch>
      <ApplicationValues
        v-if="map" :application-map="valueAdjustedMap"
        :palette="applicationMapPalette"
        :is-lime-application="isLimeApplicationMap"
      />
      <div class="total-sum">
        <FrsApplicationMapTotalSum :map="valueAdjustedMap" />
      </div>
    </CollapsibleSection>

    <div v-if="map.metaData">
      <div v-if="map.category === 'LimeApplicationMap'">
        <CollapsibleSection>
          <template #header>
            <IxRes>frs.mapManagement.details.limeApplicationMap.metaData</IxRes>
          </template>
          <div>
            <LabelAndMessage>
              <template #label>
                <IxRes>frs.mapManagement.details.limeApplicationMap.metaData.product</IxRes>:
              </template>
              <div>{{ product.name }}</div>
            </LabelAndMessage>
            <LabelAndMessage>
              <template #label>
                <IxRes>frs.mapManagement.details.limeApplicationMap.metaData.caOValue</IxRes>:
              </template>
              <div>{{ map.metaData.caOValue }}{{ $i18n.translateUnit('%') }}</div>
            </LabelAndMessage>
            <LabelAndMessage>
              <template #label>
                <IxRes>frs.mapManagement.details.limeApplicationMap.metaData.mgOValue</IxRes>:
              </template>
              <div>{{ map.metaData.mgOValue }}{{ $i18n.translateUnit('%') }}</div>
            </LabelAndMessage>
            <LabelAndMessage>
              <template #label>
                <IxRes>frs.mapManagement.details.limeApplicationMap.metaData.minAmount</IxRes>:
              </template>
              <div>{{ map.metaData.minAmount }} {{ $i18n.translateUnit('kg/ha') }}</div>
            </LabelAndMessage>
            <LabelAndMessage>
              <template #label>
                <IxRes>frs.mapManagement.details.limeApplicationMap.metaData.maxAmount</IxRes>:
              </template>
              <div>{{ map.metaData.maxAmount }} {{ $i18n.translateUnit('kg/ha') }}</div>
            </LabelAndMessage>
            <LabelAndMessage>
              <template #label>
                <IxRes>frs.mapManagement.details.limeApplicationMap.metaData.meanValue</IxRes>:
              </template>
              <div>{{ map.metaData.meanValue }} {{ $i18n.translateUnit('kg/ha') }}</div>
            </LabelAndMessage>
            <LabelAndMessage>
              <template #label>
                <IxRes>frs.mapManagement.details.limeApplicationMap.metaData.basicActiveIngredients</IxRes>:
              </template>
              <div>{{ map.metaData.basicActiveIngredients }}{{ $i18n.translateUnit('%') }}</div>
            </LabelAndMessage>
            <LabelAndMessage>
              <template #label>
                <IxRes>frs.mapManagement.details.limeApplicationMap.metaData.totalRequirement</IxRes>:
              </template>
              <div>{{ map.metaData.totalRequirement }} {{ $i18n.translateUnit('kg') }}</div>
            </LabelAndMessage>
          </div>
        </CollapsibleSection>
      </div>
    </div>

    <PhBbMapLayer v-if="isLimeApplicationMap" :map="map" auto-focus />
    <FrsApplicationMapLayer v-else :map="map" />

    <Osb v-if="map && $can('feature/application-map-download')" :application-map-ids="[map.id]" />

    <MapPrint
      v-if="!isLimeApplicationMap"
      :map-ids="[map.id]" map-type="applicationMap" />
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {mapFormFields} from '@helpers/vuex'
import {mapToGeoJsonFeatures} from '@helpers/map-color-schemes'

import MapPaletteSelector from '@components/MapPaletteSelector'
import CollapsibleSection from '@components/CollapsibleSection'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import SimpleSwitch from '@components/forms/SimpleSwitch'
import Osb from '@components/data-transfer/Osb'
import FrsApplicationMapTotalSum from '@frs/components/base/FrsApplicationMapTotalSum'
import FrsApplicationMapLayer from '@frs/components/base/FrsApplicationMapLayer'
import PhBbMapLayer from '@frs/components/base/PhBbMapLayer'
import MapPrint from '@frs/components/map-management/MapPrint'

import Details from './widgets/Details'
import ApplicationValues from './widgets/ApplicationValues'

import PermissionMixin from '@mixins/PermissionMixin'

const categoryToNutrient = {
  'BasicFertilization_Phosphorus': 'phosphorus',
  'BasicFertilization_Magnesium': 'magnesium',
  'BasicFertilization_pH': 'lime',
  'BasicFertilization_Potassium': 'potassium'
}

export default {
  components: {
    Details,
    MapPaletteSelector,
    CollapsibleSection,
    ApplicationValues,
    LabelAndMessage,
    SimpleSwitch,
    FrsApplicationMapTotalSum,
    FrsApplicationMapLayer,
    PhBbMapLayer,
    Osb,
    MapPrint
  },
  mixins: [
    PermissionMixin
  ],
  props: {
    map: Object
  },
  data () {
    return {
      palette: 'agrosat',
      showDetails: true,
      showValues: true,
      applicationAmounts: true
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/mapManagement', [
      'ui.applicationMapPalette'
    ]),
    ...mapGetters('masterData', [
      'products'
    ]),
    isLimeApplicationMap () {
      return this.map.category === 'LimeApplicationMap'
    },
    paletteOptions () {
      if (!this.map || this.isLimeApplicationMap) return []

      if (this.map.category.startsWith('BasicFertilization')) {
        return ['relative', 'agrosat', 'lufa']
      } else {
        return ['relative', 'agrosat', 'absolute']
      }
    },
    product () {
      if (!this.map) return null
      return this.products[this.map.productId]
    },
    nutrient () {
      if (!this.map) return null

      return categoryToNutrient[this.map.category]
    },
    nutrientFactor () {
      if (!(this.product && this.nutrient)) return 0

      return this.product[this.nutrient] / 100
    },
    valueAdjustedMap () {
      if (!this.map) return {}

      if (this.applicationAmounts) return this.map

      return {
        ...this.map,
        zones: this.map.zones.map(zone => ({
          ...zone,
          value: this.nutrientFactor * zone.value
        }))
      }
    },
    features () {
      if (!Object.keys(this.valueAdjustedMap).length) return []

      return mapToGeoJsonFeatures(this.valueAdjustedMap, this.applicationMapPalette)
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/map', [
      'focus'
    ])
  },
  watch: {
    paletteOptions (options) {
      if (!options.includes(this.applicationMapPalette)) {
        this.applicationMapPalette = options[0] || null
      }
    }
  },
  created () {
    this.focus(this.features)
  }
}
</script>

<style lang="scss" scoped>
.total-sum {
  width: 50%;
}
</style>
