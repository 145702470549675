<template lang="html">
  <div class="application-map-creation">
    <component :is="component" :key="step" />
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import BasicSettings from './application-maps/BasicSettings'
import YieldPlanning from './application-maps/YieldPlanning'
import HeterogenityAdjustment from './application-maps/HeterogenityAdjustment'

import DisableDefaultMapInteractionsMixin from '@frs/mixins/DisableDefaultMapInteractionsMixin'

export default {
  mixins: [
    DisableDefaultMapInteractionsMixin
  ],
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/applicationMaps', [
      'step'
    ]),
    component () {
      switch (this.step) {
      case 'basicSettings': return BasicSettings
      case 'yieldPlanning': return YieldPlanning
      case 'heterogenityAdjustment': return HeterogenityAdjustment
      default: throw new Error('invalid step')
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/applicationMaps', [
      'back',
      'next',
      'cancel'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization/nutrientMaps', [
      'startNutrientMapDisplay'
    ])
  }
}
</script>

<style lang="scss" scoped>
.application-map-creation {
  display: flex;
  flex: 1;
  flex-direction: column;
}
</style>
