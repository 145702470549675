<template lang="html">
  <BsModal :value="value" @input="$emit('input', $event)">
    <template #title>
      <IxRes>bf.resultImport.title.ImportOrderResult</IxRes>
    </template>
    <div class="selected-order-container">
      <label class="order-label">
        <IxRes>bf.resultImport.SelectedOrder</IxRes>
      </label>
      {{ orderName }}
    </div>
    <CsvDropArea v-if="order" @load="startImportWizard" />
    <template v-else>
      <div class="alert alert-info">
        <IxRes>bf.resultImport.NoExistingOrdersInfo</IxRes>
      </div>
    </template>
  </BsModal>
</template>

<script>
import BsModal from '@components/bootstrap/BsModal'

import CsvDropArea from './CsvDropArea'

import {mapActions} from 'vuex'

export default {
  components: {
    BsModal,
    CsvDropArea
  },
  props: {
    value: Boolean,
    order: Object
  },
  data () {
    return {
      importedOrderName: '',
      epsg: 4326
    }
  },
  computed: {
    orderName () {
      return this.order.name ? this.order.name : ''
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/resultImport', [
      'startImportWizard'
    ])
  }
}
</script>

<style lang="scss" scoped>
.selected-order-container {
  display: flex;
  font-size: 1.2em;

  .order-label {
    margin-right: 4px;
  }
}
</style>
