<template lang="html">
  <div>
    <QuantityInput
      v-model="customSowingDensityWithUnit" name="QuantityInput"
      :unit-options="validUnits"
      required
    >
      <template #label>
        <IxRes>Areas.MaizeSowing.SR_MaizeSowing.CustomSowingDensity_label</IxRes>
      </template>
    </QuantityInput>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import QuantityInput from '@components/forms/QuantityInput'

export default {
  components: {
    QuantityInput
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/maizeSowing', [
      'customSowingDensity',
      'parameters.unit'
    ]),
    validUnits () {
      return ['K/m²', 'K/ha']
    },
    customSowingDensityWithUnit: {
      get () {
        return {amount: this.customSowingDensity, unit: this.unit}
      },
      set ({amount, unit}) {
        this.customSowingDensity = amount
        this.unit = unit
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/maizeSowing', [
      'setCustomSowingDensity'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
