<template lang="html">
  <SimpleTable
    :columns="columns" :data="nutrientStatus.data"
    :highlight="highlight"
    localize-headers
  >
    <IxRes slot="type" slot-scope="{value}">frs.action.planning.fertilization.nutrientSummary.labels.{{ value }}</IxRes>
  </SimpleTable>
</template>

<script>
import SimpleTable from '@components/table/SimpleTable'

import columns from './nutrient-columns'

export default {
  components: {
    SimpleTable
  },
  props: {
    details: Object,
    actions: Array,
    nutrientStatus: {
      type: Object,
      default: () => Object.assign({}, {data: []})
    }
  },
  computed: {
    columns () {
      return {
        type: '',
        ...columns
      }
    },
    highlight () {
      return x => x.type === 'remaining' ? ['remaining'] : []
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep tr.remaining {
  font-weight: bold;
}
</style>
