<template>
  <ShowDetails>
    <template #label>
      <slot />
    </template>

    <span v-for="(resource, i) in part.productQuantities.filter(x => x.amount)" :key="resource.productId">
      <span v-if="i > 0">, </span>{{ products[resource.productId].name }}
    </span>
  </ShowDetails>
</template>

<script>
import ShowDetails from './ShowDetails'

export default {
  components: {
    ShowDetails
  },
  props: {
    part: Object,
    products: Object
  }
}
</script>
