<template>
  <div>
    <component :is="component" :key="component" />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import TelemetryOverview from './TelemetryOverview'
import ImportNewData from './ImportNewData'

export default {
  components: {
    TelemetryOverview,
    ImportNewData
  },
  computed: {
    ...mapState('fieldRecordSystem/telemetry/dataImport', {
      features: state => state.features
    }),
    component () {
      return this.features ? 'ImportNewData' : 'TelemetryOverview'
    }
  }
}
</script>

<style scoped>

</style>
