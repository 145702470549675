<template>
  <div class="headland-container">
    <LabelAndMessage v-for="(headland, index) in headlandInfo" :key="index" class="headland-content">
      <template #label>
        <span class="label-style">
          <IxRes :context="{count: index + 1}">geotrax.details.headers.partialField</IxRes>:
        </span>
      </template>
      <template>
        <LabelAndMessage>
          <template #label>
            <IxRes>Areas.LanePlanning.SR_LanePlanning.DepthInfo</IxRes>:
          </template>
          <template>
            {{ headland.headlandDepth }}
          </template>
        </LabelAndMessage>

        <LabelAndMessage>
          <template #label>
            <IxRes>Areas.LanePlanning.SR_LanePlanning.LineCount</IxRes>:
          </template>
          <template>
            {{ headland.headlandLanesCount }}
          </template>
        </LabelAndMessage>
      </template>
    </LabelAndMessage>
  </div>
</template>

<script>
import IxRes from '@components/IxRes'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    IxRes,
    LabelAndMessage
  },
  props: {
    plan: {
      required: true,
      type: Object
    }
  },
  computed: {
    headlandInfo () {
      return this.plan.partialFields.map(partialField => {
        const headlandDepth = this.headlandDepth(partialField)
        const headlandLanesCount = this.headlandLanesCount(partialField)

        return {
          headlandDepth,
          headlandLanesCount
        }
      })
    }
  },
  methods: {
    headlandDepth (partialField) {
      if (partialField) {
        if (partialField.headland) {
          return partialField.headland.settings.depth + 'm'
        } else {
          return this.$i18n.translate('Areas.LanePlanning.SR_LanePlanning.NotAvailable')
        }
      } else {
        return this.$i18n.translate('Areas.LanePlanning.SR_LanePlanning.NotAvailable')
      }
    },
    headlandLanesCount (partialField) {
      if (partialField.headland) {
        if (partialField.headland.settings.depth) {
          const depth = partialField.headland.settings.depth
          return depth / (this.plan.settings.workingWidth + this.plan.settings.gap / 2)
        } else {
          return this.$i18n.translate('Areas.LanePlanning.SR_LanePlanning.NotAvailable')
        }
      } else {
        return this.$i18n.translate('Areas.LanePlanning.SR_LanePlanning.NotAvailable')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.headland-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .headland-content {
    width: 100%;
    margin-bottom: 8px;

    .label-style {
      color: #6c6c6c;
      text-decoration: underline #6C6C6C;
    }
  }
}
</style>
