<template>
  <div class="component">
    <LabelAndMessage>
      <template #label>
        <IxRes>Common.SR_Common.Name</IxRes>
      </template>
      <div>{{ selectedMetaData.name }}</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>Common.SR_Common.Date</IxRes>
      </template>
      <div>{{ date }}</div>
    </LabelAndMessage>
  </div>
</template>

<script>
import {format} from 'src/js/i18n/conversion'
import {mapFormFields} from '@helpers/vuex'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    LabelAndMessage
  },
  props: {
    details: Object
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/telemetry', [
      'data.selectedMetaData'
    ]),
    date () {
      return this.selectedMetaData.creation ? format(this.selectedMetaData.creation, 'date') : 'kein Datum angegeben'
    }
  }
}
</script>

<style lang="scss" scoped>
.component {
  display: flex;
  flex-direction: column;
}
</style>
