<template lang="html">
  <div class="form-block">
    <div class="form-content">
      <slot />
    </div>
    <div class="calculation">
      <CalculationResult :number="formatNumber" :unit="unit" :sign="sign" />
    </div>
  </div>
</template>

<script>
import CalculationResult from './CalculationResult'

export default {
  components: {
    CalculationResult
  },
  props: {
    number: Number,
    unit: String,
    sign: String
  },
  computed: {
    formatNumber () {
      return this.$i18n.parse(this.$i18n.format(this.number, 'f2'), 'number')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  display: flex;
  justify-content: space-between;
}
.form-content {
  flex: 1;
}
.calculation {
  margin-left: 10px;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
}
</style>
