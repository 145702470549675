<template>
  <div class="year-selection-container">
    <span>
      <IxRes>bf.yieldPlanning.yearSelectionHint</IxRes>
    </span>
    <BsRadioList
      v-model="numberOfYears"
      horizontal
      compact
      :options="options"
    />
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import BsRadioList from '@components/bootstrap/BsRadioList'

export default {
  components: {
    BsRadioList
  },
  props: {
    yearsCount: {
      required: true,
      type: Number
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/basicFertilization/applicationMaps', [
      'years'
    ]),
    numberOfYears: {
      get () {
        return this.years
      },
      set (value) {
        this.setYears(value)
      }
    },
    options () {
      const yearOptions = []
      for (let i = 1; i <= this.yearsCount; i++) {
        yearOptions.push({
          label: `bf.yieldPlanning.label.year_${i}`,
          value: i
        })
      }
      return yearOptions
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/applicationMaps', [
      'setYears'
    ])
  }
}
</script>

<style scoped>
.year-selection-container {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}
</style>
