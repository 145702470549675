<template lang="html">
  <div class="info-window">
    <div class="icon">
      <i v-if="icon" class="fa" :class="[`fa-${icon} fa-2x`]" />
    </div>
    <div class="info-text">
      <slot />
    </div>
    <div v-if="closeOptionVisible" class="icon close-option">
      <i
        v-if="icon" class="fa"
        :class="[`fa-${closeIcon} fa-2x`]"
        @click="$emit('closeInfoWindow')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    closeIcon: String,
    closeOptionVisible: Boolean
  }
}
</script>

<style lang="scss" scoped>
.info-window {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgb(208, 208, 208);
  padding: 7px;
  background-color: #93c47c;
}
.info-text {
  padding-left: 7px;
}
.close-option {
  padding-right: 7px;
  :hover {
    cursor: pointer;
  }
}
</style>
