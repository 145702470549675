<template lang="html">
  <VueSelectize v-model="value" :options="columns" allow-empty>
    <template #label>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ label }}</IxRes>
    </template>
  </VueSelectize>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'

import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    VueSelectize
  },
  props: {
    columnId: String,
    label: String
  },
  computed: {
    ...mapState('fieldRecordSystem/entityImport', {
      columnMapping: state => state.columnMapping
    }),
    ...mapGetters('fieldRecordSystem/entityImport', [
      'columns'
    ]),
    value: {
      get () {
        return this.columnMapping[this.columnId]
      },
      set (column) {
        this.updateColumnMapping({id: this.columnId, column})
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/entityImport', [
      'updateColumnMapping'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
