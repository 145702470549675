<template>
  <div class="mixture-tile" :class="{'current': mixture.current}">
    <h4>{{ mixture.productNames }}</h4>
    <b>ÖVF-kompatibel</b>
    <BoolSwitch :value="compatible" disabled />
    <br><br>
    <FrsMixtureTable :mixture-components="mixture.components" />
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

import BoolSwitch from '@components/forms/BoolSwitch'
import FrsMixtureTable from '@frs/components/base/FrsMixtureTable'

import {isEcoCompatible} from '../../helpers'

export default {
  components: {
    BoolSwitch,
    FrsMixtureTable
  },
  props: {
    mixture: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      collapsed: true
    }
  },
  computed: {
    ...mapGetters('fieldRecordSystem/navigation', [
      'entityLookup'
    ]),
    ...mapState('fieldRecordSystem/navigation', {
      currentOrgUnitId: state => state.location.orgUnitId
    }),
    compatible () {
      return isEcoCompatible(this.mixture.components)
    }
  }
}
</script>

<style lang="scss" scoped>
.mixture-tile {
  border: 1px solid #ddd;
}
.current {
    background-color: #ddd;
  }
</style>
