<template>
  <div>
    <FrsRouterBackLink :target="createParallelRoute('mapManagement')">
      <template #text>
        <IxRes>mm.buttons.backToMapManagement</IxRes>
      </template>
    </FrsRouterBackLink>
    <div class="details-container">
      <h3><IxRes>frs.mapManagement.headers.mapDetails</IxRes></h3>
      <component
        :is="detailsComponent" v-if="map"
        :key="detailsComponent"
        :map="map" :field-id="fieldId"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'

import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'

import ZoneMapDetails from './details/ZoneMapDetails'
import ApplicationMapDetails from './details/ApplicationMapDetails'
import NutrientMapDetails from './details/NutrientMapDetails'
import BiomassMapDetails from './details/BiomassMapDetails'
import SoilMapDetails from './details/SoilMapDetails'
import InterpolationMapDetails from './details/InterpolationMapDetails'

export default {
  components: {
    FrsRouterBackLink,
    ZoneMapDetails,
    ApplicationMapDetails,
    SoilMapDetails,
    NutrientMapDetails,
    BiomassMapDetails,
    InterpolationMapDetails
  },
  mixins: [
    MirrorRouteMixin
  ],
  data () {
    return {
      detailsComponent: null
    }
  },
  computed: {
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    ...mapGetters('fieldRecordSystem/mapManagement', [
      'mapLookup'
    ]),
    map () {
      return this.mapLookup[this.$route.params.mapId]
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'loadApplicationMapDetails',
      'loadNutrientMapDetails',
      'loadZoneMapDetails',
      'loadSoilMapDetails'
    ])
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      const mapId = to.params.mapId
      switch (to.params.mapType) {
      case 'application-maps':
        vm.loadApplicationMapDetails(mapId)
        vm.detailsComponent = 'ApplicationMapDetails'
        break
      case 'nutrient-maps':
        vm.loadNutrientMapDetails(mapId)
        vm.detailsComponent = 'NutrientMapDetails'
        break
      case 'zone-maps':
        vm.loadZoneMapDetails(mapId)
        vm.detailsComponent = 'ZoneMapDetails'
        break
      case 'biomass-maps':
        vm.loadZoneMapDetails(mapId)
        vm.detailsComponent = 'BiomassMapDetails'
        break
      case 'soil-maps':
        vm.loadSoilMapDetails(mapId)
        vm.detailsComponent = 'SoilMapDetails'
        break
      case 'interpolation-maps':
        vm.detailsComponent = 'InterpolationMapDetails'
        break
      default: throw new Error(`invalid map type: '${to.params.mapType}'`)
      }
    })
  }
}
</script>

<style scoped>
.details-container {
  display: flex;
  flex-direction: column;
  padding: 4px;
}
</style>
