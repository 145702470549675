<template lang="html">
  <div class="zone-editing">
    <template v-if="value">
      <TextInput
        v-model="newName" name="newName"
        required class="new-name"
      >
        <template #label>
          <IxRes>frs.mapManagement.labels.modifiedMapName</IxRes>
        </template>
      </TextInput>

      <HelpBox v-if="newName === map.name" type="warning">
        <IxRes>frs.mapManagement.hints.modifiedMapNameIdenticalToOriginalMapName</IxRes>
      </HelpBox>

      <HelpBox v-if="fieldStates.geometriesChanged === 'error'" type="danger">
        <IxRes>frs.mapManagement.hints.zoneGeometriesUnchanged</IxRes>
      </HelpBox>

      <div class="btn-group btn-group-justified">
        <div class="btn-group">
          <IxButton save large @click="save">
            <IxRes>frs.mapManagement.buttons.saveZones</IxRes>
          </IxButton>
        </div>
        <div class="btn-group">
          <IxButton large @click="cancel">
            <IxRes>frs.mapManagement.buttons.cancelZoneEditing</IxRes>
          </IxButton>
        </div>
      </div>

      <ZoneEditingInteraction v-model="modifiedZoneGeometries" :map="map" />
    </template>
    <template v-else>
      <IxButton
        large :disabled="!map.active"
        :title="tooltip"
        @click="start"
      >
        <IxRes>frs.mapManagement.buttons.startZoneEditing</IxRes>
      </IxButton>
    </template>

    <FrsLoadingIndicator :loading="creating" saving />
  </div>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'
import {union, polygon, multiPolygon, simplify, area} from '@turf/turf'
import {mapValues} from 'lodash'

import {notifications} from 'src/js/infrastructure'
import {modelProxy} from '@helpers/vuex'

import IxButton from '@components/IxButton'
import TextInput from '@components/forms/TextInput'
import HelpBox from '@components/help/HelpBox'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import ZoneEditingInteraction from './ZoneEditingInteraction'

import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'
import FormPartMixin from '@components/forms/FormPartMixin'
import ChangeDetectionMixin from '@components/forms/ChangeDetectionMixin'

export default {
  components: {
    IxButton,
    TextInput,
    HelpBox,
    FrsLoadingIndicator,
    ZoneEditingInteraction
  },
  mixins: [
    FormPartMixin,
    ChangeDetectionMixin,
    MirrorRouteMixin
  ],
  props: {
    value: Boolean,
    map: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      newName: null,
      modifiedZoneGeometries: null, // {[zoneId]: geometry}
      creating: false
    }
  },
  computed: {
    modelProxy,
    tooltip () {
      return this.map.active ? null : this.$i18n.translate('frs.mapManagement.hints.canOnlyEditActiveZoneMaps')
    },
    combinedModifiedZoneGeometries () {
      if (!this.modifiedZoneGeometries) return null

      return mapValues(this.modifiedZoneGeometries, (geometries, zoneId) => {
        if (geometries.length === 0) return multiPolygon([]).geometry

        let combined

        if (geometries.length === 1) {
          combined = multiPolygon([geometries[0].coordinates])
        } else {
          const features = geometries.map(geometry => polygon(geometry.coordinates))

          combined = union(...features)
        }

        if (combined.geometry.type === 'Polygon') {
          combined.geometry.type = 'MultiPolygon'
          combined.geometry.coordinates = [combined.geometry.coordinates]
        }

        // 💩 start of workaround for ASK-2653

        const coordinates = []
        const workaroundMaxVertices = 3
        const workaroundTolerance = 0.0001

        for (const polygon of combined.geometry.coordinates) {
          const brokenRings = []
          for (const ring of polygon) {
            // only filter out holes
            if (ring === polygon[0]) continue

            // +1 because rings are closed (last coord same as first coord)
            if ((ring.length <= workaroundMaxVertices + 1) && area({
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [ring]
              }
            }) <= workaroundTolerance) {
              brokenRings.push(ring)
            }
          }

          if (brokenRings.length) {
            console.error(`found ${brokenRings.length} probable broken rings with less than ${workaroundMaxVertices} points and area smaller than ${workaroundTolerance} m² after union, filtering them out`)
            coordinates.push(polygon.filter(x => !brokenRings.includes(x)))
          } else {
            coordinates.push(polygon)
          }
        }

        combined.geometry.coordinates = coordinates

        // 💩 end of workaround for ASK-2653

        simplify(combined.geometry, 0.000001, true, true)

        if (combined.geometry.coordinates.length === 0) return multiPolygon([]).geometry

        return combined.geometry
      })
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'saveModifiedZoneMap'
    ]),
    ...mapMutations('fieldRecordSystem', [
      'setRightView'
    ]),
    start () {
      this.modelProxy = true
    },
    validateGeometryChange () {
      this.$set(this.fieldStates, 'geometriesChanged', this.modifiedZoneGeometries ? 'success' : 'error')
    },
    async save () {
      this.validate()
      this.validateGeometryChange()

      await this.$nextTick()

      if (this.state !== 'success') return

      const newName = this.newName
      const geometriesByZoneId = this.combinedModifiedZoneGeometries

      try {
        this.creating = true
        const mapId = this.$route.params.mapId
        await this.saveModifiedZoneMap({newName, geometriesByZoneId, mapId})
        notifications.success(this.$i18n.translate('frs.mapManagement.zoneEditing.notification.success'))

        this.$router.push({name: this.createParallelRoute('mapManagement'), params: {...this.$route.params}})
      } catch (error) {
        notifications.error(this.$i18n.translate('frs.mapManagement.zoneEditing.notification.error'))
        console.error(error)
      } finally {
        this.creating = false
      }
    },
    cancel () {
      this.reset()
      this.$set(this.fieldStates, 'geometriesChanged', undefined)
      this.modelProxy = false
    }
  },
  watch: {
    value () {
      this.newName = this.map.name
      this.modifiedZoneGeometries = null
    }
  }
}
</script>

<style lang="scss" scoped>
.zone-editing {
  display: flex;
  flex-direction: column;

  margin-bottom: 0.5em;

  .new-name {
    margin-bottom: 0.5em;
  }
}
</style>
