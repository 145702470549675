<template lang="html">
  <canvas ref="canvas" />
</template>

<script>
import {orderBy} from 'lodash'
import Chart from 'chart.js'
import moment from 'moment'

export default {
  props: [
    'ndviData'
  ],
  computed: {
    usedCandidates () {
      return orderBy(this.ndviData.usedCandidates, ['Date'])
    },
    chartInit () {
      let datasets = []
      let usedScenes = {
        label: this.$i18n.translate('frs.zoneMap.labels.usedNdviMeans'),
        borderColor: '#ffa500',
        data: []
      }
      let selectedScene = {
        label: this.$i18n.translate('frs.zoneMap.labels.selectedNdviMean'),
        borderColor: '#0000ff',
        data: [
          {
            t: moment(this.ndviData.recordingDate), // moment(x.Date).format('LL')
            y: this.$i18n.parse(this.$i18n.format(this.ndviData.ndvi, 'f2'), 'number')
          }
        ]
      }

      this.usedCandidates.map(item => ({
        t: moment(item.date), // moment(x.Date).format('LL')
        y: this.$i18n.parse(this.$i18n.format(item.ndviStats.mean, 'f2'), 'number')
      }))

      usedScenes.data = this.usedCandidates.map(item => ({
        t: moment(item.date), // moment(x.Date).format('LL')
        y: this.$i18n.parse(this.$i18n.format(item.ndviStats.mean, 'f2'), 'number')
      }))

      datasets = [selectedScene, usedScenes]

      return {
        type: 'line',
        data: {
          // labels: this.usedCandidates.map(x => moment(x.Date).format('LL')),
          datasets
        },
        options: {
          scales: {
            xAxes: [{
              ticks: {
                maxRotation: 45,
                minRotation: 45
              },
              type: 'time'
            }],
            yAxes: [{
              ticks: {
                suggestedMin: 0,
                suggestedMax: 1
              },
              scaleLabel: {
                display: true,
                labelString: 'MEAN',
                fontStyle: 'bold'
              }
            }]
          }
        }
      }
    }
  },
  watch: {
    ndviData () {
      if (this.chart) {
        this.chart.destroy()
      }
      this.chart = new Chart(this.$refs.canvas, this.chartInit)
    }
  },
  beforeUpdate () {
    this.chart.unbindEvents()
  },
  updated () {
    this.chart.bindEvents()
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.destroy()
    }
  },
  mounted () {
    this.chart = new Chart(this.$refs.canvas, this.chartInit)
  }
}
</script>

<style lang="css" scoped>
</style>
