<template>
  <div class="product-quantity-grid">
    <template v-for="(resource, i) in productQuantities.filter(x => x.amount)">
      <span :key="`amount-${i}`" class="amount" :title="resource.productName">
        {{ $i18n.format(resource.amount) }} {{ $i18n.translateUnit(resource.unit) }} {{ products[resource.productId].name }}
      </span>
      <span :key="`separator-${i}`" class="separator">
        × {{ $i18n.format(area, 'hectare-sparse') }} =
      </span>
      <span :key="`total-${i}`">
        {{ $i18n.format(resource.amount * area / 10000) }} {{ $i18n.translateUnit(resource.unit.replace('/ha', '')) }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    productQuantities: {
      type: Array,
      required: true
    },
    products: {
      type: Object,
      required: true
    },
    area: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.product-quantity-grid {
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-auto-rows: auto;
  gap: 0.25em;

  .amount {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .separator {
    color: #bbbbbb;
  }
}
</style>
