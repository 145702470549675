<template lang="html">
  <div>
    <div v-if="applicationMap.origin !== 'BasicFertilization' && !isLimeApplication" class="planning-type">
      <IxRes>Areas.ApplicationMaps.SR_ApplicationMaps.{{ titlePlanningType }}</IxRes>
    </div>
    <div v-for="zone in orderedZoneDetails" :key="zone.name" class="zone-details">
      <i class="fa fa-square" :style="zone.style" />
      <strong class="item-info">Zone {{ zone.name }}:</strong>
      <div class="item-info">
        {{ applicationMap.origin === 'pHBB' ? zone.value : $i18n.format(zone.value, 'f2') }} {{ applicationMap.unit }}
      </div>
    </div>
  </div>
</template>

<script>
import {orderBy} from 'lodash'
import {mapToColors, colorFunctions} from '@helpers/map-color-schemes'

export default {
  props: {
    applicationMap: Object,
    palette: String,
    isLimeApplication: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    orderedZoneDetails () {
      if (!this.applicationMap || this.applicationMap.zones === undefined) return []

      const withColor = ((zone, index) => ({...zone, style: this.getZoneColor(index)}))

      const indexSorted = this.isLimeApplication
        ? orderBy(this.applicationMap.zones, '[value]').map(withColor)
        : this.applicationMap.zones.map(withColor)

      return this.isLimeApplication ? orderBy(indexSorted, ['value']) : orderBy(indexSorted, ['name'])
    },
    zoneMapColorArray () {
      if (this.isLimeApplication) {
        return []
      }
      return mapToColors(this.applicationMap, this.palette)
    },
    titlePlanningType () {
      return this.applicationMap.category !== 'SimpleApplicationMapByHeterogenity' ? 'ValuePlanned' : 'ValueAutomaticallyPlanned'
    }
  },
  methods: {
    getZoneColor (index) {
      if (this.isLimeApplication) {
        return `color: ${colorFunctions.phbb(0, index, this.applicationMap.zones.length, this.applicationMap.category)};`
      } else {
        return `color: ${this.zoneMapColorArray[index]};`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.planning-type, .zone-details {
  margin: 6px 0;
}
.fa {
  padding-top: 4px;
  margin-left: 0;
}
.zone-details {
  display: flex;
}
.item-info {
  margin-left: 8px;
}
</style>
