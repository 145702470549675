<template>
  <component
    :is="detailComponent"
    v-if="detailComponent"
    :part="part"
    v-bind="detailProps"
  />
</template>

<script>
import TransportDetails from '@frs/components/action/planning/wizard/details/TransportDetails'
import ProtectionDetails from '@frs/components/action/planning/wizard/details/ProtectionDetails'
import SeedingBroadDrillSingleDetails from '@frs/components/action/planning/wizard/details/SeedingBroadDrillSingleDetails'
import SeedingSetPlantDetails from '@frs/components/action/planning/wizard/details/SeedingSetPlantDetails'
import SeedingOtherDetails from '@frs/components/action/planning/wizard/details/SeedingOtherDetails'
import FertilizationDetails from '@frs/components/action/planning/wizard/details/FertilizationDetails'
import IrrigationDetails from '@frs/components/action/planning/wizard/details/IrrigationDetails'
import HarvestOtherDetails from '@frs/components/action/planning/wizard/details/HarvestOtherDetails'
import HarvestGrassLandDetails from '@frs/components/action/planning/wizard/details/HarvestGrassLandDetails'

export default {
  props: {
    part: {
      type: Object,
      required: true
    },
    products: Object
  },
  computed: {
    detailComponent () {
      switch (this.part.type) {
      case 'transport': return TransportDetails
      case 'protection': return this.part.procedure === 'spray' ? ProtectionDetails : null
      case 'fertilization': return FertilizationDetails
      case 'irrigation': return IrrigationDetails
      case 'seeding': {
        switch (this.part.procedure) {
        case 'plant':
        case 'set': return SeedingSetPlantDetails
        case 'other': return SeedingOtherDetails
        default: return SeedingBroadDrillSingleDetails
        }
      }
      case 'harvest': return this.part.procedure === 'gruenlandSchnitt' ? HarvestGrassLandDetails : HarvestOtherDetails
      default: return null
      }
    },
    detailProps () {
      switch (this.part.type) {
      case 'seeding': return {products: this.products}
      default: return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
