<template lang="html">
  <BsModal v-model="modelProxy">
    <template #title>
      <IxRes>frs.mapManagement.zoneMapAdditionalValues.headers.copyAdditionalValuesModal</IxRes>
    </template>

    <HelpBox type="warning">
      <IxRes>frs.mapManagement.zoneMapAdditionalValues.hint.zoneCountWarning</IxRes>
    </HelpBox>

    <FrsZoneMapSelection
      v-model="sourceZoneMapId" name="sourceZoneMapId"
      :field-id="fieldId" hide-label
      allow-empty
      required
    >
      <template #label>
        <IxRes>frs.mapManagement.zoneMapAdditionalValues.labels.sourceZoneMapId</IxRes>
      </template>
    </FrsZoneMapSelection>

    <template #footer>
      <IxButton large cancel @click="modelProxy = false">
        <IxRes>frs.mapManagement.zoneMapAdditionalValues.buttons.cancel</IxRes>
      </IxButton>
      <IxButton large @click="onSave">
        <IxRes>frs.mapManagement.zoneMapAdditionalValues.buttons.copyAdditionalValues</IxRes>
      </IxButton>

      <FrsLoadingIndicator :loading="saving" />
    </template>
  </BsModal>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import {modelProxy, mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import HelpBox from '@components/help/HelpBox'
import IxButton from '@components/IxButton'
import BsModal from '@components/bootstrap/BsModal'
import FrsZoneMapSelection from '@frs/components/base/FrsZoneMapSelection'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    HelpBox,
    IxButton,
    BsModal,
    FrsZoneMapSelection,
    FrsLoadingIndicator
  },
  mixins: [
    FormPartMixin
  ],
  props: {
    value: Boolean
  },
  data () {
    return {
      sourceZoneMapId: null,
      saving: false
    }
  },
  computed: {
    modelProxy,
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    ...mapResources([
      'frs.mapManagement.zoneMapAdditionalValues.notifications'
    ])
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement/zoneMapAdditionalValues', [
      'copyFrom'
    ]),
    async onSave () {
      this.validate()

      await this.$nextTick()

      if (this.state !== 'success') {
        notifications.warning(this.notifications.validationError)
        return
      }

      this.saving = true

      try {
        const sourceZoneMapId = this.sourceZoneMapId
        const targetZoneMapId = this.$route.params.mapId
        await this.copyFrom({sourceZoneMapId, targetZoneMapId})
        notifications.success(this.notifications.copySuccess)
      } catch (error) {
        console.error(error)
        notifications.error(this.notifications.copyError)
      } finally {
        this.saving = false
        this.sourceZoneMapId = null
        this.modelProxy = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
