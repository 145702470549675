<template lang="html">
  <div class="summary">
    <ActionDetails v-bind="{action, machines, persons, products}" />
    <div class="additional-info">
      <h4>
        <IxRes>frs.action.planning.summary.fields</IxRes>
      </h4>
      <span v-for="id in fieldIds" class="field-names" :key="id">
        • {{ fieldData[id].name }} ({{ $i18n.format(fieldAreas[id], 'area') }})
      </span>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {area} from '@turf/turf'
import ActionDetails from '@frs/components/action/ActionDetails'

export default {
  components: {ActionDetails},
  computed: {
    ...mapState('fieldRecordSystem', {
      machines: state => state.machines.data.machines,
      persons: state => state.persons.data.persons,
      fieldData: state => state.data.field
    }),
    ...mapState('fieldRecordSystem/action', {
      combinationParts: state => state.data.planningWizard.combinationParts
    }),
    ...mapState('fieldRecordSystem/action/editBuffer', [
      'fieldIds',
      'timestamps',
      'customGeometries',
      'personnel',
      'machines',
      'notes'
    ]),
    ...mapGetters('fieldRecordSystem/action/editBuffer', [
      'finalActionFeatures'
    ]),
    ...mapGetters('masterData', [
      'products'
    ]),
    fieldAreas() {
      return Object.entries(this.finalActionFeatures).reduce((lookup, [fieldId, feature]) => {
        lookup[fieldId] = area(feature)
        return lookup
      }, {})
    },
    action () {
      const {machines, personnel, notes, timestamps} = this

      return {
        machines,
        personnel,
        notes,
        timestamps,
        area: Object.keys(this.finalActionFeatures).reduce((total, fieldId) => total + this.fieldAreas[fieldId], 0),
        planningParts: this.combinationParts.map(({details, ...rest}) => ({
          ...rest,
          ...Object.values(details)[0]
        })),
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.summary {
  display: flex;

  > * {
    flex: 1;
  }
}
.additional-info {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  padding-left: 0.5em;
  border-left: 1px solid lightgrey;
}
.field-names {
  display: flex;
  flex-direction: column;
}
</style>
