<template lang="html">
  <strong :class="{missing: !value, required}">
    <span v-if="value">{{ value }}</span>
    <i v-else class="fa fa-question-circle" />
  </strong>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

export default {
  props: {
    columnId: String,
    requiredNutrient: String,
    requiredField: Boolean
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', {
      columnMapping: state => state.upload.columnMapping,
      nutrients: state => state.manual.nutrients
    }),
    ...mapGetters('fieldRecordSystem/basicFertilization/resultImport', [
      'validRows'
    ]),
    value () {
      if (this.validRows.length === 0) {
        return null
      }
      return this.validRows[0][this.columnMapping[this.columnId]]
    },
    required () {
      return this.requiredField || this.nutrients.includes(this.requiredNutrient)
    }
  }
}
</script>

<style lang="scss" scoped>
.missing {
  color: gray;

  &.required {
    color: red;
  }
}
</style>
