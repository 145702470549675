<template>
  <div class="seeding-set-plant">
    <div class="input-group">
      <LabelAndMessage>
        <template #label>
          <IxRes>frs.actions.seeding.SeedProduct</IxRes>
        </template>
        <FrsProductPicker
          v-model="productId"
          type="seed"
          :available-product-filter="availableProductFilter"
        />
      </LabelAndMessage>

      <LabelAndMessage v-if="mixtureComponents && mixtureComponents.length > 0">
        <template #label>
          <IxRes>frs.actions.seeding.MixtureComponents</IxRes>
        </template>
        <FrsMixtureTable :mixture-components="mixtureComponents" />
      </LabelAndMessage>

      <NumericInput v-model="rowDistance" unit="cm" hide-description>
        <template #label>
          <IxRes>frs.actions.seeding.RowDistance</IxRes>
        </template>
      </NumericInput>
      <NumericInput
        v-model="rowSeparation" class="row-seperation"
        unit="cm"
        hide-description
      >
        <template #label>
          <IxRes>frs.actions.seeding.RowSeparation</IxRes>
        </template>
      </NumericInput>
      <NumericInput
        ref="inputPlantsPerSqm" v-model="plantsPerSqm"
        :rule="plantsPerSqmRule"
        :addon="'pro qm'" hide-description
        @input="wasComputed.plantsPerSqm = false"
      >
        <template #label>
          <IxRes>frs.actions.seeding.PlantsPerSqm</IxRes>
        </template>
      </NumericInput>
      <NumericInput
        ref="inputTotalPlants" v-model="totalPlants"
        :rule="totalPlantsRule"
        unit="piece" hide-description
        @input="wasComputed.totalPlants = false"
      >
        <template #label>
          <IxRes>frs.actions.seeding.TotalPlants</IxRes>
        </template>
      </NumericInput>
      <NumericInput v-model="packageSize" unit="piece" hide-description>
        <template #label>
          <IxRes>frs.actions.seeding.PackageSize</IxRes>
        </template>
      </NumericInput>
      <div class="number-required-delivery">
        <strong>
          <IxRes>frs.actions.seeding.numberRequiredDelivery</IxRes>
        </strong>
        <span class="total-packages">
          {{ totalPackages }} {{ $i18n.translateUnit('piece') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@components/forms/NumericInput'
import FrsProductPicker from '@frs/components/base/FrsProductPicker'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import FrsMixtureTable from '@frs/components/base/FrsMixtureTable'

import SeedingMixin from './SeedingMixin'

export default {
  components: {
    NumericInput,
    FrsProductPicker,
    LabelAndMessage,
    FrsMixtureTable
  },
  mixins: [
    SeedingMixin
  ]
}
</script>

<style lang="scss" scoped >
.number-required-delivery {
  margin-top: 20px;
}
.total-packages {
  margin-left: 5px;
}
</style>
