<template>
  <LabelAndMessage>
    <template #label>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CropCulture</IxRes>
    </template>
    <div>
      <IxRes>materData.crops.crop.{{ selectedMetaData.cropUsageId }}_name</IxRes>
    </div>
  </LabelAndMessage>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    LabelAndMessage
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/telemetry', [
      'data.selectedMetaData'
    ])
  }
}
</script>

<style>
</style>
