<template>
  <div class="default-yields">
    <FrsHeader>
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DY_DefaultYields</IxRes>
      </template>
      <template #subTitle>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DY_Manage</IxRes>
      </template>
    </FrsHeader>

    <div class="new-yield">
      <FrsCropUsagePicker v-model="cropUsageId" />

      <NumericInput v-model="yieldProxy">
        <template #label>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DY_YieldLabel</IxRes>
        </template>
      </NumericInput>
      <IxButton
        add large
        :disabled="!canAdd"
        @click="add"
      >
        <IxRes>Common.SR_Common.AddLabel</IxRes>
      </IxButton>
    </div>

    <label>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DY_DefaultYields</IxRes>
    </label>
    <div class="yield-table">
      <SimpleTable :columns="columns" :data="defaultYields">
        <template #cropUsageId="{row}">
          <IxRes>masterData.crops.usage.{{ row.cropUsageId }}_name</IxRes>
        </template>
        <template #action="{row}">
          <a href="#" @click.prevent="remove(row.cropUsageId)"><IxRes>Common.SR_Common.Remove</IxRes></a>
        </template>
      </SimpleTable>
    </div>

    <IxButton save large @click="save">
      <IxRes>Common.SR_Common.Save</IxRes>
    </IxButton>

    <FrsLoadingIndicator :requests="['defaultYields.editing.load', 'defaultYields.editing.save']" />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import makeReloadDataMixin from '@frs/mixins/make-reload-data-mixin'

import NumericInput from '@components/forms/NumericInput'
import SimpleTable from '@components/table/SimpleTable'
import FrsHeader from '@frs/components/base/FrsHeader'
import IxButton from '@components/IxButton'
import FrsCropUsagePicker from '@frs/components/base/FrsCropUsagePicker'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    NumericInput,
    SimpleTable,
    FrsHeader,
    IxButton,
    FrsCropUsagePicker,
    FrsLoadingIndicator
  },
  mixins: [
    makeReloadDataMixin([
      state => state.fieldRecordSystem.navigation.location.orgUnitId,
      state => state.fieldRecordSystem.navigation.location.fieldId
    ])
  ],
  computed: {
    ...mapState('fieldRecordSystem/defaultYields', [
      'defaultYields'
    ]),
    ...mapFormFields('fieldRecordSystem/defaultYields', [
      'yield',
      'cropUsageId'
    ]),
    // NOTE necessary since `yield` is a reserved word in JS and template binding is not possible
    yieldProxy: {
      get () {
        return this.yield
      },
      set (value) {
        this.yield = value
      }
    },
    columns () {
      return {
        cropUsageId: {title: 'Nutzungsrichtung', sortable: true},
        yield: {title: 'Ertrag', sortable: true},
        action: {title: 'Aktion', sortable: false}
      }
    },
    canAdd () {
      return this.cropUsageId && this.yield > 0
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/defaultYields', [
      'save',
      'startEditingDefaultYields'
    ]),
    ...mapMutations('fieldRecordSystem/defaultYields', [
      'add',
      'remove'
    ])
  },
  reloadData () {
    this.startEditingDefaultYields()
  }
}
</script>

<style lang="scss" scoped>
.default-yields {
  position: relative;
}

.new-yield {
  width: 70%;
  > * {
    margin-bottom: 10px;
  }
}
.yield-table {
  margin: 20px 0;
}
</style>
