<template lang="html">
  <div>
    <h3>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.FieldGeometryEditingHeader</IxRes>
    </h3>
    <div v-if="showImportSimplificationHint" class="alert alert-info">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ImportSimplificationHint</IxRes>
    </div>

    <FieldEditingSplitTools />

    <hr>

    <div class="save-buttons">
      <IxButton cancel large @click="showDiscardWarning = true" />
      <IxButton save large @click="onSaveClick" />
    </div>

    <!-- NOTE workaround for splitting, without v-if the modal has a null ref internally because futureCultivations are not loaded or the fieldData is unloaded or something like that -->
    <FieldEditingSaveModal v-if="showSaveModal" v-model="showSaveModal" @confirm="save" />

    <ConfirmationModal
      v-model="showDiscardWarning"
      @confirm="stop"
    >
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EditGeometry</IxRes>
      </template>
      <h3>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DismissChanges</IxRes>
      </h3>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ChangesGetLost</IxRes>
    </ConfirmationModal>

    <FrsLoadingIndicator :loading="saving" />
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'
import {mapState, mapActions} from 'vuex'

import {notifications} from 'src/js/infrastructure'
import {toWkt, combineToMultiPolygon} from 'src/js/helpers/openlayers/features'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import ConfirmationModal from '@components/modals/ConfirmationModal'
import IxButton from '@components/IxButton'

import FieldEditingSaveModal from './FieldEditingSaveModal'
import FieldEditingSplitTools from './FieldEditingSplitTools'

export default {
  components: {
    IxButton,
    FrsLoadingIndicator,
    FieldEditingSaveModal,
    FieldEditingSplitTools,
    ConfirmationModal
  },
  data: function () {
    return {
      showSaveModal: false,
      showDiscardWarning: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/map', {
      showImportSimplificationHint: state => state.editing.showImportSimplificationHint
    }),
    ...mapFormFields('fieldRecordSystem/fieldDetails', [
      'ui.saving'
    ])
  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'getCultivationUpdateCandidates',
      'saveFieldGeometry'
    ]),
    ...mapActions('fieldRecordSystem/map', [
      'startExpertMode',
      'stopEditing'
    ]),
    onSaveClick () {
      this.saving = true
      this.getCultivationUpdateCandidates()
        .then(candidatesExist => {
          if (candidatesExist) {
            this.showSaveModal = true
          } else {
            this.saving = true
            return this.save()
          }
        })
        .catch(error => {
          notifications.error(this.$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.AreaSaveError'))
          console.error(error)
        })
        .then(() => {
          this.saving = false
        })
    },
    save (selectedCultivations = []) {
      return this.stopEditing().then(features => {
        const polygons = features.filter(f => f.getGeometry().getType() === 'Polygon')
        const wkt = polygons.length ? toWkt(combineToMultiPolygon(polygons)) : null

        return this.saveFieldGeometry({
          wkt,
          cultivationIds: selectedCultivations.map(x => x.id)
        })
        .then(() => {
          notifications.success(this.$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.SavedFieldGeometry'))
        })
        .catch(error => {
          console.error(error)
          notifications.error(this.$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.AreaSaveError'))
          this.startExpertMode(features)
        })
      })
    },
    stop () {
      this.$nextTick(() => this.stopEditing())
    }
  }
}
</script>

<style lang="scss" scoped>
.save-buttons {
  display: flex;
  justify-content: space-between;
}
</style>
