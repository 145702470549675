<template lang="html">
  <div>
    <div>
      <strong><IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CalculatedNutrientRequirementForNitrogen</IxRes>:</strong> <p>{{ nitrogenRequirementFormatted }} {{ $i18n.translateUnit('kg/ha') }}</p>
    </div>
    <div>
      <strong><IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AmountForField</IxRes>:</strong> <p>{{ nitrogenRequirementForFieldFormatted }} {{ $i18n.translateUnit('kg') }}</p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    nitrogenRequirement: Number,
    nitrogenRequirementForField: Number

  },
  computed: {
    nitrogenRequirementFormatted () {
      return this.nitrogenRequirement === null ? '0' : this.$i18n.format(this.nitrogenRequirement, 'f2')
    },
    nitrogenRequirementForFieldFormatted () {
      return this.nitrogenRequirementForField === null ? '0' : this.$i18n.format(this.nitrogenRequirementForField, 'f2')
    }
  }

}
</script>

<style lang="scss" scoped>
</style>
