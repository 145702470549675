<template lang="html">
  <SimpleTable :data="tableData.filter(x => x.readonly)" :columns="tableColumns">
    <template v-for="(nutrient, i) in nutrients" :slot="nutrient" slot-scope="{row}">
      <ProbingResult :key="nutrient" :geometry-id="row.id" :nutrient="nutrient" />
    </template>
    <template slot="soilSubType" slot-scope="{row}">
      <ProbingResultSoilSubType :result="results[row.id].soilSubType" />
    </template>
  </SimpleTable>
</template>

<script>
import SimpleTable from '@components/table/SimpleTable'

import ProbingResult from './ProbingResult'
import ProbingResultSoilSubType from './ProbingResultSoilSubType'

import ResultsTableMixin from './ResultsTableMixin'

export default {
  components: {
    ProbingResult,
    ProbingResultSoilSubType,
    SimpleTable
  },
  mixins: [
    ResultsTableMixin
  ]
}
</script>

<style lang="scss" scoped>
</style>
