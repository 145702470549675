<template lang="html">
  <section>
    <p>
      <strong>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CombinationOverviewHeader</IxRes>: *
      </strong>
    </p>
    <div v-if="!combinationParts.length" class="alert alert-info">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CombinationOverviewHint</IxRes>
    </div>
    <template v-else>
      <CombinationActionPartCard
        v-for="(part, index) in combinationParts" :key="index"
        v-bind="{part, canUpdateActualArea}" @edit="startEditingCombinationPart({part, index})"
        @remove="removeCombinationPart(part)"
        @update:actual-area="updateActualArea(index, $event)"
      />
    </template>
    <div class="action-list-footer">
      <IxButton large add @click="setWizardStep('type')">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AddCombinationActionPart</IxRes>
      </IxButton>
    </div>
  </section>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'

import IxButton from '@components/IxButton'

import CombinationActionPartCard from '../widgets/CombinationActionPartCard'

export default {
  components: {
    IxButton,
    CombinationActionPartCard
  },
  computed: {
    ...mapState('fieldRecordSystem/action', {
      combinationParts: state => state.data.planningWizard.combinationParts
    }),
    ...mapState('fieldRecordSystem/action/editBuffer', [
      'fieldIds'
    ]),
    canUpdateActualArea () {
      return this.fieldIds.length === 1
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/action', [
      'setWizardStep',
      'removeCombinationPart',
      'updateActualAreaForPart'
    ]),
    ...mapActions('fieldRecordSystem/action', [
      'startEditingCombinationPart'
    ]),
    updateActualArea(index, actualArea) {
      if (!this.canUpdateActualArea) {
        throw new Error('editing actual area with multiple fields selected is currently unsupported')
      }

      const fieldId = this.fieldIds[0]

      this.updateActualAreaForPart({index, fieldId, actualArea})
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  flex: 1;
}
.action-list-footer {margin: 10px 0px;}
</style>
