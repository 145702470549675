<template>
  <div>
    <div class="values">
      <div class="original-values">
        <ZoneInput
          :value="originalZoneValues"
          :zone-map="applicationMap"
          name="zoneValues"
        >
          <template #default="{zone, value}">
            <NumericInput
              class="zone-amount"
              :name="`zone-${zone.id}-original-value`"
              :value="value"
              disabled
              :addon="applicationMap.unit"
            />
          </template>

          <template #label>
            <IxRes>bf.postProcessing.label.calculated</IxRes>
          </template>
        </ZoneInput>
      </div>
      <div class="adjusted-values">
        <ZoneInput
          :value="manuallyAdjustedValues"
          :zone-map="applicationMap"
          name="adjustedZoneValues"
        >
          <template #default="{zone, value}">
            <NumericInput
              class="zone-amount"
              :name="`zone-${zone.id}-adjusted-value`"
              :value="value"
              :addon="applicationMap.unit"
              @input="adjustZoneValueManually({zoneId: zone.id, value: $event})"
            />
          </template>

          <template #label>
            <IxRes>bf.postProcessing.label.adjusted</IxRes>
          </template>
        </ZoneInput>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

import NumericInput from '@components/forms/NumericInput'
import ZoneInput from '@components/forms/ZoneInput'

export default {
  components: {
    NumericInput,
    ZoneInput
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/applicationMapsPreview', {
      applicationMap: state => state.ui.applicationMap,
      isApplicationValue: state => state.ui.isApplicationValue
    }),
    ...mapGetters('fieldRecordSystem/basicFertilization/applicationMapsPreview', [
      'originalRawValues',
      'originalProductValues',
      'manuallyAdjustedValues'
    ]),
    originalZoneValues () {
      return this.isApplicationValue ? this.originalProductValues : this.originalRawValues
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/applicationMapsPreview', [
      'adjustZoneValueManually'
    ])
  }
}
</script>

<style lang="scss" scoped>
.values {
  display: grid;
  grid-template-areas:
    "original adjusted";
  grid-template-columns: 1 / 1;
  gap: 1rem 1rem;
}

.original-values {
  grid-area: original;
}
.adjusted-values {
  grid-area: adjusted
}

</style>
