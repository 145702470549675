<template>
  <div v-if="map">
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.detail.soilMaps.metaData.usedAlgorithm</IxRes>:
      </template>
      <div>{{ map.metaData.typeOfAlgorithm }}</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.detail.soilMaps.metaData.limingInterval</IxRes>:
      </template>
      <div>{{ map.metaData.limingInterval }}</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.detail.soilMaps.metaData.lastYearSoilTesting</IxRes>:
      </template>
      <div>{{ map.metaData.soilTesting }}</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.detail.soilMaps.metaData.isSecondApplication</IxRes>:
      </template>
      <div>
        <IxRes>Common.SR_Common.{{ map.metaData.isSecondApplication ? 'Yes' : 'No' }}</IxRes>
      </div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.detail.soilMaps.metaData.humusMap</IxRes>:
      </template>
      <div>{{ tryGetMapName('humusMap') }}</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.detail.soilMaps.metaData.phMap</IxRes>:
      </template>
      <div>{{ tryGetMapName('phMap') }}</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.detail.soilMaps.metaData.soilGroupMap</IxRes>:
      </template>
      <div>{{ tryGetMapName('soilGroupMap') }}</div>
    </LabelAndMessage>
    <LabelAndMessage v-if="map.metaData.clayMap">
      <template #label>
        <IxRes>frs.mapManagement.detail.soilMaps.metaData.clayMap</IxRes>:
      </template>
      <div>{{ tryGetMapName('clayMap') }}</div>
    </LabelAndMessage>
  </div>
</template>

<script>
import {get} from 'lodash'
import {mapGetters} from 'vuex'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    LabelAndMessage
  },
  props: {
    map: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('fieldRecordSystem/mapManagement', [
      'mapsByFieldIdByType'
    ]),
    soilMaps () {
      if (!this.mapsByFieldIdByType.soilMap) return []
      return Object.values(this.mapsByFieldIdByType.soilMap).flat()
    }
  },
  methods: {
    tryGetMapName (mapType) {
      const currentMap = this.soilMaps.find(x => x.id === this.map.metaData[mapType])
      return get(currentMap, 'name', 'N/A')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
