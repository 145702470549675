<template lang="html">
  <figure class="diagram">
    <!-- previous year -->
    <YearHeader class="previous-year year-header" :year="currentYear - 1" />

    <!-- necessary for grid auto-fill, otherwise the gap leads to layout issues -->
    <div class="previous-year" />

    <CultivationEntry class="previous-year" hide-left-border />
    <CultivationEntry class="previous-year" type="secondary" />

    <VariantDivider class="previous-year" hide-left-border />

    <CultivationEntry class="previous-year" hide-left-border />
    <CultivationEntry class="previous-year" type="primary" />

    <EndMarker class="previous-year" />

    <!-- current year -->
    <YearHeader class="current-year" :year="currentYear" />

    <StartMarker class="current-year" />

    <CultivationEntry class="current-year" type="primary" />
    <CultivationEntry class="current-year" type="secondary" />

    <VariantDivider class="current-year" />

    <CultivationEntry class="current-year" type="catch" />
    <CultivationEntry class="current-year" type="primary" />

    <EndMarker class="current-year" />

    <!-- previous year -->
    <YearHeader class="next-year" :year="currentYear + 1" />

    <StartMarker class="next-year" />

    <CultivationEntry class="next-year" type="primary" />
    <CultivationEntry class="next-year" hide-right-border />

    <VariantDivider class="next-year" hide-right-border />

    <CultivationEntry class="next-year" type="catch" />
    <CultivationEntry class="next-year" hide-right-border />

    <div class="next-year dummy-marker" />
  </figure>
</template>

<script>
import YearHeader from './diagram/YearHeader'
import CultivationEntry from './diagram/CultivationEntry'
import VariantDivider from './diagram/VariantDivider'
import StartMarker from './diagram/StartMarker'
import EndMarker from './diagram/EndMarker'

export default {
  components: {
    YearHeader,
    CultivationEntry,
    VariantDivider,
    StartMarker,
    EndMarker
  },
  props: {
    currentYear: Number
  }
}
</script>

<style lang="scss" scoped>
.diagram {
  display: grid;
  grid-template: repeat(6, auto) / 1em 1fr 1em 1fr 1fr 1em 1fr 1em;
  grid-auto-flow: column; // NOTE to eliminate the need of specifying rows for everything
  margin-top: 1em;

  > * {
    display: flex;
    grid-column-end: span 2;
  }

  > .cultivation-entry {
    grid-column-end: span 1;
  }
}

@mixin startFrom($start) {
  grid-column-start: $start;

  .cultivation-entry + &.cultivation-entry {
    grid-column-start: $start + 1;
  }
}

.previous-year { @include startFrom(1); }
.current-year { @include startFrom(4); }
.next-year { @include startFrom(7); }

.previous-year, .next-year {
  opacity: 0.4;
}

// cell layout overrides

.year-header, .variant-divider {
  &.previous-year {
    justify-content: flex-start;
  }

  &.next-year {
    justify-content: flex-end;
  }
}
</style>
