<template lang="html">
  <div>
    <TextInput v-model="name" name="name" placeholder="Optional">
      <template #label>
        <IxRes>frs.name</IxRes>
      </template>
    </TextInput>

    <LabelAndMessage>
      <template #label>
        <IxRes>Areas.MaizeSowing.SR_MaizeSowing.CulticationSelection_header</IxRes> *
      </template>
      <FrsCultivationPicker
        v-model="cultivationId"
        :field-id="fieldId" name="cultivationId"
        required
      />
    </LabelAndMessage>

    <MapPaletteSelector
      v-if="map && map.zones" v-model="zoneMapPalette"
      :options="paletteOptions" :map="map"
    />

    <FrsZoneMapSelection
      v-model="zoneMapId" name="zoneMapSelection"
      :field-id="fieldId" required
      :filter="zoneMapFilter"
      show-on-map
      hide-label
    >
      <template #label>
        <IxRes :context="{0: fieldName}">Areas.FieldRecordSystem.SR_FieldRecordSystem.ZoneMapFor</IxRes>
      </template>
    </FrsZoneMapSelection>

    <FrsProductPicker
      v-model="productId" name="productPicker"
      type="seed"
      :available-product-filter="availableProductFilter"
      required
    >
      <template #label>
        <IxRes>Areas.MaizeSowing.SR_MaizeSowing.Product_label</IxRes>
      </template>
    </FrsProductPicker>
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'

import {isMaize} from '@helpers/products'
import {mapFormFields} from '@helpers/vuex'

import MapPaletteSelector from '@components/MapPaletteSelector'
import FrsZoneMapSelection from '@frs/components/base/FrsZoneMapSelection'
import FrsCultivationPicker from '@frs/components/base/FrsCultivationPicker'
import FrsProductPicker from '@frs/components/base/FrsProductPicker'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import TextInput from '@components/forms/TextInput'

export default {
  components: {
    FrsZoneMapSelection,
    FrsCultivationPicker,
    FrsProductPicker,
    LabelAndMessage,
    TextInput,
    MapPaletteSelector
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      zoneMapLookup: state => state.data.zoneMap.mapLookup
    }),
    ...mapFormFields('fieldRecordSystem/mapManagement', [
      'ui.zoneMapPalette'
    ]),
    ...mapFormFields('fieldRecordSystem/maizeSowing', [
      'parameters.name',
      'parameters.zoneMapId',
      'parameters.cultivationId',
      'parameters.productId',
      'parameters.unit',
      'customSowingDensity'
    ]),
    map () {
      return this.zoneMapLookup[this.zoneMapId]
    },
    ...mapGetters('fieldRecordSystem', [
      'fieldId',
      'fieldName'
    ]),
    ...mapGetters('fieldRecordSystem/maizeSowing', [
      'automaticSupported'
    ]),
    availableProductFilter () {
      return product => isMaize(product)
    },
    paletteOptions () {
      if (!this.map) return []

      if (this.map.category.startsWith('BasicFertilization')) {
        return ['relative', 'agrosat', 'lufa']
      } else {
        return ['relative', 'agrosat', 'absolute']
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/maizeSowing', [
      'setAutomatic'
    ]),
    zoneMapFilter (zoneMap) {
      return zoneMap.category !== 'BiomassMap'
    }
  },
  watch: {
    unit (newUnit, oldUnit) {
      if (newUnit !== oldUnit) {
        if (newUnit === 'K/m²') {
          this.customSowingDensity = this.customSowingDensity / 10000
        } else {
          this.customSowingDensity = this.customSowingDensity * 10000
        }
      }
    },
    productId () {
      this.setAutomatic(this.automaticSupported)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
