<template>
  <div class="soil-info">
    <span>{{ soilInfo }}</span>
    <div v-if="isValid" class="icon-container" :title="warningHint">
      <FontAwesomeIcon class="icon-warning" :icon="icon.warning" />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {mapResources} from '@helpers/vuex'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {isCalculationPossible} from '@frs/components/basic-fertilization/classification-calculation'

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    result: String
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      soilSubTypes: state => state.data.soilSubTypes
    }),
    ...mapResources([
      '@frs.SR_BaseFertilization'
    ]),
    icon () {
      return {warning: faExclamationTriangle}
    },
    warningHint () {
      return this.SR_BaseFertilization.NotSupportedSoilSubType
    },
    isValid () {
      if (!this.result) return false
      return !isCalculationPossible(this.result)
    },
    soilInfo () {
      if (this.result === null) return ''
      const soilSubTypeInfo = this.soilSubTypes.find(x => x.id === this.result)
      return `${soilSubTypeInfo.id} (${soilSubTypeInfo.soilGroup})`
    }
  }
}
</script>

<style lang="scss" scoped>
.soil-info {
  display: flex;
  align-items: center;

  .icon-container {
    margin-left: 6px;

    .icon-warning {
      font-size: 1.2em;
      padding-top: 4px;
      color: #a85802;

      &:hover {
        cursor: help;
        color: #e59b03;
      }
    }
  }
}
</style>
