<template lang="html">
  <SharedSummary :meta-data="metaData">
    <template #images>
      <ZoneMapDetailsPanel>
        <template #header>
          <IxRes>frs.zoneMaps.details.headers.imageSearchOptions</IxRes>
        </template>

        <div :class="$style.options">
          <IxRes
            :context="{
              buffer: $i18n.format(metaData.imageSearchSummary.options.cloudStatisticsBuffer || 0, 'f2') + 'm',
              cloudTolerance: $i18n.format(metaData.imageSearchSummary.options.cloudCoverageThreshold, 'percent'),
              minNdvi: $i18n.format(minNdvi, 'f2'),
              maxNdvi: $i18n.format(maxNdvi, 'f2'),
              minNdviL2aOnly: $i18n.format(minNdviL2aOnly, 'f2'),
              maxNdviL2aOnly: $i18n.format(maxNdviL2aOnly, 'f2')
            }"
          >
            frs.zoneMaps.details.imageSearchOptionsDescription
          </IxRes>

          <IxRes
            :context="{
              targetPercentage: $i18n.format(metaData.imageSearchSummary.options.targetPercentage, 'percent'),
              stDevWeight: $i18n.format(metaData.imageSearchSummary.options.standardDeviationPrioritizationWeight, 'f2')
            }"
          >
            frs.zoneMaps.details.fallingEdgeOptionsDescription
          </IxRes>
        </div>
      </ZoneMapDetailsPanel>

      <ZoneMapDetailsPanel>
        <template #header>
          <IxRes>frs.zoneMaps.details.headers.imageSearchResults</IxRes>
        </template>

        <VueSelectize
          v-model="selectedCultivationSummary"
          ordered
          :options="metaData.imageSearchSummary.cultivationSummaries"
          :fields="selectizeFields"
        >
          <template #label>
            <IxRes>frs.zoneMap.details.labels.cultivation</IxRes>
          </template>
        </VueSelectize>

        <CultivationSummary :summary="selectedCultivationSummary" />
      </ZoneMapDetailsPanel>
    </template>
    <template #availableimages="{image}">
      <SatelliteImageReferenceWithStats :image="image" :statistics="stDevLookup[`${image.recordingDate}_${image.dataSource}`]" />
    </template>
    <template #stdevmean>
      <span>
        <IxRes
          :context="{
            stDevMean: $i18n.format(stDevMean, 'f2')
          }"
        >
          frs.zoneMap.details.image.standardDeviationMean
        </IxRes>
      </span>
    </template>
  </SharedSummary>
</template>

<script>
import VueSelectize from '@components/VueSelectize'
import ZoneMapDetailsPanel from '@frs/components/map-management/details/ZoneMapDetailsPanel'
import SatelliteImageReferenceWithStats from './components/SatelliteImageReferenceWithStats'
import SharedSummary from './components/SharedSummary'
import CultivationSummary from './components/CultivationSummary'

export default {
  components: {
    VueSelectize,
    ZoneMapDetailsPanel,
    CultivationSummary,
    SharedSummary,
    SatelliteImageReferenceWithStats
  },
  props: {
    metaData: Object
  },
  data () {
    return {
      selectedCultivationSummary: this.metaData.imageSearchSummary.cultivationSummaries[0]
    }
  },
  computed: {
    selectizeFields () {
      return {
        text: x => this.$i18n.translate(`masterData.crops.usage.${x.cultivation.cropUsageId}_name`),
        value: ({cultivation: {cropUsageId, harvestYear}}) => `${harvestYear}-${cropUsageId}`,
        label: x => x.cultivation.harvestYear
      }
    },
    stDevLookup () {
      const keys = Object.keys(this.metaData.imageSearchSummary.cultivationSummaries)
      let dict = {}
      keys.forEach(key => {
        const candidateImages = this.metaData.imageSearchSummary.cultivationSummaries[key].candidateImages
        candidateImages.forEach(image => {
          dict[`${image.recordingDate}_${image.dataSource}`] = image.ndvi.stDev
        })
      })
      return dict
    },
    stDevMean () {
      const usedImages = this.metaData.algorithmSummary.usedImages
      let mean = 0
      usedImages.forEach(image => {
        mean += this.stDevLookup[`${image.recordingDate}_${image.dataSource}`]
      })

      return mean / usedImages.length
    },
    minNdvi () {
      const options = this.metaData.imageSearchSummary.options

      return options.minimumNdviThreshold || 'N/A'
    },
    maxNdvi () {
      const options = this.metaData.imageSearchSummary.options

      return options.maximumNdviThreshold || 'N/A'
    },
    minNdviL2aOnly () {
      const options = this.metaData.imageSearchSummary.options

      return options.minimumNdviThresholdL2AOnly || 'N/A'
    },
    maxNdviL2aOnly () {
      const options = this.metaData.imageSearchSummary.options

      return options.maximumNdviThresholdL2AOnly || 'N/A'
    }
  }
}
</script>

<style lang="scss" module>
.options {
  display: flex;
  flex-direction: column;
}
</style>
