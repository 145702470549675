<template lang="html">
  <div class="panel panel-default">
    <div class="title elements">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ShareContent.ContentShareActionsTitle</IxRes>
    </div>
    <IxButton class="elements" large @click="showShareAllModal=!showShareAllModal">
      <IxRes :context="{content: contentstring}">Areas.FieldRecordSystem.SR_FieldRecordSystem.ShareContent.ShareAll</IxRes>
    </IxButton>
    <ConfirmationModal v-model="showShareAllModal" :title="SR_FieldRecordSystem.ShareContent" @confirm="$emit('all')">
      <IxRes :context="{content: contentstring}">Areas.FieldRecordSystem.SR_FieldRecordSystem.ShareContent.ShareAllQuestion</IxRes>
    </ConfirmationModal>
  </div>
</template>

<script>
import {mapResources} from '@helpers/vuex'

import IxButton from '@components/IxButton'
import ConfirmationModal from '@components/modals/ConfirmationModal'

export default {
  components: {
    IxButton,
    ConfirmationModal
  },
  props: {
    contentType: String
  },
  data () {
    return {
      showShareAllModal: false
    }
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    contentstring () {
      return this.$i18n.translate(`Areas.FieldRecordSystem.SR_FieldRecordSystem.${this.contentType}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.title{
  font-weight: 300;
  line-height: 1.4;
  font-size: 21px;
}
.elements{
  margin: 5px 15px;
}
</style>
