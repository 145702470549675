<template>
  <div>
    <LabelAndMessage>
      <template #label>
        <slot v-if="$slots.label" name="label" />
      </template>

      <div class="input-group">
        <input
          type="text"
          class="form-control"
          :value="mixtureName" name="mixture-name"
          disabled
        >
        <div class="input-group-btn">
          <button type="button" class="btn btn-primary" @click.prevent="showModal = true">
            <i class="fa fa-pencil" />
          </button>
        </div>
      </div>
      <BsModal v-model="showModal">
        <MixtureSelectionGrid
          v-model="selected"
          :available-mixtures="availableMixtures"
          @input="showModal = false"
        />
        <template #title>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.MixtureSelectionTitle</IxRes>
        </template>
        <div slot="footer">
          <IxButton cancel large @click="showModal = false" />
        </div>
      </BsModal>
    </LabelAndMessage>
    <IxButton @click="removeMixture">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.RemoveMixture</IxRes>
    </IxButton>
  </div>
</template>

<script>
import BsModal from '@components/bootstrap/BsModal'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import IxButton from '@components/IxButton'

import MixtureSelectionGrid from './MixtureSelectionGrid'

import {toMixtureName} from '../../helpers'

export default {
  components: {
    BsModal,
    IxButton,
    LabelAndMessage,
    MixtureSelectionGrid
  },
  props: {
    value: Array,
    availableMixtures: Array
  },
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    mixtureName () {
      return this.value && this.value.length > 0 ? toMixtureName(this.value) : ''
    },
    selected: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  },
  methods: {
    removeMixture () {
      this.$emit('remove')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
