import {mapState} from 'vuex'

import {wktFormat} from '@helpers/openlayers/features'
import {fieldBorderCultivationSelected} from '@frs/map-styles/index'

// TODO make component to replace `IxVectorLayer` usage (similar to `FrsCurrentFieldBorderLayer`) to prevent scope pollution in `CultivationPlanning`
export default {
  computed: {
    ...mapState('fieldRecordSystem', {
      fields: state => state.data.field
    }),
    ...mapState('fieldRecordSystem/navigation', {
      fieldId: state => state.location.fieldId
    }),
    ...mapState('fieldRecordSystem/cultivationPlanning/editBuffer', {
      fieldIds: state => state.fieldIds
    }),
    fieldWkts () {
      // field level, always single field
      // TODO find out if this is even necessary, or if the editBuffer has a 1-element array in `fieldIds` anyways and no special casing is required
      if (this.fieldId) {
        return [this.fields[this.fieldId].wkt]
      } else {
        return this.fieldIds.length
          ? this.fieldIds
            .map(fieldId => {
              const wkt = this.fields[fieldId].wkt
              return wkt || null
            })
            .filter(x => x)
          : []
      }
    },
    borderSegments () {
      const wkts = this.fieldWkts ? this.fieldWkts.filter(wkt => wkt !== null) : null
      return wkts
        ? wkts.map(fieldWkt => {
          const feature = wktFormat.readFeature(fieldWkt)
          feature.getGeometry().transform('EPSG:4326', 'EPSG:900913')
          return feature
        })
        : []
    },
    style () {
      return fieldBorderCultivationSelected
    }
  }
}
