<template lang="html">
  <div>
    <CollapsibleSection v-model="climateDataSection">
      <template #header>
        <IxRes>frs.weatherDetails.headers.climateData</IxRes>
      </template>
      <VueSelectize
        v-model="selectedClimateCategory"
        :options="categories"
        :fields="fields"
      >
        <template #label>
          <IxRes>frs.weatherDetails.labels.category</IxRes>
        </template>
      </VueSelectize>
      <div v-if="selectedClimateCategory && monthlyClimateData">
        <WeatherLineChart :climate-data="monthlyClimateData" :category="selectedClimateCategory" />
      </div>
    </CollapsibleSection>
    <CollapsibleSection v-model="climateForcastSection">
      <template #header>
        <IxRes>frs.weatherDetails.headers.weatherForcast</IxRes>
      </template>
      <IxCollapsePanel>
        <template #title>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Overview</IxRes>
        </template>
        <template #expand-text>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ExpandOverview</IxRes>
        </template>
        <template #collapse-text>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CollapseOverview</IxRes>
        </template>
        <SimpleTable :columns="overviewColumns" :data="transformedOverviewData">
          <span slot="label" slot-scope="{value}"><strong>{{ value }}</strong></span>
        </SimpleTable>
      </IxCollapsePanel>
      <IxCollapsePanel>
        <template #title>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ReviewPrecipitation</IxRes>
        </template>
        <template #expand-text>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ExpandReview</IxRes>
        </template>
        <template #collapse-text>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CollapseReview</IxRes>
        </template>
        <SimpleTable :columns="reviewColumns" :data="transformedReviewData">
          <span slot="label" slot-scope="{value}"><strong>{{ value }}</strong></span>
        </SimpleTable>
      </IxCollapsePanel>
      <IxCollapsePanel>
        <template #title>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Precipitation</IxRes>
        </template>
        <template #expand-text>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ExpandPrecipitation</IxRes>
        </template>
        <template #collapse-text>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CollapsePrecipitation</IxRes>
        </template>
        <template slot="additional-info">
          <simple-switch v-model="showHourlyPrecipitation">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ShowHourlyValues</IxRes>
          </simple-switch>
        </template>
        <SimpleTable v-if="showHourlyPrecipitation" :columns="precipitationHourlyForecastColumns" :data="precipitationForecastHourly">
          <span slot="label" slot-scope="{value}"><strong>{{ value }}</strong></span>
        </SimpleTable>
        <SimpleTable :columns="precipitationColumns" :data="transformedPrecipitationData">
          <span slot="label" slot-scope="{value}"><strong>{{ value }}</strong></span>
        </SimpleTable>
      </IxCollapsePanel>
      <IxCollapsePanel>
        <template #title>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.TemperatureCurve</IxRes>
        </template>
        <template #expand-text>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ExpandTemperature</IxRes>
        </template>
        <template #collapse-text>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CollapseTemperature</IxRes>
        </template>
        <template slot="additional-info">
          <simple-switch v-model="showHourlyTemperature">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ShowHourlyValues</IxRes>
          </simple-switch>
        </template>
        <SimpleTable v-if="showHourlyTemperature" :columns="temperatureHourlyForecastColumns" :data="forecastDataHourly">
          <span slot="label" slot-scope="{value}"><strong>{{ value }}</strong></span>
        </SimpleTable>
        <SimpleTable v-else :columns="temperatureColumns" :data="transformedTemperatureData">
          <span slot="label" slot-scope="{value}"><strong>{{ value }}</strong></span>
        </SimpleTable>
      </IxCollapsePanel>
    </CollapsibleSection>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import moment from 'moment'
import {sum} from 'lodash'

import {mapResources, mapFormFields} from '@helpers/vuex'

import ReloadDataOnFieldChange from '@frs/mixins/ReloadDataOnFieldChange'

import WeatherLineChart from './WeatherLineChart'
import CollapsibleSection from '@components/CollapsibleSection'
import VueSelectize from '@components/VueSelectize'
import IxCollapsePanel from '@components/ix/IxCollapsePanel'
import SimpleSwitch from '@components/forms/SimpleSwitch.vue'
import SimpleTable from '@components/table/SimpleTable'

window.moment = moment

export default {
  components: {
    IxCollapsePanel,
    SimpleSwitch,
    SimpleTable,
    CollapsibleSection,
    VueSelectize,
    WeatherLineChart
  },
  mixins: [
    ReloadDataOnFieldChange
  ],
  data () {
    return {
      showHourlyTemperature: false,
      showHourlyPrecipitation: false,
      climateDataSection: true,
      climateForcastSection: false
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/fieldDetails', [
      'ui.selectedClimateCategory'
    ]),
    ...mapResources([
      '@frs.SR_FieldRecordSystem',
      'Common.SR_Common'
    ]),
    ...mapState('fieldRecordSystem', {
      fieldDataLookup: state => state.data.field
    }),
    ...mapState('fieldRecordSystem/navigation', {
      fieldId: state => state.location.fieldId
    }),
    ...mapState('fieldRecordSystem/fieldDetails', {
      climateCategories: state => state.data.climateCategories
    }),
    categories () {
      return Object.keys(this.climateCategories)
    },
    fields () {
      return {
        text: category => this.$i18n.translate(`frs.weatherDetails.climateCategory.${category}`),
        value: category => category
      }
    },
    monthlyClimateData () {
      return this.fieldDataLookup[this.fieldId].monthlyClimateData
    },
    normalOverViewColumns () {
      return {
        date: this.SR_Common.Date,
        minTemp: this.SR_FieldRecordSystem.MinimumTemperature,
        maxTemp: this.SR_FieldRecordSystem.MaximumTemperature,
        precipitationRisc: this.SR_FieldRecordSystem.PrecipitationRisc,
        precipitation: this.SR_FieldRecordSystem.PrecipitationWithUnit,
        sunshineDuration: this.SR_FieldRecordSystem.SunshineDuration
      }
    },
    normalReviewColumns () {
      return {
        date: this.SR_Common.Date,
        precipitation: this.SR_FieldRecordSystem.PrecipitationWithUnit
      }
    },
    normalPrecipitationColumns () {
      return {
        date: this.SR_Common.Date,
        precipitation: this.SR_FieldRecordSystem.PrecipitationWithUnit
      }
    },
    normalTemperatureColumns () {
      return {
        date: this.SR_Common.Date,
        maxTemp: this.SR_FieldRecordSystem.MaximumTemperature,
        minTemp: this.SR_FieldRecordSystem.MinimumCelsiusValue
      }
    },
    normalSunshineColumns () {
      return {
        date: this.SR_Common.Date,
        duration: this.SR_FieldRecordSystem.Duration
      }
    },
    climate () {
      return this.fieldDataLookup[this.fieldId].climate
    },
    climateDataReview () {
      return this.getClimateForDays([-3, -2, -1])
    },
    climateDataForecast () {
      return this.getClimateForDays([0, 1, 2, 3])
    },
    // Variant start
    forecastDays () {
      if (!this.climateDataForecast) {
        return []
      }
      return Object.keys(this.climateDataForecast)
    },
    forecastDataHourly () {
      return new Array(24).fill(null).map((unused, hour) => {
        return this.forecastDays.map(day => {
          return this.climateDataForecast[day] ? this.climateDataForecast[day].find(x => moment(x.timestamp).hour() === hour) : null
        })
      })
    },
    totalPrecipitationForecastLabeled () {
      return {label: '', data: this.totalPrecipitationForecast}
    },
    totalPrecipitationForecast () {
      return this.forecastDays.map((day, i) => ({
        precipitation: this.forecastDataHourly.reduce((sum, row) => sum + (row[i] ? row[i].precipitation : 0), 0)
      }))
    },
    precipitationForecastHourly () {
      return this.forecastDataHourly.concat(new Array(1).fill(this.totalPrecipitationForecast))
    },
    // Variant end
    currentDateKey () {
      const date = moment()
      return {
        currentDate: `${date.year()}-0${date.month() + 1}-${date.date()}`,
        currentHour: date.hour()
      }
    },
    overviewData () {
      if (!this.climateDataForecast) {
        return null
      }
      return Object.keys(this.climateDataForecast).map(x => {
        return {
          date: this.getDate(this.climateDataForecast[x] ? this.climateDataForecast[x][0].timestamp : null),
          minTemp: this.climateDataForecast[x] ? Math.min(...this.climateDataForecast[x].map(d => d.temperature)) : 0,
          maxTemp: this.climateDataForecast[x] ? Math.max(...this.climateDataForecast[x].map(d => d.temperature)) : 0,
          precipitationRisc: 0,
          precipitation: this.climateDataForecast[x] ? sum(this.climateDataForecast[x].map(d => d.precipitation)) : 0,
          sunshineDuration: 0
        }
      })
    },
    transformedOverviewData () {
      return this.transformData(this.normalOverViewColumns, this.overviewData)
    },
    overviewColumns () {
      return this.createColumns(this.normalOverViewColumns, this.overviewData)
    },
    reviewData () {
      if (!this.climateDataReview) {
        return null
      }
      const data = Object.keys(this.climateDataReview).map(x => {
        return {
          date: this.getDate(this.climateDataReview[x] ? this.climateDataReview[x][0].timestamp : null),
          precipitation: sum(this.climateDataReview[x] ? this.climateDataReview[x].map(d => d.precipitation) : 0)
        }
      })

      const summedData = {
        date: this.SR_FieldRecordSystem.TotalLastThreeDays,
        precipitation: sum(data.map(x => x.precipitation))
      }

      data.push(summedData)

      return data
    },
    reviewColumns () {
      return this.createColumns(this.normalReviewColumns, this.reviewData)
    },
    transformedReviewData () {
      return this.transformData(this.normalReviewColumns, this.reviewData)
    },
    precipitationData () {
      if (!this.climateDataForecast) {
        return null
      }
      return Object.keys(this.climateDataForecast).map(x => {
        return {
          date: this.getDate(this.climateDataForecast[x] ? this.climateDataForecast[x][0].timestamp : null),
          precipitation: sum(this.climateDataForecast[x] ? this.climateDataForecast[x].map(d => d.precipitation) : 0)
        }
      })
    },
    precipitationColumns () {
      return this.createColumns(this.normalPrecipitationColumns, this.precipitationData)
    },
    transformedPrecipitationData () {
      return this.transformData(this.normalPrecipitationColumns, this.precipitationData)
    },
    temperatureData () {
      if (!this.climateDataForecast) {
        return null
      }
      return Object.keys(this.climateDataForecast).map(x => {
        return {
          date: this.getDate(this.climateDataForecast[x] ? this.climateDataForecast[x][0].timestamp : null),
          minTemp: this.climateDataForecast[x] ? Math.min(...this.climateDataForecast[x].map(d => d.temperature)) : 0,
          maxTemp: this.climateDataForecast[x] ? Math.max(...this.climateDataForecast[x].map(d => d.temperature)) : 0
        }
      })
    },
    temperatureColumns () {
      return this.createColumns(this.normalTemperatureColumns, this.temperatureData)
    },
    transformedTemperatureData () {
      return this.transformData(this.normalTemperatureColumns, this.temperatureData)
    },
    sunshineData () {
      if (!this.climateDataForecast) {
        return null
      }
      return Object.keys(this.climateDataForecast).map(x => {
        return {
          date: this.getDate(this.climateDataForecast[x] ? this.climateDataForecast[x][0].timestamp : null),
          duration: 0
        }
      })
    },
    sunshineColumns () {
      return this.createColumns(this.normalSunshineColumns, this.sunshineData)
    },
    transformedSunshineData () {
      return this.transformData(this.normalSunshineColumns, this.sunshineData)
    },
    // Variant start
    temperatureHourlyForecastColumns () {
      return this.hourlyColumns('temperature')
    },
    precipitationHourlyForecastColumns () {
      return this.hourlyColumns('precipitation', this.SR_Common.Total)
    },
    labeledAggregateColumns () {
      // data rows need to have form {label: '', data: [day0aggregate, day1aggregate, day2aggregate, day3aggregate]}
      const columns = {
        label: {
          title: this.SR_Common.Date,
          value: x => x.label
        }
      }

      this.forecastDays.forEach((day, i) => {
        columns[`day${i}`] = {
          title: this.forecastDays[i],
          value: x => x.data[i],
          type: 'number'
        }
      })

      return columns
    }
    // Variant end

  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'reloadDefaultFieldData',
      'reloadWeatherData',
      'getClimateData'
    ]),
    // Variant start
    hourlyColumns (property, aggregateTitle) {
      // data rows need to have form [day0object, day1object, day2object, day3object]
      const columns = {
        hour: {
          title: this.SR_FieldRecordSystem.DateAndTime,
          value: (x, i) => i < 24 ? `${i} ${this.SR_FieldRecordSystem.Clock}` : aggregateTitle
        }
      }

      this.forecastDays.forEach((day, i) => {
        columns[`day${i}`] = {
          title: this.getDate(day),
          value: x => x[i] ? x[i][property] : null,
          type: 'number'
        }
      })

      columns.hour2 = columns.hour

      return columns
    },
    // Variant end
    getClimateForDays (days) {
      if (!this.climate) return null

      const results = {}
      days.forEach(day => {
        const date = moment().add(day, 'days')
        const currentDate = `${date.year()}-0${date.month() + 1}-${date.date()}`
        results[currentDate] = this.climate[currentDate]
      })

      return results
    },
    getClimatePerHourForDays (days) {
      if (!this.climate) return null

      const results = {}
      days.forEach(day => {
        const date = moment().add(day, 'days')
        const currentDate = `${date.year()}-0${date.month() + 1}-${date.date()}`
        results[currentDate] = this.climate[currentDate]
      })

      return results
    },
    getDate (timestamp) {
      const asMoment = moment(timestamp)

      const today = moment().format('YYYY-MM-DD')
      const date = asMoment.format('YYYY-MM-DD')
      const isToday = today === date

      return `${isToday ? this.SR_Common.Today : asMoment.format('L')} (${asMoment.format('dddd')})`
    },
    createColumns (normalColumns, tableData) {
      if (!tableData) {
        return {}
      }

      const prop = Object.keys(normalColumns)[0]

      const cols = {
        label: normalColumns[prop]
      }

      for (let i = 0; i < tableData.length; i++) {
        const value = tableData[i][prop]
        const id = `columnId_${i}`
        cols[id] = value
      }

      return cols
    },
    transformData (normalColumns, tableData) {
      if (!tableData) {
        return []
      }
      return Object.keys(normalColumns).slice(1).map(prop => {
        const data = {}
        for (let i = 0; i < tableData.length; i++) {
          const id = `columnId_${i}`
          data[id] = tableData[i][prop]
        }
        data.label = normalColumns[prop]
        return data
      })
    }
  },
  reloadData () {
    this.reloadDefaultFieldData()
    this.reloadWeatherData()
    this.getClimateData()
  }
}
</script>

<style lang="css">
</style>
