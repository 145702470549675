<template lang="html">
  <!-- TODO merge details and action into single action prop and @input event -->
  <component
    :is="component" :key="component"
    v-bind="{action, name}"
    v-model="details"
  />
</template>

<script>
import {mapState} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import Transport from './parameters/Transport'
import Irrigation from './parameters/Irrigation'
import ProtectionSpray from './parameters/protection/ProtectionSpray'
import SeedingBroadDrillSingle from './parameters/seeding/SeedingBroadDrillSingle'
import SeedingSetPlant from './parameters/seeding/SeedingSetPlant'
import SeedingOther from './parameters/seeding/SeedingOther'
import FertilizationType from './parameters/fertilization/FertilizationType'
import FertilizationCalculation from './parameters/fertilization/FertilizationCalculation'
import InvalidStep from './InvalidStep'
import HarvestOtherForm from './parameters/harvest/HarvestOtherForm'
import HarvestGrassLandForm from './parameters/harvest/HarvestGrassLandForm'
import HarvestQuality from './parameters/harvest/HarvestQuality'

export default {
  components: {
    Transport,
    Irrigation,
    ProtectionSpray,
    SeedingBroadDrillSingle,
    SeedingSetPlant,
    SeedingOther,
    FertilizationType,
    FertilizationCalculation,
    InvalidStep,
    HarvestOtherForm,
    HarvestGrassLandForm,
    HarvestQuality
  },
  props: {
    name: String
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/action/editBuffer', [
      'details'
    ]),
    ...mapState('fieldRecordSystem/action', {
      step: state => state.ui.planningWizard.parameterStep,
      action: state => state.editBuffer
    }),
    fieldSize () {
      return this.fieldId ? this.fieldData[this.fieldId].area.cultivated : 0
    },
    component () {
      switch (this.step) {
      case 'transport': return 'Transport'
      case 'irrigation': return 'Irrigation'
      case 'protectionSpray': return 'ProtectionSpray'
      case 'seedingBroadDrillSingle': return 'SeedingBroadDrillSingle'
      case 'seedingSetPlant': return 'SeedingSetPlant'
      case 'seedingOther': return 'SeedingOther'
      case 'fertilizationType': return 'FertilizationType'
      case 'fertilizationAmount': return 'FertilizationCalculation'
      case 'harvestQuality': return 'HarvestQuality'
      case 'harvestOther': return 'HarvestOtherForm'
      case 'harvestGrassLand': return 'HarvestGrassLandForm'
      default: return 'InvalidStep'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
