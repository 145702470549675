<template>
  <div>
    <ShowDetails>
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_Action.FertilizerType</IxRes>:
      </template>

      <IxRes>{{ liquid }}</IxRes>
    </ShowDetails>

    <ShowDetails v-if="part.parameters.applicationMethod">
      <template #label>
        <IxRes>actions.details.labels.applicationMethod</IxRes>:
      </template>

      <IxRes>{{ applicationMethod }}</IxRes>
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_Action.UseOwnProduct</IxRes>:
      </template>

      <IxRes>{{ useCustomProduct }}</IxRes>
    </ShowDetails>
  </div>
</template>

<script>
import ShowDetails from './ShowDetails'

export default {
  components: {
    ShowDetails
  },
  props: {
    part: Object
  },
  computed: {
    liquid () {
      return this.part.parameters.isLiquid ? 'frs.action.fertilizerType.liquid' : 'frs.action.fertilizerType.solid'
    },
    useCustomProduct () {
      return this.part.parameters.useCustomProduct ? 'frs.action.useCustomProducts.yes' : 'frs.action.useCustomProducts.no'
    },
    applicationMethod () {
      return this.part.parameters.applicationMethod ? `actions.fertilizer.applicationMethod_${this.part.parameters.applicationMethod}` : ''
    }
  }
}
</script>

<style scoped>
</style>
