<template lang="html">
  <div class="period">
    <LabelAndMessage :required="required" :message="message">
      <template #label>
        <slot name="label">
          <span v-if="!$slots.label && label">{{ label }}</span>
        </slot>
      </template>
      <div class="date-range">
        <VueDateTimePicker
          :required="required" :value="value.start"
          :max="max" :format="computedFormat"
          :iso="iso" :readonly="readonly"
          :name="name ? `${name}.start` : legacyName.start"
          @input="emitStart"
        >
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Start</IxRes>
          </template>
        </VueDateTimePicker>

        <VueDateTimePicker
          :required="required" :value="value.end"
          :min="min" :format="computedFormat"
          :iso="iso" :readonly="readonly"
          :name="name ? `${name}.end` : legacyName.start"
          @input="emitEnd"
        >
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.End</IxRes>
          </template>
        </VueDateTimePicker>
      </div>
    </LabelAndMessage>
  </div>
</template>

<script>
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

let counter = 1
export default {
  components: {
    VueDateTimePicker,
    LabelAndMessage
  },
  props: {
    name: String,
    value: {},
    iso: Boolean,
    readonly: Boolean,
    label: String,
    required: Boolean,
    message: String,
    enableTime: Boolean,
    format: String,
    limits: Object // {start, end} as constraints for value (end as min for value.end, start as max for value.start) in addition to natural constraints resulting from the value itself
  },
  data () {
    return {
      idNumber: `${counter++}`
    }
  },
  computed: {
    legacyName () {
      return {
        start: `RangeStart${this.idNumber}`,
        end: `RangeEnd${this.idNumber}`
      }
    },
    computedFormat () {
      let format = this.format ? this.format : 'L'

      if (this.enableTime) {
        format = format.concat(' LT')
      }

      return format
    },
    min () {
      return this.limits && (!this.value.start || this.limits.end > this.value.start)
        ? this.limits.end
        : this.value.start
    },
    max () {
      return this.limits && (!this.value.end || this.limits.start < this.value.end)
        ? this.limits.start
        : this.value.end
    }
  },
  methods: {
    emitStart (start) {
      this.$emit('input', {start, end: this.value.end})
    },
    emitEnd (end) {
      this.$emit('input', {start: this.value.start, end})
    }
  }
}
</script>

<style lang="scss" scoped>
.period {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}
.date-range {
  padding-left: 30px;
  margin-top: 5px;
}
</style>
