<template>
  <VueSelectize
    v-model="modelProxy"
    :name="name"
    allow-empty
    :fields="algorithmFieldConfig"
    :options="productAlgorithmsArray"
    :required="required"
  >
    <template #label>
      <IxRes>frs.applicationMaps.labels.algorithmSelection</IxRes>
    </template>
  </VueSelectize>
</template>

<script>

import {mapState} from 'vuex'
import {modelProxy} from '@helpers/vuex'

import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    VueSelectize
  },
  props: {
    value: String,
    name: String,
    filter: Function,
    required: Boolean
  },
  computed: {
    modelProxy,
    ...mapState('masterData', [
      'productAlgorithms'
    ]),
    ...mapState('i18n', {
      algorithmTranslation: state => state.translations['masterData.product_algorithm']
    }),
    algorithmFieldConfig () {
      return {
        text: (value) => {
          return this.algorithmTranslation[value]
        }
      }
    },
    productAlgorithmsArray () {
      if (this.filter) {
        return Object.values(this.productAlgorithms).filter(this.filter).map(x => x.name)
      }
      return Object.values(this.productAlgorithms).map(x => x.name)
    }
  }
}
</script>

<style>
</style>
