<template>
  <div class="product-quantity-row">
    <ProductPicker
      v-model="productId" :name="`productId-${index}`"
      type="protection"
      v-bind="{availableProductFilter, entityLookup, products, recommendedIds, previouslyUsedProducts}"
    />
    <QuantityInput
      v-model="amountWithUnit" class="input"
      :unit-options="availableUnits"
      :rule="x => x >= 0"
      :required="!!productId"
    />
    <IxButton
      v-if="canDelete"
      large icon="trash"
      class="btn-danger delete"
      :title="$i18n.translate('Common.SR_Common.Remove')"
      @click="$emit('delete')"
    />
  </div>
</template>

<script>
import ProductPicker from '@components/product-picker/ProductPicker'
import QuantityInput from '@components/forms/QuantityInput'
import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton,
    ProductPicker,
    QuantityInput
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    index: Number,
    canDelete: Boolean,
    // forwarded to ProductPicker
    availableProductFilter: {
      type: Function,
      default: () => true
    },
    entityLookup: Object,
    products: Object,
    recommendedIds: {
      type: Array,
      default: () => []
    },
    previouslyUsedProducts: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    productId: {
      get () {
        const {productId} = this.value
        return productId
      },
      set (value) {
        this.$emit('input', Object.assign({}, this.value, {productId: value}))
      }
    },
    amountWithUnit: {
      get () {
        const {amount, unit} = this.value
        return {amount, unit}
      },
      set ({amount, unit}) {
        this.$emit('input', Object.assign({}, this.value, {amount, unit}))
      }
    },
    availableUnits () {
      // water is  always in l/ha only
      return this.value.productId && this.value.productId === '462e02db-a1ec-475f-b6a5-108ebd5f4b0e' ? ['l/ha'] : ['l/ha', 'kg/ha']
    }
  },
  watch: {
    productId (id) {
      if (this.value.unit) return

      const {amount} = this.value
      this.amountWithUnit = {amount, unit: this.products[id].isLiquid ? 'l/ha' : 'kg/ha'}
    }
  }
}
</script>

<style lang="scss" scoped>
.product-quantity-row {
  display: grid;
  grid-template-columns: 60% 60% 8%;
  margin-bottom: 5px;

  .input {
    margin: 0 5px;
  }

  .delete {
    height: 34px;
  }
}
</style>
