<template lang="html">
  <div>
    <SubsidyPlan v-model="subsidies" />
    <MixtureSelection
      v-model="mixtureComponents"
      :available-mixtures="availableMixtures"
      @remove="mixtureComponents = null"
    >
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CatchCropMixture</IxRes>
      </template>
    </MixtureSelection>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'

import SubsidyPlan from './SubsidyPlan'
import MixtureSelection from './mixture/MixtureSelection'

export default {
  components: {
    SubsidyPlan,
    MixtureSelection
  },
  props: {
    availableMixtures: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/cultivationPlanning/editBuffer', [
      'subsidies',
      'mixtureComponents'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
