<template lang="html">
  <div>
    <NumericInput
      v-if="temperature !== undefined"
      v-model="temperature" name="temperature"
      unit="°C" required
    >
      <template #label>
        <IxRes slot="label">frs.applicationMaps.parameters.temperature</IxRes>
      </template>
    </NumericInput>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'

import NumericInput from '@components/forms/NumericInput'

export default {
  components: {
    NumericInput
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/applicationMaps', [
      'parameters.temperature'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
