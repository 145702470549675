<template lang="html">
  <div>
    <NavigationHeader @back="exportOrders">
      <template #step>
        <IxRes>bf.probingResult.headerTitle</IxRes>
      </template>
      <template #back>
        <IxRes>bf.probingResult.button.backToStepThree</IxRes>
      </template>
    </NavigationHeader>

    <template>
      <FrsLoadingIndicatorWrapper :requests="['bf.probingResult.orders']" :show-hint="!ordersAvailable">
        <template #hint>
          <IxRes>bf.probingResult.hint.noOrdersAvailable</IxRes>
        </template>
        <VueSelectize
          v-model="order" :options="ordersWithProbingSites"
          :fields="orderFields"
          :placeholder="SR_BaseFertilization.SelectOrder"
          allow-empty
        >
          <template #label>
            <IxRes>bf.probingResult.label.orderSelection</IxRes>
          </template>
        </VueSelectize>
      </FrsLoadingIndicatorWrapper>

      <HelpBox>
        <IxRes>bf.probingResult.hints.resultEntryHint</IxRes>
      </HelpBox>

      <IxButton
        :disabled="!orderSelected"
        icon="upload"
        :title="selectOrderHint"
        @click="showUploadModal = true"
      >
        <IxRes>bf.probingResult.buttons.upload</IxRes>
      </IxButton>
      <template v-if="$can('Use.Frs.BaseFertilization.ServiceProviderFeatures')">
        <hr>
        <h4>
          <IxRes>bf.probingResult.headers.serviceProviderFunctions</IxRes>
        </h4>
        <div class="button-container">
          <IxButton
            :disabled="!orderSelected"
            icon="upload"
            :title="selectOrderHint"
            @click="showImportModal = true"
          >
            <IxRes>bf.probingResult.buttons.import</IxRes>
          </IxButton>
          <IxButton
            :disabled="!orderSelected"
            icon="hand-pointer-o"
            :title="selectOrderHint"
            @click="finalizeManualSettings"
          >
            <IxRes>bf.probingResult.buttons.manuallyEntry</IxRes>
          </IxButton>
        </div>
      </template>
    </template>
    <ResultImportModal
      v-if="order"
      :value="showImportModal"
      :order="order"
      @input="showImportModal = false"
    />
    <CreateYoutrackTicketImportModal
      :value="showUploadModal"
      @input="showUploadModal = false"
    />

    <IxVectorLayer
      :features="fieldBorderFeatures"
      :vector-style="styles.border"
      :z-index="1"
      layer-id="fieldBorder"
      auto-focus
    />
    <IxVectorLayer
      :features="pointFeatures"
      :vector-style="styles.point"
      :z-index="2"
      layer-id="points"
    />
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import parse from 'wellknown'
import {fieldBorder, probingResultPreview} from '@frs/map-styles'
import {mapFormFields, mapResources} from '@helpers/vuex'

import HelpBox from '@components/help/HelpBox'
import VueSelectize from '@components/VueSelectize'
import IxButton from '@components/IxButton'
import IxVectorLayer from '@components/map/IxVectorLayer'
import NavigationHeader from '@frs/components/basic-fertilization/widgets/NavigationHeader'
import ResultImportModal from '@frs/components/basic-fertilization/result-import/ResultImportModal'
import CreateYoutrackTicketImportModal from '@frs/components/basic-fertilization/result-import/CreateYoutrackTicketImportModal'

import FieldBorderMixin from '@frs/components/basic-fertilization/mixins/FieldBorderMixin'
import PermissionMixin from '@mixins/PermissionMixin'
import FrsLoadingIndicatorWrapper from '@frs/components/base/FrsLoadingIndicatorWrapper'

export default {
  components: {
    FrsLoadingIndicatorWrapper,
    VueSelectize,
    IxButton,
    IxVectorLayer,
    HelpBox,
    NavigationHeader,
    ResultImportModal,
    CreateYoutrackTicketImportModal
  },
  mixins: [
    FieldBorderMixin,
    PermissionMixin
  ],
  data () {
    return {
      showImportModal: false,
      showUploadModal: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', {
      orders: state => state.openOrders
    }),
    ...mapResources([
      '@frs.SR_BaseFertilization'
    ]),
    ...mapFormFields('fieldRecordSystem/basicFertilization/resultImport', [
      'manual.nutrients',
      'manual.timestamp',
      'order'
    ]),
    ordersWithProbingSites () {
      return this.orders.filter(order => order.geometries.length)
    },
    orderFields () {
      return {
        value: x => x.id,
        text: x => x.name
      }
    },
    ordersAvailable () {
      return !!this.ordersWithProbingSites.length
    },
    pointFeatures () {
      return this.order ? this.order.geometries.map(geometry => ({
        geometry: parse(geometry.geometry),
        type: 'Feature',
        properties: {
          id: geometry.id,
          results: geometry.results,
          sampleId: geometry.sampleId,
          fieldName: geometry.fieldName
        }
      })) : []
    },
    orderSelected () {
      return !!this.order
    },
    selectOrderHint () {
      return this.orderSelected ? '' : this.SR_BaseFertilization.NoOrderSelected
    },
    styles () {
      return {
        border: fieldBorder,
        point: probingResultPreview
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/resultImport', [
      'finalizeManualSettings'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization', [
      'exportOrders'
    ])
  }
}
</script>

<style lang="scss" scoped>
.button-container {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}
</style>
