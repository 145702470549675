<template lang="html">
  <svg
    class="field-border" :class="{missing: !$slots.default}"
    viewBox="-52 -52 104 104"
    width="100%" height="100%"
  >
    <slot>
      <g>
        <title>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoGeometryWarning</IxRes>
        </title>
        <polygon class="border" stroke="#eee" points="-25,-30 25,-35 40,40 -35,35" />
        <text
          class="icon" x="0"
          y="0"
          dx="-7px" dy="12px"
        >?</text>
      </g>
    </slot>
  </svg>
</template>

<script>
export default {
  props: {
    fieldId: String
  }
}
</script>

<style lang="scss" scoped>
.field-border {
  &.missing {
    // cursor: help;

    .border {
      fill: transparent;
      stroke: lightgray;
      stroke-width: 3px;
      stroke-dasharray: 8px;
    }
    .icon {
      fill: lightgray;
      font: bold 3em 'CalibreWeb';
      user-select: none;
    }

  }
}
</style>
