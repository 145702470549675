<template>
  <ItemPicker :items="availableMixtures" class="masonry">
    <div slot-scope="{item}" class="masonry-item">
      <MixtureTile
        class="tile"
        :mixture="item"
        @click.native="$emit('input', item.components)"
      />
    </div>
  </ItemPicker>
</template>

<script>
import ItemPicker from './ItemPicker'
import MixtureTile from './MixtureTile'

export default {
  components: {
    ItemPicker,
    MixtureTile
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    availableMixtures: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.tile:hover {
  cursor: pointer;
}

.masonry {
  column-count: 2;
  column-gap: 1em;
}

.masonry-item {
  display: inline-block;
  margin: 0 0 1em;
  width: 100%;
}

</style>
