var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CollapsibleSection',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('IxRes',[_vm._v("frs.weatherDetails.headers.climateData")])]},proxy:true}]),model:{value:(_vm.climateDataSection),callback:function ($$v) {_vm.climateDataSection=$$v},expression:"climateDataSection"}},[_c('VueSelectize',{attrs:{"options":_vm.categories,"fields":_vm.fields},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('IxRes',[_vm._v("frs.weatherDetails.labels.category")])]},proxy:true}]),model:{value:(_vm.selectedClimateCategory),callback:function ($$v) {_vm.selectedClimateCategory=$$v},expression:"selectedClimateCategory"}}),(_vm.selectedClimateCategory && _vm.monthlyClimateData)?_c('div',[_c('WeatherLineChart',{attrs:{"climate-data":_vm.monthlyClimateData,"category":_vm.selectedClimateCategory}})],1):_vm._e()],1),_c('CollapsibleSection',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('IxRes',[_vm._v("frs.weatherDetails.headers.weatherForcast")])]},proxy:true}]),model:{value:(_vm.climateForcastSection),callback:function ($$v) {_vm.climateForcastSection=$$v},expression:"climateForcastSection"}},[_c('IxCollapsePanel',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('IxRes',[_vm._v("Areas.FieldRecordSystem.SR_FieldRecordSystem.Overview")])]},proxy:true},{key:"expand-text",fn:function(){return [_c('IxRes',[_vm._v("Areas.FieldRecordSystem.SR_FieldRecordSystem.ExpandOverview")])]},proxy:true},{key:"collapse-text",fn:function(){return [_c('IxRes',[_vm._v("Areas.FieldRecordSystem.SR_FieldRecordSystem.CollapseOverview")])]},proxy:true}])},[_c('SimpleTable',{attrs:{"columns":_vm.overviewColumns,"data":_vm.transformedOverviewData},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var value = ref.value;
return _c('span',{},[_c('strong',[_vm._v(_vm._s(value))])])}}])})],1),_c('IxCollapsePanel',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('IxRes',[_vm._v("Areas.FieldRecordSystem.SR_FieldRecordSystem.ReviewPrecipitation")])]},proxy:true},{key:"expand-text",fn:function(){return [_c('IxRes',[_vm._v("Areas.FieldRecordSystem.SR_FieldRecordSystem.ExpandReview")])]},proxy:true},{key:"collapse-text",fn:function(){return [_c('IxRes',[_vm._v("Areas.FieldRecordSystem.SR_FieldRecordSystem.CollapseReview")])]},proxy:true}])},[_c('SimpleTable',{attrs:{"columns":_vm.reviewColumns,"data":_vm.transformedReviewData},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var value = ref.value;
return _c('span',{},[_c('strong',[_vm._v(_vm._s(value))])])}}])})],1),_c('IxCollapsePanel',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('IxRes',[_vm._v("Areas.FieldRecordSystem.SR_FieldRecordSystem.Precipitation")])]},proxy:true},{key:"expand-text",fn:function(){return [_c('IxRes',[_vm._v("Areas.FieldRecordSystem.SR_FieldRecordSystem.ExpandPrecipitation")])]},proxy:true},{key:"collapse-text",fn:function(){return [_c('IxRes',[_vm._v("Areas.FieldRecordSystem.SR_FieldRecordSystem.CollapsePrecipitation")])]},proxy:true}])},[_c('template',{slot:"additional-info"},[_c('simple-switch',{model:{value:(_vm.showHourlyPrecipitation),callback:function ($$v) {_vm.showHourlyPrecipitation=$$v},expression:"showHourlyPrecipitation"}},[_c('IxRes',[_vm._v("Areas.FieldRecordSystem.SR_FieldRecordSystem.ShowHourlyValues")])],1)],1),(_vm.showHourlyPrecipitation)?_c('SimpleTable',{attrs:{"columns":_vm.precipitationHourlyForecastColumns,"data":_vm.precipitationForecastHourly},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var value = ref.value;
return _c('span',{},[_c('strong',[_vm._v(_vm._s(value))])])}}],null,false,3514229263)}):_vm._e(),_c('SimpleTable',{attrs:{"columns":_vm.precipitationColumns,"data":_vm.transformedPrecipitationData},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var value = ref.value;
return _c('span',{},[_c('strong',[_vm._v(_vm._s(value))])])}}])})],2),_c('IxCollapsePanel',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('IxRes',[_vm._v("Areas.FieldRecordSystem.SR_FieldRecordSystem.TemperatureCurve")])]},proxy:true},{key:"expand-text",fn:function(){return [_c('IxRes',[_vm._v("Areas.FieldRecordSystem.SR_FieldRecordSystem.ExpandTemperature")])]},proxy:true},{key:"collapse-text",fn:function(){return [_c('IxRes',[_vm._v("Areas.FieldRecordSystem.SR_FieldRecordSystem.CollapseTemperature")])]},proxy:true}])},[_c('template',{slot:"additional-info"},[_c('simple-switch',{model:{value:(_vm.showHourlyTemperature),callback:function ($$v) {_vm.showHourlyTemperature=$$v},expression:"showHourlyTemperature"}},[_c('IxRes',[_vm._v("Areas.FieldRecordSystem.SR_FieldRecordSystem.ShowHourlyValues")])],1)],1),(_vm.showHourlyTemperature)?_c('SimpleTable',{attrs:{"columns":_vm.temperatureHourlyForecastColumns,"data":_vm.forecastDataHourly},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var value = ref.value;
return _c('span',{},[_c('strong',[_vm._v(_vm._s(value))])])}}],null,false,3514229263)}):_c('SimpleTable',{attrs:{"columns":_vm.temperatureColumns,"data":_vm.transformedTemperatureData},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var value = ref.value;
return _c('span',{},[_c('strong',[_vm._v(_vm._s(value))])])}}])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }