<template lang="html">
  <div class="additional-actions">
    <template v-if="permissionCheck">
      <label>
        <IxRes>frs.mapManagement.labels.createMoreMaps</IxRes>:
      </label>

      <IxButton
        v-if="!isBiomassMap" large
        :icon="icons.createPremium" @click="toAutomaticZoneMaps('premium')"
      >
        <IxRes>frs.mapManagement.buttons.createPremiumZoneMap</IxRes>
      </IxButton>

      <IxButton large :icon="icons.createAuto" @click="onCreateNewMap">
        <IxRes>frs.mapManagement.buttons.createAutomaticZoneMap</IxRes>
      </IxButton>
    </template>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {faStar, faRedo} from '@fortawesome/free-solid-svg-icons'

import IxButton from '@components/IxButton'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    IxButton
  },
  mixins: [
    PermissionMixin
  ],
  props: {
    zoneMap: Object
  },
  computed: {
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    icons () {
      return {
        createPremium: faStar,
        createAuto: faRedo
      }
    },
    isBiomassMap () {
      return this.zoneMap.category === 'BiomassMap'
    },
    permissionCheck () {
      const zoneMapCheck = this.fieldId && this.zoneMap.origin === 'AutomaticZoneMaps' && this.$can('Create.Frs.AutomaticZoneMap') && !this.isBiomassMap
      const biomassMapCheck = this.fieldId && this.isBiomassMap && this.$can('Create.Frs.BiomassMap')

      return zoneMapCheck || biomassMapCheck
    }
  },
  methods: {
    toAutomaticZoneMaps (mode) {
      this.$store.commit('fieldRecordSystem/setRightView', 'route:automaticZoneMaps') // NOTE legacy compat
      this.$router.push({name: 'automaticZoneMaps', params: {...this.$route.params, mode}})
    },
    toBioMassMaps () {
      const {params} = this.$route.params
      this.$router.push({name: 'biomassMaps', params: {...params}})
    },
    onCreateNewMap () {
      if (this.isBiomassMap) {
        this.toBioMassMaps()
      } else {
        this.toAutomaticZoneMaps('auto')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.additional-actions {
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 0.5em;
  }
}
</style>
