<template>
  <div class="detail-view">
    <CommonInfoWrapper :is-ab="planIsAb" :plan="lanePlan" />
    <template v-if="!planIsAb">
      <MachineWrapper :plan="lanePlan" />
      <HeadlandWrapper :plan="lanePlan" />
    </template>
    <template v-else>
      <LabelAndMessage>
        <template #label>
          <IxRes>geotrax.details.labels.name</IxRes>:
        </template>
        {{ lanePlan.name }}
      </LabelAndMessage>
    </template>
    <DetailViewVectorLayer />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import DetailViewVectorLayer from './DetailViewVectorLayer'
import MachineWrapper from './MachineWrapper'
import HeadlandWrapper from './HeadlandWrapper'
import CommonInfoWrapper from './CommonInfoWrapper'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    LabelAndMessage,
    CommonInfoWrapper,
    HeadlandWrapper,
    MachineWrapper,
    DetailViewVectorLayer
  },
  props: {
    plan: null
  },
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning', {
      selectedPlan: (state) => state.data.selectedPlan
    }),
    lanePlan () {
      return this.plan ? this.plan : this.selectedPlan
    },
    planIsAb () {
      if (!this.lanePlan) return false
      return (this.lanePlan.hasOwnProperty('abLine'))
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-view {
  margin: 8px;
  display: flex;
  flex-direction: column;

  ::v-deep hr {
    margin: 8px 0;
    width: 100%;
    border-color: #cbcbcb;
  }
}
</style>
