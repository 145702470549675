<template>
  <div>
    <label><IxRes>Common.SR_Common.Separator</IxRes> &amp; <IxRes>Common.SR_Common.DecimalSeparator</IxRes></label>
    <SimpleSwitch v-model="semicolonSeparated">
      <template #offLabel>
        <IxRes>Common.SR_Common.CommaPoint</IxRes>
      </template>
      <IxRes>Common.SR_Common.SemicolonComma</IxRes>
    </SimpleSwitch>
    <label><IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.ExampleForFileContent</IxRes></label>
    <div class="alert alert-info">
      <HighlightPunctuation v-for="(line, i) in exampleFileContents" :key="i" :value="line" />
    </div>
    <FileDropArea
      format-name="CSV" :validate="validate"
      accept=".csv" @input="onChanged"
    >
      <template #label>
        <IxRes>Common.SR_Common.CSVFile</IxRes>
      </template>
    </FileDropArea>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'
import {readAsText} from '@helpers/files'

import {canConvert} from './convert-csv-file'

import FileDropArea from '@components/forms/FileDropArea'
import SimpleSwitch from '@components/forms/SimpleSwitch'

import HighlightPunctuation from './HighlightPunctuation'

export default {
  components: {
    FileDropArea,
    SimpleSwitch,
    HighlightPunctuation
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', {
      openOrders: state => state.openOrders
    }),
    ...mapFormFields('fieldRecordSystem/basicFertilization/resultImport', [
      'upload.semicolonSeparated'
    ]),
    exampleFileContents () {
      return this.semicolonSeparated
        ? [
          'sampleId;pH;pH-class',
          '334-1;6,7;C'
        ]
        : [
          'sampleId,pH,pH-class',
          '334-1,6.7,C'
        ]
    }
  },
  methods: {
    onChanged (files) {
      if (canConvert(files)) {
        readAsText(files[0])
          .then(csv => this.$emit('load', csv))
      } else {
        this.validate = false
      }
    },
    validate (files) {
      return canConvert(files)
    }
  }
}
</script>

<style>
</style>
