<template lang="html">
  <div class="maize-parameters">
    <div class="input-row">
      <NumericInput
        v-model="estimatedPrecipitation"
        name="estimatedPrecipitation"
        :rule="between(100, 400)"
        required
      >
        <template #label>
          <IxRes>frs.applicationMaps.parameters.labels.estimatedPrecipitation</IxRes>
        </template>
      </NumericInput>
    </div>

    <div class="input-row">
      <NumericInput
        v-model="minSoilMoisture"
        name="minSoilMoisture"
        :rule="between(0, 400)"
        required
      >
        <template #label>
          <IxRes>frs.applicationMaps.parameters.labels.minSoilMoisture</IxRes>
        </template>
      </NumericInput>
      <NumericInput
        v-model="maxSoilMoisture"
        name="maxSoilMoisture"
        :rule="between(0, 400)"
        required
      >
        <template #label>
          <IxRes>frs.applicationMaps.parameters.labels.maxSoilMoisture</IxRes>
        </template>
      </NumericInput>
    </div>

    <CollapsibleSection v-if="zoneMap" v-model="showZoneInput">
      <template #header>
        <IxRes>frs.applicationMaps.parameters.additionalZoneValues</IxRes>
      </template>
      <div class="zone-values">
        <ZoneInput
          v-model="additionalZoneValues"
          :zone-map="zoneMap"
        >
          <template #default="{zone, value, onInput}">
            <div class="input-row">
              <NumericInput
                :value="value && value.acreRatio !== undefined ? value.acreRatio : null"
                :name="`acreRatio-input-${zone.id}`"
                :rule="between(0, 106)"
                required
                @input="x => onInput({...defaultAdditionalZoneValues, ...value, acreRatio: x})"
              >
                <template #label>
                  <IxRes>frs.mapManagement.zoneMapAdditionalValues.labels.acreRatio</IxRes>
                </template>
              </NumericInput>

              <NumericInput
                :value="value && value.pwc !== undefined ? value.pwc : null"
                :name="`pwc-input-${zone.id}`"
                :rule="between(60, 420)"
                required
                @input="x => onInput({...defaultAdditionalZoneValues, ...value, pwc: x})"
              >
                <template #label>
                  <IxRes>frs.mapManagement.zoneMapAdditionalValues.labels.pwc</IxRes>
                </template>
              </NumericInput>
            </div>
          </template>
        </ZoneInput>
      </div>
    </CollapsibleSection>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'
import NumericInput from '@components/forms/NumericInput'
import ZoneInput from '@components/forms/ZoneInput'
import CollapsibleSection from '@components/CollapsibleSection'

export default {
  components: {
    NumericInput,
    ZoneInput,
    CollapsibleSection
  },
  mixins: [
    StandardRulesMixin
  ],
  data () {
    return {
      showZoneInput: true
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/applicationMaps', [
      'parameters.estimatedPrecipitation',
      'parameters.minSoilMoisture',
      'parameters.maxSoilMoisture',
      'parameters.additionalZoneValues'
    ]),
    ...mapState('fieldRecordSystem/applicationMaps', {
      zoneMapId: state => state.parameters.zoneMapId
    }),
    ...mapState('fieldRecordSystem/mapManagement', {
      zoneMapLookup: state => state.data.zoneMap.mapLookup
    }),
    zoneMap () {
      return this.zoneMapLookup[this.zoneMapId]
    },
    defaultAdditionalZoneValues () {
      return {
        acreRatio: null,
        pwc: null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.maize-parameters {
  display: flex;
  flex-direction: column;

  .input-row {
    display: flex;

    > * {
      flex: 1;
    }

    > :not(:first-child) {
      margin-left: 0.5em;
    }
  }
}

.zone-values ::v-deep .input-row label.control-label {
  font-size: 0.8em;
  color: gray;
}
</style>
