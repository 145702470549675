<template lang="html">
  <BsModal v-model="modelProxy" :loading="saving" saving>
    <template v-if="isNew" #title>
      <IxRes>frs.action.planning.fertilization.customProduct.title.add</IxRes>
    </template>
    <template v-else #title>
      <IxRes>frs.action.planning.fertilization.customProduct.title.edit</IxRes>
    </template>
    <template #description>
      <IxRes>frs.action.planning.fertilization.customProduct.description</IxRes>
    </template>

    <CustomProductForm v-if="modelProxy" :procedure="procedure" />

    <template #footer>
      <IxButton save large @click="save">
        <IxRes>frs.action.planning.fertilization.customProduct.save</IxRes>
      </IxButton>
    </template>
  </BsModal>
</template>

<script>

import {modelProxy} from '@helpers/vuex'

import CustomProductForm from './CustomProductForm'

import IxButton from '@components/IxButton'
import BsModal from '@components/bootstrap/BsModal'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    IxButton,
    CustomProductForm,
    BsModal
  },
  mixins: [
    FormPartMixin
  ],
  props: {
    value: Boolean,
    isNew: Boolean,
    procedure: String,
    saving: Boolean
  },
  computed: {
    modelProxy
  },
  methods: {
    async save () {
      this.validate()
      await this.$nextTick()

      if (this.state !== 'success') return

      this.$emit('save')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
