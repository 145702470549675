<template lang="html">
  <div>
    <template v-if="compact">
      <IxRes v-if="type">frs.actionPlanning.abbreviations.cultivationType_{{ type }}</IxRes>
      <IxRes v-else>frs.actionPlanning.abbreviations.remainingField</IxRes>
    </template>
    <template v-else>
      <IxRes v-if="type">Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ cultivationTypeTranslationKeys[type] }}</IxRes>
      <IxRes v-else>frs.actionPlanning.labels.remainingField</IxRes>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: null
    },
    compact: Boolean
  },
  computed: {
    cultivationTypeTranslationKeys () {
      return {
        main: 'MainCrop',
        eco: 'EcologicalPriorityArea',
        catch: 'CatchCrop'
      }
    }
  }
}
</script>
