<template lang="html">
  <div class="algorithm-collection-selection">
    <h3>
      <IxRes>frs.applicationMaps.headers.algorithmGroupSelection</IxRes>
    </h3>

    <HelpBox>
      <IxRes>frs.applicationMaps.hints.algorithmGroupSelection</IxRes>
    </HelpBox>

    <div class="algorithm-collection-buttons">
      <IxButton
        v-for="collection in algorithmCollections" :key="collection"
        large class="collection"
        :disabled="!$can(productGroupPermissions[collection])"
        @click="algorithmCollection = collection"
      >
        <IxRes>frs.applicationMaps.buttons.algorithmCollection_{{ collection }}</IxRes>
      </IxButton>
    </div>

    <div class="button-container">
      <IxButton large back @click="cancelCreation" />
    </div>
  </div>
</template>

<script>
import {uniqBy} from 'lodash'
import {mapGetters, mapActions} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import productGroupPermissions from './product-group-permissions'

import IxButton from '@components/IxButton'
import HelpBox from '@components/help/HelpBox'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    IxButton,
    HelpBox
  },
  mixins: [
    PermissionMixin
  ],
  computed: {
    ...mapGetters('masterData', [
      'productAlgorithmArray'
    ]),
    ...mapFormFields('fieldRecordSystem/applicationMaps', [
      'ui.algorithmCollection'
    ]),
    algorithmCollections () {
      return uniqBy(this.productAlgorithmArray, x => x.productGroup).map(x => x.productGroup)
    },
    productGroupPermissions () {
      return productGroupPermissions
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/applicationMaps', [
      'cancelCreation'
    ])
  }
}
</script>

<style lang="scss" scoped>
.algorithm-collection-selection {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.algorithm-collection-buttons {
  display: flex;
  flex-direction: column;

  .collection {
    margin: 0.25em;
  }
}

.button-container {
  margin-top: auto;
  display: flex;
}
</style>
