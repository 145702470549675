import {mapState, mapGetters} from 'vuex'

import ParameterMixin, {
  mapParameters,
  mapSingleProductQuantity,
} from '../ParameterMixin'

export default {
  mixins: [ParameterMixin],
  data() {
    return {
      isCorn: false, // TODO get as computed from selected product
      wasComputed: {
        seedsPerHa: false,
        seedsPerSqm: false,
        totalPlants: false,
        plantsPerSqm: false,
      },
    }
  },
  computed: {
    ...mapGetters('masterData', ['products']),
    ...mapGetters('fieldRecordSystem/action/editBuffer', [
      'finalActionTotalArea'
    ]),
    ...mapState('fieldRecordSystem', {
      fieldData: state => state.data.field,
    }),
    ...mapParameters([
      'plantsPerSqm',
      'rowDistance', // cm
      'rowSeparation', // cm
      'thousandSeedWeight',
      'germinationCapacity', // not normalized
      'emergence', // not normalized
      'coating',
      // 'seedsPerHa',
      'totalPlants',
      'packageSize',
      'mixtureComponents',
    ]),
    seedsPerHa: {
      get() { return this.amount },
      set(value) { this.amount = value },
    },
    ...mapSingleProductQuantity(),
    unitSeedsPerHa() {
      return this.unit
    },
    unitTotalSeeds() {
      return this.unit.replace('/ha', '')
    },
    areaToProcessHa() {
      return this.finalActionTotalArea / 10000
    },
    totalSeeds() {
      return this.$i18n.format(this.seedsPerHa * this.areaToProcessHa, 'f2') || 0
    },
    productFields() {
      return {
        text: id => this.products[id].name,
      }
    },
    calculatedSeedsPerHa() {
      if (this.isCorn) {
        if (!(this.emergence && this.plantsPerSqm)) return

        // returning something in m² whereas the computed is meant to return something in ha is very dangerous
        // this is because in case of corn the unit is changed to something per m²
        return this.plantsPerSqm / (this.normalizedEmergenceFactor)
      }

      if (this.isSetPlantAction) {
        if (!this.plantsPerSqm) return
        const squareMeterToHectare = 10000
        return this.plantsPerSqm * squareMeterToHectare
      }

      if (!( this.germinationCapacity && this.thousandSeedWeight && this.plantsPerSqm && this.emergence )) return

      const squareMeterToHectare = 1E4
      const gramToKg = 1E3
      const kiloSeedsToSeeds = 1E3

      const unitConversionFactor = squareMeterToHectare / (gramToKg * kiloSeedsToSeeds)

      return this.thousandSeedWeight * this.plantsPerSqm * unitConversionFactor / this.totalFactorAfterCumulativeLosses
    },
    calculatePlantsPerSqm() {
      if (!(this.rowDistance && this.rowSeparation)) return

      const squareCentiMeterToSquareMeter = 1E4
      const plantsPerSqm = squareCentiMeterToSquareMeter / (this.rowDistance * this.rowSeparation )

      return plantsPerSqm
    },
    calculateTotalPlants() {
      if (!this.calculatePlantsPerSqm) return

      const squareMeterToHectare = 1E4
      return this.calculatePlantsPerSqm * squareMeterToHectare * this.areaToProcessHa
    },
    totalPackages() {
      if (!(this.packageSize && this.totalPlants)) return

      return Math.round(this.totalPlants / this.packageSize)
    },
    seedsPerHaRule() {
      return () => {
        return this.wasComputed.seedsPerHa ? 'info' : 'success'
      }
    },
    plantsPerSqmRule() {
      return () => {
        return this.wasComputed.plantsPerSqm ? 'info' : 'success'
      }
    },
    totalPlantsRule() {
      return () => {
        return this.wasComputed.totalPlants ? 'info' : 'success'
      }
    },
    availableProductFilter() {
      return product => !product.isArchived || product.id === this.productId
    },
    normalizedEmergenceFactor () {
      return this.emergence / 100
    },
    normalizedGerminationCapacityFactor () {
      return this.germinationCapacity / 100
    },
    totalFactorAfterCumulativeLosses  () {
      return this.normalizedEmergenceFactor * this.normalizedGerminationCapacityFactor
    },
    isSetPlantAction () {
      return ['set', 'plant'].includes(this.action.procedure)
    }
  },
  watch: {
    productId: {
      immediate: true,
      handler(newVal) {
        const product = this.products[newVal]
        if (
          product &&
          product.mixtureComponents &&
          product.mixtureComponents.length > 0
        ) {
          this.mixtureComponents = product.mixtureComponents
        } else {
          this.mixtureComponents = null
        }
      },
    },
    isCorn: {
      // instead of having an immediate watcher it would be better to initialize a default unit in data
      // and switch the unit based on isCorn and action procedure
      immediate: true,
      handler(isCorn) {
        if (isCorn) {
          this.unit = 'K/m²'
        } else if (this.action.procedure === 'set') {
          this.unit = 'piece/ha'
        } else {
          this.unit = 'kg/ha'
        }
      }
    },
    calculatedSeedsPerHa(value) {
      if (value === undefined) return

      this.wasComputed.seedsPerHa = true
      this.seedsPerHa = value
      if (this.$refs.inputSeedsPerHa !== undefined) {
        this.$refs.inputSeedsPerHa.validate()
      }
    },
    calculatePlantsPerSqm(value) {
      if (value === undefined) return

      this.wasComputed.plantsPerSqm = true
      this.plantsPerSqm = value
      if (this.$refs.inputPlantsPerSqm !== undefined) {
        this.$refs.inputPlantsPerSqm.validate()
      }
      if (this.$refs.inputTotalPlants !== undefined) {
        this.$refs.inputTotalPlants.validate()
      }
    },
    calculateTotalPlants(value) {
      if (value === undefined) return

      this.wasComputed.totalPlants = true
      this.totalPlants = value
    },
  },
}
