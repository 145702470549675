<template lang="html">
  <i v-if="fullySaved" class="fa fa-lock" :title="SR_BaseFertilization.AlreadyStoredResult" />
  <i v-else-if="somethingMissing" class="fa fa-warning" :title="SR_BaseFertilization.NotAllDataRecorded" />
  <i v-else-if="allDataEntered" class="fa fa-check" :title="SR_BaseFertilization.NutrientResultIsSaved" />
  <span v-else />
</template>

<script>
import {mapGetters} from 'vuex'

import {mapResources} from '@helpers/vuex'

export default {
  props: {
    geometryId: String,
    nutrient: String
  },
  computed: {
    ...mapResources([
      '@frs.SR_BaseFertilization'
    ]),
    ...mapGetters('fieldRecordSystem/basicFertilization/resultImport', [
      'savedResultsLookup',
      'newResultsValidity'
    ]),
    validity () {
      return this.newResultsValidity[this.geometryId] && this.newResultsValidity[this.geometryId][this.nutrient]
    },
    savedResults () {
      return this.savedResultsLookup[this.geometryId]
    },
    fullySaved () {
      if (!this.savedResults) return false

      return this.nutrient === 'lime'
        ? this.savedResults.limeRecommendation
        : this.savedResults[`${this.nutrient}Measurement`] && this.savedResults[`${this.nutrient}ContentClass`]
    },
    somethingMissing () {
      return this.validity === false
    },
    allDataEntered () {
      return this.validity === true
    }
  }
}
</script>

<style lang="scss" scoped>
.fa {
  cursor: help;
  margin-right: 0.25em;
}
.fa-check {
  color: green;
}
.fa-warning {
  color: darkorange;
}
.fa-lock {
  color: lightgray;
}
</style>
