<template lang="html">
  <div class="dlg-harvest-year-help">
    <BsModal v-model="showModal" size="large">
      <template #title>
        <IxRes>frs.dlgCertIntegration.modals.harvestYearHelp.title</IxRes>
      </template>

      <div class="hints">
        <strong v-if="firstTime">
          <IxRes>frs.dlgCertIntegration.modals.harvestYearHelp.hints.firstTime</IxRes>
        </strong>

        <IxRes>frs.dlgCertIntegration.modals.harvestYearHelp.hints.explanation</IxRes>

        <IxRes>frs.dlgCertIntegration.modals.harvestYearHelp.hints.harvestYearDescription</IxRes>
      </div>

      <DlgHarvestYearHelpDiagram :current-year="currentYear" />

      <template v-if="firstTime" #footer>
        <IxButton large next @click="onClick">
          <slot name="next" />
        </IxButton>
      </template>
      <template v-else #close>
        <IxRes>frs.dlgCertIntegration.modals.harvestYearHelp.buttons.close</IxRes>
      </template>
    </BsModal>

    <div class="show-help">
      <FontAwesomeIcon :icon="icons.showHelp" />
      <a href="#" @click.prevent="showModal = true">
        <slot />
      </a>
    </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'

import BsModal from '@components/bootstrap/BsModal'
import IxButton from '@components/IxButton'

import DlgHarvestYearHelpDiagram from './DlgHarvestYearHelpDiagram'

export default {
  components: {
    BsModal,
    IxButton,
    FontAwesomeIcon,
    DlgHarvestYearHelpDiagram
  },
  props: {
    value: Boolean, // user has accepted the firstTime info
    currentYear: Number
  },
  data () {
    return {
      showModal: !this.value
    }
  },
  computed: {
    firstTime () {
      return !this.value
    },
    icons () {
      return {
        showHelp: faInfoCircle
      }
    }
  },
  methods: {
    onClick () {
      this.showModal = false
      this.$emit('input', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.hints {
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 0.5em;
  }
}

.show-help {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 0.5em;

  a {
    margin-left: 0.5em;
  }
}
</style>
