<template lang="html">
  <div>
    <div class="n-requirement-values">
      <NitrogenRequirement
        :nitrogen-requirement="nitrogenRequirement"
        :nitrogen-requirement-for-field="nitrogenRequirementForField"
      />
    </div>
    <div>
      <p>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ManualNutrientRequirementAdditions</IxRes>:
      </p>
      <div class="box-container">
        <div class="radio-box">
          <BsRadio v-model="selected" :value="1">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Addition</IxRes>
          </BsRadio>
        </div>
        <div class="radio-box">
          <BsRadio v-model="selected" :value="2">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Revocation</IxRes>
          </BsRadio>
        </div>
      </div>
      <div class="amount-input">
        <LabelAndMessage required>
          <template #label>
            <IxRes>Common.SR_Common.Amount</IxRes>:
          </template>
          <NumericInput
            v-model="amount" name="amount"
            unit="kg/ha"
            :rule="selected === 2 ? between(0, nitrogenRequirement) : () => true"
          />
        </LabelAndMessage>
      </div>
      <LabelAndMessage>
        <template #label>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.RecalculatedNRequirement</IxRes>:
        </template>
        <p>{{ $i18n.format(recalculatedNRequirement, 'f2') }} {{ $i18n.translateUnit('kg/ha') }}</p>
      </LabelAndMessage>
      <LabelAndMessage>
        <template #label>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.RecalculatedNRequirementForField</IxRes>:
        </template>
        <p>{{ $i18n.format(recalculatedNRequirementForField, 'f2') }} {{ $i18n.translateUnit('kg/ha') }}</p>
      </LabelAndMessage>
      <LabelAndMessage>
        <template #label>
          <IxRes>Common.SR_Common.Comment</IxRes>:
        </template>
        <textarea
          v-model="tempCorrection.note" name="name"
          rows="5"
          cols="60"
        />
      </LabelAndMessage>
      <div class="buttons">
        <IxButton large cancel @click="setWizardStep('overview')">
          <IxRes v-if="currentCorrection.id">Common.SR_Common.StopEditing</IxRes>
          <IxRes v-else>Areas.FieldRecordSystem.SR_FieldRecordSystem.CancelAdding</IxRes>
        </IxButton>
        <IxButton
          large :disabled="currentCorrection.amount === null || state === 'error'"
          save
          @click="save"
        >
          <IxRes v-if="selected === 1 && currentCorrection.id">Areas.FieldRecordSystem.SR_FieldRecordSystem.SaveAddition</IxRes>
          <IxRes v-if="selected === 2 && currentCorrection.id">Areas.FieldRecordSystem.SR_FieldRecordSystem.SaveRevocation</IxRes>
          <IxRes v-if="selected === 1 && !currentCorrection.id">Areas.FieldRecordSystem.SR_FieldRecordSystem.AddAddition</IxRes>
          <IxRes v-if="selected === 2 && !currentCorrection.id">Areas.FieldRecordSystem.SR_FieldRecordSystem.AddRevocation</IxRes>
        </IxButton>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import BsRadio from '@components/bootstrap/BsRadio'
import IxButton from '@components/IxButton'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import NumericInput from '@components/forms/NumericInput'

import NitrogenRequirement from './NitrogenRequirement'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'
import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    BsRadio,
    IxButton,
    NumericInput,
    LabelAndMessage,
    NitrogenRequirement
  },
  mixins: [
    FormPartMixin,
    StandardRulesMixin
  ],
  data () {
    return {
      hideInfo: false,
      kiloPerHectarUnit: 'kg/ha',
      tonUnit: 't',
      selected: 1,
      nitrogenRequirementPreview: null,
      tempAmount: 0,
      tempCorrection: {}
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/nutrientRequirement/editBuffer', [
      'currentCorrection'
    ]),
    ...mapResources([
      'Messages.State.SR_StateMessages'
    ]),
    ...mapState('fieldRecordSystem', {
      nutrientRequirements: state => state.nutrientRequirement.data.field,
      harvestYear: state => state.userSettings.harvestYear,
      fields: state => state.data.field
    }),
    ...mapState('fieldRecordSystem/navigation', {
      fieldId: state => state.location.fieldId
    }),
    amount: {
      set (value) {
        this.tempCorrection.amount = value
      },
      get () {
        return Math.abs(this.tempCorrection.amount)
      }
    },
    field () {
      return this.fields[this.fieldId]
    },
    fieldArea () {
      return this.field.area.geometrical
    },
    nutrientRequirementsForField () {
      return this.nutrientRequirements[this.fieldId]
    },
    currentNutrientRequirement () {
      return this.nutrientRequirementsForField.find(x => x.harvestYear === this.harvestYear)
    },
    corrections () {
      return this.currentNutrientRequirement.corrections
    },
    nitrogenRequirement () {
      return this.currentNutrientRequirement ? this.corrections.reduce((acc, val) => acc + val.amount, 0) + this.currentNutrientRequirement.nitrogenRequirement - this.currentCorrection.amount : 0
    },
    nitrogenRequirementForField () {
      return this.nitrogenRequirement ? this.nitrogenRequirement * this.fieldArea / 1000 : null
    },
    recalculatedNRequirement () {
      return this.selected === 1 ? this.nitrogenRequirement + this.amount : this.nitrogenRequirement - this.amount
    },
    recalculatedNRequirementForField () {
      return this.recalculatedNRequirement * this.fieldArea
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/nutrientRequirement', [
      'setWizardStep'
    ]),
    ...mapActions('fieldRecordSystem/nutrientRequirement', [
      'saveCorrection'
    ]),
    save () {
      this.tempCorrection.amount = Math.abs(this.tempCorrection.amount)
      this.currentCorrection = this.tempCorrection
      if (this.selected === 2) {
        this.currentCorrection.amount *= -1
      }

      this.saveCorrection()
        .then(() => {
          notifications.success(this.SR_StateMessages.SaveSuccess)
          this.$store.commit('fieldRecordSystem/setDataLoading', false)
        })
        .catch(() => {
          notifications.error(this.SR_StateMessages.SaveError)
          this.$store.commit('fieldRecordSystem/setDataLoading', false)
        })
      this.setWizardStep('overview')
    }
  },
  created () {
    this.selected = this.currentCorrection.amount < 0 ? 2 : 1
    this.tempCorrection = JSON.parse(JSON.stringify(this.currentCorrection))
  }
}
</script>

<style lang="scss" scoped>
  .n-requirement-values {
    margin-bottom: 10px;
  }
  .box-container {
    display: flex;
    flex-direction: row;
    margin-top: -15px;
  }
  .radio-box {
    margin-left: 24px;
  }
  .amount-input {
    width: 40%;
    margin-bottom: 5px;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
  }
</style>
