var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"probing-sites"},[_c('div',{staticClass:"buttons"},[_c('IxButton',{attrs:{"icon":"check-circle"},on:{"click":function($event){return _vm.setActiveForAll(true)}}},[_c('IxRes',[_vm._v("frs.baseFertilization.orderPlanning.buttons.selectAll."+_vm._s(_vm.probingSiteMode))])],1),_c('IxButton',{attrs:{"icon":"times-circle"},on:{"click":function($event){return _vm.setActiveForAll(false)}}},[_c('IxRes',[_vm._v("frs.baseFertilization.orderPlanning.buttons.deselectAll")])],1)],1),_c('SimpleTable',{attrs:{"columns":_vm.columns,"data":_vm.data,"highlight":_vm.highlight},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var row = ref.row;
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(value))]),(!row.markedForDeletion)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.rename(row)}}},[_c('IxRes',[_vm._v("Common.SR_Common.EditLabel")])],1):_c('span',{staticClass:"disabled-link",attrs:{"title":_vm.$i18n.translate('Areas.FieldRecordSystem.SR_BaseFertilization.PointsCanNotBeRenamed')}},[_c('IxRes',[_vm._v("Common.SR_Common.EditLabel")])],1)]}},{key:"isActive",fn:function(ref){
var row = ref.row;
var value = ref.value;
return [_c('SimpleTableAutoCell',{attrs:{"value":value}}),(!row.markedForDeletion && !row.isNew)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleActive(row)}}},[(value)?_c('IxRes',[_vm._v("Common.SR_Common.Deactivate")]):_c('IxRes',[_vm._v("Common.SR_Common.Activate")])],1):_c('span',{staticClass:"disabled-link",attrs:{"title":_vm.$i18n.translate('Areas.FieldRecordSystem.SR_BaseFertilization.NewOrToBeDeletedPointsCanNotBeDeactivated')}},[_c('IxRes',[_vm._v("Common.SR_Common.Deactivate")])],1)]}},{key:"delete",fn:function(ref){
var row = ref.row;
return _c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleDeletion(row)}}},[(row.markedForDeletion)?_c('IxRes',[_vm._v("Common.SR_Common.Restore")]):_c('IxRes',[_vm._v("Common.SR_Common.Remove")])],1)}}])}),_c('BsModal',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('IxRes',[_vm._v("frs.baseFertilization.orderPlanning.headers.changePointLabel")])]},proxy:true},{key:"footer",fn:function(){return [_c('IxButton',{attrs:{"confirm":""},on:{"click":_vm.confirmModal}},[_c('IxRes',[_vm._v("Common.SR_Common.Confirm")])],1),_c('IxButton',{attrs:{"cancel":""},on:{"click":function($event){_vm.showModal = false}}},[_c('IxRes',[_vm._v("Common.SR_Common.Cancel")])],1)]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('TextInput',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('IxRes',[_vm._v("frs.baseFertilization.orderPlanning.labels.newPointLabel")])]},proxy:true}]),model:{value:(_vm.newLabel),callback:function ($$v) {_vm.newLabel=$$v},expression:"newLabel"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }