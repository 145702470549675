<template lang="html">
  <div class="combination-action-part-card">
    <ListCard :show-details="!collapsed">
      <template #addon>
        <div class="addon-slot">
          <div slot="header" class="addon-slot-buttons">
            <template v-if="canUpdateActualArea">
              <span v-if="actualArea"><IxRes>frs.actions.documentation.actualArea</IxRes>: {{ $i18n.format(actualArea, 'area') }}</span>
              
              <IxButton icon="edit" @click="openActualAreaModal">
                <IxRes>frs.actions.documentation.editActualArea</IxRes>
              </IxButton>
            </template>

            <IxButton icon="edit" @click="$emit('edit')">
              <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EditAction</IxRes>
            </IxButton>
            <IxButton icon="trash" @click="$emit('remove')">
              <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.RemoveAction</IxRes>
            </IxButton>
          </div>
        </div>
      </template>
      <template #title>
        <IxRes>{{ type }}</IxRes>
      </template>
      <template v-if="procedure" #subtitle>
        <IxRes>{{ procedure }}</IxRes>
      </template>

      <component
        :is="component" :key="component"
        :action="part"
      />
    </ListCard>

    <Portal to="modals">
      <BsModal
        v-model="showActualAreaModal"
      >
        <template #title>
          <IxRes>frs.action.documentation.actualArea.modal.title</IxRes>
        </template>

        <IxRes>frs.action.documentation.actualArea.modal.description</IxRes>

        <NumericInput v-model="actualAreaHectares" addon="ha">
          <template #label>
            <IxRes>frs.actions.documentation.actualArea</IxRes>
          </template>
        </NumericInput>

        <template slot="footer">
          <IxButton large @click="acceptActualArea">
            <IxRes>frs.action.documentation.actualArea.modal.accept</IxRes>
          </IxButton>
          <IxButton large @click="showActualAreaModal = false">
            <IxRes>frs.action.documentation.actualArea.modal.cancel</IxRes>
          </IxButton>
        </template>
      </BsModal>
    </Portal>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import IxCollapsePanel from '@components/ix/IxCollapsePanel'
import ListCard from '@components/card/ListCard'
import IxButton from '@components/IxButton'
import NumericInput from '@components/forms/NumericInput'
import BsModal from '@components/bootstrap/BsModal'

import TransportDetails from '@frs/components/action/planning/wizard/details/TransportDetails'
import SeedingBroadDrillSingleDetails from '@frs/components/action/planning/wizard/details/SeedingBroadDrillSingleDetails'
import SeedingSetPlantDetails from '@frs/components/action/planning/wizard/details/SeedingSetPlantDetails'
import SeedingOtherDetails from '@frs/components/action/planning/wizard/details/SeedingOtherDetails'
import ProtectionDetails from '@frs/components/action/planning/wizard/details/ProtectionDetails'

import {getTypeKey, getProcedureKey} from '@frs/components/action/translation-key-helper'

export default {
  components: {
    ListCard,
    IxCollapsePanel,
    IxButton,
    NumericInput,
    BsModal,
    TransportDetails,
    SeedingBroadDrillSingleDetails,
    SeedingSetPlantDetails,
    SeedingOtherDetails,
    ProtectionDetails,
  },
  props: {
    part: {
      type: Object
    },
    canUpdateActualArea: Boolean
  },
  data () {
    return {
      collapsed: true,
      showActualAreaModal: false,
      actualAreaHectares: null
    }
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      step: state => state.action.ui.planningWizard.parameterStep,
      fieldData: state => state.data.field
    }),
    actualArea () {
      return Object.values(this.part.details)[0].actualArea
    },
    component () {
      switch (this.step) {
      case 'transport': return 'TransportDetails'
      // case 'irrigation': return 'Irrigation'
      case 'protectionSpray': return 'ProtectionDetails'
      case 'seedingBroadDrillSingle': return 'SeedingBroadDrillSingleDetails'
      case 'seedingSetPlant': return 'SeedingSetPlantDetails'
      case 'seedingOther': return 'SeedingOtherDetails'
      default: return ''
      }
    },
    type () {
      return getTypeKey(this.part)
    },
    procedure () {
      return getProcedureKey(this.part)
    }
  },
  methods: {
    openActualAreaModal () {
      this.actualAreaHectares = this.actualArea ? this.actualArea / 10000 : null
      this.showActualAreaModal = true
    },
    acceptActualArea () {
      this.$emit('update:actual-area', this.actualAreaHectares ? this.actualAreaHectares * 10000 : null)
      this.showActualAreaModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.combination-action-part-card {
  margin-top: 5px;
}
.addon-slot {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.addon-slot-buttons
{
  display: flex;
  justify-content: flex-end;
}
.addon-slot-buttons > * {
  margin-right: 3px;
}
</style>
