<template lang="html">
  <div class="quick-action-icon" :class="{disabled}" @click.stop.prevent="onClick">
    <slot>
      <i class="fa" :class="[`fa-${icon}`]" />
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    disabled: Boolean
  },
  methods: {
    onClick () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.quick-action-icon {
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;

  .fa {
    margin: 0 0.3em;
  }

  &:hover:not(.disabled) {
    background-color: #d8e1f5;
    color: darken(darkgray, 20%);
  }

  &.disabled {
    cursor: default;

    i {
      color: lightgray;
    }
  }
}
</style>
