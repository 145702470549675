<template lang="html">
  <div class="basic-settings">
    <Header>
      <template #subtitle>
        <IxRes>bf.createApplicationMap.header.subtitle</IxRes>
      </template>
      <template #hint>
        <IxRes>bf.createApplicationMap.header.hint</IxRes>
      </template>
    </Header>

    <FrsFieldSelectionInfo :field-ids="selectedFieldIds">
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Field</IxRes>
      </template>
      <template #description>
        <IxRes>bf.fieldSelectionApplicationMap.info</IxRes>
      </template>
    </FrsFieldSelectionInfo>

    <HelpBox v-if="fieldStates.selectedFieldId === 'error'" type="danger">
      <IxRes>bf.applicationMaps.help.selectedFieldIdValidationError</IxRes>
    </HelpBox>

    <LabelAndMessage required>
      <NutrientSelector v-model="nutrients" hide-ph />

      <template #label>
        <IxRes>bf.createApplicationMap.nutrientSelection</IxRes>
      </template>
    </LabelAndMessage>

    <HelpBox v-if="fieldStates.nutrients === 'error'" type="danger">
      <IxRes>bf.applicationMaps.help.nutrientsValidationError</IxRes>
    </HelpBox>

    <div v-if="!onlyPhSelected" class="desired-zones">
      <LabelAndMessage>
        <div>
          <BsRadio
            v-for="count in validZoneCounts" :key="count"
            v-model="desiredZones"
            :value="count" inline
          >
            {{ count }}
          </BsRadio>
        </div>
        <template #label>
          <IxRes>bf.createApplicationMap.desiredZones</IxRes>
        </template>
      </LabelAndMessage>
      <div class="hint-desired-zones">
        <HelpBox>
          <IxRes>bf.applicationMaps.help.onlyForPKMg</IxRes>
        </HelpBox>
      </div>
    </div>

    <div class="footer-buttons">
      <div class="yield-navigation-button">
        <IxButton large colored @click="next">
          <IxRes>bf.createApplicationMap.navigateToYieldPlanning</IxRes>
        </IxButton>
      </div>
      <div>
        <IxButton next large @click="startApplicationMapPreview">
          <IxRes>bf.applicationMaps.preview.goToApplicationMapPreview</IxRes>
        </IxButton>
      </div>
    </div>
    <div class="loading-indicator">
      <FrsLoadingIndicator small :requests="['lime-recommendations']" />
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import {mapFormFields} from '@helpers/vuex'
import {smarterGet} from '@helpers/vuex/data-loading'

import {notifications} from 'src/js/infrastructure'

import Header from './widgets/Header'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import BsRadio from '@components/bootstrap/BsRadio'
import IxButton from '@components/IxButton'
import NutrientSelector from './widgets/NutrientSelector'
import FrsFieldSelectionInfo from '@frs/components/base/FrsFieldSelectionInfo'
import HelpBox from '@components/help/HelpBox'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    BsRadio,
    FrsFieldSelectionInfo,
    NutrientSelector,
    LabelAndMessage,
    HelpBox,
    IxButton,
    Header,
    FrsLoadingIndicator
  },
  mixins: [
    FormPartMixin
  ],
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/applicationMaps', [
      'selectedFieldId'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'harvestYear'
    ]),
    ...mapGetters('fieldRecordSystem/basicFertilization/applicationMaps', [
      'onlyPhSelected',
      'mapsToCreate'
    ]),
    ...mapFormFields('fieldRecordSystem/basicFertilization/applicationMaps', [
      'creationParameters.nutrients',
      'creationParameters.desiredZones'
    ]),
    selectedFieldIds () {
      return this.selectedFieldId ? [this.selectedFieldId] : []
    },
    validZoneCounts () {
      return [1, 3, 5, 7, 9]
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/applicationMaps', [
      'startYieldPlanning'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization/applicationMapsPreview', [
      'startApplicationMapPreview'
    ]),
    async next () {
      this.validate()
      this.validateField()
      this.validateNutrients()

      if (this.state !== 'success' || Object.values(this.fieldStates).some(x => x === 'error')) {
        notifications.error(this.$i18n.translate('bf.applicationMaps.notifications.basicSettingsValidationError'))
      } else {
        try {
          let limeRecommendationsComplete = true
          if (this.mapsToCreate.includes('ph')) {
            await smarterGet('/api/v2/base-fertilization/orders/{selectedFieldId}/lime-recommendations-complete/{harvestYear}', {
              id: 'lime-recommendations',
              expiry: 0,
              inputs: {
                selectedFieldId: () => this.selectedFieldId,
                harvestYear: () => this.harvestYear
              },
              onResult (result) {
                limeRecommendationsComplete = result
              }
            })
          }

          if (limeRecommendationsComplete) {
            this.startYieldPlanning()
          } else {
            notifications.error(this.$i18n.translate('bf.applicationMaps.notifications.missingLimeRecommendation'))
          }
        } catch (error) {
          console.error(error)
          notifications.error(this.$i18n.translate('bf.applicationMaps.notifications.basicSettingsValidationError'))
        }
      }
    },
    validateField () {
      this.$set(this.fieldStates, 'selectedFieldId', this.selectedFieldId ? 'success' : 'error')
    },
    validateNutrients () {
      this.$set(this.fieldStates, 'nutrients', Object.values(this.nutrients).some(x => x.selected) ? 'success' : 'error')
    }
  },
  watch: {
    selectedFieldId: 'validateField',
    nutrients: 'validateNutrients'
  }
}
</script>

<style lang="scss" scoped>
.basic-settings {
  display: flex;
  flex-direction: column;
}
.footer-buttons {
  display: flex;
  flex-direction: column;

  margin-top: 15px;

  .yield-navigation-button {
    margin-bottom: 5px;
  }
}

.desired-zones .hint-desired-zones {
  margin-top: 15px;
}

.loading-indicator {
  position: relative;
}

</style>
