<template>
  <div class="application-map-result-container">
    <div class="header">
      <h4>
        <IxRes v-if="fromView === 'applicationMapCreationResult'">Areas.ApplicationMaps.SR_ApplicationMaps.AlgorithmicApplicationMaps</IxRes>
        <IxRes v-else>Areas.ApplicationMaps.SR_ApplicationMaps.{{ fromView.replace('applicationMapCreationResult', '') }}</IxRes>
      </h4>
      <div class="creation-status">
        <!-- <FrsLoadingIndicator small :loading="!creationReady" /> -->
        <i v-if="creationReady" class="fa fa-check-circle" />
        <p class="info-text">
          <IxRes v-if="creationReady">Areas.ApplicationMaps.SR_ApplicationMaps.InfoCreatedApplicationMap</IxRes>
          <IxRes v-else>Areas.ApplicationMaps.SR_ApplicationMaps.InfoCalculateApplicationMap</IxRes>
        </p>
      </div>
      <hr>
    </div>
    <div class="map-details">
      <ApplicationMapDetails v-if="map" :map="map" />
    </div>
    <div class="button-container">
      <div v-if="$can('feature/application-map-download')">
        <MapPaymentTermsCheckbox v-model="termsAccepted" />
        <IxButton
          large icon="download"
          :disabled="!(termsAccepted)"
          @click="download"
        >
          <IxRes>Areas.ApplicationMaps.SR_ApplicationMaps.DownloadMap</IxRes>
        </IxButton>
      </div>
      <IxButton large icon="repeat" @click="createNewMap">
        <IxRes>Areas.ApplicationMaps.SR_ApplicationMaps.CreateAdditionalMap</IxRes>
      </IxButton>
      <IxButton large icon="folder-open" @click="toOverview">
        <IxRes>Areas.ApplicationMaps.SR_ApplicationMaps.ToMapAdministration</IxRes>
      </IxButton>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'

import MapPaymentTermsCheckbox from '@components/MapPaymentTermsCheckbox'
import IxButton from '@components/IxButton'
import ApplicationMapDetails from '@frs/components/map-management/details/ApplicationMapDetails'

import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'
import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    IxButton,
    ApplicationMapDetails,
    MapPaymentTermsCheckbox
  },
  mixins: [
    MirrorRouteMixin,
    PermissionMixin
  ],
  data () {
    return {
      termsAccepted: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      fromView: state => state.ui.view.right
    }),
    ...mapState('fieldRecordSystem/applicationMaps', {
      creationReady: state => state.data.applicationMapCalculated,
      creationFailed: state => state.data.applicationMapCreationFailed,
      applicationMapId: state => state.data.applicationMapId
    }),
    ...mapGetters('fieldRecordSystem/mapManagement', [
      'mapLookup'
    ]),
    map () {
      return this.mapLookup[this.applicationMapId]
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/simpleApplicationMaps', [
      'startSimpleApplicationMaps'
    ]),
    ...mapActions('fieldRecordSystem/maizeSowing', [
      'startMaizeSowing'
    ]),
    ...mapActions('fieldRecordSystem/simonModule', [
      'goBackToSimon'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization', [
      'startApplicationMapCreation'
    ]),
    ...mapActions('fieldRecordSystem/download', [
      'startDownload'
    ]),
    ...mapActions('fieldRecordSystem/applicationMaps', [
      'startCreation'
    ]),
    ...mapMutations('fieldRecordSystem/applicationMaps', [
      'resetCreationStatus',
      'reset'
    ]),
    download () {
      this.startDownload({ids: [this.applicationMapId], type: 'applicationMap'})
    },
    createNewMap () {
      this.reset()
      switch (this.fromView) {
      case 'applicationMapCreationResult':
        this.startCreation()
        break
      case 'applicationMapCreationResultSimpleApplicationMaps':
        // TODO: replace this action call by router push, once this result page supports routing
        this.startSimpleApplicationMaps()
        break
      case 'applicationMapCreationResultMaizeSowing':
        this.startMaizeSowing()
        break
      case 'applicationMapCreationResultBasicFertilization':
        this.startApplicationMapCreation()
        break
      case 'applicationMapCreationResultSimon':
        this.goBackToSimon()
        break
      }
    },
    toOverview () {
      this.reset()
      this.$router.push({name: this.createParallelRoute('mapManagement'), params: {...this.$route.params}})
    }
  },
  beforeDestroy () {
    this.resetCreationStatus()
  }
}
</script>

<style scoped>
hr {
  margin-top: 8px;
  border-color: #cbcbcb;
}
.creation-status {
  display: flex;
  justify-content: flex-start;
}
.fa-check-circle {
  margin-top: 0;
  margin-right: 4px;
  font-size: 3em;
  color: green;
}
.button-container {
  margin-top: 5px;
}
.button-container > * {
  margin: 0 0 4px 0;
}
</style>
