<template lang="html">
  <transition mode="out-in">
    <slot v-if="!loading" />
    <div v-else class="loading-indicator-container">
      <div class="loading-indicator" />
    </div>
  </transition>
</template>

<script>
export default {
  props: [
    'loading'
  ]
}
</script>

<style lang="scss" scoped>
.v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-active, .v-leave-active {
  transition: opacity 0.2s;
}
.loading-indicator-container {
  display: flex;
  overflow: hidden;
  flex: 1;
}
.loading-indicator {
  width: 40px;
  height: 40px;
  background-color: rgb(222, 222, 222);
  margin: auto;
  animation: 2s infinite loading;
  transition: transform 0.3s;
}
@keyframes loading {
  0% {
    transform: translate(-40px, -40px);
  }
  25% {
    transform: translate(40px, -40px);
  }
  50% {
    transform: translate(40px, 40px);
  }
  75% {
    transform: translate(-40px, 40px);
  }
  100% {
    transform: translate(-40px, -40px);
  }
}
</style>
