import { render, staticRenderFns } from "./ColumnMappingManual.vue?vue&type=template&id=c65ab42a&scoped=true&lang=html&"
import script from "./ColumnMappingManual.vue?vue&type=script&lang=js&"
export * from "./ColumnMappingManual.vue?vue&type=script&lang=js&"
import style0 from "./ColumnMappingManual.vue?vue&type=style&index=0&id=c65ab42a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c65ab42a",
  null
  
)

export default component.exports