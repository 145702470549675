<template lang="html">
  <div>
    <h3>
      <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.SamplingTime</IxRes>
    </h3>
    <h3>
      <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.Nutrients</IxRes>
    </h3>
    <NutrientSelector v-model="nutrientsProxy" hide-min-max />

    <h3>
      <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.ColumnMapping</IxRes>
    </h3>
    <div class="mapping-options">
      <SimpleSwitch v-model="useLabPreset">
        <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.UsePredefinedLab</IxRes>
      </SimpleSwitch>
      <SimpleSwitch v-model="ignoreClassification">
        <template #offLabel>
          <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.ImportContentClasses</IxRes>
        </template>
        <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.CalculateContentClasses</IxRes>
      </SimpleSwitch>
    </div>
    <br>
    <ColumnMappingLabPreset v-if="useLabPreset" />
    <ColumnMappingManual v-else />

    <h3>
      <IxRes>Common.SR_Common.Preview</IxRes>
    </h3>
    <ColumnMappingPreview />

    <IxButton next :disabled="!mayProceed" @click="finalizeImport" />
    <FrsLoadingIndicator :loading="loading">
      <IxButton cancel @click="loading = false" />
    </FrsLoadingIndicator>

    <div class="help-box">
      <HelpBox v-if="validRows.length !== parsedCsvData.length" type="warning">
        <template>
          <IxRes :context="{column: sampleIdColumn, countValidRows: validRows.length}">Areas.FieldRecordSystem.SR_BaseFertilization.ProbingResultUpload.HelpBox.InvalidRowsDetected</IxRes>
        </template>
      </HelpBox>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import SimpleSwitch from '@components/forms/SimpleSwitch'
import IxButton from '@components/IxButton'
import HelpBox from '@components/help/HelpBox'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import NutrientSelector from './application-maps/widgets/NutrientSelector'
import ColumnMappingLabPreset from './result-import/ColumnMappingLabPreset'
import ColumnMappingManual from './result-import/ColumnMappingManual'
import ColumnMappingPreview from './result-import/ColumnMappingPreview'

export default {
  components: {
    FrsLoadingIndicator,
    IxButton,
    SimpleSwitch,
    NutrientSelector,
    ColumnMappingLabPreset,
    ColumnMappingManual,
    ColumnMappingPreview,
    HelpBox
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', {
      sampleIdColumn: state => state.upload.columnMapping.sampleId
    }),
    ...mapGetters('fieldRecordSystem/basicFertilization/resultImport', [
      'mappingComplete',
      'validRows',
      'parsedCsvData'
    ]),
    ...mapFormFields('fieldRecordSystem/basicFertilization/resultImport', [
      'manual.nutrients',
      'upload.useLabPreset',
      'ignoreClassification'
    ]),
    nutrientsProxy: {
      get () {
        return ['phosphorus', 'potassium', 'magnesium', 'ph', 'lime'].reduce((lookup, nutrient) => Object.assign(lookup, {[nutrient]: {min: null, max: null, selected: this.nutrients.includes(nutrient)}}), {})
      },
      set (nutrientLookup) {
        this.nutrients = Object.keys(nutrientLookup).filter(nutrient => nutrientLookup[nutrient].selected)
      }
    },
    mayProceed () {
      return this.mappingComplete && this.nutrients.length
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/resultImport', [
      'finalizeImportSettings'
    ]),
    async finalizeImport () {
      this.loading = true
      await new Promise((resolve) => setTimeout(() => resolve(), 50))
      await this.finalizeImportSettings()
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.mapping-options {
  align-items: center;
  display: flex;

  > * {
    margin-right: 30px;
  }
}

.help-box {
  margin-top: 10px;
}
</style>
