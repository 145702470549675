<template lang="html">
  <div :class="$style.summary">
    <h3><IxRes>frs.zoneMaps.details.headers.userInput</IxRes></h3>

    <ZoneMapDetailsPanel>
      <template #header>
        <IxRes>frs.zoneMaps.details.parameters</IxRes>
      </template>

      <div :class="$style.parameters">
        <code>
          <pre>{{ JSON.stringify(showFullParameters ? metaData.parameters : abbreviatedInput, null, 2) }}</pre>
        </code>
      </div>

      <BsCheckbox v-model="showFullParameters" inline>
        <IxRes>frs.zoneMaps.details.inputs.showFullParameters</IxRes>
      </BsCheckbox>
    </ZoneMapDetailsPanel>

    <h3><IxRes>frs.zoneMaps.details.headers.imageSelection</IxRes></h3>

    <slot name="images" />

    <h3><IxRes>frs.zoneMaps.details.headers.algorithmResult</IxRes></h3>

    <label>
      <IxRes>frs.zoneMaps.details.algorithmType</IxRes>:
    </label>
    <IxRes>frs.zoneMaps.algorithm.option.{{ metaData.algorithmSummary.algorithmType }}</IxRes>

    <label>
      <IxRes>frs.zoneMaps.details.countOfClasses</IxRes>:
    </label>
    {{ metaData.algorithmSummary.intendedZoneCount }}

    <ZoneMapDetailsPanel>
      <template #header>
        <IxRes>frs.zoneMaps.details.selectedSatScenes</IxRes>
      </template>
      <div :class="$style.dates">
        <div
          v-for="(image, i) in metaData.algorithmSummary.availableImages" :key="i"
          :class="{
            [$style.ignored]: !metaData.algorithmSummary.usedImages.find(x => x.recordingDate === image.recordingDate && x.dataSource === image.dataSource)
          }"
        >
          <slot name="availableimages" :image="image" />
        </div>
      </div>
      <slot name="stdevmean" />
    </ZoneMapDetailsPanel>

    <ZoneMapDetailsPanel v-if="metaData.algorithmSummary.correlations.length">
      <template #header>
        <IxRes>frs.zoneMaps.details.correlations</IxRes>
      </template>

      <div v-for="(correlation, i) in metaData.algorithmSummary.correlations" :key="i">
        {{ correlation.from.recordingDate.substring(0, 4) }} ↔ {{ correlation.to.recordingDate.substring(0, 4) }}: {{ $i18n.format(correlation.correlationCoefficient, 'f2') }}
        <span v-if="correlation.correlationCoefficient >= metaData.algorithmSummary.minimumCorrelation">🆗</span>
        <span v-else>❌</span>
      </div>
    </ZoneMapDetailsPanel>
  </div>
</template>

<script>
import BsCheckbox from '@components/bootstrap/BsCheckbox'
import ZoneMapDetailsPanel from '@frs/components/map-management/details/ZoneMapDetailsPanel'

export default {
  components: {
    BsCheckbox,
    ZoneMapDetailsPanel
  },
  props: {
    metaData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showFullParameters: false
    }
  },
  computed: {
    abbreviatedInput () {
      const keysToAbbreviate = [
        'geometry',
        'cultivations',
        'selectedImage',
        'selectedImages'
      ]

      return Object.keys(this.metaData.parameters).reduce((obj, key) => {
        obj[key] = keysToAbbreviate.includes(key)
          ? '...'
          : this.metaData.parameters[key]

        return obj
      }, {})
    }
  }
}
</script>

<style lang="scss" module>
.summary, .parameters {
  display: flex;
  flex-direction: column;
}

.dates {
  display: flex;
  flex-direction: column;
}

.dates > .ignored {
  opacity: 0.5;
}
</style>
