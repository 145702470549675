<template lang="html">
  <ListCard
    show-avatar
    :selected="selected"
    class="cultivation-card" show-details
  >
    <template #title>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationType_{{ cultivation.type }}</IxRes>
    </template>

    <template #subtitle>
      <IxRes v-if="cultivation.type === 'main'">masterData.crops.usage.{{ cultivation.cropUsageId }}_name</IxRes>
    </template>

    <template #avatar>
      <FieldEditingCultivationAvatar :field-feature="fieldFeature" :cultivation="cultivation" />
    </template>

    <div>
      <span v-for="year in cultivation.harvestYears" :key="year" class="label label-default">
        {{ year | formatHarvestYear }}
      </span>
    </div>
  </ListCard>
</template>

<script>
import moment from 'moment'

import ListCard from '@components/card/ListCard'
import FieldEditingCultivationAvatar from './FieldEditingCultivationAvatar'

export default {
  components: {
    FieldEditingCultivationAvatar,
    ListCard
  },
  filters: {
    formatHarvestYear (year) {
      const yearAsMoment = moment({year})
      return `${yearAsMoment.clone().subtract(1, 'year').format('YY')}/${yearAsMoment.format('YY')}`
    }
  },
  props: {
    fieldFeature: Object,
    cultivation: {
      type: Object,
      required: true
    },
    selected: Boolean
  }
}
</script>

<style lang="scss" scoped>
.cultivation-card {
  cursor: pointer;
}
</style>
