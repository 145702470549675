<template lang="html">
  <div>
    <SimpleTable :data="[fertilizerNutrientContents]" :columns="columns" localize-headers />
  </div>
</template>

<script>
import SimpleTable from '@components/table/SimpleTable'

import ParameterMixin, {mapParameters} from '../ParameterMixin'

import columns from './nutrient-columns'

export default {
  components: {
    SimpleTable
  },
  mixins: [
    ParameterMixin
  ],
  props: {
    value: Object
  },
  data () {
    return {
      columns
    }
  },
  computed: {
    ...mapParameters([
      'fertilizerNutrientContents'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
