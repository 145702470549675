<template>
  <ZoneInput
    v-if="zoneMap"
    :zone-map="zoneMap"
    :value="null"
    :palette="zoneMapPalette"
  />
</template>

<script>
import {mapState} from 'vuex'

import ZoneInput from '@components/forms/ZoneInput'

export default {
  components: {
    ZoneInput
  },
  props: {
    zoneMapId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      zoneMapLookup: state => state.data.zoneMap.mapLookup,
      zoneMapPalette: state => state.ui.zoneMapPalette
    }),
    zoneMap () {
      return this.zoneMapLookup[this.zoneMapId]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
