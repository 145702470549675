<template lang="html">
  <div class="zone-editing-overlay">
    <button
      v-for="(zone, i) in zoneMap.zones" :key="zone.id"
      class="target-zone-button"
      :style="{backgroundColor: colors[i], color: textColors[i]}"
      @click="$emit('select', zone.id)"
    >
      {{ zone.number }}
    </button>
  </div>
</template>

<script>
import {mapToColors} from '@helpers/map-color-schemes'
import {readableTextColor} from '@helpers/color'

import OverlayMixin from '@components/map/mixins/OverlayMixin'

export default {
  mixins: [
    OverlayMixin
  ],
  props: {
    zoneMap: {
      type: Object,
      required: true
    },
    palette: {
      type: String,
      required: true
    }
  },
  computed: {
    colors () {
      return mapToColors(this.zoneMap, this.palette)
    },
    textColors () {
      return this.colors.map(readableTextColor)
    }
  }
}
</script>

<style lang="scss" scoped>
.zone-editing-overlay {
  display: flex;

  border: 1px solid black;
  border-radius: 2em;
  background-color: rgba(0, 0, 0, 0.4);
}

.target-zone-button {
  border-radius: 50%;
  width: 2em;
  height: 2em;

  border: 2px solid black;
  margin: 0.25em;

  font-size: 1.5em;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: border-color 0.25s;

  &:hover {
    border-color: white;
  }
}
</style>
