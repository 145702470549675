<template>
  <div>
    <div class="nitrogen-requirement">
      <NitrogenRequirement
        :nitrogen-requirement="nitrogenRequirement"
        :nitrogen-requirement-for-field="nitrogenRequirementForField"
      />
    </div>

    <PhosphorRequirement :phosphor-requirement="phosphorRequirement" />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import NitrogenRequirement from '../NitrogenRequirement'
import PhosphorRequirement from '../PhosphorRequirement'

const pContentClassLookup = {
  'A': 2,
  'B': 1.5,
  'C': 1,
  'D': 0.5,
  'E': 0
}

export default {
  components: {
    NitrogenRequirement,
    PhosphorRequirement
  },
  data () {
    return {
      hideInfo: false,
      kiloPerHectarUnit: 'kg/ha',
      tonUnit: 't'
    }
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      harvestYear: state => state.userSettings.harvestYear,
      fields: state => state.data.field
    }),
    ...mapState('fieldRecordSystem/navigation', {
      fieldId: state => state.location.fieldId
    }),
    ...mapState('fieldRecordSystem/nutrientRequirement', {
      nutrientRequirements: state => state.data.field,
      crops: state => state.data.editing.crops,
      phosphorusPlans: state => state.editBuffer.phosphorusPlans,
      nitrogenRequirement: state => state.editBuffer.nitrogenRequirement,
      totalPRevocation: state => state.editBuffer.totalPRevocation,
      averageYieldLast3Years: state => state.editBuffer.averageYieldLast3Years
    }),
    field () {
      return this.fields[this.fieldId]
    },
    fieldArea () {
      return this.field.area.geometrical
    },
    nutrientRequirementsForField () {
      return this.nutrientRequirements[this.fieldId]
    },
    currentNutrientRequirement () {
      return this.nutrientRequirements[this.fieldId].find(x => x.harvestYear === this.harvestYear)
    },
    nitrogenRequirementForField () {
      return this.nitrogenRequirement ? (this.nitrogenRequirement * (this.fieldArea / 10000)) : 0
    },
    pRequirementFor3Years () {
      return this.totalPRevocation ? this.totalPRevocation * pContentClassLookup[this.phosphorusPlans[0].phosphorusContentClass] : 0
    },
    pRequirementFor3YearsForField () {
      return this.pRequirementFor3Years ? this.pRequirementFor3Years * (this.fieldArea / 10000) : 0
    },
    nutrientRequirementFor3Years () {
      return {
        currentYear: this.nutrientRequirementsForField.find(x => x.harvestYear === this.harvestYear),
        secondYear: this.nutrientRequirementsForField.find(x => x.harvestYear === this.harvestYear + 1),
        thirdYear: this.nutrientRequirementsForField.find(x => x.harvestYear === this.harvestYear + 2)
      }
    },
    dataYears () {
      return [
        {
          currentYear: this.phosphorusPlans[0] ? this.averageYieldLast3Years : 0,
          secondYear: this.phosphorusPlans[1] ? this.phosphorusPlans[1].expectedYield : 0,
          thirdYear: this.phosphorusPlans[2] ? this.phosphorusPlans[2].expectedYield : 0
        },
        {
          currentYear: this.phosphorusPlans[0] ? this.phosphorusPlans[0].phosphorusRequirement : 0,
          secondYear: this.phosphorusPlans[1] ? this.phosphorusPlans[1].phosphorusRequirement : 0,
          thirdYear: this.phosphorusPlans[2] ? this.phosphorusPlans[2].phosphorusRequirement : 0
        }
      ]
    },
    phosphorRequirement () {
      return {
        pRequirementFor3Years: this.pRequirementFor3Years, // Düngeempfehlung für 3 Jahre nach Bodengehaltsklassen
        pRequirementFor3YearsForField: this.pRequirementFor3YearsForField, // Menge für den gesamten Schlag für drei Jahre
        totalPRevocation: this.totalPRevocation, // Summe P-Entzug Fruchtfolge
        data: this.dataYears
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .header {
    h3 {
      margin: 0px;
    }
    h4 {
      margin: 0px;
    }
    border-bottom: 1px solid #7b7b7b;
    margin-bottom: 5px;
  }

  .nitrogen-requirement {
    border-bottom: 1px solid #7b7b7b;
    margin-bottom: 5px;
  }
</style>
