<template lang="html">
  <div class="grid-wrapper">
    <div class="loading-indicator text-center">
      <i class="fa fa-cog fa-spin fa-5x" />
    </div>
    <div class="grid-container fade">
      <script class="data" type="application/json">
        {{ JSON.stringify(data) }}
      </script>
      <script class="columnoptions" type="application/json">
        {{ JSON.stringify(columnOptions) }}
      </script>
      <table
        ref="table" :data-title="title"
        :data-subtitle="subtitle" class="table table-condensed table-striped display"
        :data-selection="selection" :data-minimal="minimal"
        :data-paging="paging"
      >
        <tbody />
      </table>
      <div
        ref="table-buttons" class="dt-buttons btn-group edit-button-group"
        :data-add-mode="inline ? 'Inline' : 'None'"
        :data-edit-mode="inline ? 'Inline' : 'None'" :data-delete-mode="inline ? 'Inline' : 'None'"
      />
    </div>
  </div>
</template>

<script>
const defaultColumnOptions = {
  minDecimalDigits: 2,
  maxDecimalDigits: 2,
  dateTimeFormat: 'L LT',
  guidSelectItems: null,
  isIdColumn: false,
  invisible: false,
  readonly: false, // editor ?
  required: false, // editor ?
  nullable: false, // editor ?
  loadAction: null, // ajax
  customTitle: null, // ?
  localizedTitle: '',
  fallbackTitle: '', // editor ?
  typeName: '',
  defaultValue: null, // editor
  propertyName: '',
  order: 0,
  create: false // editor ?
}

export default {
  props: {
    value: {type: Array, default: () => []},
    data: {type: Array, required: true},
    idAccessor: Function,
    // schema: {type: Object, required: true},
    columns: {type: Array, required: true},
    // header
    title: {type: String, default: ''},
    subtitle: String,
    // options
    minimal: Boolean,
    paging: Boolean,
    selection: String, // 'single', 'multiple', 'os',
    addButton: Boolean,
    editButton: Boolean,
    deleteButton: Boolean,
    customButtons: Array,
    inline: Boolean
  },
  data () {
    return {
      grid: null,
      skipEvents: false,
      addListener: null,
      editListener: null,
      deleteListener: null
    }
  },
  computed: {
    processedData () {
      if (!this.grid) return []

      const rows = this.data.map(this.grid.converter.processJSObject)
      rows.forEach((x, i) => {
        x.DT_RowId = x.DT_RowId || `legacyGrid_row_${i}`
      })
      return rows
    },
    columnOptions () {
      return this.columns.map(x => Object.assign({}, defaultColumnOptions, x))
    }
  },
  methods: {
    setup () {
      require('src/coffee/controls/grid')
      const options = {
        selection: this.selection,
        paging: this.paging,
        minimal: this.minimal,
        promises: {addedToDom: Promise.resolve()},
        customButtons: this.customButtons
      }

      this.grid = $(this.$refs.table).staticgrid(options)[0]
      // tab column width fix
      let el = this.$el
      while (el) {
        if (el.classList && el.classList.contains('tab-pane')) {
          el.addEventListener('shown', () => {
            this.$nextTick(() => this.grid.table.columns.adjust())
          })
          break
        }
        el = el.parentNode
      }
      //
      this.grid.table.on('change.selection', event => {
        if (!this.skipEvents) {
          this.$emit('input', event.rows)
          this.setButtonState(event.rows)
        }
      })
      // bind button events
      if (!this.inline) {
        this.setButtonState([])
      } else {
        // NOTE inline editing does not work properly, would need refactoring of core grid code
        this.grid.editor.on('submitSuccess', () => {
          // console.log(event)
          // const mod = this.grid.editor.modifier()
          // console.log(this.grid.table.cell(mod).index())
          this.$emit('change', this.grid.state.rows())
        })
        // workaround for lack of editor use on row addition
        this.grid.$table.on('postCreate', () => {
          this.$emit('add')
        })
        this.grid.editor.on('postEdit', () => {
          this.$emit('edit')
        })
        this.grid.editor.on('postRemove', () => {
          this.$emit('delete')
        })
      }
    },
    setButtonState (selection) {
      for (const name of ['add', 'edit', 'delete']) {
        const disableButton = () => {
          this.grid.editControls[name].$button.disable()
          const listener = this[`${name}Listener`]
          if (listener) {
            this.grid.editControls[name].$button.get(0).removeEventListener('click', listener)
            this[`${name}Listener`] = null
          }
        }

        if (this[`${name}Button`]) {
          // adding handlers to grid buttons, making sure to not add the selection independent 'add' handler twice
          if ((selection.length || name === 'add') && !this[`${name}Listener`]) {
            this.grid.editControls[name].$button.enable()
            const listener = event => {
              this.$emit(name)
              event.preventDefault()
            }
            this[`${name}Listener`] = listener
            this.grid.editControls[name].$button.get(0).addEventListener('click', listener)
          }
          // remove handlers from edit/delete grid buttons if selection is empty
          if (!selection.length && name !== 'add') {
            disableButton()
          }
        } else {
          disableButton()
        }

        if (this.customButtons) {
          if (selection.length) {
            this.customButtons.forEach(button => {
              $(this.$refs['table-buttons']).find(`#id${button.id}`).enable()
            })
          } else {
            this.customButtons.forEach(button => {
              $(this.$refs['table-buttons']).find(`#id${button.id}`).disable()
            })
          }
        }
      }
    },
    refreshButtonState () {
      this.setButtonState(this.value)
    }
  },
  watch: {
    processedData (rows) {
      if (!this.grid) return

      this.grid.table.rows().deselect()
      this.grid.table.rows().remove()
      this.grid.table.rows.add(rows)

      this.grid.table.draw(false)
    },
    value (rows) {
      if (!this.idAccessor) return

      // NOTE experimental
      const indices = rows.map(x => this.data.indexOf(this.data.find(item => this.idAccessor(x) === this.idAccessor(item))))

      this.skipEvents = true
      this.grid.table.rows().deselect()
      this.grid.table.rows(indices).select().draw(false)
      this.skipEvents = false

      this.refreshButtonState()
    },
    title (title) {
      this.grid.$container.find('.grid-header > .lead').text(title)
    },
    addButton: 'refreshButtonState',
    editButton: 'refreshButtonState',
    deleteButton: 'refreshButtonState'
  },
  mounted () {
    require.ensure('src/coffee/controls/grid', this.setup)
  }
}
</script>

<style lang="scss" scoped>
</style>
