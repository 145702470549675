<template>
  <div class="soil-map-details-container">
    <h4>
      <IxRes>frs.mapManagement.soilMap.details.heading.{{ `MapCategory_${map.category}` }}</IxRes>
    </h4>

    <CommonDetails :map="map" />

    <CollapsibleSection>
      <template #header>
        <IxRes v-if="map.category === 'caOApplicationMap' || map.category === 'caORequiredNutrient'">Areas.ApplicationMaps.SR_ApplicationMaps.ApplicationValue</IxRes>
        <IxRes v-else>frs.mapManagement.details.soilMaps.soilParameter</IxRes>
      </template>
      <div class="legend-container">
        <ZoneValues :map="map" />
      </div>
    </CollapsibleSection>

    <CollapsibleSection v-if="map.humusClasses">
      <template #header>
        <IxRes>frs.mapManagement.details.soilMaps.humusClasses</IxRes>
      </template>
      <HumusClasses :map="map" />
    </CollapsibleSection>

    <div v-if="map.metaData">
      <div v-if="map.category === 'caORequiredNutrient'">
        <CollapsibleSection>
          <template #header>
            <IxRes>frs.mapManagement.details.soilMaps.metaData</IxRes>
          </template>
          <RequiredNutrientDetails :map="map" />
        </CollapsibleSection>
      </div>
      <div v-if="map.category === 'caOApplicationMap'">
        <CollapsibleSection>
          <template #header>
            <IxRes>frs.mapManagement.details.soilMaps.metaData</IxRes>
          </template>
          <ApplicationMapDetails :map="map" />
        </CollapsibleSection>
      </div>
      <div v-if="map.metaData.validation">
        <CollapsibleSection>
          <template #header>
            <IxRes>frs.mapManagement.details.soilMaps.metaData</IxRes>
          </template>
          <ValidationDetails :map="map" />
        </CollapsibleSection>
      </div>
    </div>
    <PhBbMapLayer :map="map" auto-focus />
  </div>
</template>

<script>
import PhBbMapLayer from '@frs/components/base/PhBbMapLayer'
import CollapsibleSection from '@components/CollapsibleSection'

import CommonDetails from './soil-map-details/CommonDetails'
import ZoneValues from './soil-map-details/ZoneValues'
import HumusClasses from './soil-map-details/HumusClasses'
import RequiredNutrientDetails from './soil-map-details/RequiredNutrientDetails'
import ApplicationMapDetails from './soil-map-details/ApplicationMapDetails'
import ValidationDetails from './soil-map-details/ValidationDetails'

export default {
  components: {
    CollapsibleSection,
    PhBbMapLayer,
    CommonDetails,
    ZoneValues,
    HumusClasses,
    RequiredNutrientDetails,
    ApplicationMapDetails,
    ValidationDetails
  },
  props: {
    map: Object
  }
}
</script>

<style lang="scss" scoped>
.legend-container {
  display: flex;
  flex-direction: column;
}
</style>
