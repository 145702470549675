<template>
  <div class="selection-container">
    <div>
      <VueSelectize
        v-model="selectedMapId" required
        allow-empty :options="fertilizationOptions"
        name="applicationOptions" :fields="fields"
      >
        <template #label>
          <IxRes>Common.SR_Common.MapSelection</IxRes>
        </template>
      </VueSelectize>
      <div class="product">
        <label><IxRes>Common.SR_Common.Product</IxRes>:</label>
        <p class="product-name">
          <span v-if="productName">{{ productName }}</span>
          <IxRes v-else>Common.SR_Common.NoProduct</IxRes>
        </p>
      </div>

      <BsPanel v-if="selectedApplication != null && selectedApplication.type === 'application' " title="Datensatzstatistiken">
        <div class="crop">
          <label class="crop-name"><IxRes>Common.SR_Common.MaxYield</IxRes>:</label>
          <p class="crop-name">
            {{ selectedApplication.max }}
          </p>
          <label class="crop-name"><IxRes>Common.SR_Common.MinYield</IxRes>:</label>
          <p class="crop-name">
            {{ selectedApplication.min }}
          </p>
        </div>

        <div class="crop">
          <label class="crop-name"><IxRes>Common.SR_Common.AverageYield</IxRes>:</label>
          <p class="crop-name">
            {{ $i18n.format(selectedApplication.average, 'f2') }}
          </p>
          <label class="crop-name"><IxRes>Common.SR_Common.StandardDeviation</IxRes>:</label>
          <p class="crop-name">
            {{ $i18n.format(selectedApplication.stdDeviation, 'f2') }}
          </p>
        </div>
      </BsPanel>
      <div v-if="pointData">
        <div>
          <NumericInput
            v-model="minValue" required
            hide-description
            name="minValue" unit="kg/ha"
          >
            <template #label>
              <IxRes>Common.SR_Common.Minimum</IxRes>:
            </template>
          </NumericInput>
        </div>
        <div>
          <NumericInput
            v-model="maxValue" required
            hide-description
            name="maxValue" unit="kg/ha"
          >
            <template #label>
              <IxRes>Common.SR_Common.Maximum</IxRes>:
            </template>
          </NumericInput>
        </div>
      </div>
      <div>
        <NumericInput
          v-model="withdrawalFactor" required
          hide-description
          name="withdrawalFactor"
          percent percent-not-normalized
        >
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_SimonModule.NutrientPortion</IxRes>
          </template>
        </NumericInput>
      </div>
    </div>
    <div class="button-container">
      <IxButton
        save large
        :disabled="!isValid"
        @click="save"
      />
      <IxButton cancel large @click="cancel" />
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import BsPanel from 'src/vue/components/bootstrap/BsPanel.vue'
import VueSelectize from '@components/VueSelectize'
import IxButton from '@components/IxButton'
import NumericInput from '@components/forms/NumericInput'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    IxButton,
    VueSelectize,
    NumericInput,
    BsPanel
  },
  mixins: [
    FormPartMixin
  ],
  computed: {
    ...mapFormFields('fieldRecordSystem/simonModule', [
      'applicationDetails.id',
      'applicationDetails.withdrawalFactor',
      'applicationDetails.selectedMapId'
    ]),
    ...mapFormFields('fieldRecordSystem/simonModule', {
      'applicationDetails.minValue': {mutation: 'setApplicationMinValue'},
      'applicationDetails.maxValue': {mutation: 'setApplicationMaxValue'}
    }),
    ...mapState('fieldRecordSystem/simonModule', {
      AllapplicationMetaData: state => state.data.applicationMetaData
    }),
    ...mapGetters('masterData', [
      'products'
    ]),
    applicationMetaData () {
      return this.AllapplicationMetaData.filter(x => x.type === 'application')
    },
    productName () {
      const selectedMap = this.applicationMetaData.find(x => x.id === this.selectedMapId)
      return selectedMap ? this.products[selectedMap.productId].name : null
    },
    fertilizationOptions () {
      return this.applicationMetaData.map(map => map.id)
    },
    pointData () {
      const selectedMap = this.applicationMetaData.find(x => x.id === this.selectedMapId)
      return selectedMap ? selectedMap.type === 'application' : null
    },
    isValid () {
      return this.state === 'success'
    },
    fields () {
      return {
        text: id => this.applicationMetaData.find(map => map.id === id).name
      }
    },
    selectedApplication () {
      return this.applicationMetaData.find(x => x.id === this.selectedMapId)
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/simonModule', [
      'saveProductionDetails'
    ]),
    ...mapMutations('fieldRecordSystem/simonModule', [
      'resetApplicationData'
    ]),
    ...mapMutations('fieldRecordSystem', [
      'setRightView'
    ]),
    cancel () {
      this.setRightView('simonModule')
    },
    save () {
      this.validate()

      this.$nextTick()
        .then(() => {
          if (!this.isValid) return
          this.saveProductionDetails()
          this.setRightView('simonModule')
        })
    }
  },
  beforeDestroy () {
    this.resetApplicationData()
  }
}
</script>

<style scoped>
.selection-container {
  display: flex;
  flex-direction: column;
}
.product {
  display: flex;
  margin-top: 5px;
}
.product-name {
  margin-left: 8px;
}
.button-container {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}
.crop {
  display: flex;
  margin-top: 5px;
}
.crop-name {
  margin-left: 8px;
}
</style>
