<template lang="html">
  <div>
    <IxVectorLayer
      layer-id="actions"
      :features="actionFeatures"
      :vector-style="styles.action"
    />
    <IxVectorLayer
      layer-id="borders"
      :features="borderFeatures"
      :vector-style="styles.border"
    />
  </div>
</template>

<script>
import {helpers} from '@turf/turf'
import {mapState, mapGetters} from 'vuex'

import {fieldBorder, action} from '@frs/map-styles'

import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  components: {
    IxVectorLayer
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      fieldData: state => state.data.field
    }),
    ...mapGetters('fieldRecordSystem', [
      'fieldsForCurrentOrgUnit',
      'entityLookup'
    ]),
    ...mapState('fieldRecordSystem/action/editBuffer', [
      'type'
    ]),
    ...mapGetters('fieldRecordSystem/action/editBuffer', [
      'finalActionFeatures'
    ]),
    borderFeatures () {
      return this.fieldsForCurrentOrgUnit
        .filter(field => this.fieldData[field.id].geometry)
        .map(field => helpers.feature(this.fieldData[field.id].geometry, {
          id: field.id,
          name: field.name
        }))
    },
    actionFeatures () {
      return Object.keys(this.finalActionFeatures).map(fieldId => {
        return {
          ...this.finalActionFeatures[fieldId],
          properties: {
            id: fieldId,
            type: this.type
          }
        }
      })
    },
    styles () {
      return {
        action,
        border: fieldBorder
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
