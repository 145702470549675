<template lang="html">
  <div>
    <ProductSelection v-model="modelProxy" :procedure="action.procedure" />

    <FertilizationAmount v-model="modelProxy" :action="action" />

    <template v-if="action.fieldIds.length > 1">
      <CollapsibleSection v-model="showCharts">
        <template #header>
          <IxRes>frs.action.planning.fertilization.org.requirementCharts.header</IxRes>
        </template>
        <template #hint-show>
          <IxRes>frs.action.planning.fertilization.org.requirementCharts.showHint</IxRes>
        </template>
        <template #hint-hide>
          <IxRes>frs.action.planning.fertilization.org.requirementCharts.hideHint</IxRes>
        </template>

        <template v-for="fieldId in action.fieldIds">
          <label :key="`${fieldId}-label`">{{ entityNameLookup[fieldId] }}:</label>

          <RequirementChart :key="`${fieldId}-chart`" :nutrient-status="nutrientStatusLookup[fieldId]" />
        </template>
      </CollapsibleSection>

      <h4>
        <IxRes>frs.action.planning.fertilization.requirementSummaryHeader</IxRes>:
      </h4>
      <RequirementSummary :action="action" :nutrient-status="nutrientStatusLookup" />

      <CollapsibleSection v-model="showTables">
        <template #header>
          <IxRes>frs.action.planning.fertilization.org.nutrientSummaries.header</IxRes>
        </template>
        <template #hint-show>
          <IxRes>frs.action.planning.fertilization.org.nutrientSummaries.showHint</IxRes>
        </template>
        <template #hint-hide>
          <IxRes>frs.action.planning.fertilization.org.nutrientSummaries.hideHint</IxRes>
        </template>

        <label>
          <IxRes :context="{0: orgUnitName}">Areas.FieldRecordSystem.SR_Action.OrgUnitNutrientStatus</IxRes>:
        </label>
        <NutrientSummary :nutrient-status="nutrientStatusOrgUnit" />

        <div v-for="fieldId in action.fieldIds" :key="fieldId">
          <FieldInfoTitle :action="action" :field-id="fieldId" />
          <NutrientSummary :details="value[fieldId]" :actions="actionLookup[fieldId] || []" :nutrient-status="nutrientStatusLookup[fieldId]" /><!-- for single field -->
        </div>
      </CollapsibleSection>
    </template>
    <template v-else>
      <label>
        <IxRes>Areas.FieldRecordSystem.SR_Action.SingleFieldNutrientStatus</IxRes>:
      </label>
      <NutrientSummary :details="value[action.fieldIds[0]]" :actions="actionLookup[action.fieldIds[0]] || []" :nutrient-status="nutrientStatusLookup[action.fieldIds[0]]" />

      <label>

        <IxRes>Areas.FieldRecordSystem.SR_Action.SingleFieldFertilizationRequirements</IxRes>:
      </label>
      <RequirementChart :nutrient-status="nutrientStatusLookup[action.fieldIds[0]]" />
    </template>
    <FrsLoadingIndicator small :loading="loadingData" />
  </div>
</template>

<script>
import {flatten, uniq, sumBy} from 'lodash'
import {mapState, mapGetters} from 'vuex'
import axios from 'axios'

import {modelProxy} from '@helpers/vuex'

import CollapsibleSection from '@components/CollapsibleSection'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import ProductSelection from './ProductSelection'
import FertilizationAmount from './FertilizationAmount'
import RequirementChart from './RequirementChart'
import RequirementSummary from './RequirementSummary'
import NutrientSummary from './NutrientSummary'
import FieldInfoTitle from './FieldInfoTitle'

import ParameterMixin, {mapParameters} from '../ParameterMixin'

export default {
  components: {
    CollapsibleSection,
    FrsLoadingIndicator,
    ProductSelection,
    FertilizationAmount,
    RequirementChart,
    RequirementSummary,
    NutrientSummary,
    FieldInfoTitle
  },
  mixins: [
    ParameterMixin
  ],
  data () {
    return {
      showTables: false,
      showCharts: false,
      loadingData: false,
      nutrientRequirements: {}
    }
  },
  computed: {
    modelProxy, // TODO find out what this is, maybe a details lookup?
    ...mapState('fieldRecordSystem/action', {
      actionLookup: state => state.data.editing.actionsByField
    }),
    ...mapGetters('fieldRecordSystem', [
      'entityNameLookup',
      'orgUnitName'
    ]),
    ...mapParameters([
      'isLiquid',
      'useCustomProduct',
      'fertilizerData'
    ]),
    ...mapState('fieldRecordSystem', {
      harvestYear: state => state.userSettings.harvestYear
    }),
    nutrientStatusLookup () {
      return this.action.fieldIds.reduce((lookup, fieldId) => {
        const {fertilizerNutrientContents} = this.action.details[fieldId].parameters

        const nutrients = Object.keys(fertilizerNutrientContents)

        const sumByNutrient = (nutrientAmounts1, nutrientAmounts2) => nutrients.reduce((obj, nutrient) => {
          obj[nutrient] = (nutrientAmounts1[nutrient] || 0) + (nutrientAmounts2[nutrient] || 0)
          return obj
        }, {})

        const multiplyNutrientAmounts = (nutrientAmounts, factor) => nutrients.reduce((obj, nutrient) => {
          obj[nutrient] = ((nutrientAmounts[nutrient] || 0) * factor) || 0
          return obj
        }, {})

        const sumReducer = (runningTotal, actionOrNutrientAmount) => {
          if (actionOrNutrientAmount.type) {
            const action = actionOrNutrientAmount

            // currently if no product or amount has been entered yet, productQuantities is an empty array
            // after one has been selected, all but this property are initialized to `null`
            if (action.productQuantities.length === 0 || action.productQuantities[0].amount === null) {
              return runningTotal
            }

            const {amount, unit} = action.productQuantities[0]
            const {isLiquid} = action.parameters
            const amountInPercent = amount / 100

            let correctedAmount
            if (isLiquid) {
              // const specificWeight = ?
              const density = this.fertilizerData.density

              const V = unit === 'l/ha' ? (amount / 1000.0) : amount
              // const g = 9.80665

              // correctedAmount = V * specificWeight / g
              correctedAmount = V * density
            } else {
              correctedAmount = unit === 'dt/ha' ? amountInPercent * 100 : amountInPercent
            }
            // TODO calculate correct nutrient amounts in kg/ha depending on productQuantity unit

            const nutrientAmounts = multiplyNutrientAmounts(fertilizerNutrientContents, correctedAmount)

            return sumByNutrient(runningTotal, nutrientAmounts)
          } else {
            const nutrientAmount = actionOrNutrientAmount

            return sumByNutrient(runningTotal, nutrientAmount)
          }
        }

        const previousFertilizationActions = (this.actionLookup[fieldId] || []).filter(x => x.id !== this.action.id && x.type === 'fertilization' && x.timestamps.actual.end) || []
        const plannedFertilizationActions = (this.actionLookup[fieldId] || []).filter(x => x.id !== this.action.id && x.type === 'fertilization' && !x.timestamps.actual.end) || []

        const seed = () => ({
          nitrogen: 0,
          phosphorus: 0,
          potassium: 0,
          nitrate: 0,
          ammonium: 0,
          nitrogenAmide: 0,
          magnesium: 0,
          sulphur: 0
        })

        const previousFertilizationAmounts = previousFertilizationActions.reduce(sumReducer, seed())
        const plannedFertilizationAmounts = plannedFertilizationActions.reduce(sumReducer, seed())

        const currentFertilizationAmounts = [{type: 'fertilization', ...this.value[fieldId]}].reduce(sumReducer, seed())

        const allAmounts = [
          multiplyNutrientAmounts(this.nutrientRequirements, -1),
          previousFertilizationAmounts,
          plannedFertilizationAmounts,
          currentFertilizationAmounts
        ]
        const remaining = allAmounts.reduce(sumReducer, {})

        for (const nutrient in remaining) {
          if (this.nutrientRequirements[nutrient] === undefined) {
            delete remaining[nutrient]
          }
        }

        const data = [
          {type: 'required', ...this.nutrientRequirements},
          {type: 'previous', ...previousFertilizationAmounts},
          {type: 'planned', ...plannedFertilizationAmounts},
          {type: 'current', ...currentFertilizationAmounts},
          {type: 'remaining', ...remaining}
        ]

        if (!lookup[fieldId]) {
          lookup[fieldId] = null
        }
        lookup[fieldId] = {fieldId, data}
        return lookup
      }, {})
    },
    nutrientStatusOrgUnit () {
      const nutrients = Object.keys(this.nutrientStatusLookup).map(fieldId => this.nutrientStatusLookup[fieldId].data)

      const props = ['nitrogen', 'phosphorus', 'potassium', 'nitrate', 'ammonium', 'nitrogenAmide']
      const flat = flatten(nutrients)
      const names = uniq(flat.map(x => x.type)) // required, previous, planned...

      const data = names.map((name) => {
        const nutrientsByName = flat.filter(x => x.type === name)

        const sumByType = props.reduce((lookup, prop) => {
          const sum = sumBy(nutrientsByName, prop)
          if (sum !== undefined) {
            lookup[prop] = sum
          }
          return lookup
        }, {})

        return Object.assign({}, sumByType, {type: name})
      })

      return {data}
    }
  },
  created () {
    this.loadingData = true
    this.action.fieldIds.forEach(fieldId => {
      axios.get(`/api/v2/frs/${this.harvestYear}/fields/${fieldId}/nutrient-requirements`)
        .then(response => response.data)
        .then((nutrients) => {
          const latestNutrient = nutrients[nutrients.length - 1] || {} // nutrients sorted by Date
          const nitrogen = latestNutrient.nitrogenRequirement || 0
          const phosphorus = latestNutrient.phosphorusRequirement || 0
          this.nutrientRequirements = {nitrogen, phosphorus}
        })
        .finally(() => {
          this.loadingData = false
        })
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
