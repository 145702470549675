<template>
  <div>
    <ShowDetails>
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.WaterAmount</IxRes>:
      </template>

      {{ part.parameters.waterAmount }} {{ $i18n.translateUnit('mm/m²') }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.WaterExtractionSource</IxRes>:
      </template>

      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.WaterExtractionSource_{{ part.parameters.waterExtraction }}</IxRes>
    </ShowDetails>
  </div>
</template>

<script>
import ShowDetails from './ShowDetails'

export default {
  components: {
    ShowDetails
  },
  props: {
    part: Object
  }
}
</script>

<style scoped>
p {
  margin-left: 5px;
}
</style>
