<template>
  <div class="preview-container">
    <NavigationHeader hide-back-button>
      <template #step>
        <IxRes>bf.applicationMapPreview.header.title</IxRes>
      </template>
    </NavigationHeader>

    <div v-if="selectedFieldId">
      <div class="data-table">
        <SimpleTable
          :data="tableData"
          :columns="tableColumns"
          :highlight="highlight"
          localize-headers
        >
          <template slot="edit" slot-scope="{row}">
            <a href="#" @click.stop.prevent="loadApplicationMap(row.id)">
              <IxRes>bf.applicationMapPreview.selectMap</IxRes>
            </a>
          </template>
        </SimpleTable>
      </div>

      <PostProcessing />
    </div>
    <div v-else>
      <HelpBox>
        <IxRes>bf.applicationMapPreview.hints.fieldSelection</IxRes>
      </HelpBox>
    </div>

    <div class="footer-buttons">
      <IxButton next large @click="openMapManagement">
        <IxRes>bf.applicationMaps.preview.goToMapManagement</IxRes>
      </IxButton>

      <IxButton refresh large @click="refreshData">
        <IxRes>bf.applicationMaps.preview.refreshData</IxRes>
      </IxButton>

      <IxButton
        class="btn-warning"
        refresh large
        :disabled="!applicationMap"
        @click="resetInputs"
      >
        <IxRes>bf.applicationMap.preview.resetInputs</IxRes>
      </IxButton>

      <IxButton
        remove large
        :disabled="!applicationMap"
        @click="showDeleteConfirmationModal = true"
      >
        <IxRes>bf.applicationMaps.preview.map.delete</IxRes>
      </IxButton>

      <IxButton
        :disabled="!applicationMap || !productId"
        large
        save
        @click="showSaveConfirmationModal = true"
      />
    </div>
    <ConfirmationModal
      v-model="showSaveConfirmationModal"
      @confirm="saveMap"
    >
      <template #title>
        <IxRes>bf.applicationMaps.preview.modal.title.saveMap</IxRes>
      </template>

      <IxRes>bf.applicationMaps.preview.modal.title.saveMapQuestion</IxRes>
    </ConfirmationModal>

    <ConfirmationModal
      v-model="showDeleteConfirmationModal"
      @confirm="deleteMap"
    >
      <template #title>
        <IxRes>bf.applicationMaps.preview.modal.title.deleteMap</IxRes>
      </template>

      <IxRes>bf.applicationMaps.preview.modal.title.deleteMapQuestion</IxRes>
    </ConfirmationModal>

    <FrsLoadingIndicator small :loading="isLoading" :requests="['orgUnit.bf.applicationMaps.preview', 'bf.applicationMap.preview.delete']" />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import {orderBy} from 'lodash'

import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'
import HelpBox from '@components/help/HelpBox'
import SimpleTable from '@components/table/SimpleTable'

import PostProcessing from './PostProcessing'
import NavigationHeader from '../../widgets/NavigationHeader'

import makeReloadDataMixin from '@frs/mixins/make-reload-data-mixin'
import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import ConfirmationModal from '@components/modals/ConfirmationModal'

export default {
  components: {
    NavigationHeader,
    SimpleTable,
    PostProcessing,
    IxButton,
    HelpBox,
    FrsLoadingIndicator,
    ConfirmationModal
  },
  mixins: [
    makeReloadDataMixin([
      state => state.fieldRecordSystem.navigation.location.fieldId
    ]),
    MirrorRouteMixin
  ],
  data () {
    return {
      showSaveConfirmationModal: false,
      showDeleteConfirmationModal: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/applicationMapsPreview', {
      selectedFieldId: state => state.selectedFieldId,
      applicationMaps: state => state.data.applicationMaps,
      applicationMapIdsByFieldId: state => state.data.applicationMapIdsByFieldId,
      applicationMap: state => state.ui.applicationMap,
      productId: state => state.ui.productId
    }),
    tableData () {
      const applicationMapIds = this.applicationMapIdsByFieldId[this.selectedFieldId]
      return orderBy(this.applicationMaps.filter(map => applicationMapIds.includes(map.id)), ['generatedAt'], ['desc'])
    },
    tableColumns () {
      return {
        edit: '',
        name: {
          title: 'bf.applicationMaps.preview.table.labels.name',
          sortable: true,
          type: 'string'
        },
        mapType: {
          title: 'bf.applicationMaps.preview.table.labels.mapType',
          sortable: true,
          type: 'string',
          value: map => this.$i18n.translate(`bf.postProcessing.helpBoxes.productDetails.nutrient.${this.mapType(map.nutrient.toLowerCase())}`)
        },
        generatedAt: {
          title: 'bf.applicationMaps.preview.table.labels.date',
          sortable: true,
          type: 'datetime'
        }
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/applicationMapsPreview', [
      'loadApplicationMap',
      'deleteSelectedMap',
      'saveApplicationMap',
      'loadApplicationMapsPreview'
    ]),
    ...mapMutations('fieldRecordSystem/basicFertilization/applicationMapsPreview', [
      'resetInputs'
    ]),
    ...mapMutations('dataLoading', [
      'invalidate'
    ]),
    async saveMap () {
      this.isLoading = true

      try {
        await this.saveApplicationMap()
        notifications.success(this.$i18n.translate('bf.applicationMaps.preview.notifications.saveMap.success'))
      } catch (error) {
        console.error(error)
        notifications.error(this.$i18n.translate('bf.applicationMaps.preview.notifications.saveMap.error'))
      }
      this.isLoading = false
    },
    async deleteMap () {
      this.isLoading = true
      try {
        await this.deleteSelectedMap()
        notifications.success(this.$i18n.translate('bf.applicationMaps.preview.notifications.deleteMap.success'))
      } catch (error) {
        console.error(error)
        notifications.error(this.$i18n.translate('bf.applicationMaps.preview.notifications.deleteMap.error'))
      }
      this.isLoading = false
    },
    openMapManagement () {
      this.$router.push({name: this.createParallelRoute('mapManagement'), params: {fieldId: this.selectedFieldId}})
    },
    highlight (row) {
      const applicationMap = this.applicationMap
      return applicationMap ? (applicationMap.id === row.id ? 'info' : '') : ''
    },
    mapType (type) {
      return type === 'ph' ? 'lime' : type
    },
    refreshData () {
      this.invalidate('orgUnit.bf.applicationMaps.preview')
      this.loadApplicationMapsPreview()
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.footer-buttons {
  display: flex;

  & > :nth-child(even) {
    margin: 0 5px 0 5px;
  }

  margin-top: 10px;
}

.data-table {
  max-height: 400px;
  overflow-y: auto;
}
</style>
