<template lang="html">
  <div class="nutrient-map-display">
    <NavigationHeader @back="startManualEntry">
      <template #step>
        <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.DisplayNutrientMaps</IxRes>
      </template>
      <template #back>
        <IxRes>bf.nutrientMapDisplay.button.backToStepFour</IxRes>
      </template>
    </NavigationHeader>
    <!--
      TODO: set correct view once it exists
      <IxButton
      class="back" back
      large
      @click="setRightView('basicFertilizationField')"
    >
      <IxRes :resource="SR_BaseFertilization">BackToBasicFunctions</IxRes>
    </IxButton>
    -->

    <template v-if="!selectedFieldId">
      <HelpBox>
        <IxRes>bf.nutrientMaps.hints.noFieldSelected</IxRes>
      </HelpBox>
    </template>

    <template v-else>
      <div v-if="!(nutrientMaps.length && nutrientMaps.every(x => x.zones))" class="alert alert-info">
        <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.NoNutrientMapsAvailable</IxRes>
      </div>

      <template v-else>
        <NutrientMapNutrientSelector v-model="selectedNutrient" :nutrient-maps="nutrientMaps" />

        <NutrientMapDetails v-if="selectedNutrientMap" :map="selectedNutrientMap" :field-id="selectedFieldId" />
        <br>
        <IxButton v-if="$can('Use.Frs.BaseFertilization.ServiceProviderFeatures') && selectedNutrient" large @click="recreateNutritionMaps">
          <IxRes :context="{nutrient: $i18n.translate(`bf.labels.nutrient_${selectedNutrient}`)}">bf.nutrientMaps.buttons.recreate</IxRes>
        </IxButton>
      </template>
      <hr>
      <IxButton v-if="$can('Use.Frs.BaseFertilization.ServiceProviderFeatures')" large @click="recreateNutritionMaps(true)">
        <IxRes>bf.nutrientMaps.buttons.recreateAllMaps</IxRes>
      </IxButton>
      <FrsLoadingIndicator :requests="['nutrientMaps.forField']" />
    </template>
  </div>
</template>

<script>
import {uniq} from 'lodash'
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import {mapFormFields, mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import NavigationHeader from '@frs/components/basic-fertilization/widgets/NavigationHeader'
import IxButton from '@components/IxButton'
import HelpBox from '@components/help/HelpBox'
import NutrientMapDetails from '@frs/components/map-management/details/NutrientMapDetails'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import NutrientMapNutrientSelector from './nutrient-maps/NutrientMapNutrientSelector'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    FrsLoadingIndicator,
    NutrientMapNutrientSelector,
    NutrientMapDetails,
    NavigationHeader,
    IxButton,
    HelpBox
  },
  mixins: [
    PermissionMixin
  ],
  computed: {
    ...mapResources([
      'bf.nutrientMaps.notifications'
    ]),
    ...mapState('fieldRecordSystem/mapManagement', {
      mapIdsByFieldId: state => state.data.nutrientMap.mapIdsByFieldId,
      mapLookup: state => state.data.nutrientMap.mapLookup
    }),
    ...mapState('fieldRecordSystem/basicFertilization/nutrientMaps', [
      'selectedFieldId'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'entityNameLookup'
    ]),
    ...mapFormFields('fieldRecordSystem/basicFertilization/nutrientMaps', [
      'selectedNutrient'
    ]),
    nutrientMaps () {
      return (this.mapIdsByFieldId[this.selectedFieldId] || []).map(id => this.mapLookup[id])
    },
    selectedNutrientMap () {
      return this.nutrientMaps.find(x => x.nutrient === this.selectedNutrient)
    },
    nutrients () {
      return uniq(this.nutrientMaps.map(x => x.nutrient)).sort()
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/resultImport', [
      'startManualEntry',
      'createNutritionMap'
    ]),
    ...mapMutations('fieldRecordSystem', [
      'setRightView'
    ]),
    recreateNutritionMaps (createAllMaps) {
      const fieldName = this.entityNameLookup[this.selectedFieldId]
      const notificationSuccess = this.$i18n.translate('bf.nutrientMaps.notifications.createNutrientMapsSuccess', {fieldName})
      const notificationError = this.$i18n.translate('bf.nutrientMaps.notifications.createNutrientMapsError', {fieldName})
      const nutrients = createAllMaps ? ['magnesium', 'potassium', 'phosphorus', 'ph'] : [this.selectedNutrient]
      return this.createNutritionMap({fieldId: this.selectedFieldId, nutrients})
        .then(() => {
          notifications.info(notificationSuccess)
        })
        .catch(() => {
          notifications.error(notificationError)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.nutrient-map-display {
  position: relative;
}

.back {
  margin: 6px 0;
}

</style>
