<template lang="html">
  <div class="harvest-year-assignment" :class="{invalid: !hasValidDateRange}">
    <template v-if="hasMultipleYears">
      <div class="timeline">
        <div v-if="!harvestYears.length" class="timeline-overlay">
          <IxRes>frs.cultivationPlanning.hints.noHarvestYears</IxRes>
        </div>

        <!-- render all connecting lines -->
        <template v-if="baseHarvestYears.length === 2">
          <div class="timeline-edge full-width interactive" :class="{included: harvestYears.length === 2}" />
        </template>
        <template v-else-if="baseHarvestYears.length === 3">
          <div
            class="timeline-edge from-first half-width"
            :class="{interactive: interactiveHarvestYears.includes(firstYear), included: harvestYears.includes(firstYear)}"
          />
          <div
            class="timeline-edge to-last half-width"
            :class="{interactive: interactiveHarvestYears.includes(lastYear), included: harvestYears.includes(lastYear)}"
          />
        </template>
        <template v-else-if="baseHarvestYears.length > 3">
          <div class="timeline-edge from-first" :class="{interactive: interactiveHarvestYears.includes(firstYear), included: harvestYears.includes(firstYear)}" />
          <div class="timeline-edge fill-middle included" />
          <div class="timeline-edge to-last" :class="{interactive: interactiveHarvestYears.includes(lastYear), included: harvestYears.includes(lastYear)}" />
        </template>

        <!-- render nodes for all relevant years -->
        <template v-for="(year) in baseHarvestYears">
          <HarvestYearTimelineEntry
            :key="`${year}-wrapper`"
            v-slot="{current, positionClasses, ...scope}" :year="year"
            :current-harvest-year="harvestYear"
            :base-harvest-years="baseHarvestYears"
            :interactive-harvest-years="interactiveHarvestYears"
            :harvest-years="harvestYears"
            @toggle="toggleInclusion"
          >
            <HarvestYearTimelineMarker v-bind="scope" :class="positionClasses" />
            <HarvestYearTimelineLabel v-if="scope.show" v-bind="scope" :class="positionClasses" />
            <HarvestYearTimelineLabel
              v-if="current"
              v-bind="scope"
              bottom :class="positionClasses"
            >
              <IxRes>frs.cultivationPlanning.labels.currentHarvestYear</IxRes>
            </HarvestYearTimelineLabel>
          </HarvestYearTimelineEntry>
        </template>
      </div>

      <HelpBox>
        <IxRes :context="{firstYear, lastYear, totalYears: baseHarvestYears.length}">frs.cultivationPlanning.hints.multipleHarvestYearsPossible</IxRes>
      </HelpBox>
    </template>

    <HelpBox v-else>
      <IxRes :context="{year: harvestYear}">frs.cultivationPlanning.hints.onlySingleHarvestYearPossible</IxRes>
    </HelpBox>
  </div>
</template>

<script>
import moment from 'moment'

import HelpBox from '@components/help/HelpBox'

import HarvestYearTimelineEntry from './timeline/HarvestYearTimelineEntry'
import HarvestYearTimelineMarker from './timeline/HarvestYearTimelineMarker'
import HarvestYearTimelineLabel from './timeline/HarvestYearTimelineLabel'

export default {
  components: {
    HelpBox,
    HarvestYearTimelineEntry,
    HarvestYearTimelineMarker,
    HarvestYearTimelineLabel
  },
  props: {
    defaultHarvestYears: Array,
    harvestYear: Number,
    dateTimeRange: Object // {start: String/ISODate, end: String/ISODate}
  },
  data () {
    return {
      include: []
    }
  },
  computed: {
    hasValidDateRange () {
      return !!(this.dateTimeRange.start && this.dateTimeRange.end)
    },
    firstYear () {
      return moment(this.dateTimeRange.start).year()
    },
    lastYear () {
      return moment(this.dateTimeRange.end).year()
    },
    baseHarvestYears () {
      return this.hasValidDateRange
        ? new Array(Math.max(this.lastYear - this.firstYear + 1, 0)).fill(null).map((x, i) => this.firstYear + i)
        : []
    },
    hasMultipleYears () {
      return this.baseHarvestYears.length > 1
    },
    canExcludeStart () {
      return this.hasMultipleYears && this.firstYear !== this.harvestYear
    },
    canExcludeEnd () {
      return this.hasMultipleYears && this.lastYear !== this.harvestYear
    },
    interactiveHarvestYears () {
      return this.baseHarvestYears.filter(year => (year === this.firstYear && this.canExcludeStart) || (year === this.lastYear && this.canExcludeEnd))
    },
    harvestYears () {
      return this.baseHarvestYears.filter((year, i) => {
        const canBeExcluded = (i === 0 && this.canExcludeStart) || (i === this.baseHarvestYears.length - 1 && this.canExcludeEnd)
        const shouldBeExcluded = (i === 0 && !this.include.includes('start')) || (i === this.baseHarvestYears.length - 1 && !this.include.includes('end'))

        return !(canBeExcluded && shouldBeExcluded)
      })
    }
  },
  methods: {
    toggleInclusion (year) {
      const position = {
        [this.firstYear]: 'start',
        [this.lastYear]: 'end'
      }[year]

      if (!position) return

      if (this.include.includes(position)) {
        this.include = this.include.filter(x => x !== position)
      } else {
        this.include.push(position)
      }
    }
  },
  watch: {
    harvestYears: {
      immediate: true,
      handler (harvestYears) {
        this.$emit('input', harvestYears)
      }
    }
  },
  created () {
    if (this.defaultHarvestYears) {
      if (this.canExcludeStart && this.defaultHarvestYears.includes(this.firstYear)) {
        this.toggleInclusion(this.firstYear)
      }
      if (this.canExcludeEnd && this.defaultHarvestYears.includes(this.lastYear)) {
        this.toggleInclusion(this.lastYear)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.harvest-year-assignment {
  display: flex;
  flex-direction: column;

  .timeline {
    margin-bottom: 0.5em;

    display: grid;
    grid-template-columns: 1fr [first-start] 1fr [first-line-start] 1fr [first-end second-start] 1fr [first-line-end] 1fr [second-end] 1fr [mid-start] 1fr [center] 1fr [mid-end] 1fr [second-to-last-start] 1fr [last-line-start] 1fr [second-to-last-end last-start] 1fr [last-line-end] 1fr [last-end] 1fr;
    grid-template-rows: [top-start] auto [top-end mid-start] auto [mid-end bottom-start] auto [bottom-end];
    align-items: center;

    .timeline-edge {
      grid-row: 2 / 3;
      height: 5px;

      transition: background 0.2s;

      background: rgb(89, 89, 89);

      &.interactive {
        background: repeating-linear-gradient(to right,rgba(91, 137, 255, 0.75) 0,rgba(91, 137, 255, 0.75) 6px,transparent 6px,transparent 10px);

        &.included {
          background: rgb(91, 137, 255);
        }
      }
    }

    .timeline-overlay {
      grid-area: 1 / 1 / -1 / -1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .timeline-edge.full-width {
      grid-column: first-line-start / last-line-end;
    }

    .timeline-edge.from-first {
      grid-column: first-line-start / first-line-end;
    }

    .timeline-edge.from-first.half-width {
      grid-column: first-line-start / center;
    }

    .timeline-edge.fill-middle {
      grid-column: first-line-end / last-line-start;
    }

    .timeline-edge.to-last {
      grid-column: last-line-start / last-line-end;
    }

    .timeline-edge.to-last.half-width {
      grid-column: center / last-line-end;
    }

    .timeline-column-start {
      grid-column: first-start / first-end;
    }

    .timeline-column-mid {
      grid-column: mid-start / mid-end;
    }

    .timeline-column-end {
      grid-column: last-start / last-end;
    }

    .timeline-column-start.timeline-column-mid {
      grid-column: second-start / second-end;
    }

    .timeline-column-end.timeline-column-mid {
      grid-column: second-to-last-start / second-to-last-end;
    }
  }
}
</style>
