<template>
  <div v-if="!isPlaceholder" class="cultivation-container">
    <div class="color-container" :style="{backgroundColor: color}" />
    {{ label }}
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {cultivationToColor, cultivationToName} from '@frs/components/cultivation-overview/helpers'

export default {
  props: {
    cultivation: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      mixtures: state => state.catchCropMixtures.data.selection.orgUnit
    }),
    isPlaceholder () {
      return !this.cultivation.id
    },
    color () {
      return cultivationToColor(this.cultivation)
    },
    label () {
      const name = cultivationToName(this.cultivation)

      const {underseedMixtureId} = this.cultivation

      return underseedMixtureId
        ? `${name} ${this.$i18n.translate('frs.cultivation.underseedMixture', {mixture: this.mixtures[underseedMixtureId].name})}`
        : name
    }
  }
}
</script>

<style lang="scss" scoped>
.cultivation-container {
  display: flex;

  .color-container {
    height: 22px;
    margin-right: 4px;
    width: 10px;
  }
}
</style>
