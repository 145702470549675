<template>
  <div class="measurements">
    <section>
      <label>
        <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.MeasurementResult</IxRes>
      </label>
      <div>{{ $i18n.format(value) + addon }}</div>
    </section>

    <section>
      <label>
        <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.SamplingTime</IxRes>
      </label>
      <div>{{ $i18n.format(timestamp, 'date') }}</div>
    </section>
  </div>
</template>

<script>
import store from 'src/js/store'

export default {
  props: {
    measurement: Object // {value, timestamp, type}
  },
  computed: {
    value () {
      return this.measurement.value
    },
    timestamp () {
      return this.measurement.timestamp
    },
    addon () {
      return this.measurement.type.toUpperCase() === 'PH' ? '' : ` ${this.$i18n.translateUnit('mg/100g')}`
    }
  },
  store
}
</script>

<style lang="scss" scoped>
.measurements {
  display: flex;
  flex-direction: column;

  section {
    margin-bottom: 6px;
  }

  section label {
    font-size: 1.1em;
    color: gray;
  }
  section div {
    font-size: 1.4em;
    font-weight: 300;
  }
}
</style>
