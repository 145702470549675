<template lang="html">
  <div>
    <p>
      <IxRes>Areas.FieldRecordSystem.SR_Action.FertilizerType</IxRes>: *
    </p>
    <BsRadioList v-model="selectedType" horizontal :options="typeOptions" />

    <VueSelectize
      v-if="procedure === 'organic'"
      v-model="applicationMethod"
      class="application-method"
      :options="applicationMethodOptions"
      :fields="applicationMethodFields"
      name="method-selection"
      required allow-empty
    >
      <template #label>
        <IxRes>actions.fertilizer.labels.applicationMethod</IxRes>:
      </template>
    </VueSelectize>

    <p>
      <IxRes>Areas.FieldRecordSystem.SR_Action.FertilizerSelection</IxRes> *
    </p>
    <BsRadioList
      v-if="procedure === 'organic'"
      v-model="selectedFertilizer"
      horizontal
      :options="fertilizerOptions"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import BsRadioList from '@components/bootstrap/BsRadioList'
import VueSelectize from '@components/VueSelectize'

import ParameterMixin, {mapParameters, mapSingleProductQuantity} from '../ParameterMixin'

export default {
  components: {
    VueSelectize,
    BsRadioList
  },
  mixins: [
    ParameterMixin
  ],
  computed: {
    ...mapState('fieldRecordSystem/action/editBuffer', {
      procedure: state => state.procedure
    }),
    ...mapParameters([
      'isLiquid',
      'useCustomProduct',
      'applicationMethod'
    ]),
    ...mapSingleProductQuantity(),
    selectedType: {
      get () {
        return this.isLiquid ? 'liquid' : 'solid'
      },
      set (value) {
        this.isLiquid = value === 'liquid'
      }
    },
    typeOptions () {
      return [
        {label: 'Areas.FieldRecordSystem.SR_FieldRecordSystem.SR_Action.solid', value: 'solid'},
        {label: 'Areas.FieldRecordSystem.SR_FieldRecordSystem.SR_Action.liquid', value: 'liquid'}
      ]
    },
    selectedFertilizer: {
      get () {
        return this.useCustomProduct ? 'nutrientAmount' : 'product'
      },
      set (value) {
        this.useCustomProduct = value === 'nutrientAmount'
      }
    },
    fertilizerOptions () {
      return [
        {label: 'Areas.FieldRecordSystem.SR_FieldRecordSystem.SR_Action.product', value: 'product'},
        {label: 'Areas.FieldRecordSystem.SR_FieldRecordSystem.SR_Action.nutrientAmount', value: 'nutrientAmount'}
      ]
    },
    applicationMethodOptions () {
      return [
        'spreader_1', // Breitverteiler ohne Einarbeitung
        'spreader_2', // Breitverteiler und Einarbeitung
        'spreader_3', // Breitverteiler und Einarbeitung = 1 h
        'spreader_4', // Breitverteiler und Einarbeitung > 1 h bis = 4 h
        'spreader_5', // Breitverteiler und Einarbeitung > 4 h bis = 12 h
        'spreader_6', // Breitverteiler und Einarbeitung > 12 h bis 24 h
        'spreader_7', // Breitverteiler und Einarbeitung > 24 h bis 48 h
        'spreader_8', // Breitverteiler auf Vegetation
        'spreader_9', // Breitverteiler auf Vegetation mit Einarbeitung
        'spreader_10', // Breitverteiler auf Dauergrünland
        'dragHose_1', // Schleppschlauch ohne Einarbeitung
        'dragHose_2', // Schleppschlauch und Einarbeitung
        'dragHose_3', // Schleppschlauch und Einarbeitung = 1 h
        'dragHose_4', // Schleppschlauch und Einarbeitung >1 h bis = 4h
        'dragHose_5', // Schleppschlauch auf kurze Vegetation
        'dragHose_6', // Schleppschlauch auf Vegetation
        'dragHose_7', // Schleppschlauch unterhalb der Vegetation
        'dragHose_8', // Schleppschlauch auf Dauergrünland
        'dragHose_9', // Schleppschlauch
        'channelProcess_1', // Schlitzverfahren (offener Schlitz)
        'cultivator_1' // Güllegrubber oder andere Injektionsverfahren
      ]
    },
    applicationMethodFields () {
      return {
        text: method => this.$i18n.translate(`actions.fertilizer.applicationMethod_${method}`),
        value: method => method
      }
    }
  },
  watch: {
    isLiquid: {
      immediate: true,
      handler (isLiquid) {
        this.unit = isLiquid
          ? 'l/ha'
          : 'kg/ha'
      }
    }
  },
  mounted () {
    if (this.procedure !== 'organic') {
      this.applicationMethod = null
    }
  }
}
</script>

<style lang="scss" scoped>
.application-method {
  margin: 8px 0;
  width: 40%;
}
</style>
