<template>
  <div class="year-form-container">
    <h4 v-if="showHarvestYear">
      <IxRes>bf.yieldPlanning.harvestYear</IxRes>
      {{ harvestYear + year | format }}
    </h4>

    <div :class="wrap">
      <FrsCropUsagePicker
        v-model="cropUsageId"
        class="crop-picker"
        :name="`crop-picker-${year}`"
        :filter="cropUsageHasCropHarvestQualities"
        :required="!onlyPhSelected"
      >
        <template #label>
          <IxRes>bf.yieldPlanning.cropType</IxRes>
        </template>
      </FrsCropUsagePicker>

      <VueSelectize
        v-model="cropHarvestQualityId"
        class="quality-drop-down"
        :name="`quality-select-${year}`"
        :disabled="!cropUsageId || harvestQualityIds.length === 1"
        :options="harvestQualityIds"
        :fields="qualitySelectizeFields"
        :required="!onlyPhSelected"
        allow-empty
      >
        <template #label>
          <IxRes>bf.yieldPlanning.cropHarvestQuality</IxRes>
        </template>
      </VueSelectize>
    </div>

    <QuantityInput
      v-if="showAverageYield"
      v-model="averageYieldWithUnit"
      class="value-with-unit"
      :name="`yield-input-${year}`"
      :unit-options="validUnits"
      :required="!onlyPhSelected"
    >
      <IxRes slot="label">
        bf.yieldPlanning.averageYield
      </IxRes>
    </QuantityInput>

    <BsCheckBox v-model="useByProduct" inline>
      <IxRes>bf.yieldPlanning.byProduct</IxRes>
    </BsCheckBox>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {groupBy} from 'lodash'
import moment from 'moment'

import {mapFormFieldsFromValue} from '@helpers/vue/forms'

import VueSelectize from '@components/VueSelectize'
import QuantityInput from '@components/forms/QuantityInput'
import FrsCropUsagePicker from '@frs/components/base/FrsCropUsagePicker'
import BsCheckBox from '@components/bootstrap/BsCheckbox'

import {getCropHarvestQualityTranslations} from '@frs/helpers/crops'

export default {
  components: {
    VueSelectize,
    BsCheckBox,
    FrsCropUsagePicker,
    QuantityInput
  },
  filters: {
    format (year) {
      const yearAsMoment = moment({year})
      return `${yearAsMoment.clone().subtract(1, 'year').format('YY')}/${yearAsMoment.format('YY')}`
    }
  },
  props: {
    value: {
      required: true,
      Type: Object
    },
    year: {
      required: true,
      type: Number
    },
    showAverageYield: {
      type: Boolean,
      default: true
    },
    showHarvestYear: Boolean
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      harvestYear: state => state.userSettings.harvestYear
    }),
    ...mapState('fieldRecordSystem/basicFertilization/applicationMaps', {
      years: state => state.years
    }),
    ...mapGetters('fieldRecordSystem/basicFertilization/applicationMaps', [
      'onlyPhSelected'
    ]),
    ...mapGetters('masterData', [
      'unitArray',
      'cropHarvestQualityArray'
    ]),
    ...mapFormFieldsFromValue([
      'cropUsageId',
      'cropHarvestQualityId',
      'useByProduct'
    ]),
    harvestQualityIds () {
      const cropQualities = this.cropHarvestQualityArray.filter(x => x.cropUsageId === this.value.cropUsageId)
      return cropQualities.length ? cropQualities.map(x => x.id) : []
    },
    qualitySelectizeFields () {
      return {
        text: function (id) {
          return getCropHarvestQualityTranslations(id).name
        }
      }
    },
    harvestQualityIdsByCropUsageId () {
      return groupBy(this.cropHarvestQualityArray, quality => quality.cropUsageId)
    },
    averageYieldWithUnit: {
      get () {
        return {amount: this.value.averageYield, unit: this.value.unit}
      },
      set ({amount, unit}) {
        this.$emit('input', {
          ...this.value,
          averageYield: amount,
          unit
        })
      }
    },
    wrap () {
      return this.showHarvestYear ? 'wrap-content' : 'non-wrap-content'
    },
    validUnits () {
      // NOTE currently only dt/ha supported due to calculation not supporting multiple units
      return this.unitArray.filter(x => /* x.type === 'MassPerArea' */x.shortcut === 'dt/ha').map(x => x.shortcut)
    }
  },
  methods: {
    cropUsageHasCropHarvestQualities (cropUsage) {
      return this.harvestQualityIdsByCropUsageId[cropUsage.id]
    }
  },
  watch: {
    cropUsageId () {
      if (this.harvestQualityIds.length === 1) {
        this.cropHarvestQualityId = this.harvestQualityIds[0]
      } else {
        this.cropHarvestQualityId = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.year-form-container {
  display: flex;
  flex-direction: column;

  h4 {
    margin-top: 1em;
  }
}
.value-with-unit {
  width: 60%;
}
</style>
