<template lang="html">
  <div>
    <NavigationHeader @back="startManualEntry">
      <template #step>
        <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.InputOfSamplingResults</IxRes>
      </template>
      <template #back>
        <IxRes>bf.probingResult.button.back</IxRes>
      </template>
    </NavigationHeader>
    <div class="options">
      <div class="date-time">
        <VueDateTimePicker
          v-model="timestamp" format="L LT"
          class="date-time-picker"
          required
          iso
          name="timestamp"
        >
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.SamplingTime</IxRes>
          </template>
        </VueDateTimePicker>
      </div>
      <div class="probing-method">
        <VueSelectize
          v-model="probingMethod"
          :options="['CAL', 'DL']"
          name="probingMethod"
          allow-empty
          required
          :disabled="order.probingMethod !== null"
        >
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.ProbingMethod</IxRes>
          </template>
          <template #description>
            <div class="regions">
              <div class="cal">
                <span><strong>CAL <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AvailableIn</IxRes>:</strong></span>
                <span v-for="region in regionsObject.cal" :key="region">{{ region }}</span>
              </div>
              <div class="dl">
                <span><strong>DL <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AvailableIn</IxRes>:</strong></span>
                <span v-for="region in regionsObject.dl" :key="region">{{ region }}</span>
              </div>
            </div>
          </template>
        </VueSelectize>
      </div>
    </div>
    <br>
    <template v-if="selectedSamplingOrderGeometryId">
      <p class="lead">
        <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.ValuesForSamplingPoint</IxRes> <strong>{{ sampleId }}</strong>
      </p>
      <p>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Area</IxRes>: <strong>{{ fieldNameForSelectedSample }}</strong>
      </p>
      <hr>
      <div class="input-container">
        <ResultEntryWidget
          v-for="nutrient in ['phosphorus', 'potassium', 'magnesium', 'ph']" :key="nutrient"
          :nutrient="nutrient"
          @input="updateResult"
        />
      </div>
      <div class="soil-type-container">
        <VueSelectize
          v-model="soilSubTypeId" class="selectize-soil-type"
          :options="soilSubTypeIds"
          :fields="soilTypesFields"
          allow-empty
          :required="isSoilSubTypeRequired"
          :disabled="disable"
          name="soilType"
        >
          <template #label>
            <IxRes>bf.probingResult.label.soilType</IxRes> *
          </template>
        </VueSelectize>

        <div class="soil-type-recalculation-button">
          <IxButton
            large
            :title="SR_BaseFertilization.ConditionsContentClass"
            :disabled="!canCalculateContentClasses"
            @click="calculateClassifications"
          >
            <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.CalculateContentClass</IxRes>
          </IxButton>

          <div class="soil-type-recalculation-description">
            <VueInfo placement="top" class="info" trigger="hover">
              <template #description>
                <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.CalculateContentClassDescription</IxRes>
              </template>
            </VueInfo>
          </div>

          <FontAwesomeIcon v-if="classificationsAlreadyCalculated" class="check-icon" :icon="icon.check" />
        </div>
      </div>
      <br>
    </template>

    <HelpBox v-if="pointsCountMissingField" type="warning">
      <span>
        {{ pointsCountMissingField }}
        <IxRes>bf.resultImport.hints.missingFieldsFirst</IxRes>
        {{ orderPointCount }}
        <IxRes>bf.resultImport.hints.missingFieldsSecond</IxRes>
      </span>
    </HelpBox>
    <ResultsTableEditable v-model="selectedSamplingOrderGeometryId" />

    <template v-if="readonlySamples.length">
      <h4>
        <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.AlreadyStoredResults</IxRes> <i class="fa fa-lock" />
      </h4>

      <ResultsTableReadOnly />
    </template>

    <HelpBox v-if="fieldStates.newResults === 'error'" type="danger">
      <IxRes>bf.resultManual.someValuesCannotBeSaved</IxRes>
    </HelpBox>

    <IxButton save @click="onSave" />

    <BsModal v-model="showModal">
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.SamplingResults</IxRes>
      </template>
      <div class="notification-result-modal">
        <div class="lead">
          <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.NoMeasuredValuesForAllSamplingPoints</IxRes>
        </div>
        <div class="modal-statistic">
          <div v-if="numberCompleteSamples > 0">
            <b>{{ numberCompleteSamples }} <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.MeasuringPointsComplete</IxRes></b>
          </div>
          <div v-if="numberIncompleteSamples > 0">
            <b>{{ numberIncompleteSamples }} <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.MeasuringPointsNotComplete</IxRes></b>
          </div>
        </div>
        <div>
          <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.CompleteOrder_Hint1</IxRes>
        </div>
        <div class="modal-actions">
          <IxButton large colored @click="doActualSave(false)">
            <i class="fa fa-clock-o" aria-hidden="true" />
            <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.SaveSamplingResults_Label1</IxRes>
          </IxButton>
          <IxButton large save @click="doActualSave(true)">
            <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.SaveSamplingResults_Label2</IxRes>
          </IxButton>
        </div>
        <div class="modal-actions">
          <IxButton large back @click="showModal = false">
            <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.SaveSamplingResults_Label3</IxRes>
          </IxButton>
        </div>
      </div>
    </BsModal>

    <SelectInteraction
      v-model="selection"
      :features="affectedFieldFeatures"
      :selection-style="styles.selectionStyle"
      :layer-filter="layer => layer.get('id') === 'field'"
      :hover-style="styles.hoverStyle"
      toggle
    />
    <IxVectorLayer
      :features="affectedFieldFeatures"
      :vector-style="styles.field"
      :z-index="1"
      layer-id="field"
      auto-focus
    />
    <IxVectorLayer
      :features="pointFeatures"
      :vector-style="styles.point"
      :z-index="2"
      layer-id="points"
    />

    <FrsLoadingIndicator saving :loading="loading">
      <IxButton cancel @click="loading = false" />
    </FrsLoadingIndicator>
  </div>
</template>

<script>

import {notifications} from 'src/js/infrastructure'
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'
import {mapResources, mapFormFields} from '@helpers/vuex'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {flatMap, uniq} from 'lodash'

import parse from 'wellknown'
import {field, probingResultPreview, hoverStyle, selectionStyle} from '@frs/map-styles'

import IxButton from '@components/IxButton'
import BsModal from '@components/bootstrap/BsModal'
import VueSelectize from '@components/VueSelectize'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import VueInfo from '@components/help/VueInfo'
import HelpBox from '@components/help/HelpBox'
import IxVectorLayer from '@components/map/IxVectorLayer'
import SelectInteraction from '@components/map/interactions/SelectInteraction'

import NavigationHeader from '@frs/components/basic-fertilization/widgets/NavigationHeader'
import ResultEntryWidget from '@frs/components/basic-fertilization/result-import/ResultEntryWidget'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import {regions} from './regions'
import ResultsTableEditable from './ResultsTableEditable'
import ResultsTableReadOnly from './ResultsTableReadOnly'

import FieldBorderMixin from '@frs/components/basic-fertilization/mixins/FieldBorderMixin'
import FormPartMixin from '@components/forms/FormPartMixin'
import DisableDefaultMapLayersMixin from '@frs/mixins/DisableDefaultMapLayersMixin'

import booleanContains from '@turf/boolean-contains'

export default {
  components: {
    FrsLoadingIndicator,
    ResultEntryWidget,
    ResultsTableEditable,
    VueDateTimePicker,
    ResultsTableReadOnly,
    NavigationHeader,
    IxButton,
    IxVectorLayer,
    FontAwesomeIcon,
    VueSelectize,
    HelpBox,
    BsModal,
    VueInfo,
    SelectInteraction
  },
  mixins: [
    FieldBorderMixin,
    FormPartMixin,
    DisableDefaultMapLayersMixin
  ],
  data () {
    return {
      showModal: false,
      loading: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', {
      order: state => state.order,
      nutrients: state => state.manual.nutrients,
      selectedFieldId: state => state.manual.selectedFieldId
    }),
    ...mapState('fieldRecordSystem', {
      soilSubTypes: state => state.data.soilSubTypes
    }),
    ...mapGetters('fieldRecordSystem/basicFertilization/resultImport', [
      'newResults',
      'newResultsValidity',
      'newResultsCombinedValidity',
      'validNewResults',
      'savedResultsLookup',
      'canCalculateContentClasses'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'entityNameLookup'
    ]),
    ...mapResources([
      '@frs.SR_BaseFertilization',
      '@frs.SR_FieldRecordSystem',
      'bf'
    ]),
    ...mapFormFields('fieldRecordSystem/basicFertilization/resultImport', [
      'manual.timestamp',
      'manual.selectedSamplingOrderGeometryId',
      'manual.probingMethod',
      'results',
      'lastCalculationSoilSubTypeLookup'
    ]),
    selection: {
      get () {
        return this.selectedFieldId
      },
      set (id) {
        this.toggleSelectedFieldId(id)
        this.focus(this.affectedFieldFeatures.filter(feature => feature.properties.id === id))
      }
    },
    affectedFieldFeatures () {
      return this.fieldBorderFeatures.filter(feature => this.availableFieldIds.includes(feature.properties.id))
    },
    regionsObject () {
      return regions
    },
    disable () {
      const savedResult = this.savedResultsLookup[this.selectedSamplingOrderGeometryId]
      return !!(savedResult && savedResult.soilSubType)
    },
    icon () {
      return {
        check: faCheck
      }
    },
    isSoilSubTypeRequired () {
      const idsWithoutSoilSubType = this.geometryIdsWithNewResults.filter(id => {
        const {limeRecommendation, magnesiumMeasurement, phMeasurement, phosphorusMeasurement, potassiumMeasurement, soilSubType} = this.results[id]
        const isMeasurementSet = !!magnesiumMeasurement || !!phMeasurement || !!phosphorusMeasurement || !!potassiumMeasurement
        const isLimeRecommendationSet = !!limeRecommendation
        const isSoilSubtypeSet = !!soilSubType
        if (!isMeasurementSet && isLimeRecommendationSet) return false
        return isMeasurementSet && !isSoilSubtypeSet
      })
      return idsWithoutSoilSubType.length > 0
    },
    orderPointCount () {
      return this.order.geometries.length
    },
    pointsCountMissingField () {
      const points = this.order.geometries.filter(geometry => !Object.keys(this.order.fieldInfoByGeometry).includes(geometry.id))
      return points.length
    },
    soilTypeLookup () {
      const lookup = {}
      this.soilSubTypes.forEach(x => {
        lookup[x.id] = x
      })
      return lookup
    },
    soilSubTypeIds () {
      return this.soilSubTypes.map(x => x.id)
    },
    soilTypesFields () {
      return {
        text: row => {
          let soilSubType = this.soilTypeLookup[row]
          return `${soilSubType.name} (${soilSubType.id}${soilSubType.aliases.map(x => ', ' + x)}) - ${this.bf.soilGroup} ${soilSubType.soilGroup}`
        }
      }
    },
    geometryIdsWithNewResults () {
      return this.newResults.map(x => x.samplingOrderGeometryId)
    },
    pointFeatures () {
      return this.order.geometries
        .map(geometry => ({
          geometry: parse(geometry.geometry),
          type: 'Feature',
          properties: {
            fieldName: geometry.fieldName,
            id: geometry.id,
            results: geometry.results,
            sampleId: geometry.sampleId,
            isSelected: geometry.id === this.selectedSamplingOrderGeometryId, // NOTE sample id from server is string at the moment
            isCompleted: this.geometryIdsWithNewResults.includes(geometry.id)
          }
        }))
    },
    fieldNameForSelectedSample () {
      const point = this.order.geometries.find(x => x.id === this.selectedSamplingOrderGeometryId)

      return point ? point.fieldName : ''
    },
    canSave () {
      return this.geometryIdsWithNewResults.length > 0
    },
    classificationsAlreadyCalculated () {
      const lastSoilSubTypeId = this.lastCalculationSoilSubTypeLookup[this.selectedSamplingOrderGeometryId]
      return lastSoilSubTypeId && lastSoilSubTypeId === this.soilSubTypeId
    },
    sampleId () {
      const geometry = this.order.geometries.find(geometry => geometry.id === this.selectedSamplingOrderGeometryId)
      return geometry ? geometry.sampleId : null
    },
    editableSamples () {
      return this.order.geometries
        .filter(geometry => !this.savedResultsLookup[geometry.id] || Object.values(this.savedResultsLookup[geometry.id]).some(x => x === null))
    },
    readonlySamples () {
      return this.order.geometries.filter(geometry => !this.editableSamples.includes(geometry))
    },
    numberIncompleteSamples () {
      return this.editableSamples.filter(geometry => Object.values(this.results[geometry.id]).some(x => x === null)).length
    },
    numberCompleteSamples () {
      return this.editableSamples.length - this.numberIncompleteSamples
    },
    soilSubTypeId: {
      get () {
        const result = this.results[this.selectedSamplingOrderGeometryId]
        return result ? result.soilSubType : null
      },
      set (soilSubType) {
        this.updateResult({soilSubType})
      }
    },
    availableFieldIds () {
      return uniq(flatMap(Object.values(this.order.fieldInfoByGeometry), array => array.map(x => x.fieldId)))
    },
    styles () {
      return {
        field: field,
        point: probingResultPreview,
        hoverStyle,
        selectionStyle
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/basicFertilization/resultImport', [
      'updateResult',
      'toggleSelectedFieldId'
    ]),
    ...mapActions('fieldRecordSystem/map', [
      'focus'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization/resultImport', [
      'saveResults',
      'createNutritionMap',
      'calculateClassifications'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization/resultImport', [
      'startManualEntry'
    ]),
    validateNewResults () {
      this.$set(this.fieldStates, 'newResults', Object.values(this.newResultsCombinedValidity).some(x => x === false) ? 'error' : 'success')
    },
    async onSave () {
      this.validate()
      this.validateNewResults()

      await this.$nextTick()

      if (this.state !== 'success') {
        // notifications.warning
        return
      }

      if (this.numberIncompleteSamples) {
        this.showModal = true
      } else {
        await this.doActualSave(true)
      }
    },
    async doActualSave (forceComplete) {
      this.showModal = false

      try {
        this.loading = true
        const {affectedFields, affectedNutrients} = await this.saveResults(forceComplete)

        this.loading = false
        notifications.success(this.SR_FieldRecordSystem.CatchManualValuesForProbingResultSuccess)

        if (!affectedNutrients.length) return

        const notificationNutritionMapGenerated = this.$i18n.translate('bf.nutrientMaps.notifications.nutritionMapGeneratedForFields',
          {numberOfFields: affectedFields.length})
        notifications.info(notificationNutritionMapGenerated)

        return affectedFields.map(fieldId => this.createNutritionMap({fieldId, nutrients: affectedNutrients})
          .then(() => {
            const notificationSuccess = this.$i18n.translate('bf.nutrientMaps.notifications.createNutrientMapsSuccess',
              {fieldName: this.entityNameLookup[fieldId]})
            notifications.persistentSuccess(notificationSuccess)
          })
          .catch(() => {
            const notificationError = this.$i18n.translate('bf.nutrientMaps.notifications.createNutrientMapsError',
              {fieldName: this.entityNameLookup[fieldId]})
            notifications.error(notificationError)
          })
        )
      } catch (error) {
        console.error(error)
        this.loading = false
        const body = error.response.data
        if (!(body && body.error)) {
          notifications.error(this.SR_FieldRecordSystem.CatchManualValuesForProbingResultError)
          return
        }

        const errorMessage = body.error.toString()

        switch (body.error) {
        case 'duplicateMeasurements': {
          notifications.criticalError(this.$i18n.translate(`bf.resultImport.notifications.${errorMessage}Error`,
            {timestamp: body.timestamp, type: body.type}))
          break
        }
        }
      }
    }
  },
  watch: {
    editableSamples: {
      immediate: true,
      handler (geometries) {
        if (geometries.length) {
          this.focus(this.pointFeatures)
        }
      }
    },
    newResultsCombinedValidity (validity) {
      if (Object.values(validity).every(x => x)) {
        this.validateNewResults()
      }
    },
    selectedSamplingOrderGeometryId (id) {
      const pointFeature = this.pointFeatures.find(feature => feature.properties.id === id)
      const fieldPolygonFeatures = this.fieldBorderFeatures
        .map(feature => feature.geometry.type !== 'Polygon'
          ? feature.geometry.coordinates.map(coordinates => ({type: 'Polygon', coordinates, id: feature.properties.id}))
          : ({type: feature.geometry.type, coordinates: feature.geometry.coordinates, id: feature.properties.id})).flat()

      const filterFieldsByContainingPoints = fieldPolygonFeatures.filter(field => booleanContains(field, pointFeature.geometry)).map(fb => fb.id)
      const fields = this.fieldBorderFeatures.filter(x => filterFieldsByContainingPoints.includes(x.properties.id))

      if (fields.length === 0) {
        this.focus(pointFeature)
      } else {
        this.focus(fields)
      }
    },
    selectedFieldId (value) {
      if (!value) {
        this.focus(this.affectedFieldFeatures)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.options {
  display: flex;
  .date-time-picker {
    margin-right: 60px;
  }
  .probing-method {
    width: 25%;
  }
}
.input-container {
  display: flex;
}
.lead {
  margin-bottom: 0;
}

.soil-type-container {
  width: 80%;
  display: flex;
  align-items: center;

  .soil-type-recalculation-button {
    display: flex;
    margin-top: 17px;

    .soil-type-recalculation-description {
      margin-left: 3px;
    }
  }
  .selectize-soil-type {
    padding-top: 4px;
    margin: 0 8px;
    width: 50%;
  }
  .check-icon {
    margin-left: 8px;
    color: #3aa84c;
    font-size: 1.8em;
  }
}
.notification-result-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .modal-statistic {
    display: flex;
    flex-direction: column;
  }

  .modal-actions {
    display: flex;
    margin-bottom: 5px;
  }

  > * {
    margin-bottom: 20px;
  }
}
.regions {
  display: flex;
  flex-direction: column;

  .cal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .dl {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
