<template lang="html">
  <div>
    <label><IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.ContentClass</IxRes></label>
    <br>
    <div class="btn-group">
      <ToggleButton
        v-for="(letter, i) in contentClassOptions" :key="letter"
        v-model="proxyValue"
        class="selector-button"
        :value="letter" :disabled="disabled"
      >
        <template v-if="value === letter">
          <strong class="letter">{{ letter }}</strong>
          <div class="color-marker" :style="{backgroundColor: colors[i]}" />
        </template>
        <span v-else class="letter">{{ letter }}</span>
      </ToggleButton>
    </div>
  </div>
</template>

<script>
import colors from '@frs/map-styles/basic-fertilization/colors'

import ToggleButton from '@components/ToggleButton'

export default {
  components: {
    ToggleButton
  },
  props: {
    nutrient: String,
    value: String,
    disabled: Boolean
  },
  computed: {
    proxyValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    contentClassOptions () {
      return this.nutrient === 'ph' ? ['A', 'B', 'C', 'D', 'E', 'F'] : ['A', 'B', 'C', 'D', 'E']
    },
    colors () {
      return this.contentClassOptions.map(letter => colors.classification[letter])
    }
  }
}
</script>

<style lang="scss" scoped>
.selector-button {
  width: 30px;
  height: 30px;

  .letter {
    display: flex;
    justify-content: center;
  }
  .color-marker {
    height: 3px;
    width: 20px;
    margin-left: -8px;
  }
}
</style>
