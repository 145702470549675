<template lang="html">
  <VueSelectize
    v-model="value"
    :options="columnOptions"
    allow-empty :disabled="isDisabled"
  >
    <slot slot="label" />
    <slot v-if="$slots.description" slot="description" name="description" />
  </VueSelectize>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'

import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    VueSelectize
  },
  props: {
    columnId: String,
    requiredNutrient: String,
    disabled: Boolean
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', {
      columnMapping: state => state.upload.columnMapping,
      nutrients: state => state.manual.nutrients
    }),
    ...mapGetters('fieldRecordSystem/basicFertilization/resultImport', [
      'csvHeaders'
    ]),
    columnOptions () {
      return this.csvHeaders.map(text => ({text, value: text.toUpperCase()}))
    },
    value: {
      get () {
        return this.columnOptions.find(x => x.value === this.columnMapping[this.columnId]) || null
      },
      set (option) {
        const column = option !== null ? option.value : null
        this.updateColumnMapping({id: this.columnId, column})
      }
    },
    isDisabled () {
      if (this.disabled) return true
      return this.requiredNutrient && !this.nutrients.includes(this.requiredNutrient)
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/basicFertilization/resultImport', [
      'updateColumnMapping'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
