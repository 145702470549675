<template lang="html">
  <div :class="$style.text">
    <IxRes v-if="satPic === null">SR_FieldInfo.SatPicMetaDataError</IxRes>
    <template v-else>
      <IxRes
        :context="{
          name: satPic.fileName,
          date: $i18n.format(satPic.recordingDate, 'datetime')
        }"
      >
        frs.maps.zoneMap.satellitePicture2Parameter
      </IxRes>
      <span v-if="satPic.correlationIndex">({{ satPic.correlationIndex }})</span>
      <template v-if="satPic.sentinelData">
        <IxRes :context="{cloud: $i18n.format(satPic.sentinelData.cloudMean)}">frs.maps.zoneMap.cloudCover</IxRes>
        <IxRes :context="{ndvi: $i18n.format(satPic.sentinelData.ndviMean)}">frs.maps.zoneMap.ndviMean</IxRes>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    satPic: Object
  }
}
</script>

<style lang="scss" module>
.text {
  display: flex;
  flex-wrap: wrap;

  > :not(:last-child) {
    margin-right: 0.25em;
  }
}
</style>
