<template lang="html">
  <SharedSummary :meta-data="metaData">
    <template #images>
      <SatelliteImageReference :image="metaData.parameters.selectedImage" />
    </template>
    <template #availableimages="{image}">
      <SatelliteImageReference :image="image" />
    </template>
  </SharedSummary>
</template>

<script>
import SharedSummary from './components/SharedSummary'
import SatelliteImageReference from './components/SatelliteImageReference'

export default {
  components: {
    SharedSummary,
    SatelliteImageReference
  },
  props: {
    metaData: Object
  }
}
</script>

<style lang="scss" module>

</style>
