<template lang="html">
  <div>
    <IxVectorLayer
      layer-id="cutOff"
      :features="Object.values(cutOffCultivationFeatures)"
      :vector-style="styles.cutOff"
    />
    <IxVectorLayer
      layer-id="cultivations"
      :features="cultivationFeatures"
      :vector-style="styles.actionCultivationSelection"
    />
    <IxVectorLayer
      layer-id="customGeometries"
      :features="customGeometryFeatures"
      :vector-style="styles.action"
    />
    <IxVectorLayer
      layer-id="borders"
      :features="borderFeatures"
      :vector-style="styles.border"
    />
    <SelectInteraction
      :value="selectedFeatureIds"
      :features="cultivationFeatures"
      :layer-filter="layer => layer.get('id') === 'cultivations'"
      :hover-style="styles.hover"
      :selection-style="styles.selected"
      multiple
      @toggle="toggleFeature"
    />
  </div>
</template>

<script>
import {helpers} from '@turf/turf'
import {mapState, mapGetters, mapActions} from 'vuex'

import {
  fieldBorder,
  action,
  actionCultivationSelection,
  actionCultivationSelectionHover,
  actionCultivationSelectionSelected,
  actionCultivationSelectionCutOff
} from '@frs/map-styles'

import IxVectorLayer from '@components/map/IxVectorLayer'
import SelectInteraction from '@components/map/interactions/SelectInteraction'

const generateFeatureId = ({fieldId, cultivationId}) => cultivationId
  ? `cultivation-${cultivationId}-field-${fieldId}`
  : `remaining-${fieldId}`

export default {
  components: {
    IxVectorLayer,
    SelectInteraction
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      fieldData: state => state.data.field
    }),
    ...mapState('masterData', [
      'cropUsages'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'fieldsForCurrentOrgUnit',
      'entityLookup',
      'fieldId'
    ]),
    ...mapState('fieldRecordSystem/action/editBuffer', [
      'fieldIds',
      'selectedCultivationIds',
      'customGeometries',
      'remainingFieldSelected'
    ]),
    ...mapGetters('fieldRecordSystem/action/editBuffer', [
      'remainingFieldFeatures',
      'clippedCultivationFeatures',
      'cutOffCultivationFeatures'
    ]),
    selectedFeatureIds () {
      return [
        ...Object.keys(this.selectedCultivationIds)
          .map(cultivationId => generateFeatureId({
            cultivationId,
            fieldId: this.selectedCultivationIds[cultivationId]
          })),
        ...Object.keys(this.remainingFieldSelected)
          .filter(fieldId => this.remainingFieldSelected[fieldId])
          .map(fieldId => generateFeatureId({
            fieldId
          }))
      ]
    },
    relevantFields () {
      return this.fieldId
        ? [this.entityLookup[this.fieldId]]
        : this.fieldsForCurrentOrgUnit
    },
    borderFeatures () {
      return this.relevantFields
        .filter(field => this.fieldData[field.id].geometry)
        .map(field => helpers.feature(this.fieldData[field.id].geometry, {
          id: field.id,
          name: field.name
        }))
    },
    customGeometryFeatures () {
      return Object.keys(this.customGeometries).reduce((features, fieldId) => {
        const geometry = this.customGeometries[fieldId]

        if (geometry) {
          features.push(helpers.feature(geometry, {
            id: `custom-${fieldId}`
          }))
        }

        return features
      }, [])
    },
    cultivationFeatures () {
      const fieldIds = this.relevantFields.map(x => x.id)

      const remainingFeatures = fieldIds
        .filter(fieldId => this.remainingFieldFeatures[fieldId])
        .map(fieldId => {
          return {
            ...this.remainingFieldFeatures[fieldId],
            properties: {
              id: generateFeatureId({
                fieldId
              }),
              fieldId
            }
          }
        })

      const cultivationFeatures = fieldIds
        .reduce((features, fieldId) => {
          features.push(...this.clippedCultivationFeatures[fieldId].map(feature => ({
            ...feature,
            properties: {
              id: generateFeatureId({
                cultivationId: feature.properties.cultivation.id,
                fieldId
              }),
              fieldId,
              cultivationId: feature.properties.cultivation.id,
              cultivation: feature.properties.cultivation,
              cropUsage: this.cropUsages[feature.properties.cultivation.cropUsageId]
            }
          })))

          return features
        }, [])

      return remainingFeatures.concat(cultivationFeatures)
    },
    styles () {
      return {
        action,
        actionCultivationSelection,
        border: fieldBorder,
        cutOff: actionCultivationSelectionCutOff,
        hover: actionCultivationSelectionHover,
        selected: actionCultivationSelectionSelected
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/action/editBuffer', [
      'toggleCultivationId'
    ]),
    toggleFeature (feature) {
      const {cultivationId, fieldId} = feature.properties

      this.toggleCultivationId({
        fieldId,
        cultivationId
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
