<template lang="html">
  <div class="content">
    <div class="hint">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ActionStatusInfo1</IxRes>
      <strong>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ActionStatusInfo2</IxRes>
      </strong>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ActionStatusInfo3</IxRes>
    </div>
    <hr>
    <div class="date-picker">
      <div class="row">
        <div class="col-md-3">
          <p>
            <strong>
              <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ActionStatusBeginDate</IxRes>
            </strong>
          </p>
          <VueDateTimePicker
            v-model="start"
            iso
            :format="withTime ? 'L LT' : 'L'"
            name="ActionStatusBeginDate"
          />
        </div>
        <div class="col-md-3">
          <p>
            <strong>
              <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ActionStatusEndDate</IxRes>
            </strong>
          </p>
          <VueDateTimePicker
            v-model="end"
            iso
            :format="withTime ? 'L LT' : 'L'"
            :min="start"
            :readonly="!start"
            name="ActionStatusEndDate"
          />
        </div>
      </div>
    </div>

    <div class="status-view">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ActionStatusStatusText</IxRes>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ status }}</IxRes>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import VueDateTimePicker from 'src/vue/components/forms/VueDateTimePicker'

export default {
  components: {
    VueDateTimePicker
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/action/editBuffer', {
      'timestamps.actual.start': 'setActualStart',
      'timestamps.actual.end': 'setActualEnd'
    }),
    ...mapState('fieldRecordSystem/action/editBuffer', {
      withTime: state => state.timestamps.withTime
    }),
    status () {
      return this.end
        ? 'ActionStatusFinished'
        : this.start
          ? 'ActionStatusStarted'
          : 'ActionStatusPlanned'
    }
  },
  watch: {
    start (value) {
      if (!value) {
        this.end = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content > * {

  margin-bottom: 8px;
}
.hint {
  margin-top: 16px;
  margin-right: 8px;
}
.status-view {
  margin-top: 10px;
  margin-bottom: 14px;
}
.date-picker {
  margin-top: 16px;
}
p {
  margin-bottom: 6px;
}
hr {
  margin-top: 16px;
}
</style>
