export default [
  'nitrogen',
  'nitrate',
  'ammonium',
  'nitrogenAmide',
  'phosphorus',
  'potassium'
].reduce((obj, id) => ({
  ...obj,
  [id]: {title: `frs.action.planning.fertilization.nutrientSummary.headers.${id}`, formatString: 'f4'}
}), {})
