<template lang="html">
  <div>
    <div class="form-group crop-display">
      <FrsCropUsagePicker v-model="cropUsageId" required name="cropUsagePicker">
        <template #label>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationSelection</IxRes>
        </template>
      </FrsCropUsagePicker>
    </div>
    <div v-if="hasPrediction && predictionValues.length">
      <label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CropSuggestionLabel</IxRes>:
      </label>
      <div class="link-container">
        <template v-for="(cropUsageId, i) in predictionValues">
          <span v-if="i > 0" :key="`${cropUsageId}-separator`">,&nbsp;</span>
          <a :key="`${cropUsageId}-link`" @click="setSelectedCropUsageId(cropUsageId)">
            <IxRes>masterData.crops.usage.{{ cropUsageId }}_name</IxRes>
          </a>
        </template>
      </div>
      <br>
    </div>
    <div v-if="crop && crop.isLeguminous">
      <SubsidyPlan v-model="subsidies" />
    </div>
    <div class="expected-yield">
      <QuantityInput
        v-model="expectedYieldObject"
        placement="right" name="expectedYield"
        :unit-options="validUnits"
        :rule="min(0)"
      >
        <template #label>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ExpectedYield</IxRes>:
        </template>
      </QuantityInput>
    </div>
    <MixtureSelection v-model="mixtureComponents" :available-mixtures="availableMixtures" @remove="mixtureComponents = null">
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.UnderseedMixtures</IxRes>
      </template>
    </MixtureSelection>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {uniq} from 'lodash'

import {mapFormFields} from '@helpers/vuex'

import QuantityInput from '@components/forms/QuantityInput'
import FrsCropUsagePicker from '@frs/components/base/FrsCropUsagePicker'

import MixtureSelection from './mixture/MixtureSelection'
import SubsidyPlan from './SubsidyPlan'

import ChangeDetectionMixin from '@components/forms/ChangeDetectionMixin'
import StandardRulesMixin from '@components/forms/StandardRulesMixin'

export default {
  components: {
    QuantityInput,
    SubsidyPlan,
    FrsCropUsagePicker,
    MixtureSelection
  },
  mixins: [
    ChangeDetectionMixin,
    StandardRulesMixin
  ],
  props: {
    availableMixtures: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      validUnits: ['dt/ha', 'piece/ha', 'Bund/ha']
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/cultivationPlanning/editBuffer', [
      'expectedYield',
      'expectedYieldUnit',
      'subsidies',
      'cropUsageId',
      'mixtureComponents'
    ]),
    ...mapState('masterData', [
      'crops',
      'cropUsages'
    ]),
    ...mapState('fieldRecordSystem/cultivationPlanning', {
      predictedCrops: state => state.predictedCrops
    }),
    expectedYieldObject: {
      get () {
        return {amount: this.expectedYield, unit: this.expectedYieldUnit}
      },
      set ({amount, unit}) {
        this.expectedYield = amount
        this.expectedYieldUnit = unit
      }
    },
    hasPrediction () {
      return Object.keys(this.predictedCrops).length > 0
    },
    predictionValues () {
      return uniq(Object.values(this.predictedCrops).filter(cropUsageId => cropUsageId !== null))
    },
    crop () {
      const usage = this.cropUsages[this.cropUsageId]
      return usage ? this.crops[usage.cropId] : null
    }
  },
  methods: {
    setSelectedCropUsageId (cropUsageId) {
      this.cropUsageId = cropUsageId
    }
  }
}
</script>

<style lang="scss" scoped>
.expected-yield {
  padding-bottom: 15px;
  width: 55%;
}
.picker {
  width: 80%;
}
.crop-display {
  width: 70%;
}
a:hover {
  cursor: pointer;
}
.link-container {
  display: flex;
  flex-direction: row;
}
</style>
