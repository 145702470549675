<template lang="html">
  <div class="component">
    <DetailsHeader :timestamps="action.timestamps" />
    <DetailsCommonInfo v-bind="{action, machines, persons}" />

    <div v-for="(part, i) in action.planningParts" :key="part.id">
      <CollapsibleSection v-model="showPartLookup[i]">
        <template #header>
          <IxRes>{{ translateType(part) }}</IxRes>
          <template v-if="part.procedure">
            <span>, </span>
            <IxRes>{{ translateProcedure(part) }}</IxRes>
          </template>
        </template>
        <template #hint-show>
          <IxRes>Common.SR_Common.Expand</IxRes>
        </template>
        <template #hint-hide>
          <IxRes>Common.SR_Common.Collapse</IxRes>
        </template>

        <ShowDetails v-if="part.actualArea">
          <template #label>
            <IxRes>frs.action.details.actualArea</IxRes>:
          </template>
          {{ $i18n.format(part.actualArea, 'hectare-sparse') }}
        </ShowDetails>

        <DetailsComponentSwitch v-bind="{part, products}" />

        <template v-if="part.productQuantities.length">
          <div class="product-quantities-header">
            <label>
              <IxRes>frs.action.details.applicationAmounts</IxRes>
            </label>
          </div>
          <DetailsAmount v-bind="{products, productQuantities: part.productQuantities, area: part.actualArea || action.area}" />
        </template>
      </CollapsibleSection>
    </div>
  </div>
</template>

<script>
import ShowDetails from '@frs/components/action/planning/wizard/details/ShowDetails'

import {getTypeKey, getProcedureKey} from '@frs/components/action/translation-key-helper'
import DetailsComponentSwitch from '@frs/components/action/planning/wizard/details/DetailsComponentSwitch'
import DetailsAmount from '@frs/components/action/planning/wizard/details/ProductQuantityTable'
import CollapsibleSection from '@components/CollapsibleSection'
import DetailsCommonInfo from '@frs/components/action/planning/wizard/details/DetailsCommonInfo'
import DetailsHeader from '@frs/components/action/planning/wizard/details/DetailsHeader'

export default {
  components: {
    DetailsHeader,
    DetailsCommonInfo,
    CollapsibleSection,
    DetailsAmount,
    DetailsComponentSwitch,
    ShowDetails,
  },
  props: {
    action: Object,
    machines: Array,
    persons: Array,
    products: Object
  },
  data () {
    return {
      showPartLookup: this.action.planningParts.map((x, i) => i === 0)
    }
  },
  methods: {
    translateType (part) {
      return getTypeKey(part)
    },
    translateProcedure (part) {
      return getProcedureKey(part)
    }
  }
}
</script>

<style lang="scss" scoped>
.component {
  padding: 5px;

  .products {
    display: flex;
    flex-direction: column;
  }
}

.product-quantities-header {
  display:flex;
  justify-content: center;
}
</style>
