<template>
  <FileDropArea
    v-model="files" format-name="Shape"
    label="Shapefile"
    accept=".zip, .shp, .dbf, .shx, .prj, .atx, .sbx, .qix, .aih, .cpg, .shp.xml" :description="description"
    @input="convertFiles"
  />
</template>

<script>
import {mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import FileDropArea from '@components/forms/FileDropArea'

import convert, {canConvert} from './convert-shape-files'

export default {
  components: {
    FileDropArea
  },
  data () {
    return {
      wrongFormat: false,
      description: [],
      files: null
    }
  },
  computed: {
    ...mapResources([
      'Common.SR_Common'
    ])
  },
  methods: {
    convertFiles () {
      if (canConvert(this.files)) {
        convert(this.files)
          .then(features => {
            this.$emit('load', features)
          })
          .catch(error => {
            console.error(error)
            notifications.error(error.message || 'Error during conversion')
          })
      } else {
        this.wrongFormat = true
      }
    }
  },
  created () {
    this.description.push(this.SR_Common.ShapeImportDescription)
  }
}
</script>

<style>
</style>
