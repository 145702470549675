<template>
  <div>
    <VueSelectize
      v-model="type"
      :options="typeOptions"
    />

    <component :is="formComponent" :key="formComponent" :measurement-keys="measurementKeys" />

    <div class="footer">
      <IxButton large save @click="saveData" />
    </div>

    <IxVectorLayer
      :features="polygonFeaturesForLayer"
      :vector-style="styles.preview"
      :z-index="1"
      layer-id="multiPolygon"
      auto-focus
    />
    <IxVectorLayer
      :features="pointFeaturesForLayer"
      :vector-style="styles.point"
      :z-index="2"
      layer-id="points"
    />
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'
import {mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import FertilizationForm from './widgets/FertilizationForm'
import HarvestForm from './widgets/HarvestForm'
import IxButton from '@components/IxButton'
import VueSelectize from '@components/VueSelectize'
import IxVectorLayer from '@components/map/IxVectorLayer'

import {pointBase, entityImportPreview} from '@frs/map-styles'

export default {
  components: {
    HarvestForm,
    FertilizationForm,
    IxVectorLayer,
    VueSelectize,
    IxButton
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/telemetry/dataImport', [
      'type',
      'typeOptions'
    ]),
    ...mapState('fieldRecordSystem/telemetry/dataImport', {
      features: state => state.features
    }),
    formComponent () {
      return this.type.value === 'production' ? 'HarvestForm' : 'FertilizationForm'
    },
    measurementKeys () {
      if (!this.features) return []
      const props = this.features[0].getProperties()
      return Object.keys(props)
    },
    pointFeaturesForLayer () {
      if (this.features[0].get('geometry').getType() === 'Point') {
        this.focus(this.features)
        return this.features
      } else return []
    },
    polygonFeaturesForLayer () {
      if (this.features[0].get('geometry').getType() === 'Polygon' || this.features[0].get('geometry').getType() === 'MultiPolygon') {
        this.focus(this.features)
        return this.features
      } else return []
    },
    styles () {
      return {
        point: pointBase,
        preview: entityImportPreview
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/telemetry/dataImport', [
      'save'
    ]),
    ...mapActions('fieldRecordSystem/map', [
      'focus'
    ]),
    ...mapMutations('fieldRecordSystem/telemetry/dataImport', [
      'reset'
    ]),
    saveData () {
      notifications.success('Daten werden gespeichert. Dies kann einige Zeit in Anspruch nehmen.')
      this.save().then(() => {
        notifications.success('Speichern erfolgreich')
      })
        .catch((error) => {
          console.error(error.message || error)
          notifications.error('Speichern nicht erfolgreich')
        })
    }
  },
  beforeDestroy () {
    this.reset()
  }
}
</script>

<style scoped>
.footer {
  display: flex;
  justify-content: flex-end;
}
</style>
