<template lang="html">
  <div class="result-entry-widget">
    <div class="widget-title">
      <h4>
        <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.{{ `ContentClass_${header}` }}</IxRes>
        <ResultStatusIndicator :geometry-id="selectedSamplingOrderGeometryId" :nutrient="nutrient" />
      </h4>
      <OxideSwitch v-if="nutrient !== 'ph'" :nutrient="nutrient" />
    </div>

    <NumericInput
      v-model="computedMeasurement"
      :unit="nutrient !== 'ph' ? 'mg/100g' : null"
      :disabled="!!savedResult[`${nutrient}Measurement`]"
      name="measurement"
    >
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.MeasurementValue</IxRes>
      </template>
    </NumericInput>

    <ContentClassSelector
      v-model="computedContentClass"
      :nutrient="nutrient"
      :disabled="!!savedResult[`${nutrient}ContentClass`]"
    />

    <NumericInput
      v-if="nutrient === 'ph'" v-model="computedLimeRecommendation"
      unit="dt/ha"
      :disabled="!!savedResult.limeRecommendation"
      name="limeRecommendation"
    >
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.CaRecommendation</IxRes>
      </template>
    </NumericInput>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

import NumericInput from '@components/forms/NumericInput'

import ResultStatusIndicator from './ResultStatusIndicator'
import ContentClassSelector from './ContentClassSelector'
import OxideSwitch from './OxideSwitch'

import {calculateToOxidform, calculateFromOxidform, oxideNames} from '@frs/components/basic-fertilization/oxidform-calculation'

export default {
  components: {
    NumericInput,
    ResultStatusIndicator,
    ContentClassSelector,
    OxideSwitch
  },
  props: {
    nutrient: String
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', {
      columnMapping: state => state.upload.columnMapping
    }),
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', {
      results: state => state.results,
      selectedSamplingOrderGeometryId: state => state.manual.selectedSamplingOrderGeometryId
    }),
    ...mapGetters('fieldRecordSystem/basicFertilization/resultImport', [
      'savedResultsLookup'
    ]),
    result () {
      return this.results[this.selectedSamplingOrderGeometryId]
    },
    savedResult () {
      return this.savedResultsLookup[this.selectedSamplingOrderGeometryId]
    },
    isOxide () {
      return this.columnMapping[`${this.nutrient}IsOxide`]
    },
    header () {
      return this.isOxide ? oxideNames[this.nutrient] : this.nutrient
    },
    computedMeasurement: {
      get () {
        const measurement = this.result[`${this.nutrient}Measurement`]

        return this.isOxide && measurement !== null
          ? calculateToOxidform(this.nutrient, measurement)
          : measurement
      },
      set (measurement) {
        if (this.isOxide) {
          measurement = calculateFromOxidform(this.nutrient, measurement)
        }
        this.$emit('input', {[`${this.nutrient}Measurement`]: measurement})
      }
    },
    computedContentClass: {
      get () {
        return this.result[`${this.nutrient}ContentClass`]
      },
      set (contentClass) {
        this.$emit('input', {[`${this.nutrient}ContentClass`]: contentClass})
      }
    },
    computedLimeRecommendation: {
      get () {
        return this.result.limeRecommendation
      },
      set (limeRecommendation) {
        this.$emit('input', {limeRecommendation})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.result-entry-widget {
  display: flex;
  flex-direction: column;
  width: 24%;
  margin: 5px;

  &:not(:last-child) {
    padding-right: 5px;
    margin-right: 0;
    border-right: 1px solid gray;
  }

  h4 {
    margin-top: 0;

    .fa {
      cursor: help;
    }
  }
}

.widget-title {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
</style>
