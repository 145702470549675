<template lang="html">
  <div class="marker-label-container" :class="{bottom}">
    <span class="marker-label" :class="{included, interactive}" @click="onClick">
      <slot>{{ year }}</slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    year: Number,
    bottom: Boolean,
    included: Boolean,
    interactive: Boolean,
    onClick: Function
  }
}
</script>

<style lang="scss" scoped>
.marker-label-container {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: top-start / top-end;
  height: 100%;

  &.bottom {
    grid-row: bottom-start / bottom-end;
  }
}

.marker-label {
  font-size: 0.9em;
  opacity: 0.5;

  transition: opacity 0.2s;

  &.included {
    opacity: 1;
  }
}
</style>
