<template lang="html">
  <div class="row">
    <div class="col-md-8 col-md-offset-2">
      <label>
        Anbauten
      </label>
      <FieldEditingCultivationCard
        v-for="cultivation in cultivations" :key="cultivation.id"
        :field-feature="fieldFeature"
        :cultivation="cultivation"
        :selected="value.includes(cultivation)"
        @click.native="toggle(cultivation)"
      />
    </div>
  </div>
</template>

<script>
import FieldEditingCultivationCard from './FieldEditingCultivationCard'

export default {
  components: {
    FieldEditingCultivationCard
  },
  props: {
    value: Array,
    fieldFeature: Object,
    cultivations: Array
  },
  methods: {
    toggle (cultivation) {
      if (this.value.includes(cultivation)) {
        this.$emit('input', this.value.filter(x => x !== cultivation))
      } else {
        this.$emit('input', this.value.concat([cultivation]))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
