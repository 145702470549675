<template>
  <StatusBox v-bind="{isDone}">
    <span v-if="!(isDone && plannedEqualsActual)">
      <IxRes>frs.action.details.timestamps.planned</IxRes>:
      <DateRange :value="timestamps.planned" />
    </span>
    <span v-if="isDone">
      <IxRes>frs.action.details.timestamps.actual</IxRes>:
      <DateRange :value="timestamps.actual" />
    </span>
  </StatusBox>
</template>

<script>
import StatusBox from '@frs/components/action/planning/wizard/widgets/StatusBox'
import DateRange from '@frs/components/action/planning/wizard/widgets/DateRange'

export default {
  components: {
    DateRange,
    StatusBox
  },
  props: {
    timestamps: {
      type: Object,
      required: true
    }
  },
  computed: {
    isDone () {
      return !!this.timestamps.actual.end
    },
    plannedEqualsActual () {
      return this.timestamps.planned.start === this.timestamps.actual.start &&
          this.timestamps.planned.end === this.timestamps.actual.end
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
