<template lang="html">
  <div>
    <div class="row">
      <div class="col-md-3">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.PlannedStartLabel</IxRes>
        <VueDateTimePicker
          v-model="start" :format="withTime ? 'L LT' : 'L'"
          iso
          name="PlannedStartLabel"
        />
      </div>
      <div class="col-md-3">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.PlannedEndLabel</IxRes>
        <VueDateTimePicker
          v-model="end" :format="withTime ? 'L LT' : 'L'"
          :min="start"
          iso name="PlannedEndLabel"
        />
      </div>
    </div>

    <SimpleSwitch v-model="withTime">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.IncludeTime</IxRes>
    </SimpleSwitch>
  </div>
</template>

<script>
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import SimpleSwitch from '@components/forms/SimpleSwitch'

import {mapFormFields} from '@helpers/vuex'

export default {
  components: {
    VueDateTimePicker,
    SimpleSwitch
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/action/editBuffer', {
      'timestamps.planned.start': 'setPlannedStart',
      'timestamps.planned.end': 'setPlannedEnd',
      'timestamps.withTime': {}
    })
  }
}
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 0.5em;
}
</style>
