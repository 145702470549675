<template>
  <div>
    <SimpleTable
      class="cultivation-table"
      :columns="cultivationColumns"
      :data="cultivationTableData"
      :highlight="highlight"
    >
      <template #checkbox="{row}">
        <CheckboxCell :row="row" />
      </template>
      <template #fieldName="{value}">
        {{ value }}
      </template>
      <template #type="{value: type}">
        <CultivationTypeCell v-bind="{type, compact}" />
      </template>
      <template #colorAndName="{row}">
        <CultivationNameCell :cultivation="addCropUsageColor(row)" />
      </template>
      <template #dateTimeRange="{value: dateTimeRange}">
        <CultivationRangeCell v-bind="{dateTimeRange, compact}" />
      </template>
      <template #area="{row}">
        {{ $i18n.format(row.geometryGeoJson) }}
      </template>
    </SimpleTable>
  </div>
</template>

<script>
import {sortBy} from 'lodash'
import {mapState, mapGetters} from 'vuex'

import {fromWkt} from '@helpers/geojson'

import SimpleTable from '@components/table/SimpleTable'

import CheckboxCell from './CheckboxCell'
import CultivationTypeCell from './CultivationTypeCell'
import CultivationNameCell from './CultivationNameCell'
import CultivationRangeCell from './CultivationRangeCell'

export default {
  components: {
    SimpleTable,
    CheckboxCell,
    CultivationTypeCell,
    CultivationNameCell,
    CultivationRangeCell
  },
  props: {
    fieldId: String,
    compact: Boolean
  },
  computed: {
    ...mapState('masterData', {
      cropUsages: state => state.cropUsages
    }),
    ...mapState('fieldRecordSystem/action/editBuffer', [
      'id',
      'fieldIds'
    ]),
    ...mapGetters('fieldRecordSystem/action/editBuffer', [
      'cultivationDateValidityLookup',
      'relevantCultivationsByFieldId',
      'remainingFieldFeatures'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'entityLookup'
    ]),
    cultivationTableData () {
      const rows = []

      for (const fieldId in this.relevantCultivationsByFieldId) {
        if ((!this.fieldId && !this.fieldIds.includes(fieldId)) || (this.fieldId && this.fieldId !== fieldId)) continue

        const cultivations = this.relevantCultivationsByFieldId[fieldId].map(cultivation => {
          return {
            ...cultivation,
            fieldId,
            fieldName: this.entityLookup[fieldId].name,
            geometryGeoJson: fromWkt(cultivation.geometry) // geometry needs to be converted from wkt to geoJson
          }
        })

        const sortedCultivations = sortBy(cultivations, 'dateTimeRange.start')
        rows.push(...sortedCultivations)

        // fake cultivation for remaining field
        const remainingFieldFeature = this.remainingFieldFeatures[fieldId]
        if (remainingFieldFeature) {
          // rest of field, placeholder for now
          rows.push({
            id: null,
            fieldId,
            fieldName: this.entityLookup[fieldId].name,
            geometryGeoJson: remainingFieldFeature.geometry // geometry is already geoJson
          })
        }
      }

      return rows
    },
    cultivationColumns () {
      let columns = {
        checkbox: '',
        type: 'Typ',
        fieldName: 'Fläche',
        colorAndName: 'Anbau',
        dateTimeRange: 'Zeitraum',
        area: 'Größe'
      }

      if (this.id) {
        delete columns.checkbox
      }
      if (this.fieldId) {
        delete columns.fieldName
      }

      return columns
    }
  },
  methods: {
    highlight (row) {
      // NOTE strict boolean check because remainingField is not in lookup but should be treated as true
      if (this.cultivationDateValidityLookup[row.id] === false) {
        return 'out-of-range'
      }
    },
    // to make cultivation compatible with helpers from new cultivation overview
    addCropUsageColor (row) {
      return {
        ...row,
        color: row.cropUsageId in this.cropUsages ? this.cropUsages[row.cropUsageId].color : null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cultivation-table {
  margin-top: 5px;
}

.table {
  margin-bottom: 0 !important;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  margin-left: 7px;
  margin-top: 8px;

  .checkbox-icon {
    margin-right: 8px;
    margin-top: 4px;
  }
}

::v-deep .out-of-range {
  opacity: 0.25;
}
</style>
