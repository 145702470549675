<template>
  <LabelAndMessage>
    <template #label>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ProtectionProduct</IxRes>
    </template>
    <div>{{ productName }}</div>
  </LabelAndMessage>
</template>

<script>
import {mapGetters} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    LabelAndMessage
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/telemetry', [
      'data.selectedMetaData'
    ]),
    ...mapGetters('masterData', [
      'products'
    ]),
    productName () {
      return this.products[this.selectedMetaData.productId].name
    }
  }
}
</script>

<style>
</style>
