<template>
  <div>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.year</IxRes>
      </template>
      <div>{{ plannedYield.year }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.averageYield</IxRes>
      </template>
      <div>{{ `${plannedYield.averageYield} ${plannedYield.unit}` }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.includeByproduct</IxRes>
      </template>
      <div>
        <IxRes>frs.mapManagement.labels.includeByproduct_{{ plannedYield.includeByproduct ? 'yes' : 'no' }}</IxRes>
      </div>
    </LabelAndMessage>

    <LabelAndMessage v-if="plannedYield.cropUsageId">
      <template #label>
        <IxRes>frs.mapManagement.labels.cropUsage</IxRes>
      </template>
      <div>{{ cropUsageTranslation }}</div>
    </LabelAndMessage>

    <LabelAndMessage v-if="plannedYield.cropHarvestQualityId">
      <template #label>
        <IxRes>frs.mapManagement.labels.cropHarvestQuality</IxRes>
      </template>
      <div>{{ cropHarvestQualityTranslation }}</div>
    </LabelAndMessage>
    <hr>
  </div>
</template>

<script>
import {getCropHarvestQualityTranslations, getCropUsageTranslations} from '@frs/helpers/crops'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    LabelAndMessage
  },
  props: {
    plannedYield: {
      type: Object,
      required: true
    }
  },
  computed: {
    cropUsageTranslation () {
      if (!this.plannedYield.cropUsageId) return ''

      const {name, abbreviation} = getCropUsageTranslations(this.plannedYield.cropUsageId, true)
      return `${name} (${abbreviation})`
    },
    cropHarvestQualityTranslation () {
      return getCropHarvestQualityTranslations(this.plannedYield.cropHarvestQualityId).name
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
