const mergeWith = (values) => (merged, item) => {
  const index = values.findIndex(r => r.productId === item.productId)
  if (index === -1) { // values do not contain existing buffer item => buffer item remains unchanged
    merged.push(item)
  } else { // values contain existing buffer item => update buffer item
    const updated = Object.assign({}, {...item}, {...values[index]})
    merged.push(updated)
  }
  return merged
}

export const mergeWithValue = (buffer, values) => {
  // merge existing buffer item values
  const merged = buffer.reduce(mergeWith(values), [])

  // new values not contained in buffer are added as new items
  const added = values.filter(x => !buffer.some(value => value.productId === x.productId))
  return merged.concat(added)
}

export const filterCompleted = (buffer) => {
  return buffer.filter(x => x.productId && x.amount && x.unit)
}
