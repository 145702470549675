<template lang="html">
  <div class="workflow-header">
    <ol class="breadcrumb workflow-navigation">
      <li>
        <a @click="setRightView(resolvedBack)">
          <IxRes>Common.SR_Common.BackTo</IxRes> <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ backLabel }}</IxRes>
        </a>
      </li>
      <li class="active">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ viewLabel }}</IxRes>
      </li>
    </ol>
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'

import viewTitles from 'src/js/store/modules/field-record-system/view-titles'

export default {
  computed: {
    ...mapState('fieldRecordSystem', {
      view: state => state.ui.view.right
    }),
    ...mapState('fieldRecordSystem/navigation', {
      fieldId: state => state.location.fieldId
    }),
    ...mapGetters('fieldRecordSystem', [
      'resolvedView',
      'resolvedBack'
    ]),
    backLabel () {
      return this.resolvedBack !== 'default'
        ? viewTitles[this.resolvedBack] || `${this.resolvedBack[0].toUpperCase()}${this.resolvedBack.substring(1)}`
        : this.fieldId
          ? 'FieldOverview'
          : 'CompanyDataOverview'
    },
    viewLabel () {
      return viewTitles[this.resolvedView] ||
        (this.resolvedView[0].toUpperCase() + this.resolvedView.substring(1))
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem', [
      'setRightView'
    ])
  }
}
</script>

<style lang="scss" scoped>
.workflow-header {
  display: flex;
  padding: 10px;
  height: 30px;
  align-items: center;
}
.alert {
  flex: 1
}
.workflow-navigation {
  height: 20px;
  margin-bottom: 20px;
  padding-left: 15px;
  background-color: inherit;

  a {
    cursor: pointer;
  }
}
hr {
  margin: 5px;
}
</style>
