<template lang="html">
  <div class="right-sidebar">
    <WorkflowHeader v-if="workflow && !editing.active && workflow.breadcrumbs" />
    <transition name="fade" mode="out-in">
      <!-- TODO find out how to prevent double display on route change -->
      <div v-if="!loadingNavigationData" :key="sidebarContentKey" class="right-sidebar-content">
        <!-- testing -->
        <TestingSandbox v-if="showSandbox" />
        <!-- testing -->
        <template v-else-if="editing.active && editing.flavor === 'expert'">
          <component :is="editing.component" />
        </template>
        <template v-else>
          <router-view v-if="!loadingNavigationData && (view === 'default' || routedViews.includes(view))" />
          <component :is="workflow.component" v-else />
        </template>
      </div>
      <div v-else>
        <FrsLoadingIndicator :loading="true" />
      </div>
    </transition>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import TestingSandbox from './components/testing/TestingSandbox'
import WorkflowHeader from './components/WorkflowHeader'
import TagGroupDashboard from './components/orgunit/TagGroupDashboard'
import FieldDetailsPanel from './components/field-details/FieldDetailsPanel'
import DefaultRightSidebar from './components/DefaultRightSidebar'
import CropRotationManagement from './components/crop-rotation/CropRotationManagement'
import CultivationPlanning from './components/cultivation-planning/CultivationPlanning'
import NutrientRequirement from './components/nutrient-requirement/NutrientRequirement'
import MachinesOverview from './components/machines/MachinesOverview'
import PersonsOverview from './components/persons/PersonsOverview'
import LanePlanning from './components/lane-planning/LanePlanning'
import ActionsForField from './components/action/ActionsForField'
import ActionDetailsPage from './components/action/ActionDetailsPage'
import ActionPlanning from './components/action/planning/ActionPlanning'
import ActionsForOrgUnit from './components/action/overview/ActionsForOrgUnit'
import NutritionStatusForm from './components/field-details/editing/NutritionStatusForm.vue'
import SoilDetailsForm from './components/field-details/editing/SoilDetailsForm.vue'
import WeatherDetails from 'src/vue/areas/field-record-system/components/field-details/weather/WeatherDetails'
import LanePlanSelectionOrgUnit from './components/lane-planning/manage-lane-plans/LanePlanSelectionOrgUnit'
import ResultPage from './components/lane-planning/planning-wizard/ResultPage'
import DetailView from './components/lane-planning/manage-lane-plans/widgets/DetailView'
import CompanyData from './components/orgunit/details/CompanyData'
import CompanyNumbers from './components/orgunit/details/CompanyNumbers'
import FieldEditingTools from './components/geometry-editing/FieldEditingTools'
import CultivationEditingTools from './components/geometry-editing/CultivationEditingTools'
import ActionEditingTools from './components/geometry-editing/ActionEditingTools'
import LanePlanningEditingTools from './components/geometry-editing/LanePlanningEditingTools'
import BasicFertilization from './components/basic-fertilization/BasicFertilization'
import SamplingOrderCreation from './components/basic-fertilization/order-creation/SamplingOrderCreation'
import SamplingOrderPlanning from './components/basic-fertilization/order-planning/SamplingOrderPlanning'
import ProbingOrderExport from './components/basic-fertilization/ProbingOrderExport'
import ProbingResultManualSettings from './components/basic-fertilization/result-manual/ProbingResultManualSettings'
import ProbingResultManualValues from './components/basic-fertilization/result-manual/ProbingResultManualValues'
import ProbingResultUpload from './components/basic-fertilization/ProbingResultUpload'
import NutrientMapDisplay from './components/basic-fertilization/NutrientMapDisplay'
import ApplicationMapCreation from './components/basic-fertilization/ApplicationMapCreation'
import ApplicationMapPreview from '@frs/components/basic-fertilization/application-maps/preview/ApplicationMapPreview'
import DefaultYields from '@frs/components/default-yield/DefaultYields'
import SimpleApplicationMaps from '@frs/components/simple-application-maps/SimpleApplicationMaps'
import MaizeSowing from '@frs/components/maize-sowing/MaizeSowing'
import EntityImportPreview from '@frs/components/entity-import/EntityImportPreview'
import MapDetails from '@frs/components/map-management/MapDetails'
import ApplicationMapCreationResult from '@frs/components/application-maps/ApplicationMapCreationResult'
import Telemetry from '@frs/components/telemetry/Telemetry'
import SimonModule from '@frs/components/simon-module/SimonModule'
import ApplicationDetails from '@frs/components/simon-module/ApplicationDetails'
import YieldDetails from '@frs/components/simon-module/YieldDetails'
import ZoneMapImportSettings from '@frs/components/map-management/zone-map-import/ZoneMapImportSettings'
import SoilMapImportSettings from '@frs/components/map-management/soil-map-import/SoilMapImportSettings'
import AlgorithmicApplicationMapCreation from '@frs/components/application-maps/AlgorithmicApplicationMapCreation'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    FrsLoadingIndicator,
    ActionsForField,
    TestingSandbox,
    WorkflowHeader,
    TagGroupDashboard,
    FieldDetailsPanel,
    DefaultRightSidebar,
    CultivationPlanning,
    CropRotationManagement,
    MachinesOverview,
    PersonsOverview,
    ActionPlanning,
    ActionsForOrgUnit,
    ActionEditingTools,
    CultivationEditingTools,
    FieldEditingTools,
    LanePlanningEditingTools,
    CompanyData,
    CompanyNumbers,
    LanePlanning,
    NutritionStatusForm,
    SoilDetailsForm,
    WeatherDetails,
    ActionDetailsPage,
    NutrientRequirement,
    BasicFertilization,
    SamplingOrderCreation,
    SamplingOrderPlanning,
    ProbingOrderExport,
    ProbingResultManualSettings,
    ProbingResultManualValues,
    ProbingResultUpload,
    NutrientMapDisplay,
    ApplicationMapCreation,
    LanePlanSelectionOrgUnit,
    ResultPage,
    EntityImportPreview,
    DefaultYields,
    SimpleApplicationMaps,
    MaizeSowing,
    MapDetails,
    ApplicationMapCreationResult,
    DetailView,
    Telemetry,
    SimonModule,
    ApplicationDetails,
    YieldDetails,
    ZoneMapImportSettings,
    SoilMapImportSettings,
    AlgorithmicApplicationMapCreation,
    ApplicationMapPreview
  },
  mixins: [
    PermissionMixin
  ],
  data () {
    return {
      loadingNavigationData: true,
      showSandbox: false,
      keyHandler: null
    }
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      view: state => state.ui.view.right,
      workflow: state => state.ui.workflow,
      editing: state => state.map.editing
    }),
    ...mapState('fieldRecordSystem/navigation', {
      location: state => state.location
    }),
    ...mapGetters('fieldRecordSystem/navigation', [
      'entityLookup',
      'parentLookup'
    ]),
    sidebarContentKey () {
      return this.showSandbox
        ? 'sandbox'
        : this.editing.active && this.flavor === 'expert'
          ? 'expert-editing'
          : this.view
    },
    routedViews () {
      return [
        'basicFieldInfoForm',
        'SoilDetailsForm'
      ]
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/migration', [
      'loadMigratedFields'
    ]),
    ...mapMutations('fieldRecordSystem', [
      'setRightView'
    ]),
    async selectEntityBasedOnRoute (from, to) {
      // console.log({from, to})
      const harvestYearChanged = to.params.year && from.params.year !== to.params.year

      if (harvestYearChanged) {
        // console.log('reloading tree')
        this.loadingNavigationData = true
        this.$store.commit('dataLoading/invalidate', 'orgUnit.geometries')
        await this.$store.dispatch('fieldRecordSystem/reloadSubTreeEntities')
        this.$store.dispatch('fieldRecordSystem/reloadEntities')
      }

      const {fieldId, orgUnitId} = to.params
      const contextOrgUnitId = orgUnitId || this.parentLookup[fieldId]

      // arrived on invalid orgUnit > exit to home
      if (this.location.orgUnitId && (!contextOrgUnitId || !this.entityLookup[contextOrgUnitId])) {
        // console.log('redirecting to home')
        this.loadingNavigationData = true

        await this.$store.dispatch('fieldRecordSystem/selectOrgUnit', null)
        if (to.name !== 'home') {
          this.$router.push({name: 'home'})
        }

        this.loadingNavigationData = false
        return
      }

      // in this cases it is necessary to reload critical orgUnit data
      if (contextOrgUnitId && (harvestYearChanged || contextOrgUnitId !== this.location.orgUnitId || (from.params.fieldId && !fieldId))) {
        this.loadingNavigationData = true
        // console.log(`selecting org ${contextOrgUnitId}`)

        // NOTE this includes loadLotsOfOrgUnitData
        await this.$store.dispatch('fieldRecordSystem/selectOrgUnit', contextOrgUnitId)
      }

      // somehow arrived on invalid field within valid orgUnit > exit to orgUnit
      if (fieldId && !this.entityLookup[fieldId] && this.entityLookup[contextOrgUnitId]) {
        // console.log(`redirecting to org dashboard for ${contextOrgUnitId}`)
        this.$router.push({name: 'orgDashboard', params: {orgUnitId: contextOrgUnitId}})

        this.loadingNavigationData = false
        return
      }

      // console.log(`current fieldId: ${this.location.fieldId}`)
      if (fieldId && (harvestYearChanged || fieldId !== this.location.fieldId)) {
        // console.log(`selecting field ${fieldId}`)
        await this.$store.dispatch('fieldRecordSystem/selectField', fieldId)
      }

      if (harvestYearChanged && this.$can('feature/highlight-migrated-fields')) {
        this.loadMigratedFields()
      }

      // NOTE at this point navigation location should be set correctly and all critical data should be loaded

      switch (to.name) {
      case 'fieldDashboard': {
        this.loadingNavigationData = true
        // TODO move into route view
        this.$store.dispatch('fieldRecordSystem/reloadDefaultFieldData')
        this.$store.dispatch('fieldRecordSystem/reloadWeatherData')
        break
      }
      case 'tagDashboard': {
        this.loadingNavigationData = true
        // TODO check if this can just be moved outside of switch
        // NOTE this attempts to load geometries even for a null tag
        await this.$store.dispatch('fieldRecordSystem/selectGroup', to.params.tag || null)
        break
      }
      case 'tagFieldDetails': {
        this.loadingNavigationData = true
        // TODO check if this can just be moved outside of switch
        await this.$store.dispatch('fieldRecordSystem/selectGroup', to.params.tag)
        break
      }
      }

      this.loadingNavigationData = false
    }
  },
  mounted () {
    this.keyHandler = document.body.addEventListener('keydown', event => {
      if (event.shiftKey && event.keyCode === 113) {
        this.showSandbox = !this.showSandbox
      }
    })
  },
  beforeDestroy () {
    document.body.removeEventListener('keydown', this.keyHandler)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setRightView('default')
      vm.loadingNavigationData = true
      vm.selectEntityBasedOnRoute(from, to)
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.setRightView('default')
    this.loadingNavigationData = true
    next()
    this.selectEntityBasedOnRoute(from, to)
  }
}
</script>

<style lang="scss" scoped>
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.15s;
}
.right-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.right-sidebar-content {
  flex: 1;
  padding: 5px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
