<template>
  <div v-if="map">
    <LabelAndMessage>
      <template #label>
        <IxRes>Common.SR_Common.Name</IxRes>
      </template>
      <div>{{ map.name }}</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>Common.SR_Common.CreatedAt</IxRes>
      </template>
      <div>{{ $i18n.format(map.generatedAt, 'datetime') }}</div>
    </LabelAndMessage>
  </div>
</template>

<script>
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
export default {
  components: {
    LabelAndMessage
  },
  props: {
    map: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
