<template>
  <div>
    <ProductNames v-bind="{part, products}">
      <IxRes>frs.actions.seeding.product</IxRes>:
    </ProductNames>

    <ShowDetails>
      <template #label>
        <IxRes>frs.actions.seeding.RowDistance</IxRes>:
      </template>

      {{ part.parameters.rowDistance }} {{ $i18n.translateUnit('cm') }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>frs.actions.seeding.RowSeparation</IxRes>:
      </template>

      {{ part.parameters.rowSeparation }} {{ $i18n.translateUnit('cm') }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>frs.actions.seeding.PlantsPerSqm</IxRes>:
      </template>

      {{ Math.round(part.parameters.plantsPerSqm) }} {{ $i18n.translateUnit('plants/m²') }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>frs.actions.seeding.TotalPlants</IxRes>:
      </template>

      {{ Math.round(part.parameters.totalPlants) }} {{ $i18n.translateUnit('plants') }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>frs.actions.seeding.packageSize</IxRes>:
      </template>

      {{ part.parameters.packageSize }} {{ $i18n.translateUnit('plants') }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>frs.actions.seeding.requiredPackageCount</IxRes>:
      </template>

      {{ requiredPackageCount }} {{ $i18n.translateUnit('pack') }}
    </ShowDetails>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import ShowDetails from './ShowDetails'
import ProductNames from './ProductNames'

export default {
  components: {
    ShowDetails,
    ProductNames
  },
  props: {
    part: Object,
    products: Object
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      fieldData: state => state.data.field
    }),
    requiredPackageCount () {
      return Math.ceil(this.part.parameters.totalPlants / this.part.parameters.packageSize)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
