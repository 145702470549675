<template>
  <div class="simple-application-map-details">
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.zoneMap</IxRes>
      </template>
      <div>{{ usedZoneMap }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.product</IxRes>
      </template>
      <div>{{ selectedProduct }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.cultivation</IxRes>
      </template>
      <div v-if="cultivation">
        <span>{{ cultivation.label }}</span>
        &nbsp;
        <span>({{ cultivation.dateTimeRange.start | formatDate }} - {{ cultivation.dateTimeRange.end | formatDate }})</span>
      </div>
      <div v-else>
        <IxRes>masterData.crops.usage.noInfo</IxRes>
      </div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>Areas.ApplicationMaps.SR_ApplicationMaps.SelectedProductGroup</IxRes>
      </template>
      <div>{{ selectedType }}</div>
    </LabelAndMessage>

    <LabelAndMessage v-if="!isByZonesMap && applicationMap.metaData.averageAmount">
      <template #label>
        <IxRes>frs.mapManagement.labels.averageAmount</IxRes>
      </template>
      <div>{{ `${applicationMap.metaData.averageAmount} ${applicationMap.unit}` }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.reverseZoneAmounts</IxRes>
      </template>
      <div>
        <IxRes>frs.mapManagement.labels.reverseZoneAmounts_{{ applicationMap.metaData.reverseZoneAmounts ? 'yes' : 'no' }}</IxRes>
      </div>
    </LabelAndMessage>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import moment from 'moment'

import {mapResources} from '@helpers/vuex'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import {smarterGet} from '@helpers/vuex/data-loading'
import {cultivationToName} from '@frs/components/cultivation-overview/helpers'

export default {
  components: {
    LabelAndMessage
  },
  filters: {
    formatDate (isoDate) {
      return moment(isoDate).format('DD.MM.YY')
    }
  },
  props: {
    applicationMap: Object
  },
  data () {
    return {
      cultivation: null
    }
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      mixtures: state => state.catchCropMixtures.data.selection.orgUnit
    }),
    ...mapGetters('masterData', [
      'products'
    ]),
    ...mapResources([
      'Enums.SR_Enums'
    ]),
    selectedType () {
      if (this.applicationMap) {
        const type = this.products[this.applicationMap.productId].type
        return this.SR_Enums[`ProductGroup_${type}`]
      } else {
        return 'N/A'
      }
    },
    selectedProduct () {
      if (this.applicationMap) {
        return this.products[this.applicationMap.productId].name
      } else {
        return 'N/A'
      }
    },
    usedZoneMap () {
      return this.applicationMap && this.applicationMap.metaData && this.applicationMap.metaData.zoneMap ? this.applicationMap.metaData.zoneMap.name : 'N/A'
    },
    isByZonesMap () {
      return this.applicationMap.category === 'SimpleApplicationMapByZones'
    }
  },
  created () {
    if (this.applicationMap.cultivationId) {
      smarterGet('/api/v2/org/cultivations/{cultivationId}/details', {
        id: 'mapManagement.cultivation.loadDetails',
        inputs: {
          cultivationId: () => this.applicationMap.cultivationId
        },
        onResult: details => {
          this.cultivation = {
            dateTimeRange: details.dateTimeRange,
            label: cultivationToName(details)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
label {
  margin-right: 6px;
}
.simple-application-map-details {
  display: flex;
  flex-direction: column;
}
.item {
  font-size: 1.2em;
  display: flex;
  flex-direction: row;
}
</style>
