export const regions = {
  cal: [
    'Baden-Würtemberg',
    'Bayern',
    'Berspann',
    'Brandenburg',
    'Bremen',
    'Hamburg',
    'Hessen',
    'MeckPomm',
    'Niedersachsen',
    'Nordrhein-Westfalen',
    'Rheinland-Pfalz',
    'Saarland',
    'Sachsen',
    'Sachsen-Anhalt',
    'Schleswig-Holstein',
    'Thüringen'
  ],
  dl: [
    'Brandenburg',
    'Mecklenburg-Vorpommern',
    'Sachsen-Anhalt',
    'Schleswig-Holstein'
  ]
}
