<template>
  <div class="common-info-container">
    <LabelAndMessage>
      <template #label>
        <IxRes>Areas.LanePlanning.SR_LanePlanning.SavedOn</IxRes>:
      </template>
      <template>
        {{ $i18n.format(plan.generatedAt, 'date') }}
      </template>
    </LabelAndMessage>
    <LabelAndMessage v-if="!isAb">
      <template #label>
        <IxRes>Areas.DataTransfer.SR_DataTransfer.TargetSystem</IxRes>:
      </template>
      <template>
        <span v-if="plan.settings.targetSystem">{{ plan.settings.targetSystem }}</span>
        <IxRes v-else>Areas.DataTransfer.SR_DataTransfer.DefaultTargetSystem</IxRes>
      </template>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>Areas.LanePlanning.SR_LanePlanning.HarvestYear</IxRes>:
      </template>
      <template>
        {{ $i18n.format(plan.harvestYear, 'harvestYear') }}
      </template>
    </LabelAndMessage>
    <hr>
  </div>
</template>

<script>
import IxRes from '@components/IxRes'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    IxRes,
    LabelAndMessage
  },
  props: {
    plan: {
      required: true,
      type: Object
    },
    isAb: Boolean
  }
}
</script>

<style lang="scss" scoped>
.common-info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
