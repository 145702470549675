<template>
  <div class="maize-sowing-details">
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.zoneMap</IxRes>
      </template>
      <div v-if="zoneMap">
        <span>{{ zoneMap.name }}</span>
      </div>
      <div v-else>
        <span>
          <IxRes>Common.SR_Common.NotAvailable</IxRes>
        </span>
      </div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.product</IxRes>
      </template>
      <div v-if="product">
        {{ product.name }}
      </div>
      <div v-else>
        <IxRes>Common.SR_Common.NotAvailable</IxRes>
      </div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.cultivation</IxRes>
      </template>
      <div v-if="cultivation">
        <span>{{ cultivation.label }}</span>
        &nbsp;
        <span>({{ cultivation.dateTimeRange.start | formatDate }} - {{ cultivation.dateTimeRange.end | formatDate }})</span>
      </div>
      <div v-else>
        <IxRes>masterData.crops.usage.noInfo</IxRes>
      </div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>Areas.ApplicationMaps.SR_ApplicationMaps.Heterogenity</IxRes>
      </template>
      <IxRes v-if="heterogenity">Enums.SR_Enums.HeterogenityEnum_{{ heterogenity }}</IxRes>
      <IxRes v-else>Common.SR_Common.NotAvailable</IxRes>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>Areas.MaizeSowing.SR_MaizeSowing.Mode_header</IxRes>
      </template>
      <div>
        <IxRes>frs.mapManagement.detailsMaizeSowing.labels.mode.{{ metaData.automatic ? 'automatic' : 'manual' }}</IxRes>
        {{ ' - ' }}
        <IxRes>frs.mapManagement.detailsMaizeSowing.labels.mode.{{ metaData.ignoreLimits ? 'ignoreLimits' : 'withLimits' }}</IxRes>
      </div>
    </LabelAndMessage>

    <LabelAndMessage v-if="!automaticMode && metaData.customSowingDensity">
      <template #label>
        <IxRes>Areas.MaizeSowing.SR_MaizeSowing.CustomSowingDensity_label</IxRes>
      </template>
      <div>{{ `${metaData.customSowingDensity} ${applicationMap.unit}` }}</div>
    </LabelAndMessage>

    <div v-if="automaticMode">
      <LabelAndMessage>
        <template #label>
          <IxRes>Areas.MaizeSowing.SR_MaizeSowing.WaterLevel</IxRes>
        </template>
        <IxRes>Areas.MaizeSowing.SR_MaizeSowing.WaterLevel_{{ metaData.waterLevel }}</IxRes>
      </LabelAndMessage>

      <LabelAndMessage>
        <template #label>
          <IxRes>Areas.MaizeSowing.SR_MaizeSowing.SoilQuality</IxRes>
        </template>
        <IxRes>Areas.MaizeSowing.SR_MaizeSowing.SoilQuality_{{ metaData.soilQuality }}</IxRes>
      </LabelAndMessage>

      <LabelAndMessage>
        <template #label>
          <IxRes>Areas.MaizeSowing.SR_MaizeSowing.Usage</IxRes>
        </template>
        <IxRes>Areas.MaizeSowing.SR_MaizeSowing.Usage_{{ metaData.usage }}</IxRes>
      </LabelAndMessage>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import {mapGetters} from 'vuex'
import {mapResources} from '@helpers/vuex'
import {smarterGet} from '@helpers/vuex/data-loading'
import {cultivationToName} from '@frs/components/cultivation-overview/helpers'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    LabelAndMessage
  },
  filters: {
    formatDate (isoDate) {
      return moment(isoDate).format('DD.MM.YY')
    }
  },
  props: {
    applicationMap: Object
  },
  data () {
    return {
      cultivation: null
    }
  },
  computed: {
    ...mapResources([
      '@ms.SR_MaizeSowing'
    ]),
    ...mapGetters('masterData', [
      'products'
    ]),
    product () {
      return this.products[this.applicationMap.metaData.productId]
    },
    metaData () {
      return this.applicationMap.metaData
    },
    heterogenity () {
      return this.metaData.heterogenity
    },
    automaticMode () {
      return this.metaData.automatic || false
    },
    zoneMap () {
      return this.metaData.zoneMap
    }
  },
  created () {
    if (this.applicationMap.cultivationId) {
      smarterGet('/api/v2/org/cultivations/{cultivationId}/details', {
        id: 'mapManagement.cultivation.loadDetails',
        inputs: {
          cultivationId: () => this.applicationMap.cultivationId
        },
        onResult: details => {
          this.cultivation = {
            dateTimeRange: details.dateTimeRange,
            label: cultivationToName(details)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.maize-sowing-details {
  display: flex;
  flex-direction: column;
}
</style>
