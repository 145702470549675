<template lang="html">
  <div>
    <div v-for="nutrient in nutrients" :key="nutrient">
      <BsCheckbox
        :state="selectedNutrients"
        :value="nutrient" @input="onInput(nutrient, $event)"
      >
        <strong>
          <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.{{ nutrientDisplay(nutrient) }}</IxRes>
        </strong>
        <span v-if="!hideMinMax && (nutrient !== 'ph')"><IxRes>bf.applicationMaps.hints.limitNutrients</IxRes>:</span>
      </BsCheckbox>
      <div v-if="value[nutrient].selected && !hideMinMax && (nutrient !== 'ph')" class="nutrient-limits">
        <div class="nutrient-limit">
          <div class="nutrient-limit-style">
            <NumericInput
              :value="value[nutrient].min"
              :name="`${nutrient}_minLimit`"
              :rule="max(value[nutrient].max)"
              placeholder="min"
              :addon="unitAddons[nutrient]"
              clear-button
              hide-description
              @input="handleNutrientLimitInput(nutrient, 'min', $event)"
            />
          </div>
        </div>
        <div class="nutrient-limit">
          <div class="nutrient-limit-style">
            <NumericInput
              :value="value[nutrient].max"
              :name="`${nutrient}_maxLimit`"
              :rule="min(value[nutrient].min)"
              placeholder="max"
              :addon="unitAddons[nutrient]"
              clear-button
              hide-description
              @input="handleNutrientLimitInput(nutrient, 'max', $event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {toObject} from '@helpers/reducers'

import BsCheckbox from '@components/bootstrap/BsCheckbox'
import NumericInput from '@components/forms/NumericInput'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

export default {
  components: {
    BsCheckbox,
    NumericInput
  },
  mixins: [
    StandardRulesMixin
  ],
  props: {
    value: Object,
    hidePh: Boolean,
    hideMinMax: Boolean
  },
  computed: {
    nutrients () {
      return Object.keys(this.value)
    },
    selectedNutrients () {
      return Object.keys(this.value).filter(nutrient => this.value[nutrient].selected)
    },
    unitAddons () {
      return this.nutrients.map(nutrient => [nutrient, nutrient === 'ph'
        ? this.$i18n.translateUnit('dt/ha')
        : this.$i18n.translateUnit('kg/ha')])
      .reduce(toObject, {})
    }
  },
  methods: {
    onInput (nutrient, nutrientArray) {
      this.$emit('input', {
        ...this.value,
        [nutrient]: {
          ...this.value[nutrient],
          selected: nutrientArray.includes(nutrient)
        }
      })
    },
    nutrientDisplay (nutrient) {
      const abbreviationMapping = {
        potassium: 'K',
        phosphorus: 'P',
        magnesium: 'Mg'
      }

      switch (nutrient) {
      case 'potassium':
      case 'phosphorus':
      case 'magnesium':
        return `ContentClass_${nutrient}.${abbreviationMapping[nutrient]}`
      case 'lime':
        return 'Lime'
      case 'ph':
        return this.hidePh ? 'Lime' : 'PH'
      default:
        return 'not supported'
      }
    },
    handleNutrientLimitInput (nutrient, limit, value) {
      const temp = {
        ...this.value,
        [nutrient]: {
          ...this.value[nutrient],
          [limit]: value
        }
      }
      this.$emit('input', temp)
    }
  }
}
</script>

<style lang="scss" scoped>
.nutrient-limits {
  display: flex;
}

.nutrient-limit:first-child {
  margin-right: 10px;
}

</style>
