<template>
  <div class="p-requirement-details">
    <template v-if="showCropSelection">
      <VueSelectize
        v-model="crop"
        allow-empty
        required
        class="crop-selection"
        :options="crops"
        :fields="{text: 'name', value: 'name'}"
        :placeholder="SR_FieldRecordSystem.SelectPlaceholder"
      >
        <template #label>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementCultivation</IxRes>
        </template>
      </VueSelectize>
    </template>
    <NumericInput
      v-if="year === 0"
      v-model="modelProxy"
      required readonly
      class="input"
      unit="dt/ha"
    >
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementExpectedIncomeLevel</IxRes>
      </template>
    </NumericInput>
    <NumericInput
      v-else v-model="yieldAmount"
      required
      class="input"
      unit="dt/ha"
    >
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementExpectedIncomeLevel</IxRes>
      </template>
    </NumericInput>

    <div class="by-product-removal">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementRemovalCatchCrop</IxRes>: *
      <FormBlock sign=" " :number="removalByProduct" unit="kg P2O5/ha">
        <div class="radio-container">
          <BsRadio v-model="removed" :value="1">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Yes</IxRes>
          </BsRadio>
          <BsRadio v-model="removed" class="radio-distance" :value="2">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.No</IxRes>
          </BsRadio>
        </div>
      </FormBlock>
    </div>

    <div v-if="year === 0">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementContentClass</IxRes>: *
      <div class="radio-container">
        <BsRadio v-model="phosphorusContentClass" value="A">
          A
        </BsRadio>
        <BsRadio v-model="phosphorusContentClass" value="B">
          B
        </BsRadio>
        <BsRadio v-model="phosphorusContentClass" value="C">
          C
        </BsRadio>
        <BsRadio v-model="phosphorusContentClass" value="D">
          D
        </BsRadio>
        <BsRadio v-model="phosphorusContentClass" value="E">
          E
        </BsRadio>
      </div>
    </div>
  </div>
</template>

<script>
import {mapResources, modelProxy} from '@helpers/vuex'

import FormBlock from './FormBlock'

import NumericInput from '@components/forms/NumericInput'
import BsRadio from '@components/bootstrap/BsRadio'
import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    FormBlock,
    NumericInput,
    VueSelectize,
    BsRadio
  },
  props: {
    value: Object,
    crops: Array,
    showCropSelection: Boolean,
    year: Number,
    currentYield: Number
  },
  data () {
    return {
      crop: null,
      removed: 2,
      yieldAmount: this.year === 0 ? this.currentYield : 0
    }
  },
  computed: {
    modelProxy,
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    phosphorusContentClass: {
      get () {
        return this.value.phosphorusContentClass
      },
      set (phosphorusContentClass) {
        this.$emit('phosphorusContent', {...this.value, phosphorusContentClass})
      }
    },
    byproductIsRemoved: {
      get () {
        return this.value.byproductIsRemoved
      },
      set (byproductIsRemoved) {
        this.$emit('input', {...this.value, byproductIsRemoved})
      }
    },
    expectedYield: {
      get () {
        if (this.year === 1) return this.currentYield
        else return this.value.expectedYield
      },
      set (expectedYield) {
        this.$emit('input', {...this.value, expectedYield})
      }
    },
    removalByProduct () {
      if (this.value) {
        if (this.byproductIsRemoved) {
          return this.$i18n.parse(this.$i18n.format(this.value.expectedYield * (this.value.cropPhosphorusRequirement + this.value.phosphorusContentByproduct), 'f2'), 'number')
        } else {
          return this.value.expectedYield * (this.value.cropPhosphorusRequirement)
        }
      } else {
        return 0
      }
    }
  },
  watch: {
    crop (value) {
      let partialPhosphorusPlan = null
      if (value) {
        if (this.year !== 0) {
          this.yieldAmount = value.expectedYield
        }
        partialPhosphorusPlan = {
          'cropPhosphorusRequirement': value.phosphorusRequirement,
          'mainCropId': value.name,
          'phosphorusContentByproduct': value.phosphorusContentByproduct,
          'mainToByproductRatio': value.mainToByproductRatio
        }
      } else {
        partialPhosphorusPlan = {
          'expectedYield': 0,
          'cropPhosphorusRequirement': 0,
          'mainCropId': '',
          'phosphorusContentByproduct': 0,
          'mainToByproductRatio': 0
        }
      }
      this.$emit('partialPhosphorusPlan', partialPhosphorusPlan)
    },
    yieldAmount (value) {
      this.expectedYield = value
    },
    removed (value) {
      if (value === 1) {
        this.byproductIsRemoved = true
      } else {
        this.byproductIsRemoved = false
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.year === 0) {
        this.yieldAmount = this.expectedYield
        this.removed = this.byproductIsRemoved ? 1 : 2
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.input {
  ::v-deep .input-field {
    width: 40%;
  }
}
.radio-container {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: -10px;

  :not(:first-child) {
    margin-left: 16px;
  }
}
.by-product-removal {
  margin-top: 10px;
}

</style>
