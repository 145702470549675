<template>
  <div>
    <h3>
      <IxRes>frs.mapManagement.soilMap.details.heading.{{ `MapCategory_${map.category}` }}</IxRes>
    </h3>
    <LabelAndMessage>
      <template #label>
        <IxRes>Common.SR_Common.Name</IxRes>
      </template>
      <div>{{ map.name }}</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.details.interpolationMaps.mappingDate</IxRes>
      </template>
      <div>{{ $i18n.format(map.mappingDate, 'date') }}</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>Common.SR_Common.CreatedAt</IxRes>
      </template>
      <div>{{ $i18n.format(map.createdAt, 'date') }}</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.details.interpolationMaps.layer</IxRes>
      </template>
      <div>{{ map.layerName }}</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.details.interpolationMaps.blockSize</IxRes>
      </template>
      <div>{{ map.blockSize }}</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.details.interpolationMaps.pixelSize</IxRes>
      </template>
      <div>{{ map.pixelSize }}</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.details.interpolationMaps.memorySize</IxRes>
      </template>
      <div>{{ map.memorySize }} kb</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.details.interpolationMaps.imageDimension</IxRes>
      </template>
      <div>
        {{ map.pixelHeight }} × {{ map.pixelWidth }} Pixel
      </div>
    </LabelAndMessage>

    <LabelAndMessage v-if="variogram">
      <template #label>
        <IxRes>frs.mapManagement.details.interpolationMaps.variogram.title</IxRes>
      </template>
      <div class="vario-thumb-container">
        <img
          :src="`data:image/png;base64,${variogram}`"
          class="vario-thumb"
          @click.prevent="showModal = true"
        >
      </div>
    </LabelAndMessage>

    <BsModal v-model="showModal" size="xlarge">
      <template #title>
        <IxRes>frs.mapManagement.details.interpolationMaps.variogram.title</IxRes>
      </template>

      <img
        v-if="variogram"
        class="vario-image"
        :src="`data:image/png;base64,${variogram}`"
      >

      <template #footer>
        <IxButton
          large
          colored
          @click="showModal = false"
        >
          <IxRes>frs.mapManagement.details.interpolationMaps.variogram.close</IxRes>
        </IxButton>
      </template>
    </BsModal>

    <br>
    <SensorDataGradientScale :map="map"  />

    <InterpolationMapLayer :map="map" />
  </div>
</template>

<script>
import {smarterGet} from '@helpers/vuex/data-loading'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import BsModal from 'src/vue/components/bootstrap/BsModal'
import IxButton from 'src/vue/components/IxButton'
import InterpolationMapLayer from '@frs/components/base/InterpolationMapLayer'
import SensorDataGradientScale from './widgets/SensorDataGradientScale'

export default {
  components: {
    LabelAndMessage,
    BsModal,
    IxButton,
    InterpolationMapLayer,
    SensorDataGradientScale
  },
  props: {
    map: Object
  },
  data () {
    return {
      showModal: false,
      variogram: null
    }
  },
  methods: {
    async displayVariogram () {
      await this.$refs.modal.start()
    }
  },
  mounted () {
    smarterGet('/api/v2/map-management/interpolation-maps/variogram/{mapId}', {
      id: 'interpolationMaps.variogram',
      inputs: {
        mapId: () => this.map.id
      },
      onResult: variogram => {
        this.variogram = variogram
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.vario-image {
  width: 100%;
  height: 100%;
}

.vario-thumb-container {
  :hover {
    cursor: pointer;
  }
  .vario-thumb {
  width: 50%;
  height: 50%;
}
}
</style>
