<template lang="html">
  <div>
    <h3>
      <IxRes>frs.cultivation.editCultivationEditingTool</IxRes>
    </h3>
    <div class="buttons">
      <IxButton large cancel @click="stopEditing">
        <IxRes>Common.SR_Common.StopEditing</IxRes>
      </IxButton>
      <IxButton
        :disabled="hasErrors"
        large save
        @click="save"
      >
        <IxRes>Common.SR_Common.Accept</IxRes>
      </IxButton>
    </div>
    <hr>
    <div>
      <VPopover
        trigger="hover"
      >
        <span>
          <a href="#" @click.prevent>
            <i class="fa fa-question-circle" />
          </a>
        </span>
        <template #popover>
          <IxRes>frs.geometry.editing.description.applyAreaGeometry_1</IxRes>
          <br>
          <IxRes>frs.geometry.editing.description.applyAreaGeometry_2</IxRes>
        </template>
      </VPopover>
      <IxButton
        large
        :disabled="!fieldFeature"
        @click="setGeometryFromField"
      >
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ApplyAreaGeometry</IxRes>
      </IxButton>
    </div>

    <FrsLoadingIndicator :requests="['orgUnit.cultivationsV2.loadFieldIdsAndCultivation']" />
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

import {mapFormFields} from '@helpers/vuex'
import {smarterGet} from '@helpers/vuex/data-loading'
import {makeFeature, toWkt, splitToPolygons, combineToMultiPolygon, geoJsonFormat} from 'src/js/helpers/openlayers/features'
import {findErrors} from 'src/js/helpers/geojson/errors'

import IxButton from '@components/IxButton'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import {VPopover} from 'v-tooltip'
import {fromFields} from '@frs/helpers/geojson/create-features'

export default {
  components: {
    FrsLoadingIndicator,
    IxButton,
    VPopover
  },
  data () {
    return {
      fieldId: null,
      cultivation: null
    }
  },
  computed: {
    ...mapState('masterData', {
      cropUsages: state => state.cropUsages
    }),
    ...mapGetters('fieldRecordSystem', [
      'orgUnitId',
      'harvestYear',
      'entityLookup'
    ]),
    ...mapFormFields('fieldRecordSystem/map', [
      'editing.activeFeatures',
      'editing'
    ]),
    hasErrors () {
      const errors = findErrors(this.activeFeatures)
      return errors.length > 0
    },
    fieldFeature () {
      if (!this.fieldId) return null
      const field = this.entityLookup[this.fieldId]
      const feature = fromFields([field], this.$store.state.fieldRecordSystem.data.field)[0]
      if (!feature || !feature.geometry) return null
      return geoJsonFormat.readFeature(feature)
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/map', [
      'stopEditing'
    ]),
    setGeometryFromField () {
      const wkt = toWkt(this.fieldFeature)
      const {id, type, cropUsageId} = this.cultivation
      const cropUsage = this.cropUsages[cropUsageId]

      const features = splitToPolygons(makeFeature({id, type, cropUsage, wkt})).map(feature => geoJsonFormat.writeFeatureObject(feature))
      this.activeFeatures = features
    },
    save () {
      this.stopEditing().then(features => {
        const polygons = features.filter(f => f.getGeometry().getType() === 'Polygon')
        const wkt = polygons.length ? toWkt(combineToMultiPolygon(polygons)) : null

        this.$store.commit('fieldRecordSystem/cultivationPlanning/editBuffer/set', {geometry: [wkt]})
      })
    }
  },
  created () {
    if (this.$store.state.fieldRecordSystem.cultivationOverview.selected.length < 1) return
    this.$store.commit('dataLoading/invalidate', 'orgUnit.cultivationsV2.loadFieldIdsAndCultivation')
    smarterGet(['/api/v2/entities/orgunits/{orgUnitId}/cultivations/fields/{harvestYear}',
      '/api/v2/org/cultivations/{cultivationId}'], {
      id: 'orgUnit.cultivationsV2.loadFieldIdsAndCultivation',
      inputs: {
        orgUnitId: () => this.orgUnitId,
        harvestYear: () => this.harvestYear,
        cultivationId: () => this.$store.state.fieldRecordSystem.cultivationOverview.selected[0]
      },
      onResult: ([dictionary, cultivation]) => {
        const cultivationId = this.$store.state.fieldRecordSystem.cultivationOverview.selected[0]
        this.fieldId = dictionary[cultivationId][0]
        this.cultivation = cultivation
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: space-between;
}
</style>
