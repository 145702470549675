<template lang="html">
  <div class="probing-sites">
    <div class="buttons">
      <IxButton icon="check-circle" @click="setActiveForAll(true)">
        <IxRes>frs.baseFertilization.orderPlanning.buttons.selectAll.{{ probingSiteMode }}</IxRes>
      </IxButton>
      <IxButton icon="times-circle" @click="setActiveForAll(false)">
        <IxRes>frs.baseFertilization.orderPlanning.buttons.deselectAll</IxRes>
      </IxButton>
    </div>

    <SimpleTable :columns="columns" :data="data" :highlight="highlight">
      <template slot="label" slot-scope="{row, value}">
        <strong>{{ value }}</strong>
        <a v-if="!row.markedForDeletion" href="#" @click.prevent="rename(row)">
          <IxRes>Common.SR_Common.EditLabel</IxRes>
        </a>
        <span v-else class="disabled-link" :title="$i18n.translate('Areas.FieldRecordSystem.SR_BaseFertilization.PointsCanNotBeRenamed')">
          <IxRes>Common.SR_Common.EditLabel</IxRes>
        </span>
      </template>
      <template slot="isActive" slot-scope="{row, value}">
        <SimpleTableAutoCell :value="value" />
        <a v-if="!row.markedForDeletion && !row.isNew" href="#" @click.prevent="toggleActive(row)">
          <IxRes v-if="value">Common.SR_Common.Deactivate</IxRes>
          <IxRes v-else>Common.SR_Common.Activate</IxRes>
        </a>
        <span v-else class="disabled-link" :title="$i18n.translate('Areas.FieldRecordSystem.SR_BaseFertilization.NewOrToBeDeletedPointsCanNotBeDeactivated')">
          <IxRes>Common.SR_Common.Deactivate</IxRes>
        </span>
      </template>
      <a
        slot="delete" slot-scope="{row}"
        href="#"
        @click.prevent="toggleDeletion(row)"
      >
        <IxRes v-if="row.markedForDeletion">Common.SR_Common.Restore</IxRes>
        <IxRes v-else>Common.SR_Common.Remove</IxRes>
      </a>
    </SimpleTable>

    <BsModal v-model="showModal">
      <template #title>
        <IxRes>frs.baseFertilization.orderPlanning.headers.changePointLabel</IxRes>
      </template>

      <TextInput v-model="newLabel">
        <template #label>
          <IxRes>frs.baseFertilization.orderPlanning.labels.newPointLabel</IxRes>
        </template>
      </TextInput>

      <template #footer>
        <IxButton confirm @click="confirmModal">
          <IxRes>Common.SR_Common.Confirm</IxRes>
        </IxButton>
        <IxButton cancel @click="showModal = false">
          <IxRes>Common.SR_Common.Cancel</IxRes>
        </IxButton>
      </template>
    </BsModal>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

import IxButton from '@components/IxButton'
import BsModal from '@components/bootstrap/BsModal'
import TextInput from '@components/forms/TextInput'
import SimpleTable from '@components/table/SimpleTable'
import SimpleTableAutoCell from '@components/table/SimpleTableAutoCell'

export default {
  components: {
    IxButton,
    BsModal,
    TextInput,
    SimpleTable,
    SimpleTableAutoCell
  },
  props: {
    data: Array
  },
  data () {
    return {
      showModal: false,
      selectedId: null,
      newLabel: ''
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/orderPlanning', [
      'showNumbers',
      'probingSiteMode'
    ]),
    ...mapState('fieldRecordSystem/basicFertilization/orderPlanning', {
      planningProbingSites: state => state.probingSites.existing.concat(state.probingSites.new)
    }),
    columns () {
      return Object.assign({}, this.showNumbers ? {number: '#'} : null, {
        label: 'Name',
        isActive: 'Status',
        delete: 'Löschen'
      })
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/basicFertilization/orderPlanning', [
      'addNewPlanningProbingSite',
      'renameProbingSite',
      'toggleDeletion',
      'toggleActive',
      'setActiveForAll'
    ]),
    rename ({id}) {
      this.showModal = true
      this.newLabel = this.planningProbingSites.find(x => x.id === id).label
      this.selectedId = id
    },
    confirmModal () {
      this.showModal = false
      this.renameProbingSite({id: this.selectedId, label: this.newLabel})
    },
    highlight (probingSite) {
      const classes = []

      if (probingSite.markedForDeletion) {
        classes.push('deletion')
      }
      if (probingSite.isNew) {
        classes.push('new')
      }
      return classes
    }

  }
}
</script>

<style lang="scss" scoped>
::v-deep tr {
  span.disabled-link {
    color: gray;
    user-select: none;
  }

  &.deletion {
    color: gray;

    &:not(.new) {
      background-color: rgba(255, 50, 50, 0.1);
    }
  }
  &.new {
    background-color: rgba(50, 255, 50, 0.1);

    &.deletion {
      opacity: 0.5;
    }
  }
}

.buttons {
  display: flex;
  justify-content: space-between;

  margin-bottom: 0.5em;
}
</style>
