export const getTypeKey = ({type}) => {
  return `Areas.FieldRecordSystem.SR_FieldRecordSystem.ActionType_${type}`
}

export const getProcedureKey = ({type, procedure}) => {
  switch (type) {
  case 'transport':
  case 'other':
    return null
  default:
    return `Areas.FieldRecordSystem.SR_FieldRecordSystem.ProcedureType_${type}_${procedure}`
  }
}
