var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SimpleTable',{staticClass:"cultivation-table",attrs:{"columns":_vm.cultivationColumns,"data":_vm.cultivationTableData,"highlight":_vm.highlight},scopedSlots:_vm._u([{key:"checkbox",fn:function(ref){
var row = ref.row;
return [_c('CheckboxCell',{attrs:{"row":row}})]}},{key:"fieldName",fn:function(ref){
var value = ref.value;
return [_vm._v("\n      "+_vm._s(value)+"\n    ")]}},{key:"type",fn:function(ref){
var type = ref.value;
return [_c('CultivationTypeCell',_vm._b({},'CultivationTypeCell',{type: type, compact: _vm.compact},false))]}},{key:"colorAndName",fn:function(ref){
var row = ref.row;
return [_c('CultivationNameCell',{attrs:{"cultivation":_vm.addCropUsageColor(row)}})]}},{key:"dateTimeRange",fn:function(ref){
var dateTimeRange = ref.value;
return [_c('CultivationRangeCell',_vm._b({},'CultivationRangeCell',{dateTimeRange: dateTimeRange, compact: _vm.compact},false))]}},{key:"area",fn:function(ref){
var row = ref.row;
return [_vm._v("\n      "+_vm._s(_vm.$i18n.format(row.geometryGeoJson))+"\n    ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }