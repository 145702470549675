<template>
  <div class="alert alert-success component" role="alert">
    <div>
      <div>
        <strong>
          <slot name="title" />: *
        </strong>
      </div>
    </div>
    <TagDisplay large :tags="tags" />
    <div v-if="!fieldIds.length">
      <slot name="description" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import TagDisplay from '@components/TagDisplay'

export default {
  components: {
    TagDisplay
  },
  props: {
    fieldIds: Array
  },
  computed: {
    ...mapGetters('fieldRecordSystem', [
      'entityNameLookup'
    ]),
    tags () {
      return this.fieldIds.map(id => ({
        id,
        label: this.entityNameLookup[id]
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.component > * {
  margin-bottom: 5px;
}
.alert {
  padding: 8px !important;
}
</style>
