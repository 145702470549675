<template>
  <div class="basic-fertilization-container">
    <div class="basic-fertilization-header">
      <h3>
        <IxRes>bf.basicFertilization</IxRes>
      </h3>
    </div>

    <div class="basic-fertilization-body">
      <div class="info-container">
        <BsCheckbox v-model="showStepHints" inline>
          <IxRes>bf.wizard.showInfo</IxRes>
        </BsCheckbox>
        <div class="hint">
          <IxRes>bf.wizard.fieldSelectionInfo</IxRes>
        </div>
      </div>

      <div class="card-container">
        <CollapsibleCard :is-collapsed="showStepHints" @action="startOrderCreation">
          <template #title>
            <IxRes>bf.wizard.stepOneTitle</IxRes>
          </template>
          <IxRes>bf.wizard.stepOneHint</IxRes>
          <template #action>
            <IxRes>bf.wizard.stepOneAction</IxRes>
          </template>
        </CollapsibleCard>

        <CollapsibleCard :is-collapsed="showStepHints" @action="startOrderPlanning">
          <template #title>
            <IxRes>bf.wizard.stepTwoTitle</IxRes>
          </template>
          <IxRes>bf.wizard.stepTwoHint</IxRes>
          <template #status>
            {{ statusProbingSites }}
            <IxRes v-if="statusProbingSites === 1">bf.wizard.stepTwoStatusSingle</IxRes>
            <IxRes v-else>bf.wizard.stepTwoStatus</IxRes>
          </template>
          <template #action>
            <IxRes>bf.wizard.stepTwoAction</IxRes>
          </template>
        </CollapsibleCard>

        <CollapsibleCard :is-collapsed="showStepHints" @action="exportOrders">
          <template #title>
            <IxRes>bf.wizard.stepThreeTitle</IxRes>
          </template>
          <IxRes>bf.wizard.stepThreeHint</IxRes>
          <template #status>
            {{ statusOrderExport }}
            <IxRes v-if="statusOrderExport === 1">bf.wizard.stepThreeStatusSingle</IxRes>
            <IxRes v-else>bf.wizard.stepThreeStatus</IxRes>
          </template>
          <template #action>
            <IxRes>bf.wizard.stepThreeAction</IxRes>
          </template>
        </CollapsibleCard>

        <CollapsibleCard :is-collapsed="showStepHints" @action="startManualEntry">
          <template #title>
            <IxRes>bf.wizard.stepFourTitle</IxRes>
          </template>
          <IxRes>bf.wizard.stepFourHint</IxRes>
          <template #status>
            {{ statusOrderResult }}
            <IxRes v-if="statusOrderResult === 1">bf.wizard.stepFourStatusSingle</IxRes>
            <IxRes v-else>bf.wizard.stepFourStatus</IxRes>
          </template>
          <template #action>
            <IxRes>bf.wizard.stepFourAction</IxRes>
          </template>
        </CollapsibleCard>

        <CollapsibleCard :is-collapsed="showStepHints" @action="startNutrientMapDisplay">
          <template #title>
            <IxRes>bf.wizard.stepFiveTitle</IxRes>
          </template>
          <IxRes>bf.wizard.stepFiveHint</IxRes>
          <template #status>
            {{ statusNutrientMaps }}
            <IxRes v-if="statusNutrientMaps === 1">bf.wizard.stepFiveStatusSingle</IxRes>
            <IxRes v-else>bf.wizard.stepFiveStatus</IxRes>
          </template>
          <template #action>
            <IxRes>bf.wizard.stepFiveAction</IxRes>
          </template>
        </CollapsibleCard>

        <CollapsibleCard :is-collapsed="showStepHints" @action="startApplicationMapCreation">
          <template #title>
            <IxRes>bf.wizard.stepSixTitle</IxRes>
          </template>
          <IxRes>bf.wizard.stepSixHint</IxRes>
          <template #status>
            {{ statusApplicationMaps }}
            <IxRes v-if="statusApplicationMaps === 1">bf.wizard.stepSixStatusSingle</IxRes>
            <IxRes v-else>bf.wizard.stepSixStatus</IxRes>
          </template>
          <template #action>
            <IxRes>bf.wizard.stepSixAction</IxRes>
          </template>
        </CollapsibleCard>

        <CollapsibleCard :is-collapsed="showStepHints" @action="startApplicationMapPreview">
          <template #title>
            <IxRes>bf.wizard.stepSevenTitle</IxRes>
          </template>
          <IxRes>bf.wizard.stepSevenHint</IxRes>
          <template #status>
            {{ statusApplicationMapPreviews }}
            <IxRes v-if="statusApplicationMapPreviews === 1">bf.wizard.stepSevenStatusSingle</IxRes>
            <IxRes v-else>bf.wizard.stepSevenStatus</IxRes>
          </template>
          <template #action>
            <IxRes>bf.wizard.stepSevenAction</IxRes>
          </template>
        </CollapsibleCard>
      </div>
      <div class="refresh-button">
        <IxButton
          refresh
          colored
          large
          @click="refreshStatistics"
        >
          <IxRes>bf.menu.refreshStatistics</IxRes>
        </IxButton>
      </div>
    </div>
    <FrsLoadingIndicator :requests="['bf.field.statistics']" />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'

import {mapUiFlags} from '@frs/helpers/ui'

import IxButton from '@components/IxButton'
import BsCheckbox from '@components/bootstrap/BsCheckbox'
import CollapsibleCard from '@components/card/CollapsibleCard'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    BsCheckbox,
    CollapsibleCard,
    FrsLoadingIndicator,
    IxButton
  },
  computed: {
    ...mapUiFlags([
      'baseFertilization.showStepHints'
    ]),
    ...mapState('fieldRecordSystem/basicFertilization', {
      fieldStatisticsLookup: state => state.data.fieldStatisticsLookup
    }),
    statusProbingSites () {
      return this.fieldStatisticsLookup.ordersWithoutProbingSites
    },
    statusOrderExport () {
      return this.fieldStatisticsLookup.ordersForDownload
    },
    statusOrderResult () {
      return this.fieldStatisticsLookup.ordersWithoutResults
    },
    statusNutrientMaps () {
      return Object.values(this.fieldStatisticsLookup.nutrientMapsByFieldId).reduce((acc, curr) => acc + curr.length, 0)
    },
    statusApplicationMaps () {
      return this.fieldStatisticsLookup.numberForApplicationMapCreation
    },
    statusApplicationMapPreviews () {
      return this.fieldStatisticsLookup.numberOfApplicationMapPreviews
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/orderCreation', [
      'startOrderCreation'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization/orderPlanning', [
      'startOrderPlanning'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization', [
      'exportOrders',
      'loadStatistics'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization/nutrientMaps', [
      'startNutrientMapDisplay'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization/applicationMaps', [
      'startApplicationMapCreation'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization/applicationMapsPreview', [
      'startApplicationMapPreview'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization/resultImport', [
      'startManualEntry'
    ]),
    ...mapMutations('dataLoading', [
      'invalidate'
    ]),
    refreshStatistics () {
      this.invalidate('bf.field.statistics')
      this.loadStatistics()
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  margin: 0;
  padding: 0;
}
.basic-fertilization-container {
  position: relative;
  display: flex;
  flex-direction: column;
}
.basic-fertilization-header {
  display: flex;
  justify-content: space-between;
  margin: 8px 0 16px 4px;
}
.hint {
  margin: 6px 0;
}
.info-container {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
}
.refresh-button {
  margin-top: 10px;
  margin-left: 4px;
}
</style>
