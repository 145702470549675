<template>
  <div>
    <div class="selector-container">
      <VueSelectize
        v-model="selectedMetaData"
        class="map-selector"
        allow-empty
        :options="metaDataOptions"
        :fields="{text: 'name', value: 'id', label: 'labelName'}"
      />
      <i class="fa fa-upload" title="import shape file" @click="openImportModal" />
    </div>

    <template v-if="selectedMetaData">
      <CollapsibleSection v-model="showDetails">
        <template #header>
          <IxRes>Common.SR_Common.Details</IxRes>
        </template>
        <TelemetryDetails />
        <component :is="selectedType" />

        <div v-if="isApplicationMap">
          <label>Zonenwerte:</label>
          <div class="zone-values">
            <template v-for="(zone, i) in selectedMetaData.zoneValues">
              <ZoneValueItem :key="i" :zone="zone" :unit="selectedMetaData.unit" />
            </template>
          </div>
        </div>
      </CollapsibleSection>

      <div class="footer">
        <IxButton large remove @click="removeMap" />
        <!--<IxButton large edit @click="editMetaData" />-->
      </div>
    </template>

    <GeometryImportModal v-model="showModal" @load="onLoad" />

    <IxVectorLayer
      :features="polygonFeaturesForLayer"
      :vector-style="styles.preview"
      :z-index="1"
      layer-id="applicationMap"
      auto-focus
    />
    <IxVectorLayer
      :features="pointFeaturesForLayer"
      :vector-style="styles.point"
      :z-index="2"
      layer-id="points"
    />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import {notifications} from 'src/js/infrastructure'
import {pointBase, entityImportPreview} from '@frs/map-styles'
import parse from 'wellknown'

import IxButton from '@components/IxButton'
import VueSelectize from '@components/VueSelectize'
import CollapsibleSection from '@components/CollapsibleSection'
import GeometryImportModal from '@components/forms/GeometryImportModal'
import IxVectorLayer from '@components/map/IxVectorLayer'

import TelemetryDetails from './widgets/TelemetryDetails'
import ProductionDetails from './widgets/ProductionDetails'
import ApplicationDetails from './widgets/ApplicationDetails'
import ZoneValueItem from './widgets/ZoneValueItem'

export default {
  components: {
    IxButton,
    VueSelectize,
    CollapsibleSection,
    GeometryImportModal,
    TelemetryDetails,
    ProductionDetails,
    IxVectorLayer,
    ApplicationDetails,
    ZoneValueItem
  },
  data () {
    return {
      showModal: false,
      showDetails: true
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/telemetry', [
      'data.selectedMetaData',
      'data.metaDataAll'
    ]),
    ...mapState('masterData', {
      units: state => state.data.units
    }),
    ...mapState('fieldRecordSystem/telemetry', {
      measurements: state => state.data.measurements
    }),
    metaDataOptions () {
      const applicationMap = this.metaDataAll.filter(x => x.type === 'applicationMap').map(x => ({...x, labelName: 'Applikationskarte'}))
      const application = this.metaDataAll.filter(x => x.type === 'application').map(x => ({...x, labelName: 'Düngung'}))
      const production = this.metaDataAll.filter(x => x.type === 'production').map(x => ({...x, labelName: 'Ernte'}))
      return application.concat(production).concat(applicationMap)
    },
    selectedType () {
      return this.selectedMetaData.type === 'application' || this.selectedMetaData.type === 'applicationMap' ? 'ApplicationDetails' : 'ProductionDetails'
    },
    pointFeaturesForLayer () {
      let features = []
      if (this.measurements) {
        features = this.measurements.map(measurement => ({
          geometry: parse(measurement.geometry),
          type: 'Feature',
          properties: {
            ...measurement
          }
        }))
        if (features.length && !(features[0].get('geometry').getType() === 'Point')) {
          features = []
        }
      } else {
        features = []
      }
      return features
    },
    polygonFeaturesForLayer () {
      let features = []
      if (this.selectedMetaData && this.selectedMetaData.type === 'applicationMap') {
        features = this.selectedMetaData.zoneValues.map(zone => ({
          geometry: parse(zone.geometry),
          type: 'Feature',
          properties: {
            ...zone
          }
        }))
      } else if (this.measurements) {
        features = this.measurements.map(measurement => ({
          geometry: parse(measurement.geometry),
          type: 'Feature',
          properties: {
            ...measurement
          }
        }))
        if (features.length && !(features[0].get('geometry').getType() === 'Polygon' || features[0].get('geometry').getType() === 'MultiPolygon')) {
          features = []
        }
      }
      return features
    },
    isApplicationMap () {
      return this.selectedMetaData && this.selectedMetaData.type === 'applicationMap'
    },
    styles () {
      return {
        point: pointBase,
        preview: entityImportPreview
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/telemetry/dataImport', [
      'importYieldData'
    ]),
    ...mapActions('fieldRecordSystem/telemetry', [
      'deleteTelemetry',
      'getMeasurements'
    ]),
    ...mapMutations('fieldRecordSystem/telemetry', [
      'setMeasurements'
    ]),
    onLoad (geojson) {
      this.importYieldData(geojson)
    },
    openImportModal () {
      this.showModal = true
    },
    removeMap () {
      this.deleteTelemetry().then(() => {
        notifications.success('Löschen erfolgreich')
      })
        .catch((error) => {
          console.error(error)
          notifications.error('Löschen nicht erfolgreich')
        })
    },
    editMetaData () {
      alert('edit selected Map')
    }
  },
  watch: {
    selectedMetaData (value) {
      if (value) {
        this.getMeasurements()
      } else {
        this.setMeasurements(null)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.selector-container {
  display: flex;
}
.map-selector {
  width: 90%;
}
.fa-upload {
  color: darkgrey;
  padding-top: 6px;
  margin-left: 8px;
  font-size: 1.8em;
}
.fa-upload:hover {
  cursor: pointer;
  color: black;
}
.footer {
  display: flex;
  justify-content: flex-end;

  > * {
    margin-left: 5px;
  }
}
.zone-values {
  border: 1px lightgrey solid;
  padding: 8px;
  margin-top: 8px;
}
</style>
