<template>
  <SimpleTable
    v-if="map"
    localize-headers
    :columns="humusClassColumns"
    :data="humusClassData"
  >
    <template #range="{value}">
      <IxRes>{{ `frs.mapManagement.details.soilMaps.humusClasses_range_${value}` }}</IxRes>
    </template>
    <template #area="{value}">
      {{ $i18n.format(value, 'area') }} ({{ $i18n.format(value / totalArea, 'percent') }})
    </template>
  </SimpleTable>
</template>

<script>
import SimpleTable from '@components/table/SimpleTable'

export default {
  components: {
    SimpleTable
  },
  props: {
    map: {
      type: Object
    }
  },
  computed: {
    totalArea () {
      return this.zones.reduce((total, zone) => total + zone.area, 0)
    },
    zones () {
      return [...this.map.zones].sort((a, b) => {
        return parseFloat(a.value) - parseFloat(b.value)
      })
    },
    humusClassColumns () {
      return {
        range: {title: 'frs.mapManagement.details.soilMaps.humusClasses_header_range'},
        area: {title: 'frs.mapManagement.details.soilMaps.humusClasses_header_area'}
      }
    },
    humusClassData () {
      return Object.keys(this.map.humusClasses).map(humusClass => {
        return {
          range: humusClass,
          area: this.map.humusClasses[humusClass].reduce((total, zone) => total + zone.area, 0)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
