<template lang="html">
  <div class="nutrient-map-display">
    <h3>
      <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.{{ `ContentClass_${getType}` }}</IxRes>
    </h3>

    <label>
      <IxRes>Common.SR_Common.CreatedAt</IxRes>:
    </label>
    {{ $i18n.format(map.generatedAt, 'datetime') }}

    <BsCheckbox v-model="showClassifications">
      <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.ShowSamplingValues</IxRes>
    </BsCheckbox>

    <HelpBox v-if="showClassifications">
      <IxRes>bf.nutrientMaps.hints.classificationsMapInteractionHint</IxRes>
    </HelpBox>

    <BsCheckbox v-if="showZentroidNeeded" v-model="showZentroid">
      <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.ShowZentroid</IxRes>
    </BsCheckbox>

    <ContentClassLegend :areas="areaByClassification" :nutrient="map.nutrient" />

    <FrsNutrientMapLayer :map="map" :field-id="fieldId" :show-classifications="showClassifications" />

    <template v-if="showClassifications">
      <ClickInteraction
        :hover-style="hoverStyle"
        :hit-tolerance="5"
        :layer-filter="layer => layer.get('id') === 'points'"
        @click="onFeatureClick"
      />
      <InfoOverlay
        v-if="selectedMeasurement"
        :position="selectedMeasurement.position"
      >
        <InfoPopover :measurement="selectedMeasurement" />
      </InfoOverlay>
    </template>

    <MapPrint :map-ids="[map.id]" map-type="nutrientMap" />

  </div>
</template>

<script>
import {transform} from 'ol/proj'
import {entityImportPreviewHover} from '@frs/map-styles'

import BsCheckbox from '@components/bootstrap/BsCheckbox'
import FrsNutrientMapLayer from '@frs/components/base/FrsNutrientMapLayer'
import HelpBox from '@components/help/HelpBox'
import MapPrint from '@frs/components/map-management/MapPrint'

import ContentClassLegend from '@frs/components/basic-fertilization/widgets/ContentClassLegend'
import ClickInteraction from '@components/map/interactions/ClickInteraction'
import InfoOverlay from '@components/map/overlays/InfoOverlay'
import InfoPopover from '@frs/components/basic-fertilization/nutrient-maps/InfoPopover'

import NutrientMapDisplayMixin from './NutrientMapDisplayMixin'

import {computeSize} from '@helpers/openlayers/computation'

export default {
  components: {
    BsCheckbox,
    ContentClassLegend,
    HelpBox,
    ClickInteraction,
    InfoOverlay,
    InfoPopover,
    MapPrint,
    FrsNutrientMapLayer
  },
  mixins: [
    NutrientMapDisplayMixin
  ],
  props: {
    map: Object,
    fieldId: String
  },
  data () {
    return {
      showClassifications: true,
      showZentroid: true,
      selectedPoint: null,
      selectedMeasurement: null
    }
  },
  computed: {
    getType () {
      return this.map.nutrient
    },
    areaByClassification () {
      return ((this.map && this.map.zones) || [])
        .reduce((lookup, zone) => Object.assign(lookup, {[zone.value]: computeSize(zone.geometry)}), {})
    },
    showZentroidNeeded () {
      return this.showClassifications && this.nutrientClassificationFeatures.map(x => x.geometry.type).includes('LineString')
    },
    hoverStyle () {
      return entityImportPreviewHover
    }
  },
  methods: {
    onFeatureClick ({feature}) {
      if (feature) {
        const {measurement: value, type, timestamp} = feature.getProperties()

        const geometry = feature.getGeometry()
        const position = geometry.getType() === 'Point'
          ? geometry.getCoordinates()
          : geometry.getCoordinateAt(0.5)

        this.selectedMeasurement = {
          type,
          timestamp,
          value,
          position: transform(position, 'EPSG:3857', 'EPSG:4326')
        }
      } else {
        this.selectedMeasurement = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nutrient-map-display {
  position: relative;
}

.back {
  margin: 6px 0;
}

</style>
