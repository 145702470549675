<template>
  <div class="zone-container">
    <div><label>Zone {{ zone.name }}: </label> {{ zoneInHa }} ha</div>
    <div class="zone-value">
      {{ zone.value }} {{ unit }}
    </div>
  </div>
</template>

<script>
import {format} from 'src/js/i18n/conversion'

export default {
  props: {
    zone: Object,
    unit: {
      type: String,
      default: ''
    }
  },
  computed: {
    zoneInHa () {
      return format(this.zone.area / 10000)
    }
  }
}
</script>

<style scoped>
.zone-container {
  display: flex;
  flex-direction: column;
}
.zone-value {
  margin-bottom: 4px;
}
</style>
