<template lang="html">
  <SidebarLoadingWrapper :loading="loadingData">
    <div>
      <LegacyGrid
        v-model="selectedRows"
        selection="single"
        :data="ownedPersons"
        :title="SR_FieldRecordSystem.Personnel"
        :columns="personColumns"
        add-button edit-button
        delete-button paging
        @add="addNewPerson" @edit="editPerson"
        @delete="showDeletePersonModal = !showDeletePersonModal"
      />
      <ContentShareActions v-if="$can('Content.Sharing.Staff')" content-type="persons" @all="shareAllPersonsToChilds" />
      <PersonModal>
        <template #title>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AddPerson</IxRes>
        </template>
      </PersonModal>
      <ConfirmationModal v-model="showDeletePersonModal" :title="SR_FieldRecordSystem.DeletePerson" @confirm="removeSelectedPersons">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteSelectedPersonQuestion</IxRes>
      </ConfirmationModal>
    </div>
  </SidebarLoadingWrapper>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import SidebarLoadingWrapper from '@frs/components/SidebarLoadingWrapper'
import LegacyGrid from '@components/grid/LegacyGrid'
import ConfirmationModal from '@components/modals/ConfirmationModal'

import ContentShareActions from '../content-sharing/ContentShareActions'
import PersonModal from './PersonModal'

import ReloadDataOnOrgUnitChange from '@frs/mixins/ReloadDataOnOrgUnitChange'
import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    SidebarLoadingWrapper,
    LegacyGrid,
    PersonModal,
    ConfirmationModal,
    ContentShareActions
  },
  mixins: [
    ReloadDataOnOrgUnitChange,
    PermissionMixin
  ],
  data () {
    return {
      showDeletePersonModal: false,
      selectedRows: [],
      title: ''
    }
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem',
      'Common.SR_Common',
      'Messages.State.SR_StateMessages'
    ]),
    ...mapState('fieldRecordSystem/persons', {
      persons: state => state.data.persons
    }),
    personColumns () {
      return [
        {localizedTitle: this.SR_Common.Name, typeName: 'String', propertyName: 'name'}
      ]
    },
    selectedPersons () {
      return this.selectedRows[0]
    },
    ownedPersons () {
      return this.persons.filter(x => x.orgUnitId === this.$route.params.orgUnitId && x.isArchived === false)
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/persons', [
      'startEditing',
      'loadPersons',
      'removePerson',
      'sharePersonsToChilds'
    ]),
    addNewPerson () {
      this.startEditing()
      this.title = this.SR_FieldRecordSystem.AddPerson
    },
    editPerson () {
      if (this.selectedRows.length !== 1) {
        notifications.warning(this.SR_Common.PleaseSelectExactlyOneRow)
      } else {
        this.title = this.SR_FieldRecordSystem.EditPerson
        this.startEditing(this.ownedPersons.find(x => x.id === this.selectedRows[0].id))
      }
    },
    removeSelectedPersons () {
      this.removePerson(this.selectedPersons)
        .then(() => {
          notifications.success(this.SR_StateMessages.PersonDeleted)
        })
        .catch(() => {
          notifications.error(this.SR_StateMessages.PersonNotDeleted)
        })
    },
    shareAllPersonsToChilds () {
      this.sharePersonsToChilds()
        .then(() => {
          notifications.success(this.SR_StateMessages.PersonsShared)
        })
        .catch(() => {
          notifications.error(this.SR_StateMessages.PersonsNotShared)
        })
    }
  },
  reloadData () {
    return Promise.all([
      this.loadPersons()
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
