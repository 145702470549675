<template lang="html">
  <div style="display: flex; flex-direction: column; flex: 1">
    <div class="alert alert-info">
      For testing purposes only, don't commit
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>
