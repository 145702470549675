<template lang="html">
  <div>
    <template v-if="nutrient === 'lime'">
      <span v-if="result.limeRecommendation !== undefined">
        {{ $i18n.format(result.limeRecommendation) }}
      </span>
    </template>
    <template v-else>
      <span>{{ $i18n.format(measurement) }}</span>
      <strong>{{ contentClass }}</strong>
    </template>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

import {calculateToOxidform} from '@frs/components/basic-fertilization/oxidform-calculation'

export default {
  props: {
    geometryId: String,
    nutrient: String
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', {
      columnMapping: state => state.upload.columnMapping
    }),
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', [
      'results'
    ]),
    ...mapGetters('fieldRecordSystem/basicFertilization/resultImport', [
      'newResultsValidity'
    ]),
    result () {
      return this.results[this.geometryId]
    },
    isOxide () {
      return this.columnMapping[`${this.nutrient}IsOxide`]
    },
    measurement () {
      const measurement = this.result[`${this.nutrient}Measurement`]

      if (measurement === undefined) return null

      return this.isOxide && measurement !== null
        ? calculateToOxidform(this.nutrient, measurement)
        : measurement
    },
    contentClass () {
      const contentClass = this.result[`${this.nutrient}ContentClass`]

      if (contentClass === undefined) return null

      return contentClass
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
