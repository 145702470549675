<template>
  <div class="zone-map-details-container">
    <template v-if="!zoneEditingActive">
      <label>
        <IxRes>Common.SR_Common.Name</IxRes>:
      </label>
      <div class="map-name-container">
        <span class="map-name">{{ map.name }}</span>
        <span :title="$i18n.translate('frs.mapManagement.icons.premium')">
          <FontAwesomeIcon v-if="map.origin === 'FieldInfo'" :icon="icons.premium" size="xs" />
        </span>
        <span v-if="map.originalZoneMapId || map.origin === 'import'" :title="$i18n.translate('frs.mapManagement.icons.editMap')">
          <strong class="small"><IxRes>frs.mapManagement.icons.editMap</IxRes></strong>
        </span>
        <span v-if="map.isDemo" :title="$i18n.translate('frs.mapManagement.icons.sales')">
          <strong class="small"><IxRes>frs.mapManagement.icons.sales</IxRes></strong>
        </span>
      </div>

      <label>
        <IxRes>Common.SR_Common.CreatedAt</IxRes>:
      </label>
      {{ $i18n.format(map.generatedAt, 'datetime') }}

      <HelpBox
        v-if="map.heterogenity"
        type="warning" class="heterogenity"
        :icon="icons.heterogenity"
      >
        <span>
          <strong><IxRes>Common.SR_Common.Heterogenity</IxRes>:</strong>
          <IxRes>Enums.SR_Enums.HeterogenityEnum_{{ map.heterogenity }}</IxRes>
        </span>
      </HelpBox>

      <template v-if="$can('Create.Frs.AutomaticZoneMap') && map.origin === 'FieldInfo'">
        <br>
        <HelpBox>
          <strong><IxRes>frs.mapManagement.zoneMap.details.labels.premium</IxRes></strong>
          <IxRes>frs.mapManagement.zoneMap.details.hints.adjustment</IxRes>
          <IxRes>frs.mapManagement.zoneMap.details.hints.adjustmentContact</IxRes>
        </HelpBox>
      </template>
      <div v-else class="spacer" />

      <ZoneMapActivationStatus :zone-map="map" />

      <ZoneMapAdditionalActions :zone-map="map" />

      <label>
        <IxRes>frs.zoneMaps.labels.additionalValues</IxRes>:
      </label>
      <div class="btn-group btn-group-justified">
        <div class="btn-group">
          <IxButton large @click="onEditAdditionalValues(map.id)">
            <IxRes>frs.zoneMaps.buttons.editAdditionalValues</IxRes>
          </IxButton>
        </div>
        <div class="btn-group">
          <IxButton large :disabled="!fieldId" @click="showCopyAdditionalValuesModal = true">
            <IxRes>frs.zoneMaps.buttons.copyAdditionalValues</IxRes>
          </IxButton>
        </div>
      </div>

      <CopyAdditionalValuesModal v-model="showCopyAdditionalValuesModal" />
    </template>

    <ZoneMapDetailsLegend :zone-map="map" />
    <ZoneEditing v-if="$can('Create.Frs.AutomaticZoneMap')" v-model="zoneEditingActive" :map="map" />

    <MapPrint :map-ids="[map.id]" map-type="zoneMap" />

    <template v-if="!zoneEditingActive">
      <ZoneMapMetaData v-if="$can('See.Frs.ZoneMap.Details.MetaData')" :meta-data="map.metaData" />

      <FrsZoneMapLayer :zone-map-id="map.id" />
    </template>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faStar, faCircle, faInfoCircle, faSquare} from '@fortawesome/free-solid-svg-icons'

import FrsZoneMapLayer from '@frs/components/base/FrsZoneMapLayer'
import IxButton from '@components/IxButton'
import HelpBox from '@components/help/HelpBox'
import MapPrint from '@frs/components/map-management/MapPrint'

import ZoneMapActivationStatus from './ZoneMapActivationStatus'
import ZoneMapAdditionalActions from './ZoneMapAdditionalActions'
import ZoneMapDetailsLegend from './ZoneMapDetailsLegend'
import ZoneEditing from './ZoneEditing'
import ZoneMapMetaData from './ZoneMapMetaData'
import CopyAdditionalValuesModal from './CopyAdditionalValuesModal'

import DisableDefaultMapInteractionsMixin from '@frs/mixins/DisableDefaultMapInteractionsMixin'
import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'
import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    ZoneMapActivationStatus,
    ZoneMapAdditionalActions,
    ZoneMapDetailsLegend,
    ZoneEditing,
    ZoneMapMetaData,
    CopyAdditionalValuesModal,
    MapPrint,
    FrsZoneMapLayer,
    IxButton,
    HelpBox,
    FontAwesomeIcon
  },
  mixins: [
    DisableDefaultMapInteractionsMixin,
    MirrorRouteMixin,
    PermissionMixin
  ],
  props: {
    map: Object
  },
  data () {
    return {
      showCopyAdditionalValuesModal: false,
      zoneEditingActive: false
    }
  },
  computed: {
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    icons () {
      return {
        premium: faStar,
        editMap: faCircle,
        sales: faSquare,
        heterogenity: faInfoCircle
      }
    }
  },
  methods: {
    onEditAdditionalValues (mapId) {
      this.$router.push({name: this.createParallelRoute('zoneMapAdditionalValues'), params: {...this.$route.params}})
    }
  }
}
</script>

<style lang="scss" scoped>
.zone-map-details-container {
  display: flex;
  flex-direction: column;
}

.map-name-container {
  display: flex;
  align-items: center;

  * {
    margin-right: 0.25em;
  }
}

.heterogenity {
  margin-bottom: 0;
}

.spacer {
  margin: 1em 0;
  border-bottom: 1px solid #dbdbdb;
}

.btn-group-justified {
  margin-bottom: 0.5em;
}
</style>
