export default {
  default: 'Overview',
  cultivationsDisplayField: 'Cultivations',
  cultivationPlanning: 'CultivationPlanning',
  cropRotation: 'CropRotations',
  cultivationPlan: 'Cultivations',
  mixtureManagement: 'CultivationPlanning', // NOTE what?
  companyMixtureManagement: 'Mixtures',
  actionDetailsPage: 'Details',
  actionPlanningForSingleField: 'ActionPlanning',
  actionDisplayField: 'ActionOverview',
  annualPlanDiff: 'AnnualPlanComparison',
  viewSavedLanePlans: 'viewSavedLanePlans',
  weatherDetails: 'WeatherForecastDetails',
  nutritionStatusForm: 'EditNutrientDetails',
  soilDetailsForm: 'EditSoilDetails',
  basicFieldInfoForm: 'EditGeneralDetails',
  defaultYields: 'DY_DefaultYields',
  simonModule: 'FertilizingCompensation',
  yieldDetails: 'YieldDetails',
  applicationDetails: 'ApplicationDetails'
}
