<template>
  <div>
    <LegacyGrid
      v-model="selectedRows" :data="storeData"
      :columns="columns" selection="single"
      add-button edit-button
      delete-button :title="SR_FieldRecordSystem.CompanyNumbers"
      @add="onGridAdd" @edit="onGridEdit"
      @delete="onGridRemove"
    />
    <BsModal v-model="showModal">
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NewNumber</IxRes>
      </template>
      <form v-if="modalData">
        <div class="form-group">
          <label for="name">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SupplierCooperative</IxRes>
          </label>
          <input
            id="name" v-model="modalData.name"
            type="text"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label for="number">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Number</IxRes>
          </label>
          <input
            id="number" v-model="modalData.number"
            type="text"
            class="form-control"
          >
        </div>
      </form>
      <button slot="footer" class="btn btn-success" @click.prevent="onModalConfirm">
        <IxRes v-if="editing">Common.SR_Common.Save</IxRes>
        <IxRes v-else>Areas.FieldRecordSystem.SR_FieldRecordSystem.Add</IxRes>
      </button>
      <button slot="footer" class="btn btn-warning" @click.prevent="onModalCancel">
        <IxRes>Common.SR_Common.Cancel</IxRes>
      </button>
    </BsModal>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import LegacyGrid from '@components/grid/LegacyGrid'
import BsModal from '@components/bootstrap/BsModal'

export default {
  components: {
    BsModal,
    LegacyGrid
  },
  data () {
    return {
      showModal: false,
      selectedRows: null,
      modalData: null,
      defaultModalData: {
        id: '',
        name: '',
        number: ''
      },
      editing: false
    }
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem',
      'Common.SR_Common'
    ]),
    ...mapState('fieldRecordSystem', {
      orgUnitData: state => state.data.orgUnits.data
    }),
    ...mapState('fieldRecordSystem/navigation', {
      orgUnitId: state => state.location.orgUnitId
    }),
    storeData () {
      if (!this.orgUnitData[this.orgUnitId] ||
        !this.orgUnitData[this.orgUnitId].companyNumbers) {
        return
      }

      const storeData = []
      this.orgUnitData[this.orgUnitId].companyNumbers.forEach((curr) => {
        const {id, name, number} = curr
        storeData.push({id, name, number})
      })
      return storeData
    },
    columns () {
      return [
        {localizedTitle: this.SR_Common.Name, typeName: 'String', propertyName: 'name'},
        {localizedTitle: this.SR_Common.Number, typeName: 'String', propertyName: 'number'}
      ]
    }
  },
  methods: {
    onModalConfirm () {
      this.showModal = false

      this.updateCompanyNumber(this.modalData)
        .then(() => {
          notifications.success(this.SR_FieldRecordSystem.CompanyNumberSaveSuccess)
        })
        .catch(error => {
          notifications.error(this.SR_FieldRecordSystem.CompanyNumberSaveError)
          console.error(error)
        })
    },
    onModalCancel () {
      this.showModal = false
      this.modalData = this.defaultModalData
    },
    onGridAdd () {
      this.showModal = true
      this.editing = false
      this.modalData = Object.assign({}, this.defaultModalData)
    },
    onGridEdit () {
      this.showModal = true
      this.editing = true
      this.modalData = Object.assign({}, {id: this.selectedRows[0].id, name: this.selectedRows[0].name, number: this.selectedRows[0].number})
    },
    onGridRemove () {
      if (this.selectedRows) {
        this.removeCompanyNumber(this.selectedRows[0])
          .then(() => {
            notifications.success(this.SR_FieldRecordSystem.CompanyNumberDeleteSuccess)
          })
          .catch(error => {
            notifications.error(this.SR_FieldRecordSystem.CompanyNumberDeleteError)
            console.error(error)
          })
      }
    },
    ...mapActions('fieldRecordSystem', [
      'updateCompanyNumber',
      'removeCompanyNumber'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
