<template>
  <div class="detail">
    <label class="control-label">
      <slot name="label" />
    </label>
    <FontAwesomeIcon v-if="icon" class="icon" :icon="icon" />
    <div class="value">
      <slot />
    </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    icon: Object
  }
}
</script>

<style lang="scss" scoped>
.detail {
  display: flex;
  margin: 0 0 8px 0;

  .value {
    margin-left: 3px;
  }

  .icon {
    font-size: 1.2em;
    margin-right: 4px;
    align-self: center;
  }
}
</style>
