<template>
  <div>
    <ShowDetails>
      <template #label>
        <IxRes>frs.action.details.plannedArea</IxRes>:
      </template>

      {{ $i18n.format(action.area, 'hectare-sparse') }}
    </ShowDetails>

    <ShowDetails v-if="action.mainCultivation">
      <template #label>
        <IxRes>frs.action.details.cropUsage</IxRes>:
      </template>
      <IxRes>
        masterData.crops.usage.{{ action.mainCultivation.cropUsageId }}_name
      </IxRes>
    </ShowDetails>

    <ShowDetails v-if="action.mainCultivation">
      <template #label>
        <IxRes>frs.action.details.plannedYield</IxRes>:
      </template>

      <span>
        {{ $i18n.format(action.mainCultivation.plannedYieldAmount) }} {{ action.mainCultivation.plannedYieldUnit }} <!-- TODO localize? -->
      </span>
    </ShowDetails>

    <ShowDetails v-if="personNames" :icon="icons.users">
      {{ personNames }}
    </ShowDetails>

    <ShowDetails v-if="machineNames" :icon="icons.truck">
      {{ machineNames }}
    </ShowDetails>

    <ShowDetails v-if="action.note" :icon="icons.note">
      {{ action.note }}
    </ShowDetails>
  </div>
</template>

<script>
import {faStickyNote} from '@fortawesome/free-regular-svg-icons'
import {faTractor, faUserFriends} from '@fortawesome/free-solid-svg-icons'

import ShowDetails from '@frs/components/action/planning/wizard/details/ShowDetails'

export default {
  components: {
    ShowDetails
  },
  props: {
    action: {
      type: Object,
      required: true
    },
    machines: Array,
    persons: Array,
  },
  computed: {
    machineNames () {
      return this.machines.filter(x => this.action.machines.includes(x.id)).map(y => y.name).join(', ')
    },
    personNames () {
      return this.persons.filter(x => this.action.personnel.includes(x.id)).map(y => y.name).join(', ')
    },
    icons () {
      return {
        truck: faTractor,
        users: faUserFriends,
        note: faStickyNote
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
