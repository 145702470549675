<template>
  <div class="probing-site-management">
    <NavigationHeader @back="startOrderCreation">
      <template #step>
        <IxRes>{{ headerTitle }}</IxRes>
      </template>
      <template #back>
        <IxRes>bf.orderPlanning.button.backToStepOne</IxRes>
      </template>
    </NavigationHeader>

    <div class="form-container">
      <VueSelectize
        v-model="selectedOrderId"
        class="order-selection"
        :options="incompleteOrders.map(x => x.id)"
        :fields="orderFields"
        required
        allow-empty
      >
        <template #label>
          <IxRes>bf.label.orderSelection</IxRes>
        </template>
      </VueSelectize>

      <template v-if="selectedOrderId">
        <CollapsibleSection v-model="showHelp">
          <template #header>
            <IxRes>bf.orderPlanning.probingPointsHelpTitle</IxRes>
          </template>

          <HelpBox>
            <IxRes>bf.orderPlanning.hints.probingPointsHelp_hint1</IxRes>
          </HelpBox>
          <HelpBox>
            <IxRes>bf.orderPlanning.hints.probingPointsHelp_hint2</IxRes>
          </HelpBox>
        </CollapsibleSection>

        <CollapsibleSection v-model="showMapOptions">
          <template #header>
            <IxRes>bf.orderPlanning.probingPointsMapSettingsTitle</IxRes>
          </template>

          <FrsZoneMapSelection v-model="selectedZoneMapId" :required="false" :field-id="selectedFieldId">
            <template #label>
              <IxRes>bf.orderPlanning.label.zoneMap</IxRes>
            </template>
          </FrsZoneMapSelection>

          <SimpleSwitch v-model="showNumbers">
            <IxRes>bf.orderPlanning.label.showSampleNumber</IxRes>
          </SimpleSwitch>
        </CollapsibleSection>

        <div class="btn-group">
          <ToggleButton v-model="mode" :value="'probingPoints'">
            <IxRes>bf.samplingOrderPlanning.toggle.labels.probingPoints</IxRes>
          </ToggleButton>
          <ToggleButton v-model="mode" :value="'probingLines'">
            <IxRes>bf.samplingOrderPlanning.toggle.labels.probingLines</IxRes>
          </ToggleButton>
        </div>

        <HelpBox>
          <IxRes>bf.orderPlanning.hints.probingPointInfo</IxRes>
        </HelpBox>

        <SamplingProbingSiteTable :data="tableData" />
      </template>
    </div>

    <template v-if="selectedOrderId">
      <HelpBox v-if="duplicateLabelsForField && duplicateLabelsForField.length" type="danger">
        <IxRes :context="{labels: duplicateLabelsForField}">bf.orderPlanning.hints.duplicateLabelsFoundForField</IxRes>
      </HelpBox>
      <HelpBox v-else-if="duplicateLabelsFound" type="danger">
        <IxRes>bf.orderPlanning.hints.duplicateLabelsFound</IxRes>
      </HelpBox>

      <HelpBox>
        <IxRes v-if="!allFieldsPlanned">bf.orderPlanning.hints.notAllFieldsPlanned</IxRes>
        <IxRes v-else>bf.orderPlanning.hints.pleaseDoubleCheckPoints</IxRes>
      </HelpBox>

      <ActionFooter :disabled="!allFieldsPlanned || duplicateLabelsFound" @home="save(false)" @next="save(true)">
        <template slot="saveNextStep">
          <IxRes>bf.orderPlanning.saveToStepThree</IxRes>
        </template>
      </ActionFooter>
    </template>

    <IxVectorLayer
      :features="zoneMapFeatures"
      :vector-style="zoneMapStyle"
      :z-index="1"
      layer-id="zoneMap"
      auto-focus
    />
    <IxVectorLayer
      :features="fieldBorderFeatures"
      :vector-style="styles.border"
      :z-index="2"
      layer-id="fieldBorder"
      auto-focus
    />
    <IxVectorLayer
      :features="probingSiteFeatures"
      :vector-style="styles.probingSite"
      :z-index="3"
      layer-id="probingSites"
    />

    <ClickInteraction
      v-if="selectedFieldId && (probingSiteMode === 'probingPoints')"
      :layer-filter="layer => layer.get('id') === 'fieldBorder'"
      :cursor-style="styles.cursor"
      @click="onMapClick"
    />

    <DrawInteraction
      v-if="selectedFieldId && (probingSiteMode === 'probingLines')"
      key="addLineGuide"
      type="LineString"
      @finish="onDrawFinish"
    />

    <AsyncConfirmationModal
      ref="modal"
      translation-namespace="bf.probingOrder.changes" action="reset"
    >
      <template #title>
        <IxRes>bf.probingOrder.unsavedChanges</IxRes>
      </template>

      <IxRes>bf.probingOrder.discardChangesQuestion</IxRes>
    </AsyncConfirmationModal>

    <FrsLoadingIndicator saving :loading="saving" />
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState, mapGetters} from 'vuex'
import {parse, stringify} from 'wellknown'

import booleanContains from '@turf/boolean-contains'
import inside from '@turf/inside'
import helpers from '@turf/helpers'
import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import Fill from 'ol/style/Fill'
import Circle from 'ol/style/Circle'

import {notifications} from 'src/js/infrastructure'
import {mapFormFields, mapResources} from '@helpers/vuex'
import {fieldBorder, planningPoint} from '@frs/map-styles'
import {splitToPolygonsAsGeoJson} from '@helpers/geojson/features'
import {toWkt} from 'src/js/helpers/openlayers/features'

import FrsZoneMapSelection from '@frs/components/base/FrsZoneMapSelection'
import HelpBox from '@components/help/HelpBox'
import SimpleSwitch from '@components/forms/SimpleSwitch'
import VueSelectize from '@components/VueSelectize'
import CollapsibleSection from '@components/CollapsibleSection'
import IxVectorLayer from '@components/map/IxVectorLayer'
import ClickInteraction from '@components/map/interactions/ClickInteraction'
import DrawInteraction from '@components/map/interactions/DrawInteraction'
import ToggleButton from '@components/ToggleButton'
import AsyncConfirmationModal from '@components/modals/AsyncConfirmationModal'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import ActionFooter from '@frs/components/basic-fertilization/widgets/ActionFooter'
import NavigationHeader from '@frs/components/basic-fertilization/widgets/NavigationHeader'

import FieldBorderMixin from '../mixins/FieldBorderMixin'
import ZoneMapDisplayMixin from '../mixins/ZoneMapDisplayMixin'
import SamplingProbingSiteTable from './SamplingProbingSiteTable'

import DisableDefaultMapInteractionsMixin from '@frs/mixins/DisableDefaultMapInteractionsMixin'

export default {
  components: {
    HelpBox,
    SimpleSwitch,
    VueSelectize,
    FrsZoneMapSelection,
    IxVectorLayer,
    ClickInteraction,
    CollapsibleSection,
    SamplingProbingSiteTable,
    FrsLoadingIndicator,
    ActionFooter,
    NavigationHeader,
    ToggleButton,
    DrawInteraction,
    AsyncConfirmationModal
  },
  mixins: [
    ZoneMapDisplayMixin,
    FieldBorderMixin,
    DisableDefaultMapInteractionsMixin
  ],
  data () {
    return {
      showHelp: false,
      showMapOptions: false,
      saving: false
    }
  },
  computed: {
    ...mapResources([
      '@frs.SR_BaseFertilization',
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState('fieldRecordSystem/basicFertilization', {
      simplifiedGeometries: state => state.data.simplifiedGeometries
    }),
    ...mapState('fieldRecordSystem/basicFertilization/orderPlanning', [
      'selectedFieldId',
      'incompleteOrders',
      'probingSiteMode'
    ]),
    ...mapFormFields('fieldRecordSystem/basicFertilization/orderPlanning', [
      'selectedOrderId',
      'selectedZoneMapId',
      'showNumbers'
    ]),
    ...mapState('fieldRecordSystem/basicFertilization/orderPlanning', {
      existingPlanningProbingSites: state => state.probingSites.existing,
      newPlanningProbingSites: state => state.probingSites.new,
      deletedIds: state => state.probingSites.deleted
    }),
    ...mapGetters('fieldRecordSystem/basicFertilization/orderPlanning', [
      'allFieldsPlanned',
      'duplicateLabelsByFieldId',
      'duplicateLabelsFound'
    ]),
    headerTitle () {
      return `bf.orderPlanning.headerTitle.${this.probingSiteMode}`
    },
    mode: {
      get () {
        return this.probingSiteMode
      },
      set (value) {
        if (this.probingSiteMode !== value && value !== null) {
          if (this.newPlanningProbingSites.length > 0 || this.existingPlanningProbingSites.filter(x => x.isActive).length > 0) {
            this.showResetConfirmationModal(value)
          } else {
            this.setProbingSiteMode(value)
          }
        }
      }
    },
    duplicateLabelsForField () {
      return this.duplicateLabelsByFieldId[this.selectedFieldId]
    },
    fieldWkt () {
      if (!this.selectedFieldId) return null

      return this.simplifiedGeometries[this.selectedFieldId]
    },
    planningProbingSites () {
      return this.newPlanningProbingSites.concat(this.existingPlanningProbingSites)
    },
    probingSiteFeatures () {
      return this.tableData.map(data => ({
        geometry: parse(data.geometry),
        type: 'Feature',
        properties: {
          id: data.id,
          isActive: data.isActive,
          isNew: data.isNew,
          label: data.label,
          markedForDeletion: data.markedForDeletion
        }
      }))
    },
    tableData () {
      const clone = (probingSite, i) => {
        const isNew = this.newPlanningProbingSites.indexOf(probingSite) > -1
        const markedForDeletion = this.deletedIds.map(x => x.id).includes(probingSite.id)
        return Object.assign({...probingSite, isNew, markedForDeletion}, this.showNumbers ? {number: i + 1} : null)
      }

      switch (this.probingSiteMode) {
      case 'probingPoints':
        return this.planningProbingSites.filter(x => x.geometry.startsWith('POINT')).map(clone)
      case 'probingLines':
        return this.planningProbingSites.filter(x => x.geometry.startsWith('LINESTRING')).map(clone)
      default: throw new Error('invalid planning type selection.')
      }
    },
    orderFields () {
      return {
        text: id => this.incompleteOrders.find(x => x.id === id).name
      }
    },
    hasChanges () {
      return this.newPlanningProbingSites.length || this.deletedIds.length
    },
    zoneMapIds () {
      return [this.selectedZoneMapId]
    },
    fieldIdByZoneMapId () {
      return {[this.selectedZoneMapId]: this.selectedFieldId}
    },
    styles () {
      const cursor = [
        new Style({
          image: new Circle({
            radius: 4,
            fill: new Fill({color: 'rgba(255, 255, 255, 0.4)'})
          })
        }),
        new Style({
          image: new Circle({
            radius: 12,
            stroke: new Stroke({color: 'white', width: 1})
          })
        })
      ]

      return {
        border: fieldBorder,
        probingSite: planningPoint,
        // NOTE openlayers supplies `resolution` as first parameter if you hand it a single parameter function
        // eslint-disable-next-line no-unused-vars
        cursor: (feature, resolution) => {
          return feature.get('feature') ? cursor : null
        }
      }
    },
    fieldFeature () {
      if (!this.fieldWkt) return
      return {geometry: this.fieldWkt, type: 'Feature'}
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/basicFertilization/orderPlanning', [
      'addNewProbingSite',
      'setActiveForAll',
      'setProbingSiteMode',
      'resetChanges'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization/orderPlanning', [
      'saveOrder'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization/orderCreation', [
      'startOrderCreation'
    ]),
    onMapClick ({coordinate}) {
      if (!this.fieldFeature) return

      const point = helpers.point(coordinate)
      if (inside(point, this.fieldFeature)) {
        this.addNewProbingSite(stringify(point))
      }
    },
    onDrawFinish (drawFeature) {
      if (!this.fieldFeature) return

      const featureCollection = splitToPolygonsAsGeoJson(this.fieldFeature)
      const fieldContainsFeature = featureCollection.features.some(feature => booleanContains(feature, drawFeature))

      if (fieldContainsFeature) {
        const wkt = toWkt(drawFeature)

        this.addNewProbingSite(wkt)
      }
    },
    save (continueToNextStep) {
      this.saving = true
      this.saveOrder(continueToNextStep)
        .then(() => {
          notifications.success(this.SR_BaseFertilization.BF_planning_save_success)
        })
        .catch(error => {
          console.error(error)
          notifications.error(this.SR_BaseFertilization.BF_planning_save_error)
        })
        .then(() => {
          this.saving = false
        })
    },
    async showResetConfirmationModal  (value) {
      const {finished} = await this.$refs.modal.start()
      if (finished) {
        this.setProbingSiteMode(value)
        this.resetChanges()
      }
    }
  },
  watch: {
    selectedFieldId: {
      immediate: true,
      handler () {
        if (this.fieldWkt) {
          this.$store.dispatch('fieldRecordSystem/map/focus', this.fieldWkt)
        }
        if (this.newPlanningProbingSites.some(ps => ps.geometry.startsWith('LINESTRING'))) {
          this.setProbingSiteMode('probingLines')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.probing-site-management {
  flex: 1;
  display: flex;
  flex-direction: column;

  .form-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    margin-bottom: 0.5em;
  }

  .order-selection {
    margin-top: 16px;
  }
  .import-container {
    display: flex;
    flex-direction: column;
  }

  .btn-group {
    margin-bottom: 5px;
  }
}
</style>
