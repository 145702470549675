<template lang="html">
  <div class="row">
    <div class="col-md-6">
      <VueSelectize
        v-model="lab" :options="labPresets"
        :fields="labFields"
        allow-empty :placeholder="SR_BaseFertilization.PleaseSelectALab"
        label="Labor"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapFormFields, mapResources} from '@helpers/vuex'

import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    VueSelectize
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', {
      labPresets: state => state.upload.labPresets
    }),
    ...mapFormFields('fieldRecordSystem/basicFertilization/resultImport', [
      'upload.lab'
    ]),
    ...mapResources([
      '@frs.SR_BaseFertilization'
    ]),
    labFields () {
      return {
        text: value => value.name,
        value: value => value.id,
        label: value => value.name === 'LUFA Nord-West' ? 'Agravis-Standard' : null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
