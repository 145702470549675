
<template lang="html">
  <div>
    <ZoneMapDetailsPanel>
      <template #header>
        <IxRes>frs.zoneMaps.details.metadata</IxRes>
      </template>

      <div class="grid">
        <label>
          <IxRes>frs.zoneMaps.details.algorithmType</IxRes>:
        </label>
        <IxRes>frs.zoneMaps.algorithm.option.{{ metaData.typeOfAlgorithm || 'Old' }}</IxRes>

        <label>
          <IxRes>frs.zoneMaps.details.countOfClasses</IxRes>:
        </label>
        {{ metaData.countOfClasses || 'N/A' }}

        <label>
          <IxRes>frs.zoneMaps.details.classCountByArea</IxRes>:
        </label>
        {{ metaData.numberOfClassesHa || 'N/A' }}

        <label>
          <IxRes>frs.zoneMaps.details.classCountByStandardDeviation</IxRes>:
        </label>
        {{ metaData.numberOfClassesDEV || 'N/A' }}

        <label>
          <IxRes>frs.zoneMaps.details.expectedValue</IxRes>:
        </label>
        {{ $i18n.format(metaData.expectedValue, 'f4') }}

        <label>
          <IxRes>frs.zoneMaps.details.standardDeviation</IxRes>:
        </label>
        {{ $i18n.format(metaData.standardDeviation, 'f4') }}

        <template v-if="metaData.originalZoneMapId">
          <label>
            <IxRes>frs.zoneMaps.details.originalZoneMapId</IxRes>:
          </label>
          {{ metaData.originalZoneMapId }}
        </template>
      </div>
    </ZoneMapDetailsPanel>

    <ZoneMapDetailsPanel v-if="metaData.classificationThresholds && metaData.classificationThresholds.length">
      <template #header>
        <IxRes>frs.zoneMaps.details.experimentalMetadata</IxRes>
      </template>

      <div class="grid">
        <label>
          <IxRes>frs.zoneMaps.details.innerLimit</IxRes>:
        </label>
        {{ metaData.innerLimit }}

        <label>
          <IxRes>frs.zoneMaps.details.outerLimit</IxRes>:
        </label>
        {{ metaData.outerLimit }}

        <label>
          <IxRes>frs.zoneMaps.details.combinedExpectedValue</IxRes>:
        </label>
        {{ metaData.combinedSatelliteImageMean }}

        <label>
          <IxRes>frs.zoneMaps.details.combinedStandardDeviation</IxRes>:
        </label>
        {{ metaData.combinedSatelliteImageStandardDeviation }}

        <label>
          <IxRes>frs.zoneMaps.details.classificationThresholds</IxRes>:
        </label>
        <div class="thresholds">
          <span v-for="(x, i) in metaData.classificationThresholds.slice(0, metaData.classificationThresholds.length - 1)" :key="i">
            {{ metaData.classificationThresholds.length - i - 1 }}: [{{ $i18n.format(x) }} , {{ $i18n.format(metaData.classificationThresholds[i + 1]) }})
          </span>
        </div>
      </div>
    </ZoneMapDetailsPanel>

    <ZoneMapDetailsPanel>
      <template #header>
        <IxRes>frs.zoneMaps.details.selectedSatScenes</IxRes>
      </template>

      <div v-for="(scene, i) in metaData.satPics" :key="i">
        <SatPicMeta :sat-pic="scene" />
      </div>
    </ZoneMapDetailsPanel>

    <ZoneMapDetailsPanel>
      <template #header>
        <IxRes>frs.zoneMaps.details.usedSatScenes</IxRes>
      </template>

      <div v-for="(scene, i) in metaData.usedSatScenes" :key="i">
        <SatPicMeta :sat-pic="scene" />
      </div>
    </ZoneMapDetailsPanel>

    <ZoneMapDetailsNdviLineChart v-if="ndviData" :ndvi="ndviData" />

    <template v-if="metaData.calculatedCorrelations && metaData.calculatedCorrelations.length">
      <br>

      <ZoneMapDetailsPanel>
        <template #header>
          <IxRes>frs.zoneMaps.details.correlations</IxRes>
        </template>

        <div v-for="(correlation, i) in metaData.calculatedCorrelations" :key="i">
          {{ correlation.relation }}: {{ correlation.value }}
        </div>
      </ZoneMapDetailsPanel>
    </template>
  </div>
</template>

<script>
import ZoneMapDetailsPanel from '@frs/components/map-management/details/ZoneMapDetailsPanel'
import ZoneMapDetailsNdviLineChart from '@frs/components/map-management/details/ZoneMapDetailsNdviLineChart'

import SatPicMeta from './components/SatPicMeta'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    ZoneMapDetailsNdviLineChart,
    ZoneMapDetailsPanel,
    SatPicMeta
  },
  mixins: [
    PermissionMixin
  ],
  props: {
    metaData: Object
  },
  computed: {
    ndviData () {
      if (!this.metaData.usedSatScenes.some(x => x.sentinelData)) return null

      return this.metaData.usedSatScenes.map(scene => ({
        cropUsageId: scene.sentinelData.cropUsageId,
        recordingDate: scene.recordingDate,
        ndvi: scene.sentinelData.ndviMean,
        usedCandidates: scene.sentinelData.usedCandidates
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: end;

  label {
    align-self: start;
  }
}

.thresholds {
  display: flex;
  flex-direction: column;
}
</style>
