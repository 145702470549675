<template lang="html">
  <div>
    <VueSelectize
      v-model="selectedYieldMapId" required
      allow-empty
      :fields="fields" :options="yieldDataIds"
      name="yieldMap"
    >
      <template #label>
        <IxRes>Common.SR_Common.MapSelection</IxRes>
      </template>
    </VueSelectize>

    <div class="crop">
      <label><IxRes>Common.SR_Common.Crop</IxRes>:</label>
      <p class="crop-name">
        <IxRes v-if="selectedYield">masterData.crops.crop.{{ selectedYield.cropUsageId }}</IxRes>
        <IxRes v-else>Common.SR_Common.NoCrop</IxRes>
        {{ selectedCropName }}
      </p>
    </div>

    <BsPanel v-if="selectedYield!= null" title="Datensatzstatistiken">
      <div class="crop">
        <label class="crop-name"><IxRes>Common.SR_Common.MaxYield</IxRes>:</label>
        <p class="crop-name">
          {{ selectedYield.max }}
        </p>
        <label class="crop-name"><IxRes>Common.SR_Common.MinYield</IxRes>:</label>
        <p class="crop-name">
          {{ selectedYield.min }}
        </p>
      </div>

      <div class="crop">
        <label class="crop-name"><IxRes>Common.SR_Common.AverageYield</IxRes>:</label>
        <p class="crop-name">
          {{ $i18n.format(selectedYield.average, 'f2') }}
        </p>
        <label class="crop-name"><IxRes>Common.SR_Common.StandardDeviation</IxRes>:</label>
        <p class="crop-name">
          {{ $i18n.format(selectedYield.stdDeviation, 'f2') }}
        </p>
      </div>
    </BsPanel>

    <NumericInput
      v-model="minValue" required
      name="minValue"
      unit="kg/ha"
    >
      <template #label>
        <IxRes>Common.SR_Common.Minimum</IxRes>
      </template>
    </NumericInput>
    <NumericInput
      v-model="maxValue" required
      name="maxValue"
      unit="kg/ha"
    >
      <template #label>
        <IxRes>Common.SR_Common.Maximum</IxRes>
      </template>
    </NumericInput>
    <NumericInput
      v-model="actualYield" required
      name="actualYield"
      unit="dt/ha"
    >
      <template #label>
        <IxRes>Common.SR_Common.ActualYield</IxRes>
      </template>
    </NumericInput>
    <NumericInput
      v-model="pFactor" required
      name="pFactor"
      addon="kg P / dt Frischmasse"
    >
      <template #label>
        <IxRes>Common.SR_Common.pFactor</IxRes>
      </template>
    </NumericInput>
    <div class="buttons">
      <IxButton
        large save
        :disabled="!isValid"
        @click="onSave"
      />
      <IxButton large cancel @click="onCancel" />
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import BsPanel from 'src/vue/components/bootstrap/BsPanel.vue'
import NumericInput from '@components/forms/NumericInput'
import VueSelectize from '@components/VueSelectize'
import IxButton from '@components/IxButton'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    NumericInput,
    VueSelectize,
    IxButton,
    BsPanel
  },
  mixins: [
    FormPartMixin
  ],
  computed: {
    ...mapFormFields('fieldRecordSystem/simonModule', [
      'yieldDetails.minValue',
      'yieldDetails.maxValue',
      'yieldDetails.selectedYieldMapId',
      'yieldDetails.actualYield',
      'yieldDetails.pFactor'
    ]),
    ...mapState('fieldRecordSystem/simonModule', {
      yieldMetaData: state => state.data.yieldMetaData
    }),
    yieldDataIds () {
      return this.yieldMetaData.map(x => x.id)
    },
    fields () {
      return {
        text: id => this.yieldMetaData.find(prod => prod.id === id).name
      }
    },
    isValid () {
      return this.state === 'success'
    },
    selectedYield () {
      return this.yieldMetaData.find(x => x.id === this.selectedYieldMapId)
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/simonModule', [
      'saveYieldDetails'
    ]),
    ...mapMutations('fieldRecordSystem/simonModule', [
      'resetYieldDetails'
    ]),
    ...mapMutations('fieldRecordSystem', [
      'setRightView'
    ]),
    onSave () {
      this.validate()

      this.$nextTick()
        .then(() => {
          if (!this.isValid) return

          this.saveYieldDetails()
        })
    },
    onCancel () {
      this.resetYieldDetails()
      this.setRightView('simonModule')
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.crop {
  display: flex;
  margin-top: 5px;
}
.crop-name {
  margin-left: 8px;
}
</style>
