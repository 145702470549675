<template lang="html">
  <span class="cultivation-entry">
    <IxRes v-if="type">
      frs.dlgCertIntegration.modals.harvestYearHelp.diagram.labels.{{ type }}Cultivation
    </IxRes>
  </span>
</template>

<script>
export default {
  props: {
    type: String
  }
}
</script>

<style lang="scss" scoped>
@use './shared';

.cultivation-entry {
  @include shared.large-text;

  padding-bottom: 0.15em; // NOTE for vertical centering, with capitalize/small-caps text appears off-center

  background-color: shared.$fill;
  border: shared.$border;

  + .cultivation-entry {
    border-left: none;
  }
}
</style>
