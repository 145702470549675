import { render, staticRenderFns } from "./DetailsCommonInfo.vue?vue&type=template&id=34df8d0c&scoped=true&"
import script from "./DetailsCommonInfo.vue?vue&type=script&lang=js&"
export * from "./DetailsCommonInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34df8d0c",
  null
  
)

export default component.exports