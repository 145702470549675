<template>
  <div>
    <ProductNames v-bind="{part, products}">
      <IxRes>frs.actions.seeding.product</IxRes>:
    </ProductNames>

    <ShowDetails>
      <template #label>
        <IxRes>frs.actions.seeding.PlantsPerSqm</IxRes>:
      </template>

      {{ part.parameters.plantsPerSqm }} pro {{ $i18n.translateUnit('m²') }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>frs.actions.seeding.RowDistance</IxRes>:
      </template>

      {{ part.parameters.rowDistance }} {{ $i18n.translateUnit('cm') }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>frs.actions.seeding.RowSeparation</IxRes>:
      </template>

      {{ part.parameters.rowSeparation }} {{ $i18n.translateUnit('cm') }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>frs.actions.seeding.Emergence</IxRes>:
      </template>

      {{ part.parameters.emergence }} {{ $i18n.translateUnit('%') }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>frs.actions.seeding.GerminationCapacity</IxRes>:
      </template>

      {{ part.parameters.germinationCapacity }} {{ $i18n.translateUnit('%') }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>frs.actions.seeding.coating</IxRes>:
      </template>

      {{ part.parameters.coating }}
    </ShowDetails>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import ShowDetails from './ShowDetails'
import ProductNames from './ProductNames'

export default {
  components: {
    ShowDetails,
    ProductNames
  },
  props: {
    part: Object,
    products: Object
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      fieldData: state => state.data.field
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
