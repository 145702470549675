<template lang="html">
  <div class="default-right-sidebar">
    <HelpBox large>
      <h2>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.welcomeTitleFrs</IxRes>
      </h2>
      <h4 class="lead">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.frsVersion</IxRes>
      </h4>
      <p>
        <b>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.frsFirstSteps</IxRes>
        </b>
      </p>
      <p>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.frsWelcomeInfo</IxRes>
      </p>
    </HelpBox>
  </div>
</template>

<script>
import HelpBox from '@components/help/HelpBox'

export default {
  components: {
    HelpBox
  }
}
</script>

<style lang="scss" scoped>
.default-right-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
