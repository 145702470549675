<template>
  <div>
    <ShowDetails>
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.BBCHStage</IxRes>:
      </template>

      {{ part.parameters.bbch ? part.parameters.bbch : 'keine Angabe' }}
    </ShowDetails>
  </div>
</template>

<script>
import ShowDetails from './ShowDetails'

export default {
  components: {
    ShowDetails
  },
  props: {
    part: Object
  }
}
</script>

<style scoped>
</style>
