<template lang="html">
  <div class="column-mapping-preview">
    <div class="row">
      <div class="col-md-5">
        <label><IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.OriginalFile</IxRes></label>
        <pre>
          <code>
            {{ csvLinesRaw[0] }}
            <br>
            {{ csvLinesRaw[1] }}
            <br>
            ...
          </code>
        </pre>
      </div>
      <div class="col-md-2 arrow-column">
        <i class="fa fa-arrow-right fa-3x" />
      </div>
      <div class="col-md-5">
        <label><IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.ExampleResult</IxRes></label>
        <div>
          <div>
            <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.SampleNumber</IxRes>:
            <ColumnMappingPreviewValue column-id="sampleId" required-field />,
            <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.Area</IxRes>:
            <ColumnMappingPreviewValue column-id="fieldName" />,
            <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.SoilType</IxRes>:
            <ColumnMappingPreviewValue column-id="SoilSubType" />
          </div>
          <div>
            <span v-if="columnMapping.phosphorusIsOxide">P<sub>2</sub>O<sub>5</sub></span>
            <span v-else>P</span>
            : <ColumnMappingPreviewValue column-id="phosphorusValue" required-nutrient="phosphorus" /> mg/100g, <IxRes>
              Areas.FieldRecordSystem.SR_BaseFertilization.ContentClass
            </IxRes>: <ColumnMappingPreviewValue column-id="phosphorusClassification" required-nutrient="phosphorus" />
          </div>
          <div>
            <span v-if="columnMapping.magnesiumIsOxide">MgO</span>
            <span v-else>Mg</span>
            : <ColumnMappingPreviewValue column-id="magnesiumValue" required-nutrient="magnesium" /> mg/100g, <IxRes>
              Areas.FieldRecordSystem.SR_BaseFertilization.ContentClass
            </IxRes>: <ColumnMappingPreviewValue column-id="magnesiumClassification" required-nutrient="magnesium" />
          </div>
          <div>
            <span v-if="columnMapping.potassiumIsOxide">K<sub>2</sub>O</span>
            <span v-else>K</span>
            : <ColumnMappingPreviewValue column-id="potassiumValue" required-nutrient="potassium" /> mg/100g, <IxRes>
              Areas.FieldRecordSystem.SR_BaseFertilization.ContentClass
            </IxRes>: <ColumnMappingPreviewValue column-id="potassiumClassification" required-nutrient="potassium" />
          </div>
          <div>
            pH: <ColumnMappingPreviewValue column-id="phValue" required-nutrient="ph" />, <IxRes>
              Areas.FieldRecordSystem.SR_BaseFertilization.ContentClass
            </IxRes>: <ColumnMappingPreviewValue column-id="phClassification" required-nutrient="ph" />
          </div>
          <div>
            Ca <IxRes>
              Areas.FieldRecordSystem.SR_BaseFertilization.Recommendation
            </IxRes>: <ColumnMappingPreviewValue column-id="limeRecommendation" required-nutrient="lime" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

import ColumnMappingPreviewValue from './ColumnMappingPreviewValue'

export default {
  components: {
    ColumnMappingPreviewValue
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', {
      columnMapping: state => state.upload.columnMapping,
      nutrients: state => state.manual.nutrients
    }),
    ...mapGetters('fieldRecordSystem/basicFertilization/resultImport', [
      'csvLinesRaw'
    ])
  }
}
</script>

<style lang="scss" scoped>
code {
  white-space: nowrap;
}
.arrow-column {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
