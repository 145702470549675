<template>
  <div v-if="nutrientRequirements[fieldId]">
    <IxCollapsePanel v-model="hideInfo">
      <template #expand-text>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ExpandInfo</IxRes>
      </template>
      <template #collapse-text>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CollapseInfo</IxRes>
      </template>
      <InfoWindow icon="info-circle">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementHarvestYearNote</IxRes>
      </InfoWindow>
    </IxCollapsePanel>
    <NitrogenRequirement :nitrogen-requirement="nitrogenRequirement" :nitrogen-requirement-for-field="nitrogenRequirementForField" />
    <div class="additionsAndRevocations">
      <LabelAndMessage>
        <template #label>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AdditionAndRevocation</IxRes>:
        </template>
        <template v-for="(correction, index) in corrections">
          <CorrectionCard :key="correction.id" :correction="correction" :index="index" />
        </template>
      </LabelAndMessage>
    </div>
    <IxButton large icon="plus" @click="add">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CorrectNutrientRequirementManually</IxRes>
    </IxButton>
    <hr>
    <PhosphorRequirement :phosphor-requirement="phosphorRequirement" />
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import IxButton from '@components/IxButton'
import IxCollapsePanel from '@components/ix/IxCollapsePanel'
import InfoWindow from '@components/help/InfoWindow'

import CorrectionCard from './CorrectionCard'
import NitrogenRequirement from './NitrogenRequirement'
import PhosphorRequirement from './PhosphorRequirement'

export default {
  components: {
    IxButton,
    CorrectionCard,
    InfoWindow,
    IxCollapsePanel,
    NitrogenRequirement,
    PhosphorRequirement,
    LabelAndMessage
  },
  data () {
    return {
      hideInfo: false,
      kiloPerHectarUnit: 'kg/ha',
      tonUnit: 't'
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/nutrientRequirement', {
      nutrientRequirements: state => state.data.field,
      crops: state => state.data.editing.crops
    }),
    ...mapState('fieldRecordSystem', {
      harvestYear: state => state.userSettings.harvestYear,
      fields: state => state.data.field
    }),
    ...mapState('fieldRecordSystem/navigation', {
      fieldId: state => state.location.fieldId
    }),
    field () {
      return this.fields[this.fieldId]
    },
    fieldArea () {
      return this.field.area.geometrical
    },
    nutrientRequirementsForField () {
      return this.nutrientRequirements[this.fieldId]
    },
    currentNutrientRequirement () {
      return this.nutrientRequirementsForField.find(x => x.harvestYear === this.harvestYear)
    },
    corrections () {
      return this.currentNutrientRequirement.corrections
    },
    nitrogenRequirement () {
      return this.currentNutrientRequirement ? this.corrections.reduce((acc, val) => acc + val.amount, 0) + this.currentNutrientRequirement.nitrogenRequirement : 0
    },
    nitrogenRequirementForField () {
      return this.nitrogenRequirement ? this.nitrogenRequirement * this.fieldArea / 10000 : 0
    },
    pRequirementFor3Years () {
      const next3Years = [this.harvestYear, this.harvestYear + 1, this.harvestYear + 2]
      return this.nutrientRequirementsForField.filter(x => next3Years.includes(x.harvestYear)).reduce((acc, val) => acc + val.phosphorusRequirement, 0)
    },
    pRequirementFor3YearsForField () {
      return this.pRequirementFor3Years ? this.pRequirementFor3Years * this.fieldArea / 10000 : 0
    },
    totalPRevocation () {
      let total = 0
      for (const year in this.nutrientRequirementFor3Years) {
        const nutrientRequirement = this.nutrientRequirementFor3Years[year]
        if (nutrientRequirement) {
          total += nutrientRequirement.phosphorusRequirement
        }
      }
      return total
    },
    nutrientRequirementFor3Years () {
      return {
        currentYear: this.nutrientRequirementsForField.find(x => x.harvestYear === this.harvestYear),
        secondYear: this.nutrientRequirementsForField.find(x => x.harvestYear === this.harvestYear + 1),
        thirdYear: this.nutrientRequirementsForField.find(x => x.harvestYear === this.harvestYear + 2)
      }
    },
    dataYears () {
      return [{
        currentYear: this.nutrientRequirementFor3Years.currentYear ? this.nutrientRequirementFor3Years.currentYear.averageYieldLast3Years : 0,
        secondYear: this.nutrientRequirementFor3Years.secondYear ? this.nutrientRequirementFor3Years.secondYear.expectedYield : 0,
        thirdYear: this.nutrientRequirementFor3Years.thirdYear ? this.nutrientRequirementFor3Years.thirdYear.expectedYield : 0
      },
      {
        currentYear: this.nutrientRequirementFor3Years.currentYear ? this.nutrientRequirementFor3Years.currentYear.phosphorusRequirement : 0,
        secondYear: this.nutrientRequirementFor3Years.secondYear ? this.nutrientRequirementFor3Years.secondYear.phosphorusRequirement : 0,
        thirdYear: this.nutrientRequirementFor3Years.thirdYear ? this.nutrientRequirementFor3Years.thirdYear.phosphorusRequirement : 0
      }]
    },
    phosphorRequirement () {
      return {
        pRequirementFor3Years: this.pRequirementFor3Years,
        pRequirementFor3YearsForField: this.pRequirementFor3YearsForField,
        totalPRevocation: this.totalPRevocation,
        data: this.dataYears
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/nutrientRequirement', [
      'setWizardStep'
    ]),
    ...mapMutations('fieldRecordSystem', ['setRightView']),
    ...mapMutations('fieldRecordSystem/nutrientRequirement/editBuffer',
      ['addCorrection', 'editCorrection', 'removeCorrection']),
    add () {
      this.addCorrection()
      this.setWizardStep('correction')
    }
  }
}
</script>

<style lang="scss" scoped>
  hr {
    border-color: #7b7b7b
  }
  .additionsAndRevocations {
    margin-bottom: 10px;
  }
</style>
