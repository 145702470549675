<template lang="html">
  <div class="automatic-settings">
    <VueSelectize
      v-model="waterLevel" :options="waterLevels"
      required
      allow-empty ordered
      :fields="waterLevelFieldConfig"
      name="waterLevel"
    >
      <template #label>
        <IxRes>Areas.MaizeSowing.SR_MaizeSowing.WaterLevel</IxRes>
      </template>
    </VueSelectize>

    <VueSelectize
      v-model="soilQuality" :options="soilQualities"
      required
      allow-empty ordered
      :fields="soilQualityFieldConfig"
      name="soilQuality"
    >
      <template #label>
        <IxRes>Areas.MaizeSowing.SR_MaizeSowing.SoilQuality</IxRes>
      </template>
    </VueSelectize>

    <VueSelectize
      v-model="usage" :options="usages"
      required
      allow-empty ordered
      :fields="usageFieldConfig"
      name="usage"
    >
      <template #label>
        <IxRes>Areas.MaizeSowing.SR_MaizeSowing.Usage</IxRes>
      </template>
    </VueSelectize>
  </div>
</template>

<script>
import {waterLevels, soilQualities, usages} from 'src/js/data/maize-sowing'
import {mapResources, mapFormFields} from '@helpers/vuex'

import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    VueSelectize
  },
  computed: {
    ...mapResources([
      '@ms.SR_MaizeSowing'
    ]),
    waterLevels () {
      return waterLevels
    },
    soilQualities () {
      return soilQualities
    },
    usages () {
      return usages
    },
    ...mapFormFields('fieldRecordSystem/maizeSowing', [
      'parameters.waterLevel',
      'parameters.soilQuality',
      'parameters.usage'
    ]),
    waterLevelFieldConfig () {
      return {
        text: value => this.SR_MaizeSowing[`WaterLevel_${value}`]
      }
    },
    soilQualityFieldConfig () {
      return {
        text: value => this.SR_MaizeSowing[`SoilQuality_${value}`],
        label: value => this.SR_MaizeSowing[`SoilQuality_${value}_label`]
      }
    },
    usageFieldConfig () {
      return {
        text: value => this.SR_MaizeSowing[`Usage_${value}`]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
