<template>
  <div class="action-grid">
    <LegacyGrid
      v-model="selectedRows"
      :data="actionsFiltered"
      :columns="actionColumns"
      selection="multiple"
      add-button change-state-button
      :edit-button="selectedActions.length <= 1 && !selectedActions.some(x => x.usesExperimentalFinanceIntegration)" delete-button
      :title="title" :custom-buttons="customButtons"
      @add="$emit('add')" @state="$emit('state')"
      @edit="$emit('edit', selectedActions)"
      @delete="showDeletionConfirmationModal = true"
    />
    <ConfirmationModal v-model="showDeletionConfirmationModal" title="Maßnahmen entfernen" @confirm="$emit('delete', selectedActions)">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteSelectedActionsQuestion</IxRes>
    </ConfirmationModal>
    <ActionStatusModal v-model="showActionStatusModal" :actions="selectedActions" for-org-unit />

    <ProductQuantityGrid :data="productQuantitiesFiltered" />
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import moment from 'moment'
import {flattenDeep} from 'lodash'

import {mapResources} from '@helpers/vuex'
import {computeSize} from '@helpers/openlayers/computation'

import ConfirmationModal from '@components/modals/ConfirmationModal'
import ActionStatusModal from './ActionStatusModal'
import LegacyGrid from '@components/grid/LegacyGrid'

import ProductQuantityGrid from './ProductQuantityGrid'

import {getTypeKey, getProcedureKey} from '@frs/components/action/translation-key-helper'

export default {
  components: {
    ConfirmationModal,
    ActionStatusModal,
    LegacyGrid,
    ProductQuantityGrid
  },
  props: {
    actions: Array,
    title: String
  },
  data () {
    return {
      selectedRows: [],
      showDeletionConfirmationModal: false,
      showActionStatusModal: false,
      combinedActionFilter: null,
      combinedProductQuantityFilter: null,
      collapseActionFilter: false,
      collapseCompanyData: false
    }
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem',
      '@em.SR_EntityManagement'
    ]),
    ...mapGetters('masterData', [
      'products'
    ]),
    ...mapState('fieldRecordSystem', {
      machines: state => state.machines.data.machines,
      persons: state => state.persons.data.persons
    }),
    ...mapGetters('fieldRecordSystem', [
      'entityNameLookup'
    ]),
    customButtons () {
      return [
        {
          id: '1',
          name: this.SR_EntityManagement.EditState,
          icon: 'fa fa-exchange',
          action: () => {
            if (this.selectedRows.length) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.showActionStatusModal = true
            }
          }
        }
      ]
    },
    actionColumns () {
      return [
        {localizedTitle: this.SR_FieldRecordSystem.Date, typeName: 'String', propertyName: 'dateTime'},
        {localizedTitle: this.SR_FieldRecordSystem.Action, typeName: 'String', propertyName: 'type'},
        {localizedTitle: this.SR_FieldRecordSystem.Procedure, typeName: 'String', propertyName: 'procedure'},
        {localizedTitle: this.SR_FieldRecordSystem.Fields, typeName: 'String', propertyName: 'fields'},
        {localizedTitle: this.SR_FieldRecordSystem.Machines, typeName: 'String', propertyName: 'machines'},
        {localizedTitle: this.SR_FieldRecordSystem.Personnel, typeName: 'String', propertyName: 'personnel'},
        {localizedTitle: this.SR_FieldRecordSystem.Notes, typeName: 'String', propertyName: 'notes'},
        {localizedTitle: this.SR_FieldRecordSystem.ActionStatus, typeName: 'String', propertyName: 'status'}
      ]
    },
    actionsFiltered () {
      const persons = this.persons
      if (!this.actions) return []
      return this.actions.filter(this.combinedActionFilter || (() => true)).map(action => {
        return {
          id: action.id,
          dateTime: moment(action.timestamps.planned.start).format('LL'),
          type: action.planningParts.map(part => this.$i18n.translate(getTypeKey(part))).filter(x => x).join(', '),
          procedure: action.planningParts.filter(part => part.procedure).map(part => this.$i18n.translate(`${getProcedureKey(part)}`)).join(', '),
          fields: this.entityNameLookup[action.fieldId] || null,
          machines: action.machines.map(m => {
            const machine = this.machines.find(x => m === x.id)
            return machine.name
          }).join(', '),
          personnel: action.personnel.map(p => {
            const person = persons.find(x => p === x.id)
            return person.name
          }).join(', '),
          notes: action.notes,
          status: this.status(action.timestamps)
        }
      })
    },
    productQuantitiesForActions () {
      if (!this.actions) return []

      const actions = this.selectedActions.length
        ? this.selectedActions
        : this.actions

      const addActionIdToQuantitiesMapper = (action) => {
        return action.planningParts.map(part => {
          return part.productQuantities.map(quan => {
            quan.actionId = action.id
            return quan
          })
        })
      }

      const quantitiesWithActionId = actions.map(addActionIdToQuantitiesMapper)

      return flattenDeep(quantitiesWithActionId)
    },
    productQuantitiesFiltered () {
      const allProductQuantities = this.productQuantitiesForActions.filter(this.combinedProductQuantityFilter || (() => true))

      const totalProductQuantities = {}

      for (const productQuantity of allProductQuantities) {
        const {productId, unit} = productQuantity

        const product = this.products[productId]
        if (product === undefined) continue

        const {name, id, type} = product

        const totalQuantity = this.computeQuantity(productQuantity)
        const numerator = unit ? this.stripDenominator(unit) : ''

        if (!totalProductQuantities[id]) {
          totalProductQuantities[id] = {
            product: {
              name,
              id,
              type,
              numerator
            },
            quantity: {
              value: 0
            }
          }
        }

        totalProductQuantities[id].quantity.value += totalQuantity
      }

      return Object.values(totalProductQuantities)
    },
    selectedActions () {
      return this.selectedRows.map(row => this.actions.find(x => x.id === row.id))
    }
  },
  methods: {
    status (timestamps) {
      return timestamps.actual.end
        ? this.SR_FieldRecordSystem.ActionStatusFinished
        : timestamps.actual.start
          ? this.SR_FieldRecordSystem.ActionStatusStarted
          : this.SR_FieldRecordSystem.ActionStatusPlanned
    },
    status2 (timestamps) {
      if (timestamps.completed) {
        return this.SR_FieldRecordSystem.Completed
      }
      if (timestamps.started) {
        return this.SR_FieldRecordSystem.Started
      }
      if (timestamps.planned) {
        return this.SR_FieldRecordSystem.Planned
      }
      return this.SR_FieldRecordSystem.Planned
    },
    clearSelection () {
      this.selectedRows = []
    },
    computeQuantity (productQuantity) {
      const actions = this.actions.filter(x => x.id === productQuantity.actionId)
      const areas = actions.map(action => computeSize(action.geometry))
      const area = areas.reduce((total, area) => total + area, 0)
      return area / 10000 * productQuantity.amount
    },
    stripDenominator (unit) {
      const index = unit.indexOf('/')
      return unit.substring(0, index)
    }
  },
  watch: {
    actions () {
      this.clearSelection()
    },
    selectedRows () {
      this.$emit('selected', this.selectedActions)
    }
  }
}

</script>

<style lang="scss" scoped>
.action-grid {
  padding: 5px;
}
</style>
