<template lang="html">
  <SimpleTable :columns="columns" :data="data" localize-headers>
    <strong slot="type" slot-scope="{value}">{{ value }}</strong>
  </SimpleTable>
</template>

<script>
import {mapGetters} from 'vuex'

import SimpleTable from '@components/table/SimpleTable'

import columns from './nutrient-columns'

export default {
  components: {
    SimpleTable
  },
  props: {
    action: Object,
    nutrientStatus: Object
  },
  computed: {
    ...mapGetters('fieldRecordSystem', [
      'entityNameLookup'
    ]),
    columns () {
      return {
        type: '',
        ...columns
      }
    },
    data () {
      return this.action.fieldIds.map((fieldId) => {
        const nutrients = this.nutrientStatus[fieldId].data.find(columns => columns.type === 'remaining')
        return Object.assign({}, nutrients, {type: this.entityNameLookup[fieldId]})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
