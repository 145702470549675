<template lang="html">
  <div class="split-tools">
    <IxButton
      large
      :disabled="!canMakeNewFields || working"
      @click="split"
    >
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NewFieldFromSelectedGeometry</IxRes>
    </IxButton>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'
import {mapState, mapGetters, mapActions} from 'vuex'

import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton
  },
  data () {
    return {
      working: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/map', {
      activeFeatures: state => state.editing.activeFeatures,
      selectedIds: state => state.editing.selectedIds
    }),
    ...mapFormFields('fieldRecordSystem/fieldDetails', [
      'ui.saving'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'fieldName'
    ]),
    ...mapGetters('fieldRecordSystem/map', [
      'selectedFeatures'
    ]),
    canMakeNewFields () {
      return this.activeFeatures.length && this.activeFeatures.every(feature => feature.geometry.type === 'Polygon')
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/fieldDetails', [
      'splitField'
    ]),
    async split () {
      var geometries = this.activeFeatures.map(x => x.geometry)

      if (geometries.length < 2) throw new Error('cannot split without more than one geometry')

      // TODO maybe block not just split button but full sidebar
      this.working = true
      this.saving = true

      try {
        await this.splitField(geometries)

        notifications.success(this.$i18n.translate('frs.fields.notifications.splitSuccess'))
      } catch (error) {
        console.error(error)
        notifications.error(this.$i18n.translate('frs.fields.notifications.splitError'))
      } finally {
        this.working = false
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
