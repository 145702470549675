<template lang="html">
  <div>
    <WizardHeader :title="stepData.title" :action="stepData.action">
      <template #subTitle>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ stepData.title }}</IxRes>
      </template>
      <template v-if="stepData.action" #description>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ stepData.action }}</IxRes>
      </template>
    </WizardHeader>

    <component :is="stepData.component" :key="wizardStep" />
    <FrsLoadingIndicator :loading="loading" />
    <WizardFooter />
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import StepNitrogenRequirement from './wizard/StepNitrogenRequirement'
import StepPhosphorusRequirement from './wizard/StepPhosphorusRequirement'
import ViewRequirement from './ViewRequirement'
import WizardFooter from './wizard/widgets/WizardFooter'
import WizardHeader from './wizard/widgets/WizardHeader'
import NutrientRequirementCorrection from './NutrientRequirementCorrection'
import WizardResultPage from './wizard/WizardResultPage'

import ReloadDataOnFieldChange from '@frs/mixins/ReloadDataOnFieldChange'

export default {
  components: {
    StepPhosphorusRequirement,
    StepNitrogenRequirement,
    ViewRequirement,
    WizardResultPage,
    NutrientRequirementCorrection,
    WizardFooter,
    WizardHeader,
    FrsLoadingIndicator
  },
  mixins: [
    ReloadDataOnFieldChange
  ],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/nutrientRequirement', {
      wizardStep: state => state.ui.wizardStep
    }),
    stepData () {
      switch (this.wizardStep) {
      case 'stepNitrogen': return {
        title: 'NutrientRequirementStep1',
        action: 'NutrientRequirementActionInfo',
        component: StepNitrogenRequirement
      }
      case 'stepPhosphorus': return {
        title: 'NutrientRequirementStep2',
        action: 'NutrientRequirementActionInfo',
        component: StepPhosphorusRequirement
      }
      case 'result': return {
        title: 'NutrientRequirementAnalyzing',
        action: null,
        component: WizardResultPage
      }
      case 'correction': return {
        title: 'ManualNutrientRequirementAdditions',
        action: 'FormRequiredHint',
        component: NutrientRequirementCorrection
      }
      case 'overview': return {
        title: 'NutrientRequirementAnalyzing',
        action: null,
        component: ViewRequirement
      }
      default: throw new Error(`invalid step: '${this.wizardStep}'`)
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/nutrientRequirement', [
      'setStep'
    ]),
    ...mapMutations('fieldRecordSystem/nutrientRequirement/editBuffer', [
      'set'
    ]),
    ...mapActions('fieldRecordSystem/nutrientRequirement', [
      'back',
      'next',
      'cancel',
      'getNutrientRequirement'
    ])
  },
  reloadData (firstLoad) {
    if (firstLoad) return

    this.loading = true

    return Promise.all([
      this.getNutrientRequirement()
    ])
      .then(() => {
        this.loading = false
      })
  }
}

</script>

<style lang="css">
</style>
