<template lang="html">
  <CollapsibleSection v-model="showDetails">
    <template #header>
      <IxRes>frs.mapManagement.zoneMap.details.title</IxRes>
    </template>

    <small v-if="metaData" :class="$style.schema">
      <IxRes :context="{schema: metaData.$schema}">frs.mapManagement.zoneMap.schemaVersion</IxRes>
    </small>

    <component :is="metaDataComponent" :meta-data="metaData" />

    <small v-if="metaData && metaData.serviceVersion" :class="$style.schema">
      {{ metaData.serviceVersion }}
    </small>
  </CollapsibleSection>
</template>

<script>
import HelpBox from '@components/help/HelpBox'
import CollapsibleSection from '@components/CollapsibleSection'

import ZoneMapDetailsPanel from './ZoneMapDetailsPanel'
import ZoneMapDetailsNdviLineChart from './ZoneMapDetailsNdviLineChart'

import MissingMetaData from './meta-data/MissingMetaData'
import UnsupportedMetaData from './meta-data/UnsupportedMetaData'
import LegacyMetaData from './meta-data/zone-map/LegacyMetaData'
import AutoMetaData from './meta-data/zone-map/AutoMetaData'
import PremiumMetaData from './meta-data/zone-map/PremiumMetaData'
import BiomassMetaData from './meta-data/zone-map/BiomassMetaData'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    ZoneMapDetailsNdviLineChart,
    ZoneMapDetailsPanel,
    HelpBox,
    CollapsibleSection
  },
  mixins: [
    PermissionMixin
  ],
  props: {
    metaData: Object
  },
  data () {
    return {
      showDetails: false
    }
  },
  computed: {
    metaDataComponent () {
      switch (this.metaData.$schema) {
      // TODO find out which versions actually work
      case 'https://cdn.ixmap.eu/file_data/schemas/zonemaps/metadata/1.0.0/legacy.json':
      case 'https://cdn.ixmap.eu/file_data/schemas/zonemaps/metadata/1.1.0/legacy.json':
      case 'https://cdn.ixmap.eu/file_data/schemas/zonemaps/metadata/1.2.0/legacy.json':
      case 'https://cdn.ixmap.eu/file_data/schemas/zonemaps/metadata/1.3.0/legacy.json':
      case 'https://cdn.ixmap.eu/file_data/schemas/zonemaps/metadata/1.4.0/legacy.json':
      case 'https://cdn.ixmap.eu/file_data/schemas/zonemaps/metadata/1.4.0/sentinel.json':
        return LegacyMetaData
      case 'https://cdn.ixmap.eu/file_data/schemas/zonemaps/metadata/2.0.0/auto.json':
      case 'https://cdn.ixmap.eu/file_data/schemas/zonemaps/metadata/2.1.0/auto.json':
      case 'https://cdn.ixmap.eu/file_data/schemas/zonemaps/metadata/2.2.0/auto.json':
        return AutoMetaData
      case 'https://cdn.ixmap.eu/file_data/schemas/zonemaps/metadata/2.0.0/premium.json':
        return PremiumMetaData
      case 'https://cdn.ixmap.eu/file_data/schemas/zonemaps/metadata/2.0.0/biomass.json':
        return BiomassMetaData
      case null:
        return MissingMetaData
      default:
        return UnsupportedMetaData
      }
    }
  }
}
</script>

<style lang="scss" module>
.schema {
  opacity: 0.3;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
}
</style>
