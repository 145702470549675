<template lang="html">
  <ZoneMapDetailsPanel>
    <template #header>
      <IxRes>frs.zoneMaps.details.zonelegend</IxRes>
    </template>

    <MapPaletteSelector
      v-if="$can('See.Frs.ZoneMap.Details.ZonesLegend')"
      v-model="zoneMapPalette"
      :options="zoneMapPalettes"
      :map="zoneMap"
    />
    <FrsZoneMapLayerLegend :zone-map-id="zoneMap.id" />
    <ZoneMapGradientScale :map="zoneMap" :palette="zoneMapPalette" />
  </ZoneMapDetailsPanel>
</template>

<script>
import {mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import FrsZoneMapLayerLegend from '@frs/components/base/FrsZoneMapLayerLegend'
import MapPaletteSelector from '@components/MapPaletteSelector'

import ZoneMapDetailsPanel from './ZoneMapDetailsPanel'
import ZoneMapGradientScale from './widgets/ZoneMapGradientScale'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    ZoneMapDetailsPanel,
    FrsZoneMapLayerLegend,
    MapPaletteSelector,
    ZoneMapGradientScale
  },
  mixins: [
    PermissionMixin
  ],
  props: {
    zoneMap: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/mapManagement', [
      'ui.zoneMapPalette'
    ]),
    ...mapState('fieldRecordSystem/mapManagement', {
      zoneMapPalettes: state => state.ui.zoneMapPalettes
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
