<template lang="html">
  <div>
    <div class="row">
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="sampleId">
          <IxRes>bf.resultImport.selector.sampleId</IxRes>
        </ColumnMappingDropdown>
      </div>
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="fieldName">
          <IxRes>bf.resultImport.selector.fieldName</IxRes>
        </ColumnMappingDropdown>
      </div>
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="SoilSubType">
          <IxRes>bf.resultImport.selector.soilSubType</IxRes>
          <template #description>
            <IxRes>bf.resultImport.selector.soilSubType.description</IxRes>
          </template>
        </ColumnMappingDropdown>
      </div>
    </div>
    <div class="row flex">
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="phosphorusValue" required-nutrient="phosphorus">
          <IxRes>bf.resultImport.selector.phosphorusValue</IxRes>
        </ColumnMappingDropdown>
      </div>
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="phosphorusClassification" required-nutrient="phosphorus" :disabled="ignoreClassification">
          <IxRes>bf.resultImport.selector.phosphorusContentClass</IxRes>
        </ColumnMappingDropdown>
      </div>
      <div class="col-md-4 flex-align-center">
        <OxideSwitch nutrient="phosphorus" />
      </div>
    </div>
    <div class="row flex">
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="potassiumValue" required-nutrient="potassium">
          <IxRes>bf.resultImport.selector.potassiumValue</IxRes>
        </ColumnMappingDropdown>
      </div>
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="potassiumClassification" required-nutrient="potassium" :disabled="ignoreClassification">
          <IxRes>bf.resultImport.selector.potassiumContentClass</IxRes>
        </ColumnMappingDropdown>
      </div>
      <div class="col-md-4 flex-align-center">
        <OxideSwitch nutrient="potassium" />
      </div>
    </div>
    <div class="row flex">
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="magnesiumValue" required-nutrient="magnesium">
          <IxRes>bf.resultImport.selector.magnesiumValue</IxRes>
        </ColumnMappingDropdown>
      </div>
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="magnesiumClassification" required-nutrient="magnesium" :disabled="ignoreClassification">
          <IxRes>bf.resultImport.selector.magnesiumContentClass</IxRes>
        </ColumnMappingDropdown>
      </div>
      <div class="col-md-4 flex-align-center">
        <OxideSwitch nutrient="magnesium" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="phValue" required-nutrient="ph">
          <IxRes>bf.resultImport.selector.phValue</IxRes>
        </ColumnMappingDropdown>
      </div>
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="phClassification" required-nutrient="ph" :disabled="ignoreClassification">
          <IxRes>bf.resultImport.selector.phContentClass</IxRes>
        </ColumnMappingDropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="limeRecommendation" required-nutrient="lime">
          <IxRes>bf.resultImport.selector.limeRecommendation</IxRes>
        </ColumnMappingDropdown>
      </div>
    </div>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'

import ColumnMappingDropdown from './ColumnMappingDropdown'
import OxideSwitch from './OxideSwitch'

export default {
  components: {
    ColumnMappingDropdown,
    OxideSwitch
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/basicFertilization/resultImport', [
      'ignoreClassification'
    ])
  }
}
</script>

<style lang="scss" scoped>
.flex {
  display:flex;
}
.flex-align-center {
  align-self: center;
}
</style>
