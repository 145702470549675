import {mapState} from 'vuex'

import parse from 'wellknown'
import {zonemap} from '@frs/map-styles'
import {getZoneCount} from '@helpers/zone-map'

export default {
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      zoneMapLookup: state => state.data.zoneMap.mapLookup
    }),
    ...mapState('fieldRecordSystem/basicFertilization', {
      simplifiedGeometries: state => state.data.simplifiedGeometries
    }),
    zoneMapFeatures () {
      const makePlaceholderFeature = fieldId => fieldId ? ({
        geometry: this.simplifiedGeometries[fieldId],
        type: 'Feature',
        properties: {
          placeholder: this.SR_FieldRecordSystem['NoZoneMap']
        }
      }) : []

      const zoneMapFeatures = this.zoneMapIds.reduce((features, zoneMapId) => {
        const zoneMap = this.zoneMapLookup[zoneMapId]

        if (!zoneMap || !zoneMap.zones) {
          const fieldId = this.fieldIdByZoneMapId && this.fieldIdByZoneMapId[zoneMapId]

          if (!fieldId) return features

          return features.concat(makePlaceholderFeature(fieldId))
        }

        const zoneCount = getZoneCount(zoneMap)

        const zoneToFeature = ({geometry, number: zone}) => ({
          geometry: parse(geometry),
          type: 'Feature',
          properties: {
            zone,
            zoneCount,
            id: this.SR_FieldRecordSystem[`Zone_${zone}`]
          }
        })

        return features.concat(zoneMap.zones.map(zoneToFeature))
      }, [])

      return zoneMapFeatures
    },
    zoneMapStyle () {
      return zonemap
    }
  },
  created () {
    if (!this.SR_FieldRecordSystem) {
      throw new Error('[ZoneMapDisplayMixin] SR_FieldRecordSystem missing')
    }
    if (!this.zoneMapIds) {
      throw new Error('[ZoneMapDisplayMixin] zoneMapIds computed missing')
    }
  }
}
