<template>
  <div class="content-class-legend">
    <label>
      <IxRes>bf.nutrientMaps.labels.contentClassLegend</IxRes>:
    </label>
    <div class="legend-container">
      <div v-for="classification in classifications" :key="classification" class="legend-row">
        <div class="legend-box" :style="{backgroundColor: colors[classification]}" />

        <div class="text">
          <span class="classification">{{ classification }} - </span>
          <IxRes class="classification-label">
            bf.nutrientMaps.labels.contentClass_{{ classification }}
          </IxRes>
          <span v-if="areas[classification]" class="area">({{ $i18n.format(areas[classification], 'area') }})</span>
        </div>
      </div>

      <div class="legend-row">
        <div class="legend-box no-data" />

        <div class="text">
          <IxRes>bf.nutrientMaps.labels.contentClasses_noData</IxRes>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import colors from '@frs/map-styles/basic-fertilization/colors'

export default {
  props: {
    areas: {
      type: Object, // {[contentClass]: area}
      default: () => ({})
    },
    nutrient: String
  },
  computed: {
    colors () {
      return colors.classification
    },
    classifications () {
      return this.nutrient !== 'ph'
        ? Object.keys(this.colors).slice(0, -1)
        : Object.keys(this.colors)
    }
  }
}
</script>

<style lang="scss" scoped>
.legend-container {
  display: flex;
  flex-direction: column;
}

.legend-row {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.legend-box {
  border: 1px black solid;
  margin-right: 6px;
  width: 40px;
  height: 20px;
}

.no-data {
  background-color: lightgrey;
}
</style>
