<template>
  <div style="position: relative;">
    <div v-if="applicationMap" class="post-processing-container">
      <div class="product-container">
        <FrsProductPicker
          v-model="productId" name="productId"
          type="fertilizer"
          class="product-container"
          required
        >
          <template #label>
            <IxRes>bf.postProcessing.label.productId</IxRes>
          </template>
        </FrsProductPicker>

        <div class="previous-fertilization">
          <NumericInput
            :value="previousFertilization"
            :addon="applicationMap.nutrient === 'ph' ? $i18n.translateUnit('dt/ha') : $i18n.translateUnit('kg/ha')"
            :rule="min(0)"
            name="previousFertilization"
            @input="setPreviousFertilization"
          >
            <template #label>
              <IxRes>bf.applicationMaps.preview.labels.previousFertilization</IxRes> (Reinnährstoff):
            </template>
          </NumericInput>
        </div>

        <div class="value-mode">
          <label>
            <IxRes>bf.postProcessing.label.valueMode</IxRes>:
          </label>
          <SimpleSwitch v-model="isApplicationValue">
            <template #offLabel>
              <IxRes>bf.postProcessing.label.pureNutrient</IxRes>
            </template>
            <template #default>
              <IxRes>bf.postProcessing.label.applicationValue</IxRes>
            </template>
          </SimpleSwitch>
        </div>

        <div v-if="selectedProduct" class="product-info">
          <HelpBox>
            <label><IxRes>bf.postProcessing.helpBoxes.label.productDetails</IxRes>:</label>
            <ul>
              <li v-for="type in productNutrientTypes" :key="type">
                <IxRes :context="{type}">bf.postProcessing.helpBoxes.productDetails.nutrient.{{ type }}</IxRes>:
                {{ productValue(type) }}
              </li>
            </ul>
          </HelpBox>
        </div>
        <div class="help-box">
          <HelpBox v-if="isApplicationValue && !productFactor" type="warning">
            <IxRes>bf.postProcessing.productFactor.display.empty</IxRes>
          </HelpBox>
        </div>
      </div>

      <div class="application-values">
        <ApplicationValueDisplay />
      </div>

      <div class="total-sum">
        <FrsApplicationMapTotalSum :map="valueAdjustedMap" />
      </div>

      <IxVectorLayer
        :features="features"
        :vector-style="style"
        :z-index="1"
        layer-id="applicationMap"
      />
    </div>
    <div v-else>
      <HelpBox>
        <IxRes>bf.applicationMapPreview.hints.applicationMapSelection</IxRes>
      </HelpBox>
    </div>
    <FrsLoadingIndicator small :requests="['applicationMapPreview.details']" />
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import {mapFormFields} from '@helpers/vuex'
import {mapToGeoJsonFeatures, mapToStyle} from '@helpers/map-color-schemes'

import ApplicationValueDisplay from './ApplicationValueDisplay'

import FrsProductPicker from '@frs/components/base/FrsProductPicker'
import FrsApplicationMapTotalSum from '@frs/components/base/FrsApplicationMapTotalSum'

import NumericInput from '@components/forms/NumericInput'
import IxVectorLayer from '@components/map/IxVectorLayer'
import SimpleSwitch from '@components/forms/SimpleSwitch'
import HelpBox from '@components/help/HelpBox'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

export default {
  components: {
    ApplicationValueDisplay,
    FrsProductPicker,
    FrsApplicationMapTotalSum,
    IxVectorLayer,
    NumericInput,
    SimpleSwitch,
    HelpBox,
    FrsLoadingIndicator
  },
  mixins: [
    StandardRulesMixin
  ],
  data () {
    return {
      productNutrientTypes: ['phosphorus', 'potassium', 'magnesium', 'lime']
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/applicationMapsPreview', {
      applicationMap: state => state.ui.applicationMap,
      previousFertilization: state => state.ui.previousFertilization
    }),
    ...mapFormFields('fieldRecordSystem/basicFertilization/applicationMapsPreview', [
      'ui.productId',
      'ui.isApplicationValue'
    ]),
    ...mapGetters('fieldRecordSystem/basicFertilization/applicationMapsPreview', [
      'selectedProduct',
      'productFactor',
      'manuallyAdjustedValues'
    ]),
    valueAdjustedMap () {
      if (!this.applicationMap) return {}

      return {
        ...this.applicationMap,
        zones: this.applicationMap.zones.map(zone => ({
          ...zone,
          value: this.manuallyAdjustedValues[zone.id]
        }))
      }
    },
    features () {
      if (!Object.keys(this.applicationMap).length) return []

      return mapToGeoJsonFeatures(this.applicationMap, 'agrosat')
    },
    style () {
      return mapToStyle(this.applicationMap, 'agrosat')
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/applicationMapsPreview', [
      'setPreviousFertilization'
    ]),
    productValue (type) {
      return this.selectedProduct[type] ? `${this.$i18n.format(this.selectedProduct[type], 'f2')}` : this.$i18n.translate('Common.SR_Common.noInformation')
    }
  }
}
</script>

<style lang="scss" scoped>
.post-processing-container {
  display: grid;
  grid-template-areas:
    "product values values"
    ". . total";
  grid-template-columns: 1.5fr 1fr 1fr;
  gap: 1rem 1rem;
}

.product-container {
  grid-area: product;
  margin-top: 10px;

  .previous-fertilization {
    margin-top: 5px;
  }

  .value-mode {
    margin-top: 5px;
  }

  .product-info {
    ul {
      list-style: none;
    }
  }
}

.application-values {
  grid-area: values;
}

.total-sum {
  grid-area: total;
}
</style>
