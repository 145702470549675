<template>
  <div>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.zoneMap</IxRes>
      </template>
      <div>{{ metaData.zoneMap.name }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.label.product</IxRes>
      </template>
      <div>{{ product.name }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.unit</IxRes>
      </template>
      <div>{{ applicationMap.unit }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.applicationMaps.labels.algorithmSelection</IxRes>
      </template>
      <div>{{ metaData.algorithmName }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.applicationMaps.parameters.labels.estimatedPrecipitation</IxRes>
      </template>
      <div>{{ metaData.parameters.estimatedPrecipitation }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.applicationMaps.parameters.labels.minSoilMoisture</IxRes>
      </template>
      <div>{{ metaData.parameters.minSoilMoisture }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.applicationMaps.parameters.labels.maxSoilMoisture</IxRes>
      </template>
      <div>{{ metaData.parameters.maxSoilMoisture }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.detailsSeedExpert.labels.productCorrectionFactor</IxRes>
      </template>
      <div>{{ metaData.parameters.productCorrectionFactor }}</div>
    </LabelAndMessage>

    <strong>
      <IxRes>frs.mapManagement.detailsSeedExpert.header.zoneMapValues</IxRes>
    </strong>
    <div v-for="zone in metaData.parameters.zones" :key="zone.zoneId">
      {{ `${zoneTranslation} ${zone.zoneId}` + ': ' }}
      <span class="zone-info">
        {{ `${acresRatioTranslation}: ${zone.acresRatio} ` + ' ' }}
      </span>
      <span class="zone-info">
        {{ `${pwcTranslation}: ${zone.pwc}` }}
      </span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    LabelAndMessage
  },
  props: {
    applicationMap: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('masterData', [
      'products'
    ]),
    metaData () {
      return this.applicationMap.metaData
    },
    product () {
      return this.products[this.applicationMap.productId]
    },
    zoneTranslation () {
      return this.$i18n.translate('frs.mapManagement.detailsSeedExpert.zone')
    },
    acresRatioTranslation () {
      return this.$i18n.translate('frs.mapManagement.detailsSeedExpert.acresRatio')
    },
    pwcTranslation () {
      return this.$i18n.translate('frs.mapManagement.detailsSeedExpert.pwc')
    }
  }
}
</script>
<style lang="scss" scoped>
.zone-info{
  margin-left: 5px;
}
</style>
