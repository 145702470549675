<template>
  <div class="navigation-header-container">
    <div class="navigation-buttons">
      <IxButton v-if="!hideBackButton && $slots.back" back @click="$emit('back')">
        <slot name="back" />
      </IxButton>

      <IxButton @click="navigateToStartPage">
        <FontAwesomeIcon :icon="icon.home" />
        <IxRes>bf.navigation.backToMainMenu</IxRes>
      </IxButton>
    </div>

    <h4 v-if="$slots.step">
      <slot name="step" />
    </h4>

    <template v-if="$slots.hint">
      <slot name="hint" />
    </template>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faHome, faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons'

import IxButton from '@components/IxButton'

export default {
  components: {
    FontAwesomeIcon,
    IxButton
  },
  props: {
    hideBackButton: Boolean
  },
  computed: {
    icon () {
      return {
        home: faHome,
        back: faArrowCircleLeft
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization', [
      'navigateToStartPage'
    ])
  }
}
</script>

<style scoped lang="scss">
.navigation-header-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  .navigation-buttons {
    display: flex;
    justify-content: space-between;
  }
}
</style>
