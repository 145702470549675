<template lang="html">
  <div class="space">
    <ListCard @click.native="$emit('onCardClick', infoCardDetails)">
      <span slot="title">
        {{ infoCardDetails.number }}  -  {{ infoCardDetails.name }}
      </span>
      <span slot="addon" class="removeBtn">
        <a @click.stop.prevent="$emit('removeInfoCard', infoCardDetails)"> <i class="fa fa-trash" /></a>
      </span>
    </ListCard>
  </div>
</template>

<script>
import ListCard from '@components/card/ListCard'

export default {
  components: {
    ListCard
  },
  props: {
    infoCardDetails: Object
  }
}
</script>

<style lang="scss" scoped>
.space {
  position: relative;
  margin-bottom: 5px;
}
.removeBtn {
  margin-top: 4px;
}
</style>
