<template>
  <span :class="{hasKey: !hasKey}">
    <i v-if="!hasKey" class="fa fa-question-circle" /> {{ value }}
  </span>
</template>

<script>
export default {
  props: {
    features: Array,
    selectedKey: String
  },
  computed: {
    value () {
      return this.features[0].get(this.selectedKey)
    },
    hasKey () {
      return !(!this.value || this.value === undefined || this.value === '')
    }
  }
}
</script>

<style lang="scss" scoped>
.hasKey {
  color: red;
}
</style>
