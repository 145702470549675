<template lang="html">
  <span>
    <SatelliteImageReference :image="image" />
    <span v-if="statistics">
      <IxRes
        :context="{
          stDev: $i18n.format(statistics, 'f2')
        }"
      >
        frs.zoneMap.details.image.standardDeviation
      </IxRes>
    </span>
  </span>
</template>

<script>
import SatelliteImageReference from './SatelliteImageReference'

export default {
  components: {
    SatelliteImageReference
  },
  props: {
    image: {
      type: Object,
      required: true
    },
    statistics: null
  },
  computed: {

  }
}
</script>

<style lang="scss" module>

</style>
