<template lang="html">
  <div>
    <FrsHeader :sub-title="title" :description="action">
      <slot slot="title" name="title" />
      <slot slot="subTitle" name="subTitle" />
      <slot slot="description" name="description" />
    </FrsHeader>
    <div class="header-buttons">
      <IxButton
        v-if="wizardStep === 'stepPhosphorus'" large
        back
        @click="back"
      >
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.BackToNRequirement</IxRes>
      </IxButton>
      <IxButton
        v-if="wizardStep === 'result'" large
        back
        @click="back"
      >
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.BackToPRequirement</IxRes>
      </IxButton>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

import IxButton from '@components/IxButton'
import FrsHeader from '@frs/components/base/FrsHeader'

export default {
  components: {
    IxButton,
    FrsHeader
  },
  props: {
    title: {}, // TODO find out correct type
    action: {} // TODO find out correct type
  },
  computed: {
    ...mapState('fieldRecordSystem/nutrientRequirement', {
      wizardStep: state => state.ui.wizardStep
    })
  },
  methods: {
    ...mapActions('fieldRecordSystem/nutrientRequirement', [
      'back'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
