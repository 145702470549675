<template lang="html">
  <BsRadioList v-model="modelProxy" :options="nutrients">
    <template #label>
      <IxRes>bf.nutrientMaps.labels.nutrientSelector</IxRes>:
    </template>
    <template #default="{option: nutrient}">
      <IxRes>bf.labels.nutrient_{{ nutrient }}</IxRes>
    </template>
  </BsRadioList>
</template>

<script>
import {uniq} from 'lodash'

import {modelProxy} from '@helpers/vuex'

import BsRadioList from '@components/bootstrap/BsRadioList'

export default {
  components: {
    BsRadioList
  },
  props: {
    value: String,
    nutrientMaps: Array
  },
  computed: {
    modelProxy,
    nutrients () {
      return uniq(this.nutrientMaps.map(x => x.nutrient)).sort()
    },
    options () {
      return this.nutrients
    }
  },
  watch: {
    nutrientMaps: {
      immediate: true,
      handler () {
        if (!this.value || !this.nutrients.includes(this.value)) {
          this.modelProxy = this.nutrients[0] || null
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
