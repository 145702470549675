<template>
  <div class="input-group">
    <div>
      <SimpleSwitch v-model="isCorn">
        <IxRes>frs.actions.seeding.maize</IxRes>
      </SimpleSwitch>

      <LabelAndMessage>
        <template #label>
          <IxRes>frs.actions.seeding.SeedProduct</IxRes>
        </template>
        <FrsProductPicker
          v-model="productId"
          type="seed"
          :available-product-filter="availableProductFilter"
        />
      </LabelAndMessage>

      <LabelAndMessage v-if="mixtureComponents && mixtureComponents.length > 0">
        <template #label>
          <IxRes>frs.actions.seeding.MixtureComponents</IxRes>
        </template>
        <FrsMixtureTable :mixture-components="mixtureComponents" />
      </LabelAndMessage>

      <NumericInput
        ref="inputPlantsPerSqm" v-model="plantsPerSqm"
        :rule="plantsPerSqmRule"
        name="plantsPerSqm" :addon="'pro m²'"
        hide-description @input="wasComputed.plantsPerSqm = false"
      >
        <template #label>
          <IxRes>frs.actions.seeding.PlantsPerSqm</IxRes>
        </template>
      </NumericInput>
      <div v-if="isCorn">
        <!-- corn -->
        <NumericInput
          v-model="rowDistance" name="rowDistance"
          unit="cm" hide-description
        >
          <template #label>
            <IxRes>frs.actions.seeding.RowDistance</IxRes>
          </template>
        </NumericInput>
        <NumericInput
          v-model="rowSeparation" name="rowSeparation"
          unit="cm" hide-description
        >
          <template #label>
            <IxRes>frs.actions.seeding.RowSeparation</IxRes>
          </template>
        </NumericInput>
      </div>
      <div v-else>
        <!-- grain -->
        <NumericInput
          v-model="thousandSeedWeight" name="thousandSeedWeight"
          unit="g" hide-description
        >
          <template #label>
            <IxRes>frs.actions.seeding.ThousandSeedWeight</IxRes>
          </template>
        </NumericInput>
        <NumericInput
          v-model="germinationCapacity" name="germinationCapacity"
          unit="%" hide-description
          percent-not-normalized
        >
          <template #label>
            <IxRes>frs.actions.seeding.GerminationCapacity</IxRes>
          </template>
        </NumericInput>
      </div>
      <NumericInput
        v-model="emergence" name="emergence"
        unit="%" hide-description
        percent-not-normalized
      >
        <template #label>
          <IxRes>frs.actions.seeding.Emergence</IxRes>
        </template>
      </NumericInput>
      <text-input v-model="coating" name="coating">
        <template #label>
          <IxRes>frs.actions.seeding.coating</IxRes>
        </template>
      </text-input>
      <NumericInput
        ref="inputSeedsPerHa" v-model="seedsPerHa"
        :rule="seedsPerHaRule"
        name="seedsPerHa" :description="seedsDescription"
        :unit="unitSeedsPerHa" @input="wasComputed.seedsPerHa = false"
      >
        <template #label>
          <IxRes>frs.actions.seeding.SeedsPerHa</IxRes>
        </template>
      </NumericInput>
    </div>
    <hr>
    <div>
      <strong>
        <IxRes>frs.actions.seeding.TotalSeeds</IxRes>
      </strong>
      <span>{{ totalSeeds }} {{ unitTotalSeeds }}</span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {isMaize} from '@helpers/products'
import {mapResources} from '@helpers/vuex'

import NumericInput from '@components/forms/NumericInput'
import TextInput from '@components/forms/TextInput'
import SimpleSwitch from '@components/forms/SimpleSwitch'
import FrsProductPicker from '@frs/components/base/FrsProductPicker'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import FrsMixtureTable from '@frs/components/base/FrsMixtureTable'
import SeedingMixin from './SeedingMixin'

export default {
  components: {
    SimpleSwitch,
    NumericInput,
    TextInput,
    FrsProductPicker,
    LabelAndMessage,
    FrsMixtureTable
  },
  mixins: [
    SeedingMixin
  ],
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapGetters('masterData', [
      'cropArray'
    ]),
    seedsDescription () {
      return this.isCorn ? this.SR_FieldRecordSystem.SeedsPerHaDescriptionCorn : this.SR_FieldRecordSystem.SeedsPerHaDescription
    },
    unitSeedsPerHa () {
      return this.isCorn ? this.$i18n.translateUnit('K/m²') : this.$i18n.translateUnit('kg/ha')
    },
    unitTotalSeeds () {
      return this.isCorn ? this.$i18n.translateUnit('K') : this.$i18n.translateUnit('kg')
    },
    availableProductFilter () {
      return product => (isMaize(product) === this.isCorn && !product.isArchived) || product.id === this.productId
    }
  }
}
</script>

<style scoped>
</style>
