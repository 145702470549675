<template>
  <div>
    <GradientScale :colors="colors">
      <template #startLabel>
        <IxRes>frs.mapManagement.labels.lowYield</IxRes>
      </template>
      <template #endLabel>
        <IxRes>frs.mapManagement.labels.highYield</IxRes>
      </template>
    </GradientScale>
  </div>
</template>

<script>
import GradientScale from '@components/GradientScale'
import {mapToColors} from '@helpers/map-color-schemes'

export default {
  components: {
    GradientScale
  },
  props: {
    map: {
      type: Object,
      required: true
    },
    palette: {
      type: String,
      required: true
    }
  },
  computed: {
    colors () {
      return mapToColors(this.map, this.palette)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
