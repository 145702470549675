<template lang="html">
  <div>
    <Header>
      <template #subtitle>
        <IxRes>Common.SR_Common.Area</IxRes>: {{ fieldName }} ({{ fieldNumber }})
      </template>
    </Header>

    <div class="zone-values">
      <FrsZoneValueInput
        v-model="zoneAmounts"
        name="zoneValueInput"
        :zone-map="zoneMap"
        percent
      />
    </div>
    <br>
    <div class="footer">
      <IxButton save large @click="save">
        <IxRes>bf.createApplicationMap.saveHeterogenityFactors</IxRes>
      </IxButton>
      <IxButton cancel large @click="startYieldPlanning">
        <IxRes>Common.SR_Common.Cancel</IxRes>
      </IxButton>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'

import Header from './widgets/Header'

import FrsZoneValueInput from '@frs/components/base/FrsZoneValueInput'
import IxButton from '@components/IxButton'

export default {
  components: {
    Header,
    IxButton,
    FrsZoneValueInput
  },
  data () {
    return {
      zoneAmounts: {}
    }
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      fields: state => state.data.field,
      zoneMapsLookup: state => state.mapManagement.data.zoneMap.mapLookup
    }),
    ...mapState('fieldRecordSystem/basicFertilization/applicationMaps', {
      selectedFieldId: state => state.selectedFieldId,
      zoneMapId: state => state.creationParameters.zoneMapId,
      heterogenityFactors: state => state.creationParameters.heterogenityFactors
    }),
    ...mapGetters('fieldRecordSystem', [
      'entityNameLookup'
    ]),
    fieldNumber () {
      return this.fields[this.selectedFieldId].code
    },
    fieldName () {
      return this.entityNameLookup[this.selectedFieldId]
    },
    zoneMap () {
      return this.zoneMapsLookup[this.zoneMapId]
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/applicationMaps', [
      'startYieldPlanning',
      'saveHeterogenityFactors'
    ]),
    save () {
      this.saveHeterogenityFactors(this.zoneAmounts)
    }
  },
  created () {
    this.zoneAmounts = this.heterogenityFactors
  }
}
</script>

<style lang="scss" scoped>
.zone-values {
  width: 40%;
}
.footer {
  display: flex;
  justify-content: space-between;
}
</style>
