<template>
  <LegacyGrid
    :title="SR_FieldRecordSystem.OperatingResources" :data="quantities"
    :columns="columns"
    selection="none"
  />
</template>

<script>
import {mapResources} from '@helpers/vuex'

import LegacyGrid from '@components/grid/LegacyGrid'

export default {
  components: {
    LegacyGrid
  },
  props: [
    'data'
  ],
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem',
      'Common.SR_Common'
    ]),
    quantities () {
      if (!this.data) return []

      return this.data.map(({product, quantity}) => ({
        productName: product.name,
        quantity: quantity.value,
        numerator: product.numerator
      }))
    },
    columns () {
      return [
        {localizedTitle: this.SR_Common.Product, typeName: 'String', propertyName: 'productName'},
        {localizedTitle: this.SR_Common.Amount, typeName: 'Float', propertyName: 'quantity'},
        {localizedTitle: this.SR_Common.Unit, typeName: 'String', propertyName: 'numerator'}
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
