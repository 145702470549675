<template lang="html">
  <div class="component">
    <div class="data-row">
      <TextInput
        v-model="name" class="product-name"
        required
        name="name">
        <template #label>
          <IxRes>frs.action.planning.fertilization.customProduct.name</IxRes>
        </template>
      </TextInput>
    </div>
    <label>
      <IxRes>frs.action.planning.fertilization.{{ procedure }}.labels.productNutrientContentForm</IxRes>: *
    </label>
    <div class="data-row sparse">
      <NumericInput
        v-model="nitrogen" name="nitrogen"
        hide-description
        :rule="min(0)">
        <template #label>
          <IxRes>{{ labels.nitrogen.title }}</IxRes>:
        </template>
      </NumericInput>
      <NumericInput
        v-model="nitrate" name="nitrate"
        hide-description
        :rule="between(0, 100)">
        <template #label>
          <IxRes>{{ labels.nitrate.title }}</IxRes>:
        </template>
      </NumericInput>
      <NumericInput
        v-model="ammonium" name="ammonium"
        hide-description
        :rule="between(0, 100)">
        <template #label>
          <IxRes>{{ labels.ammonium.title }}</IxRes>:
        </template>
      </NumericInput>
      <NumericInput
        v-model="nitrogenAmide" name="nitrogenAmide"
        hide-description
        :rule="between(0, 100)">
        <template #label>
          <IxRes>{{ labels.nitrogenAmide.title }}</IxRes>:
        </template>
      </NumericInput>
    </div>

    <div class="data-row regular">
      <NumericInput
        v-model="phosphorus" name="phosphorus"
        hide-description
        :rule="between(0, 100)"
      >
        <template #label>
          <IxRes>{{ labels.phosphorus.title }}</IxRes>:
        </template>
      </NumericInput>
      <NumericInput
        v-model="potassium" name="potassium"
        hide-description
        :rule="between(0, 100)"
      >
        <template #label>
          <IxRes>{{ labels.potassium.title }}</IxRes>:
        </template>
      </NumericInput>
      <NumericInput
        v-if="isLiquid" v-model="density"
        name="density" hide-description
        addon="kg/m³"
        :rule="min(0)"
      >
        <template #label>
          <IxRes>frs.action.planning.fertilization.liquid.labels.density</IxRes>:
        </template>
      </NumericInput>

      <NumericInput
        v-model="drySubstrateAmount"
        name="drySubstrateAmount"
        percent percent-not-normalized
        class="numeric-input"
        hide-description
        :rule="between(0, 100)"
      >
        <template #label>
          <IxRes>masterData.dryMatter</IxRes>
        </template>
      </NumericInput>

      <NumericInput
        v-model="minimumEfficiency"
        name="minimumEfficiency"
        percent percent-not-normalized
        class="numeric-input"
        hide-description
        :rule="between(0, 100)"
      >
        <template #label>
          <IxRes>masterData.minimumEfficiency</IxRes>
        </template>
      </NumericInput>
    </div>

    <div>
      <IxButton large :icon="icons.pdf" @click="openMinimumEfficiencyOverview">
        <IxRes>frs.action.planing.fertilization.labels.minimumEfficiencyOverview</IxRes>
      </IxButton>
    </div>

    <BsCheckbox v-model="isComposted" inline name="isComposted">
      <IxRes>masterData.labels.isComposted</IxRes>
    </BsCheckbox>
    <br>
    <VueSelectize
      v-model="consistency"
      :options="consistencyData"
      :fields="consistencyFields"
      name="fertilizerConsistency"
      allow-empty
    >
      <template #label>
        <IxRes>masterData.labels.fertilizerConsistency</IxRes>
      </template>
    </VueSelectize>
    <VueSelectize
      v-model="fertilizerSource"
      :options="sourceData"
      :fields="sourceFields"
      name="fertilizerSource"
      allow-empty
    >
      <template #label>
        <IxRes>masterData.labels.fertilizerSource</IxRes>
      </template>
    </VueSelectize>
  </div>
</template>

<script>
import {faFilePdf} from '@fortawesome/free-regular-svg-icons'

import ParameterMixin from '../ParameterMixin'
import StandardRulesMixin from '@components/forms/StandardRulesMixin'

import {mapFormFields} from '@helpers/vuex'

import {consistency, source} from 'src/js/store/modules/master-data/fertilizerProperties.js'

import VueSelectize from '@components/VueSelectize'
import IxButton from '@components/IxButton'
import BsCheckbox from '@components/bootstrap/BsCheckbox'
import NumericInput from 'src/vue/components/forms/NumericInput'
import TextInput from '@components/forms/TextInput'

import columns from './nutrient-columns'

export default {
  components: {
    NumericInput,
    TextInput,
    VueSelectize,
    BsCheckbox,
    IxButton
  },
  mixins: [
    ParameterMixin,
    StandardRulesMixin
  ],
  props: {
    procedure: String
  },
  computed: {
    ...mapFormFields('masterData/management', [
      'editBuffer.nitrogen',
      'editBuffer.nitrate',
      'editBuffer.ammonium',
      'editBuffer.nitrogenAmide',
      'editBuffer.phosphorus',
      'editBuffer.potassium',
      'editBuffer.isLiquid',
      'editBuffer.density',
      'editBuffer.name',
      'editBuffer.isComposted',
      'editBuffer.consistency',
      'editBuffer.fertilizerSource',
      'editBuffer.minimumEfficiency',
      'editBuffer.drySubstrateAmount'
    ]),
    icons () {
      return {
        pdf: faFilePdf
      }
    },
    labels () {
      return columns
    },
    consistencyData () {
      return consistency
    },
    sourceData () {
      return source
    },
    consistencyFields () {
      return {
        text: id => this.$i18n.translate(`masterData.fertilizerProperties.consistency_${id}`)
      }
    },
    sourceFields () {
      return {
        text: id => this.$i18n.translate(`masterData.fertilizerProperties.source_${id}`)
      }
    }
  },
  methods: {
    openMinimumEfficiencyOverview () {
      window.open('https://cdn.ixmap.eu/file_data/documents/ASK/Fertilization/Mindestwirksamkeit.pdf')
    }
  }
}
</script>

<style lang="scss" scoped>
.component {
  display: flex;
  flex-direction: column;

  .data-row {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;

    .product-name {
      width: 300px;
    }
  }

  .sparse {
    // justify-content: space-between;

    > * {
      margin-right: 10px;
      width: 100px;
    }
  }

  .regular {
    /*
      select's the first two elements and make them smaller
      because the third one (density) needs more space since it has a unit addon
    */
    > * {
      margin-right: 10px;
      width: 100px;
    }
  }
}
</style>
