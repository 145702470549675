import {mapState, mapGetters} from 'vuex'

import {oxideNames} from '@frs/components/basic-fertilization/oxidform-calculation'

export default {
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', [
      'order',
      'results'
    ]),
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', {
      selectedFieldId: state => state.manual.selectedFieldId,
      nutrients: state => state.manual.nutrients,
      columnMapping: state => state.upload.columnMapping
    }),
    ...mapGetters('fieldRecordSystem/basicFertilization/resultImport', [
      'savedResultsLookup'
    ]),
    tableData () {
      let geometries = []
      if (!this.selectedFieldId) {
        geometries = this.order.geometries
      } else {
        const geometryIds = this.samplingOrderGeometryIdsByFieldId[this.selectedFieldId]

        if (geometryIds) {
          geometries = this.order.geometries.filter(geometry => geometryIds.indexOf(geometry.id) >= 0)
        }
      }

      geometries.forEach(geometry => {
        if (!geometry.fieldName) {
          geometry.fieldName = this.results[geometry.id].fieldName
        }
        geometry.readonly = this.savedResultsLookup[geometry.id] && Object.values(this.savedResultsLookup[geometry.id]).every(x => x !== null)
      })
      return geometries
    },
    samplingOrderGeometryIdsByFieldId () {
      return Object.entries(this.order.fieldInfoByGeometry)
        .reduce((lookup, [samplingOrderGeometryId, array]) => {
          array.forEach(({fieldId}) => {
            if (!lookup[fieldId]) {
              lookup[fieldId] = []
            }
            lookup[fieldId].push(samplingOrderGeometryId)
          })
          return lookup
        }, {})
    },
    nutrients () {
      return ['phosphorus', 'potassium', 'magnesium', 'ph', 'lime']
    },
    tableColumns () {
      const units = {
        phosphorus: this.$i18n.translateUnit('mg/100g'),
        potassium: this.$i18n.translateUnit('mg/100g'),
        magnesium: this.$i18n.translateUnit('mg/100g'),
        ph: '',
        lime: this.$i18n.translateUnit('dt/ha')
      }

      return {
        sampleId: 'Nummer',
        fieldName: 'Feld',
        edit: '',
        geometry: {
          title: 'Typ',
          value: x => x.geometry.startsWith('POINT') ? 'Punkt' : 'Beganglinie'
        },
        ...this.nutrients.reduce((columns, nutrient) => {
          const nutrientHeader = this.columnMapping[`${nutrient}IsOxide`] ? oxideNames[nutrient] : this.$i18n.translate(`bf.labels.nutrient_${nutrient}`)

          const unit = units[nutrient]
          columns[nutrient] = unit ? `${nutrientHeader} [${unit}]` : nutrientHeader
          return columns
        }, {}),
        soilSubType: 'Bodenart'
      }
    }
  }
}
