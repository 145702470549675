<template lang="html">
  <div class="common-settings">
    <FrsFieldSelectionInfo :field-ids="fieldIds">
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Fields</IxRes>
      </template>
      <template #description>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ActionPlanningSelectedFieldHint</IxRes>
      </template>
    </FrsFieldSelectionInfo>

    <VueSelectize
      v-model="type" class="form-group cultivation-selection"
      sort-field="text"
      :fields="typeFields" :options="cultivationTypeOptions"
      name="cultivationType" required
    >
      <template #label>
        <IxRes>Common.SR_Common.Type</IxRes>:
      </template>
    </VueSelectize>

    <div class="date-range-time-picker">
      <FrsDateTimeRangePicker
        v-model="dateTimeRange" required
        name="dateTimeRange"
        class="picker" iso
        :limits="dateTimeRangeLimits"
      >
        <template #label>
          <IxRes>Common.SR_Common.Period</IxRes>:
        </template>
      </FrsDateTimeRangePicker>

      <label>
        <IxRes>frs.cultivationPlanning.labels.harvestYears</IxRes>
      </label>
      <HarvestYearAssignment
        :default-harvest-years="harvestYears"
        :harvest-year="harvestYear"
        :date-time-range="dateTimeRange"
        @input="harvestYears = $event"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'

import {mapFormFields} from '@helpers/vuex'

import VueSelectize from '@components/VueSelectize'
import FrsDateTimeRangePicker from '@frs/components/base/FrsDateTimeRangePicker'
import FrsFieldSelectionInfo from '@frs/components/base/FrsFieldSelectionInfo'

import HarvestYearAssignment from './HarvestYearAssignment'

export default {
  components: {
    FrsFieldSelectionInfo,
    VueSelectize,
    FrsDateTimeRangePicker,
    HarvestYearAssignment
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      harvestYear: state => state.userSettings.harvestYear
    }),
    ...mapState('fieldRecordSystem/navigation', {
      planningIsFromOrgUnit: state => !state.location.fieldId
    }),
    ...mapState('fieldRecordSystem/cultivationPlanning', {
      cultivationTypeOptions: state => state.data.cultivationTypes.map(type => type.name)
    }),
    ...mapState('fieldRecordSystem/cultivationPlanning/editBuffer', {
      fieldIds: state => state.fieldIds
    }),
    ...mapFormFields('fieldRecordSystem/cultivationPlanning/editBuffer', [
      'type',
      'dateTimeRange',
      'harvestYears'
    ]),
    dateTimeRangeLimits () {
      return {
        start: moment({year: this.harvestYear, month: 11, day: 31}).toISOString(),
        end: moment({year: this.harvestYear, month: 0, day: 1}).toISOString()
      }
    },
    typeFields () {
      return {
        text: name => this.$i18n.translate(`Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationType_${name}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
