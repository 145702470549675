<template lang="html">
  <div class="algorithmic-application-map-creation">
    <AlgorithmCollectionSelection v-if="!algorithmCollection" />

    <template v-else>
      <AlgorithmParameters v-if="page === 'parameters'" />
      <AlgorithmPreview v-else :is-seeding="planningType" />
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import AlgorithmCollectionSelection from './AlgorithmCollectionSelection'
import AlgorithmParameters from './AlgorithmParameters'
import AlgorithmPreview from './AlgorithmPreview'

export default {
  components: {
    AlgorithmCollectionSelection,
    AlgorithmParameters,
    AlgorithmPreview
  },
  computed: {
    ...mapState('fieldRecordSystem/applicationMaps', {
      page: state => state.ui.page,
      algorithmCollection: state => state.ui.algorithmCollection
    }),
    planningType () {
      return this.algorithmCollection === 'Seeds'
    }
  }
}
</script>

<style lang="scss" scoped>
.algorithmic-application-map-creation {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
