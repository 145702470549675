<template lang="html">
  <div v-if="action" class="detail-container">
    <div class="details">
      <ActionDetails v-bind="{action, machines, persons, products}" />
    </div>

    <div>
      <div class="audit">
        <ShowDetails>
          <template #label>
            <IxRes>frs.action.details.updated</IxRes>:
          </template>

          {{ action.auditInfo.updatedBy.toLowerCase() }}, {{ $i18n.format(action.auditInfo.updatedAt, 'datetime-short') }}
        </ShowDetails>
        <ShowDetails>
          <template #label>
            <IxRes>frs.action.details.created</IxRes>:
          </template>

          {{ action.auditInfo.createdBy.toLowerCase() }}, {{ $i18n.format(action.auditInfo.createdAt, 'datetime-short') }}
        </ShowDetails>
      </div>
      <div class="button-container">
        <IxButton
            icon="edit"
            :disabled="action.usesExperimentalFinanceIntegration"
            :title="action.usesExperimentalFinanceIntegration ? $i18n.translate('frs.actions.usesExperimentalFinanceIntegration') : ''"
            @click="editAction"
        >
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EditAction</IxRes>
        </IxButton>
        <ixButton icon="edit" @click="showActionStatusModal = true">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.changeStateAction</IxRes>
        </ixButton>
        <IxButton add @click="createAction">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CreateAction</IxRes>
        </IxButton>
        <IxButton remove @click="showModal = true">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteAction</IxRes>
        </IxButton>
      </div>
    </div>

    <ConfirmationModal
      v-model="showModal"
      @confirm="deleteAction"
      @cancel="showModal = false"
    >
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteAction</IxRes>
      </template>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteSelectedActionQuestion</IxRes>
    </ConfirmationModal>
    <ActionStatusModal v-model="showActionStatusModal" :actions="[action]" />

    <ActionMapLayer
      :actions="[action]"
    />
  </div>
</template>

<script>
import axios from 'axios'
import {mapMutations, mapActions, mapState, mapGetters} from 'vuex'

import IxButton from '@components/IxButton'
import ConfirmationModal from '@components/modals/ConfirmationModal'

import ActionStatusModal from './overview/ActionStatusModal'

import ActionDetails from './ActionDetails'
import ActionMapLayer from './ActionMapLayer'
import ShowDetails from '@frs/components/action/planning/wizard/details/ShowDetails'

export default {
  components: {
    ShowDetails,
    IxButton,
    ActionDetails,
    ActionMapLayer,
    ActionStatusModal,
    ConfirmationModal
  },
  data () {
    return {
      showModal: false,
      showActionStatusModal: false,
      action: null
    }
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      machines: state => state.machines.data.machines,
      persons: state => state.persons.data.persons
    }),
    ...mapState('fieldRecordSystem/action', {
      actionId: state => state.ui.selection.actionId
    }),
    ...mapGetters('masterData', [
      'products'
    ]),
  },
  methods: {
    ...mapActions('fieldRecordSystem/machines', [
      'loadMachines'
    ]),
    ...mapActions('fieldRecordSystem/persons', [
      'loadPersons'
    ]),
    ...mapMutations('fieldRecordSystem', [
      'setRightView'
    ]),
    ...mapActions('fieldRecordSystem/action', [
      'removeActions',
      'startEditingAction'
    ]),
    createAction () {
      this.startEditingAction({navigationSource: {saveTarget: 'actionDisplayField', cancelTarget: 'actionDetailsPage'}})
    },
    editAction () {
      this.startEditingAction({actionId: this.action.id, navigationSource: {saveTarget: 'actionDetailsPage', cancelTarget: 'actionDetailsPage'}})
    },
    deleteAction () {
      this.showModal = false
      this.removeActions([this.action])
      this.setRightView('actionDisplayField')
    }
  },
  async created () {
    this.loadMachines()
    this.loadPersons()

    const {data: action} = await axios.get(`/api/v2/actions/${this.actionId}`)

    this.action = action
  }
}
</script>

<style lang="scss" scoped>
.detail-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .details {
    flex: 1;
    overflow-y: auto;
  }

  .button-container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    column-gap: 2px;
    row-gap: 2px;
  }

  .audit {
    font-size: 0.8em;

    .detail {
      margin: 0;
    }
  }
}
</style>
