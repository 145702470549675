<template lang="html">
  <SimpleTable :data="tableData.filter(x => !x.readonly)" :columns="tableColumns" :highlight="highlight">
    <template v-for="nutrient in nutrients" :slot="nutrient" slot-scope="{row}">
      <div :key="nutrient" class="cell-with-icon">
        <ResultStatusIndicator v-if="value" :geometry-id="value" :nutrient="nutrient" />

        <ProbingResult :geometry-id="row.id" :nutrient="nutrient" />
      </div>
    </template>
    <template slot="edit" slot-scope="{row}">
      <a v-if="value !== row.id" href="#" @click.stop.prevent="$emit('input', row.id)">
        <IxRes>Common.SR_Common.Edit</IxRes>
      </a>
    </template>
    <template slot="soilSubType" slot-scope="{row}">
      <ProbingResultSoilSubType :result="results[row.id].soilSubType" />
    </template>
  </SimpleTable>
</template>

<script>
import {mapGetters} from 'vuex'

import SimpleTable from '@components/table/SimpleTable'

import ResultStatusIndicator from '@frs/components/basic-fertilization/result-import/ResultStatusIndicator'

import ProbingResult from './ProbingResult'
import ProbingResultSoilSubType from './ProbingResultSoilSubType'

import ResultsTableMixin from './ResultsTableMixin'

export default {
  components: {
    ProbingResultSoilSubType,
    ResultStatusIndicator,
    ProbingResult,
    SimpleTable
  },
  mixins: [
    ResultsTableMixin
  ],
  props: {
    value: String
  },
  computed: {
    ...mapGetters('fieldRecordSystem/basicFertilization/resultImport', [
      'newResultsCombinedValidity'
    ])
  },
  methods: {
    highlight (row) {
      if (row.id === this.value) return ['info']

      if (this.newResultsCombinedValidity[row.id] === true) return ['success']

      return []
    }
  }
}
</script>

<style lang="scss" scoped>
.cell-with-icon {
  display: flex;
  align-items: center;
}
</style>
