<template>
  <div>
    <slot
      v-for="item in items" :item="item"
    />
  </div>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
