<template lang="html">
  <LabelAndMessage
    :required="required"
    :feedback-classes="feedbackClasses"
    :description="combinedDescription" :message="message"
  >
    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>

    <div class="input-group">
      <div class="crop-color" :style="{backgroundColor: usage ? usage.color || '#3b3b3b' : 'rgba(0,0,0,0.15)'}" />
      <input
        :id="id"
        type="text"
        class="form-control"
        :aria-describedby="`${id}-sr-status`"
        :value="cropUsageDisplay" name="cropUsageDisplay"
        disabled
      >
      <div class="input-group-btn">
        <button type="button" class="btn btn-primary" @click.prevent="showModal = true">
          <i class="fa fa-pencil" />
        </button>
      </div>
    </div>
    <BsModal v-model="showModal">
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CropTypeSelectTitle</IxRes>
      </template>

      <FrsCropUsageSelectionGrid v-model="modelProxy" :filter="filter" @input="showModal = false" />
    </BsModal>
  </LabelAndMessage>
</template>

<script>
import {mapState} from 'vuex'

import {mapResources, modelProxy} from '@helpers/vuex'
import {getCropUsageTranslations} from '@frs/helpers/crops'

import BsModal from '@components/bootstrap/BsModal'
import FrsCropUsageSelectionGrid from '@frs/components/base/FrsCropUsageSelectionGrid'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage.vue'

import InputMixin from '@components/forms/input-base/InputMixin'
import FormPartMixin from 'src/vue/components/forms/FormPartMixin'
import ChangeDetectionMixin from 'src/vue/components/forms/ChangeDetectionMixin'
import RuleMixin from '@components/forms/RuleMixin'

let counter = 0

export default {
  components: {
    LabelAndMessage,
    FrsCropUsageSelectionGrid,
    BsModal
  },
  mixins: [
    RuleMixin,
    InputMixin,
    FormPartMixin,
    ChangeDetectionMixin
  ],
  props: {
    value: String,
    label: String,
    required: Boolean,
    filter: Function,
    name: String
  },
  data () {
    return {
      id: `frs-product-picker-${counter++}`,
      dirty: false,
      showModal: false
    }
  },
  computed: {
    modelProxy,
    ...mapResources([
      'Messages.Info.SR_InfoMessages'
    ]),
    ...mapState('masterData', [
      'cropUsages'
    ]),
    cropUsageDisplay () {
      if (!this.value) return ''

      const {name, abbreviation} = getCropUsageTranslations(this.value, true)

      return `${name} (${abbreviation})`
    },
    usage () {
      return this.cropUsages[this.value]
    },
    state () {
      // never validate without user entry
      if (!this.dirty) {
        return undefined
      }

      const states = {}

      if (this.required) {
        if (this.value && this.value !== '') {
          states.required = 'success'
        } else {
          states.required = 'error'
        }
      }
      states.rule = this.ruleState

      // the 'or success' part coerces the undefined state that you get when no rules are available into a success state
      return this.combineStates(states) || 'success'
    },
    combinedDescription () {
      return [this.ruleDescription, this.description].filter(x => x)
    },
    message () {
      const builtinMessages = {
        error: this.SR_InfoMessages.RequiredField
      }
      const combinedMessages = Object.assign(builtinMessages, this.ruleMessages)

      return combinedMessages[this.state]
    }
  }
}
</script>

<style lang="scss" scoped>
.crop-color {
  width: 5px;
  top: 0;
  bottom: 0;
  position: absolute
}
.form-control {
  margin-left: 5px;
}
</style>
