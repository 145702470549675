<template lang="html">
  <div class="content">
    <DlgHarvestYearHelpIntegration flag="frs.action.hasSeenFirstTimeHint">
      <IxRes>frs.actionPlanning.dlgCertIntegration.buttons.showHarvestYearHelp</IxRes>

      <template #next>
        <IxRes>frs.actionPlanning.dlgCertIntegration.buttons.createActionNow</IxRes>
      </template>
    </DlgHarvestYearHelpIntegration>

    <FrsFieldSelectionInfo v-if="!isFieldLevel" class="content-group" :field-ids="fieldIds">
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Fields</IxRes>
      </template>
      <template #description>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ActionPlanningSelectedFieldHint</IxRes>
      </template>
    </FrsFieldSelectionInfo>

    <div class="content-group">
      <div class="content-group-title">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ActionPlannedDateHeader</IxRes>: *
      </div>

      <PlannedDateSelection />

      <br>

      <IxRes v-if="!isFieldLevel && fieldIds.length">frs.actionPlanning.hint.selectCultivationsHint</IxRes>

      <div v-if="hasFieldIds && !id" class="cultivation-plan">
        <LabelAndMessage>
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationPlan</IxRes>:
          </template>
          <CultivationTable style="padding: 5px;" />
          <div v-if="fieldId" class="checkbox-container">
            <FontAwesomeIcon
              class="checkbox-icon"
              :icon="selectionSummaryByFieldId[fieldId].everythingSelected ? checkboxIcons.checked : checkboxIcons.unchecked"
              @click="toggleAll"
            />
            <IxRes>frs.actionPlanning.label.selectAllCultivations</IxRes>
          </div>
        </LabelAndMessage>
      </div>

      <div v-if="Object.keys(selectedCultivationIds).some(id => cultivationDateValidityLookup[id] === false)" class="warning">
        <FontAwesomeIcon class="warning-icon" :icon="icons.noCultivationWarning" />
        <IxRes>frs.actionPlanning.hint.cultivationsOutsideOfActionDateSelected</IxRes>
      </div>

      <div class="area-count-container" :class="{warning: totalArea === 0}">
        <template v-if="totalArea === 0">
          <FontAwesomeIcon class="warning-icon" :icon="icons.noCultivationWarning" />
          <IxRes>frs.actionPlanning.hint.noCultivationsSelected</IxRes>
        </template>
        <IxRes v-else :context="{totalArea}">
          frs.actionPlanning.hint.cultivationAreaCountHint
        </IxRes>
      </div>

      <div v-if="isFieldLevel || id" class="custom-geometry">
        <IxButton large @click="startEditingActionGeometry">
          <IxRes v-if="customGeometries[fieldIds[0]]">frs.actionPlanning.buttons.editCustomActionGeometry</IxRes>
          <IxRes v-else>frs.actionPlanning.buttons.addCustomActionGeometry</IxRes>
        </IxButton>
        <FontAwesomeIcon v-if="customGeometries[fieldIds[0]]" class="custom-geometry-icon" :icon="icons.customGeometry" />
      </div>

      <BasicSettingsMapContent v-if="!id" />
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faExclamationTriangle, faCheck} from '@fortawesome/free-solid-svg-icons'
import {faCheckSquare, faSquare} from '@fortawesome/free-regular-svg-icons'

import IxButton from '@components/IxButton'
import FrsFieldSelectionInfo from '@frs/components/base/FrsFieldSelectionInfo'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import DlgHarvestYearHelpIntegration from '@frs/components/dlg-cert-integration/DlgHarvestYearHelpIntegration'

import CultivationTable from '@frs/components/action/planning/wizard/widgets/CultivationTable'
import PlannedDateSelection from '@frs/components/action/planning/wizard/widgets/PlannedDateSelection'

import BasicSettingsMapContent from './BasicSettingsMapContent'

export default {
  components: {
    DlgHarvestYearHelpIntegration,
    FontAwesomeIcon,
    LabelAndMessage,
    CultivationTable,
    PlannedDateSelection,
    FrsFieldSelectionInfo,
    IxButton,
    BasicSettingsMapContent
  },
  computed: {
    ...mapState('fieldRecordSystem/action/editBuffer', [
      'selectedCultivationIds',
      'customGeometries',
      'fieldIds',
      'timestamps',
      'id'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    ...mapGetters('fieldRecordSystem/action/editBuffer', [
      'cultivationDateValidityLookup',
      'relevantCultivationsByFieldId',
      'selectionSummaryByFieldId',
      'finalActionTotalArea'
    ]),
    totalArea() {
      return this.finalActionTotalArea === 0 
        ? this.finalActionTotalArea 
        : this.$i18n.format(this.finalActionTotalArea, 'area')
    },
    isFieldLevel () {
      return this.fieldId
    },
    hasFieldIds () {
      return !!this.fieldIds.length
    },
    icons () {
      return {
        noCultivationWarning: faExclamationTriangle,
        customGeometry: faCheck
      }
    },
    checkboxIcons () {
      return {
        checked: faCheckSquare,
        unchecked: faSquare
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/action/editBuffer', [
      'toggleCultivationId',
      'toggleAll'
    ]),
    ...mapActions('fieldRecordSystem/action', [
      'startEditingActionGeometry'
    ])
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 0 5px 0 5px;
  margin-bottom: 20px;

  .content-group {
    margin-bottom: 15px;

    > * {
      margin-bottom: 6px;
    }
  }

  .content-group-title {
    font-weight: bold;
  }

  .cultivation-plan {
    margin-top: 16px;
  }

  .checkbox-container {
    margin-left: 7px;
    margin-top: 8px;
    display: flex;
    flex-direction: row;

    .checkbox-icon {
      margin-top: 4px;
      margin-right: 8px;
    }
  }
}

.custom-geometry {
  display: flex;
  align-items: center;

  .custom-geometry-icon {
    color: green;
    margin-left: 0.5em;
  }
}

.warning {
  color: #d9534f;
}
.warning-icon {
  margin-top: 5px;
  margin-right: 4px;
  font-size: 16px;
}
</style>
