<template lang="html">
  <BsModal v-model="modelProxy" title="Maßnahmenstatus anpassen">
    <div class="content">
      <VueSelectize
        v-model="selectedStatus" class="status"
        allow-empty
        :fields="fields" :options="stateOptions"
      />

      <div class="date-picker">
        <div class="row">
          <div v-if="showStartDate" class="col-md-6">
            <p>
              <strong>
                <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ActionStatusBeginDate</IxRes>
              </strong>
            </p>
            <VueDateTimePicker
              v-model="start"
              iso
              :format="withTime ? 'L LT' : 'L'"
              name="ActionStatusBeginDate"
            />
          </div>
          <div v-if="showEndDate" class="col-md-6">
            <p>
              <strong>
                <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ActionStatusEndDate</IxRes>
              </strong>
            </p>
            <VueDateTimePicker
              v-model="end"
              iso
              :format="withTime ? 'L LT' : 'L'"
              :min="start"
              :readonly="!start"
              name="ActionStatusEndDate"
            />
          </div>
        </div>
      </div>
      <IxButton
        save large
        :disabled="!selectedStatus"
        @click="save"
      />
      <IxButton cancel large @click="cancel" />
    </div>
  </BsModal>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {modelProxy, mapResources, mapFormFields} from '@helpers/vuex'

import VueSelectize from '@components/VueSelectize'
import BsModal from 'src/vue/components/bootstrap/BsModal'
import VueDateTimePicker from 'src/vue/components/forms/VueDateTimePicker'
import IxButton from '@components/IxButton'

export default {
  components: {
    VueSelectize,
    BsModal,
    VueDateTimePicker,
    IxButton
  },
  props: {
    value: {
      type: Boolean
    },
    actions: {
      type: Array,
      required: true
    },
    forOrgUnit: {
      type: Boolean
    }
  },
  data () {
    return {
      selectedStatus: null,
      stateOptions: ['ActionStatusFinished', 'ActionStatusStarted', 'ActionStatusPlanned']
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/action/editBuffer', {
      'timestamps.actual.start': 'setActualStart',
      'timestamps.actual.end': 'setActualEnd'
    }),
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState('fieldRecordSystem/action/editBuffer', {
      withTime: state => state.timestamps.withTime
    }),
    showStartDate () {
      if (this.selectedStatus == null) return false
      return this.selectedStatus === 'ActionStatusStarted' || this.showEndDate
    },
    showEndDate () {
      if (this.selectedStatus == null) return false
      return this.selectedStatus === 'ActionStatusFinished'
    },
    fields () {
      return {
        text: state => this.SR_FieldRecordSystem[`${state}`]
      }
    },
    newState () {
      return {
        start: this.start,
        end: this.end
      }
    },
    modelProxy
  },
  methods: {
    ...mapActions('fieldRecordSystem/action', [
      'saveModifiedActionStatus'
    ]),
    save () {
      this.saveModifiedActionStatus({actions: this.actions, newState: this.newState, forOrgUnit: this.forOrgUnit})
      this.modelProxy = false
    },
    cancel () {
      this.modelProxy = false
    },
    resetDate () {
      this.selectedStatus = this.actions[0].timestamps.actual.end
        ? 'ActionStatusFinished'
        : this.actions[0].timestamps.actual.start
          ? 'ActionStatusStarted'
          : 'ActionStatusPlanned'
    },
    setDates (start, end) {
      this.start = start
      this.end = end
    }
  },
  watch: {
    start (value) {
      if (!value) {
        this.end = null
      }
    },
    selectedStatus (value) {
      switch (value) {
      case 'ActionStatusFinished':
        this.setDates(this.actions[0].timestamps.actual.start, this.actions[0].timestamps.actual.end)
        break
      case 'ActionStatusStarted':
        this.setDates(this.actions[0].timestamps.actual.start, null)
        break
      default:
        this.setDates(null, null)
      }
    },
    actions (value) {
      if (value.length > 0) {
        this.resetDate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content > * {
  margin-bottom: 8px;
}
.status{
  width: 35%;
}
.date-picker {
  margin-top: 16px;
}
p {
  margin-bottom: 6px;
}
hr {
  margin-top: 16px;
}
</style>
