<template>
  <div class="harvest-form-container">
    <label>
      <IxRes>actions.harvest.mainCropIncome</IxRes>: *
    </label>
    <span class="item-container">
      <QuantityInput v-model="mainCropYield" class="input-item" :unit-options="validUnits" />
    </span>

    <label>
      <IxRes>actions.harvest.catchCropIncome</IxRes>:
    </label>
    <span class="item-container">
      <QuantityInput v-model="catchCropYield" class="input-item" :unit-options="validUnits" />
    </span>
  </div>
</template>

<script>
import QuantityInput from '@components/forms/QuantityInput'

import ParameterMixin, {mapParameters} from '../ParameterMixin'

export default {
  components: {
    QuantityInput
  },
  mixins: [
    ParameterMixin
  ],
  computed: {
    ...mapParameters([
      'mainCropYield',
      'catchCropYield'
    ]),
    validUnits () {
      return [
        'kg/ha',
        'dt/ha',
        'Bund/ha',
        'piece/ha'
      ].sort()
    }
  }
}
</script>

<style scoped>
.harvest-form-container {
  display: flex;
  flex-direction: column;
}
.item-container {
  display: flex;
  margin-bottom: 16px;
}
.input-item {
  width: 21%;
}
.unit-selection {
  width: 13%;
}
</style>
