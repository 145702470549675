<template lang="html">
  <IxVectorLayer
    :features="features" :vector-style="vectorStyle"
    :z-index="1"
  />
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {mapToGeoJsonFeatures} from '@helpers/map-color-schemes'
import * as mapStyles from '@frs/map-styles/maps'

import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  components: {
    IxVectorLayer
  },
  props: {
    zoneMapId: {
      type: String,
      required: true
    },
    applicationValues: {
      type: Object,
      required: true
    },
    palette: {
      type: String,
      default: 'agrosat'
    }
    // showPlaceholder: Boolean
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      zoneMapLookup: state => state.data.zoneMap.mapLookup
    }),
    features () {
      const zoneMap = this.zoneMapLookup[this.zoneMapId]

      if (!zoneMap || !zoneMap.zones) {
        if (!this.showPlaceholder) return []
        //
        // const fieldId = this.fieldIdByZoneMapId && this.fieldIdByZoneMapId[this.zoneMapId]
        //
        // if (!fieldId) return []
        //
        // return features.concat(makePlaceholderFeature(fieldId))
      }

      if (zoneMap.zones.length !== Object.keys(this.applicationValues).length) {
        throw new Error('zoneMap and applicationValues have different length')
      }

      const applicationMap = {
        ...zoneMap,
        zones: zoneMap.zones.map(zone => ({...zone, value: this.applicationValues[zone.id]}))
      }

      return mapToGeoJsonFeatures(applicationMap, this.palette)
    },
    vectorStyle () {
      return mapStyles[this.palette]
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'loadZoneMapDetails'
    ])
  },
  watch: {
    zoneMapId: {
      immediate: true,
      handler (zoneMapId) {
        if (zoneMapId) {
          this.loadZoneMapDetails(zoneMapId)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
