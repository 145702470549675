<template lang="html">
  <div>
    <h3>
      <IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.CommonSettings_header</IxRes>
    </h3>

    <div class="input-row">
      <FrsCultivationPicker
        v-model="cultivationId" name="cultivationId"
        :field-id="fieldId"
        required
      >
        <template #label>
          <IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.CulticationSelection_header</IxRes>
        </template>
      </FrsCultivationPicker>
    </div>

    <div class="input-row">
      <FrsProductPicker
        v-model="productId" name="productId"
        :type="productType"
        :available-product-filter="availableProductFilter"
        required
      >
        <template #label>
          <IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.ProductSettings_header</IxRes>
        </template>
      </FrsProductPicker>

      <FrsUnitPicker
        v-model="unit" name="unit"
        :filter="unitFilter"
        :disabled="!algorithm"
        required
      >
        <template #label>
          <IxRes>applicationMaps.algorithmicCreation.labels.unit</IxRes>
        </template>
      </FrsUnitPicker>
    </div>

    <div class="input-row">
      <AlgorithmSelection
        v-model="algorithm" name="algorithm"
        :filter="algorithmFilter"
        required
      />
    </div>

    <div class="input-row">
      <FrsZoneMapSelection
        v-model="zoneMapId" name="zoneMapId"
        :field-id="fieldId"
        show-on-map hide-label
        required
      >
        <template #label>
          <IxRes :context="{0: fieldName}">Areas.FieldRecordSystem.SR_FieldRecordSystem.ZoneMapFor</IxRes>
        </template>
      </FrsZoneMapSelection>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

import {mapFormFields} from '@helpers/vuex'
import {validUnits} from '@store/modules/field-record-system/application-maps/algorithm-definitions'
import {can} from '@helpers/permissions'
import {smarterGet} from '@helpers/vuex/data-loading'

import productGroupPermissions from './product-group-permissions'

import FrsCultivationPicker from '@frs/components/base/FrsCultivationPicker'
import FrsProductPicker from '@frs/components/base/FrsProductPicker'
import FrsZoneMapSelection from '@frs/components/base/FrsZoneMapSelection'
import FrsUnitPicker from '@frs/components/base/FrsUnitPicker'
import AlgorithmSelection from './AlgorithmSelection'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    FrsCultivationPicker,
    FrsProductPicker,
    FrsZoneMapSelection,
    FrsUnitPicker,
    AlgorithmSelection
  },
  mixins: [
    PermissionMixin
  ],
  data () {
    return {
      cultivationsByFieldId: {}
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/applicationMaps', [
      'parameters.unit',
      'parameters.productId',
      'parameters.zoneMapId',
      'parameters.cultivationId',
      'parameters.cropUsageId',
      'parameters.additionalZoneValues'
    ]),
    ...mapFormFields('fieldRecordSystem/applicationMaps', {
      'parameters.algorithm': {
        write (value) {
          return this.productAlgorithms[value]
        }
      }
    }),
    ...mapState('fieldRecordSystem', {
      harvestYear: state => state.userSettings.harvestYear,
      orgUnitId: state => state.navigation.location.orgUnitId
    }),
    ...mapState('fieldRecordSystem/applicationMaps', {
      algorithmCollection: state => state.ui.algorithmCollection
    }),
    ...mapState('masterData', {
      cropUsages: state => state.cropUsages
    }),
    ...mapGetters('fieldRecordSystem', [
      'fieldId',
      'fieldName'
    ]),
    ...mapState({
      permissions: state => state.permissions
    }),
    ...mapState('masterData', [
      'productAlgorithms'
    ]),
    ...mapGetters('masterData', [
      'unitArray'
    ]),
    algorithmObject () {
      return this.productAlgorithms[this.algorithm]
    },
    validUnits () {
      return validUnits[this.algorithmObject && this.algorithmObject.algorithmGroup] || []
    },
    cultivation () {
      return (this.cultivationsByFieldId[this.fieldId] || []).find(x => x.id === this.cultivationId) || null
    },
    productType () {
      return this.algorithmCollection === 'Seeds' ? 'seed' : 'fertilizer'
    },
    availableProductFilter () {
      return this.productType === 'seed'
        ? product => !this.cultivation || product.cropId === this.cropUsages[this.cultivation.cropUsageId].cropId
        : undefined
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/applicationMaps', [
      'loadAdditionalZoneValues'
    ]),
    unitFilter (unit) {
      return this.validUnits.includes(unit.shortcut)
    },
    algorithmFilter (algorithm) {
      return this.$can(productGroupPermissions[algorithm.productGroup]) && algorithm.productGroup === this.algorithmCollection
    }
  },
  watch: {
    zoneMapId: 'loadAdditionalZoneValues',
    algorithm: 'loadAdditionalZoneValues',
    cultivationId (value) {
      this.ctopUsageId = value ? this.cultiavtion.cropUsageId : null
    }
  },
  async created () {
    if (can(this.permissions, 'Use.Frs.Cultivations')) {
      this.cultivationsByFieldId = await smarterGet('/api/v2/entities/orgunits/{orgUnitId}/cultivations/fields/{harvestYear}/crop-usage', {
        id: 'orgUnit.cultivations.applicationMaps',
        inputs: {
          orgUnitId: () => this.orgUnitId,
          harvestYear: () => this.harvestYear
        },
        onResult: (result) => {
          return result
        }
      })
    } else {
      Promise.resolve()
    }
  }
}
</script>

<style lang="scss" scoped>
.input-row {
  display: flex;

  > * {
    flex: 1;
  }

  > * + * {
    margin-left: 0.5em;
  }
}
</style>
