<template lang="html">
  <div>
    <LabelAndMessage>
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EcologicalPriorityArea</IxRes>:
      </template>
      <SimpleSwitch :value="value.ecoPriorityArea" @input="emitEcoPriorityArea" />
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.OtherSubsidyPlan</IxRes>:
      </template>
      <SimpleSwitch :value="value.other" @input="emitOtherSubsidy" />
    </LabelAndMessage>
  </div>
</template>

<script>
import SimpleSwitch from '@components/forms/SimpleSwitch'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    LabelAndMessage,
    SimpleSwitch
  },
  props: {
    value: Object
  },
  methods: {
    emitEcoPriorityArea (ecoPriorityArea) {
      const {other} = this.value
      this.$emit('input', {ecoPriorityArea, other})
    },
    emitOtherSubsidy (other) {
      const {ecoPriorityArea} = this.value
      this.$emit('input', {ecoPriorityArea, other})
    }
  }
}
</script>

<style lang="css" scoped>
  .subsidy-plan {
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>
