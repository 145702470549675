<template>
  <div>
    <div class="main-crop-container">
      <BsRadio v-model="selected" class="bs-radio" :value="1">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.PreviousCrop</IxRes>
      </BsRadio>
      <VueSelectize
        v-model="previousCrop"
        :disabled="selected === 2"
        class="crop-selector"
        allow-empty
        :options="previousCropsData"
        :placeholder="SR_FieldRecordSystem.SelectPlaceholder"
      />
    </div>
    <div class="catch-crop-container">
      <BsRadio v-model="selected" class="bs-radio" :value="2">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CatchCrop</IxRes>
      </BsRadio>
      <VueSelectize
        v-model="catchCrop"
        :disabled="selected === 1"
        allow-empty
        class="crop-selector"
        :options="catchCropsData"
        :placeholder="SR_FieldRecordSystem.SelectPlaceholder"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'

import BsRadio from '@components/bootstrap/BsRadio'
import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    BsRadio,
    VueSelectize
  },
  data () {
    return {
      selected: null
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/nutrientRequirement/editBuffer', [
      'catchCrop',
      'previousCrop'
    ]),
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState('fieldRecordSystem', {
      previousCrops: state => state.nutrientRequirement.data.editing.previousCrops,
      catchCrops: state => state.nutrientRequirement.data.editing.catchCrops
    }),
    previousCropsData () {
      return Object.keys(this.previousCrops).map(crop => ({text: crop, value: crop, amount: this.previousCrops[crop]}))
    },
    catchCropsData () {
      return Object.keys(this.catchCrops).map(crop => ({text: crop, value: crop, amount: this.catchCrops[crop]}))
    },
    isCatchCrop () {
      return this.catchCrop ? (!!this.catchCrop.text) : false
    },
    isPreviousCrop () {
      return this.previousCrop ? (!!this.previousCrop.text) : false
    }
  },
  watch: {
    selected (value) {
      if (value === 1) {
        this.catchCrop = null
      } else {
        this.previousCrop = null
      }
    }
  },
  mounted () {
    this.selected = this.isPreviousCrop ? 1 : (this.isCatchCrop ? 2 : 1)
  }
}
</script>

<style lang="scss" scoped>
.main-crop-container, .catch-crop-container, .crop-selector {
  margin-left: 20px;
}
.crop-selector {
  width: 75%;
  margin-bottom: -5px;
}
</style>
