<template>
  <div class="component">
    <FrsLoadingIndicator :loading="computing" />

    <FrsHeader>
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_SimonModule.SimonModule</IxRes>
      </template>
      <template #subTitle>
        <IxRes>Areas.FieldRecordSystem.SR_SimonModule.CreatePApplicationMap</IxRes>
      </template>
    </FrsHeader>

    <div class="content">
      <div>
        <IxButton
          class="space" large
          icon="plus"
          @click="showYieldDetails"
        >
          <IxRes>Areas.FieldRecordSystem.SR_SimonModule.AddYieldDetails</IxRes>
        </IxButton>
        <div v-if="orderedYieldData.length">
          <InfoCard
            v-for="data in orderedYieldData" :key="data.id"
            :info-card-details="data" @removeInfoCard="onRemoveYieldCard(data)"
            @onCardClick="showYieldDetails(data)"
          />
        </div>
        <div v-else>
          <IxRes>Areas.FieldRecordSystem.SR_SimonModule.NoYieldsSelected</IxRes>
        </div>
      </div>
      <br>
      <div>
        <IxButton
          class="space" large
          icon="plus"
          @click="addApplicationMap"
        >
          <IxRes>Areas.FieldRecordSystem.SR_SimonModule.AddApplicationDetails</IxRes>
        </IxButton>
        <div v-if="orderedApplicationData.length">
          <InfoCard
            v-for="data in orderedApplicationData" :key="data.id"
            :info-card-details="data" @removeInfoCard="onRemoveProductionCard(data)"
            @onCardClick="showProductionDetails(data)"
          />
        </div>
        <div v-else>
          <IxRes>Areas.FieldRecordSystem.SR_SimonModule. NoApplicationsSelected</IxRes>
        </div>
      </div>

      <NumericInput
        v-model="numberZones" integer
        :minimum="1"
        name="ZoneCount" keyboard-input-disabled
      >
        <template #label>
          <IxRes>Areas.FieldRecordSystem.SR_SimonModule.ZoneNumber</IxRes>
        </template>
      </NumericInput>

      <NumericInput
        v-model="numberYears" integer
        :minimum="1"
        name="YearCount" keyboard-input-disabled
      >
        <template #label>
          <IxRes>Areas.FieldRecordSystem.SR_SimonModule.YearNumber</IxRes>
        </template>
      </NumericInput>

      <NumericInput
        v-model="maxApplicationValue" class="half-width"
        required
        unit="kg/ha" name="maxApplicationValue"
      >
        <template #label>
          <IxRes>Areas.FieldRecordSystem.SR_SimonModule.MaximalOutput</IxRes>
        </template>
      </NumericInput>

      <div class="half-width">
        <TextInput v-model="mapName" required name="mapName">
          <template #label>
            <IxRes>Common.SR_Common.ApplicationMapName</IxRes>
          </template>
        </TextInput>
      </div>

      <div class="half-width">
        <FrsProductPicker v-model="productId" name="selectedProduct">
          <template #label>
            <IxRes>Common.SR_Common.Product</IxRes>
          </template>
        </FrsProductPicker>
      </div>

      <div class="component-actions">
        <IxButton submit large @click="onStartClick">
          <IxRes>Common.SR_Common.StartCalculation</IxRes>
        </IxButton>
      </div>
    </div>
  </div>
</template>

<script>
import {orderBy} from 'lodash'
import Vue from 'vue'

import {mapFormFields} from '@helpers/vuex'
import {mapState, mapActions, mapMutations} from 'vuex'

import makeReloadDataMixin from '@frs/mixins/make-reload-data-mixin'
import StandardRulesMixin from '@components/forms/StandardRulesMixin'
import FormPartMixin from '@components/forms/FormPartMixin'

import {notifications} from 'src/js/infrastructure'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import FrsHeader from '@frs/components/base/FrsHeader'
import IxButton from '@components/IxButton'
import NumericInput from '@components/forms/NumericInput'
import TextInput from '@components/forms/TextInput'
import FrsProductPicker from '@frs/components/base/FrsProductPicker'

import InfoCard from './InfoCard'

export default {
  components: {
    TextInput,
    FrsHeader,
    IxButton,
    NumericInput,
    InfoCard,
    FrsLoadingIndicator,
    FrsProductPicker
  },
  mixins: [
    StandardRulesMixin,
    FormPartMixin,
    makeReloadDataMixin([
      state => state.fieldRecordSystem.navigation.location.orgUnitId,
      state => state.fieldRecordSystem.navigation.location.fieldId
    ])
  ],
  data () {
    return {
      computing: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/simonModule', {
      yieldData: state => state.creation.yieldData,
      applicationData: state => state.creation.applicationData
    }),
    ...mapFormFields('fieldRecordSystem/simonModule', [
      'creation.numberZones',
      'creation.numberYears',
      'creation.maxApplicationValue',
      'creation.mapName',
      'creation.productId'
    ]),
    orderedYieldData () {
      return orderBy(this.yieldData, ['number'])
    },
    orderedApplicationData () {
      return orderBy(this.applicationData, ['number'], ['desc'])
    },
    state () {
      const states = {
        inferredState: this.inferredState
      }

      if (this.applicationData.Length === 0) {
        states.applicationDataState = 'error'
      }

      if (this.yieldData.Length === 0) {
        states.yieldDataState = 'error'
      }

      return this.combineStates(states) || 'success'
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/simonModule', [
      'startComputation',
      'startAddApplicationMap',
      'viewResultPage'
    ]),
    ...mapMutations('fieldRecordSystem/simonModule', [
      'setApplicationData',
      'resetYieldDetails',
      'removeYieldCard',
      'removeProductionCard'
    ]),
    ...mapMutations('fieldRecordSystem', [
      'setRightView'
    ]),
    ...mapActions('fieldRecordSystem/applicationMaps', [
      'createApplicationMapSuccess'
    ]),
    onStartClick () {
      this.validate()
      Vue.nextTick()
        .then(() => {
          if (this.state !== 'success') {
            return
          }

          this.computing = true
          this.viewResultPage()
          this.startComputation()
            .then((applicationMapId) => {
              this.computing = false
              notifications.success('Map created!')
              this.createApplicationMapSuccess(applicationMapId)
            })
            .catch(() => {
              this.computing = false
              notifications.error('Error in map creation. o.O')
            })
        })
    },
    showYieldDetails (yieldData) {
      this.resetYieldDetails(yieldData)
      this.setRightView('yieldDetails')
    },
    showProductionDetails (value) {
      this.setApplicationData(value)
      this.startAddApplicationMap()
    },
    addApplicationMap () {
      this.startAddApplicationMap()
    },
    onRemoveYieldCard (yieldData) {
      this.removeYieldCard(yieldData.id)
    },
    onRemoveProductionCard (applicationData) {
      this.removeProductionCard(applicationData.id)
    }
  },
  reloadData () {
  }
}
</script>

<style lang="scss" scoped>
.space {
  margin-bottom: 5px;
}
.component {
  .content {
    > * {
      margin-bottom: 8px;
    }
  }
  .component-actions {
    margin-top: 6px;
    margin-left: 4px;
    display: flex;
    justify-content: flex-end;
  }
}
.half-width{
  width: 50%;
}
</style>
