<template lang="html">
  <div class="zone-map-import-settings">
    <h3>
      <IxRes>frs.mapManagement.soilMapImport.headers.main</IxRes>
    </h3>

    <TextInput v-model="name" name="name" required>
      <template #label>
        <IxRes>frs.mapManagement.soilMapImport.labels.name</IxRes>
      </template>
    </TextInput>

    <VueDateTimePicker
      v-model="generatedAt" name="generatedAt"
      format="L LT" iso
      required
    >
      <template #label>
        <IxRes>frs.mapManagement.soilMapImport.labels.createdAt</IxRes>
      </template>
    </VueDateTimePicker>

    <VueSelectize
      v-model="idColumn" name="idColumn"
      :options="availableColumns"
      required allow-empty
    >
      <template #label>
        <IxRes :context="{mapType: $i18n.translate(`frs.mapManagement.soilMapImport.labels.mapType_${mapType}`)}">frs.mapManagement.soilMapImport.labels.idColumn</IxRes>
      </template>
    </VueSelectize>

    <IxVectorLayer :features="previewFeatures" :vector-style="vectorStyle" auto-focus />

    <div class="buttons">
      <IxButton large cancel @click="onCancel" />
      <IxButton
        large save
        @click="onSave"
      >
        <IxRes>frs.mapManagement.zoneMapImport.buttons.finishImport</IxRes>
      </IxButton>
    </div>

    <HelpBox v-if="mapType === 'soilEstimation'" class="soil-estimation-help-text">
      <IxRes>frs.mapManagement.soilMapImport.helpText.soilGroupAlsoImported</IxRes>
    </HelpBox>

    <FrsLoadingIndicator :loading="saving" />
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import {toWkt} from '@helpers/openlayers/features'

import {groupBy, sortBy} from 'lodash'

import {mapFormFields} from '@helpers/vuex'
import {phbb} from '@frs/map-styles'
import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'
import TextInput from '@components/forms/TextInput'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import VueSelectize from '@components/VueSelectize'
import IxVectorLayer from '@components/map/IxVectorLayer'
import HelpBox from '@components/help/HelpBox'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'
import DisableDefaultMapInteractionsMixin from '@frs/mixins/DisableDefaultMapInteractionsMixin'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    IxButton,
    HelpBox,
    TextInput,
    VueDateTimePicker,
    VueSelectize,
    IxVectorLayer,
    FrsLoadingIndicator
  },
  mixins: [
    DisableDefaultMapInteractionsMixin,
    FormPartMixin,
    MirrorRouteMixin
  ],
  data () {
    return {
      saving: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement/soilMapImport', [
      'features',
      'mapType'
    ]),
    ...mapGetters('fieldRecordSystem/mapManagement/soilMapImport', [
      'availableColumns'
    ]),
    ...mapFormFields('fieldRecordSystem/mapManagement/soilMapImport', [
      'name',
      'generatedAt',
      'idColumn'
    ]),
    previewFeatures () {
      if (this.idColumn === null) return []

      const groupedZones = groupBy(this.features, feature => feature.properties[this.idColumn])

      const orderedZoneValues = sortBy(this.features.map(feature => feature.properties[this.idColumn]), x => x).filter((value, index, arr) => arr.indexOf(value) === index).map(value => value.toString())
      const orderedGroupedZoneFeatures = {}
      orderedZoneValues.forEach(value => {
        orderedGroupedZoneFeatures[value] = groupedZones[value]
      })

      const zoneCount = Object.keys(orderedGroupedZoneFeatures).length

      const features = []
      Object.keys(orderedGroupedZoneFeatures).forEach((key, i) => {
        const groupedFeatures = orderedGroupedZoneFeatures[key]
        const feats = groupedFeatures.map((x) => ({
          ...x,
          properties: {
            ...x.properties,
            value: x.properties[this.idColumn].toString(),
            zone: i + 1,
            zoneCount,
            displayValue: x.properties[this.idColumn].toString()
          }
        }))
        feats.forEach(feat => features.push(feat))
      })

      return features
    },
    vectorStyle () {
      return phbb(0.85, this.mapType)
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement/soilMapImport', [
      'finishImport',
      'setMapName'
    ]),
    ...mapMutations('fieldRecordSystem', [
      'setRightView'
    ]),
    ...mapMutations('dataLoading', [
      'invalidate'
    ]),
    errorMessage (feature) {
      return toWkt(feature)
    },
    onCancel () {
      /* setting the default right view and commenting out the router push is a temporary fix to enable navigation from this component which was broken.
         once the navigation to this component is handled by routing, setting the right view should be replaced by the router push
         */
      // this.$router.push({name: this.createParallelRoute('mapManagement'), params: {...this.$route.params}})
      this.setRightView('default')
    },
    async onSave () {
      this.validate()
      await this.$nextTick()

      if (this.state !== 'success') {
        // TODO notification
        return
      }

      this.saving = true

      try {
        await this.finishImport()
        // needs to be called to enable reload of zonemaps after import
        this.invalidate('soilMapInfos.forField')
        notifications.success(this.$i18n.translate('frs.soilMapImport.notifications.success'))
      } catch (error) {
        console.error(error)
        notifications.error(error.response.data.failure)
      } finally {
        this.saving = false
        /* setting the default right view and commenting out the router push is a temporary fix to enable navigation from this component which was broken.
         once the navigation to this component is handled by routing, setting the right view should be replaced by the router push
         */
        // this.$router.push({name: this.createParallelRoute('mapManagement'), params: {...this.$route.params}})
        this.setRightView('default')
      }
    }
  },
  watch: {
    mapType () {
      this.setMapName()
    }
  }
}
</script>

<style lang="scss" scoped>
.zone-map-import-settings {
  position: relative;
}

.buttons {
  >* {
    margin-right: 8px;
  }
}

.soil-estimation-help-text{
  margin-top: 20px;
}
</style>
