<template lang="html">
  <div class="lane-planning-field-tools">
    <h3>geoTrax Fahrspurplanung</h3>
    <h4>Fläche teilen</h4>
    <hr>
    <p>
      Teilen oder bearbeiten Sie die Fläche über die Geometrie-Funktion auf der linken
      Seite. Wenn Sie die Fläche in mehrere Flächen trennen, können Sie anschließend
      seperate Fahrspurplanungen für die verschiedenen Teilflächen durchführen.
    </p>
    <p><b>Die Bearbeitung wird nur für diese Fahrspurplanung und nicht dauerhaft gespeichert</b></p>
    <hr>
    <IxButton large remove @click="stopEditing">
      Abbrechen
    </IxButton>
    <IxButton large save @click="commitEditedFieldGeometry">
      Speichern und weiterplanen
    </IxButton>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton
  },
  methods: {
    ...mapActions('fieldRecordSystem/map', [
      'stopEditing'
    ]),
    ...mapActions('fieldRecordSystem/lanePlanning', [
      'commitEditedFieldGeometry'
    ])
  }
}
</script>

<style lang="scss" scoped>
  hr {
    border-color: #cbcbcb;
  }
</style>
