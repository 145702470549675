<template>
  <SimpleTable
    :columns="{name: 'Nutzungsrichtung', percentage: 'Anteil [%]'}"
    :data="data"
  />
</template>

<script>
import {orderBy} from 'lodash'

import {mapState} from 'vuex'

import SimpleTable from '@components/table/SimpleTable'

export default {
  components: {
    SimpleTable
  },
  props: {
    mixtureComponents: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('i18n', {
      usageTranslations: state => state.translations['masterData.crops.usage']
    }),
    sortedComponents () {
      return orderBy(this.mixtureComponents, ['percentage', 'cropUsageId'], ['desc', 'asc'])
    },
    data () {
      return this.sortedComponents.map(x => ({name: this.usageTranslations[`${x.cropUsageId}_name`], percentage: x.percentage * 100}))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
