<template lang="html">
  <div class="requirement-chart" :style="{height: '200px', width: '95%'}">
    <BarChart
      ref="barChart" :data="data"
      stacked
      percent :color-function="colorFunction"
      :additional-options="additionalOptions" :plugins="[plugin]"
    />
  </div>
</template>

<script>
import {mapResources} from '@helpers/vuex'

import BarChart from '@components/charts/BarChart'

import columns from './nutrient-columns'

export default {
  components: {
    BarChart
  },
  props: {
    nutrientStatus: Object
  },
  computed: {
    ...mapResources([
      'frs.action.planning.fertilization.nutrientSummary.headers'
    ]),
    data () {
      const requirements = this.nutrientStatus.data[0]

      return this.nutrientStatus.data.slice(1, 4).map(row => {
        return Object.keys(columns).map((nutrient) => {
          const value = row[nutrient]
            ? row[nutrient] / (requirements[nutrient] || 0)
            : 0
          const label = this.headers[nutrient]

          return {
            value,
            label
          }
        })
      })
    },
    additionalOptions () {
      return {
        scales: {
          yAxes: [{
            ticks: {
              suggestedMax: 1.2
            }
          }]
        },
        tooltips: {
          callbacks: {
            title: tooltipItemArray => {
              const nutrient = Object.keys(columns)[tooltipItemArray[0].index]
              return this.headers[nutrient]
            }
          }
        },
        maintainAspectRatio: false
      }
    },
    plugin () {
      return {
        beforeDatasetsDraw: chart => {
          chart.ctx.save()

          const y = this.dataValueToCanvasY(1)

          chart.ctx.beginPath()

          chart.ctx.moveTo(chart.chartArea.left, y)
          chart.ctx.lineTo(chart.chartArea.right, y)

          chart.ctx.lineWidth = 1
          chart.ctx.strokeStyle = 'darkgreen'
          chart.ctx.setLineDash([10, 10])

          chart.ctx.stroke()

          chart.ctx.restore()
        },
        afterDatasetsDraw: chart => {
          chart.ctx.save()

          const y = this.dataValueToCanvasY(1)

          const meta = chart.getDatasetMeta(2)

          meta.data
            .filter(x => x._model.y <= y)
            .forEach(element => {
              chart.ctx.fillStyle = 'green'
              chart.ctx.textAlign = 'center'
              chart.ctx.textBaseline = 'bottom'
              chart.ctx.fillText('✅', element._model.x, Math.max(element._model.y - 2, 25))
            })

          chart.ctx.restore()
        }
      }
    }
  },
  methods: {
    colorFunction ({datasetIndex}) {
      const colors = ['blue', 'darkorange', 'gray']
      return colors[datasetIndex]
    },
    dataValueToCanvasY (value) {
      const yScale = this.$refs.barChart.chart.scales['y-axis-0']

      return yScale.bottom - (value / (yScale.end - yScale.start)) * yScale.height
    }
  }
}
</script>

<style lang="scss" scoped>
.requirement-chart {
  margin-top: 10px;
}
</style>
