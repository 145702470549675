<template lang="html">
  <div class="quick-actions" @click.stop>
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.quick-actions {
  display: flex;
  cursor: default;

  color: darkgray;
  font-size: 1em;
}
</style>
