<template>
  <div class="rows">
    <div class="label-row">
      <label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SeedProduct</IxRes>
      </label>
      <label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ApplicationRate</IxRes>
      </label>
    </div>
    <ProductQuantityRow
      v-for="(row, index) in updatedRows"
      :key="index"
      :value="row"
      :can-delete="buffer.length !== index"
      v-bind="{index, availableProductFilter, entityLookup, products, recommendedIds, previouslyUsedProducts}"
      @input="updateRow(index, $event)"
      @delete="deleteRow(index)"
    />
    <div v-if="hasIncompleteRows" class="alert alert-warning">
      <IxRes>core.products.quantityEditor.incompleteQuantitiesExist</IxRes>
    </div>
  </div>
</template>

<script>

import {mergeWithValue, filterCompleted} from '@components/product-quantity-editor/buffer-handling'

import ProductQuantityRow from './ProductQuantityRow'

export default {
  components: {
    ProductQuantityRow
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    // forwarded to ProductPicker
    entityLookup: Object,
    products: Object,
    recommendedIds: {
      type: Array,
      default: () => []
    },
    previouslyUsedProducts: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      buffer: [] // incompleted rows
    }
  },
  computed: {
    updatedRows () {
      const clone = [...this.buffer]
      clone.push({productId: null, amount: null, unit: null})
      return clone
    },
    availableProductFilter () {
      return product => !this.buffer.some(used => used.productId === product.id)
    },
    hasIncompleteRows () {
      return this.value.length !== this.buffer.length
    }
  },
  methods: {
    updateRow (index, event /* {productId, amount, unit} */) {
      this.buffer[index] = event
      this.$emit('input', filterCompleted(this.buffer))
    },
    deleteRow (index) {
      this.buffer.splice(index, 1)
      this.$emit('input', filterCompleted(this.buffer))
    }
  },
  watch: {
    value (quantities) {
      this.buffer = mergeWithValue(this.buffer, quantities)
    }
  },
  created () {
    this.buffer = this.value
  }
}
</script>

<style lang="scss" scoped>
.rows {
  display: flex;
  flex-direction: column;
}
.label-row {
  display: grid;
  grid-template-columns: 60% 60% 8%;
  margin-bottom: 5px;
}
</style>
