<template lang="html">
  <BsModal v-model="showModal" :title="title">
    <TextInput v-model="name">
      <template slot="label">
        <IxRes>Common.SR_Common.Name</IxRes>: *
      </template>
    </TextInput>
    <IxButton
      slot="footer" save
      :disabled="!isValid"
      @click="save"
    >
      <IxRes>Common.SR_Common.Save</IxRes>
    </IxButton>
    <IxButton slot="footer" cancel @click="stopEditing">
      <IxRes>Common.SR_Common.Cancel</IxRes>
    </IxButton>
    <FrsLoadingIndicator :loading="saving" />
    <slot slot="title" name="title" />
  </BsModal>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import BsModal from '@components/bootstrap/BsModal'
import IxButton from '@components/IxButton'
import TextInput from '@components/forms/TextInput'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    BsModal,
    IxButton,
    TextInput,
    FrsLoadingIndicator
  },
  props: [
    'title'
  ],
  data: () => ({
    saving: false
  }),
  computed: {
    ...mapFormFields('fieldRecordSystem/persons/editBuffer', [
      'name'
    ]),
    ...mapResources([
      'Messages.State.SR_StateMessages'
    ]),
    ...mapState('fieldRecordSystem/persons', {
      editing: state => state.ui.editing
    }),
    showModal: {
      get () {
        return this.editing
      },
      set (value) {
        this.setEditing(value)
      }
    },
    isValid () {
      return this.name
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/persons', [
      'stopEditing',
      'savePerson'
    ]),
    ...mapMutations('fieldRecordSystem/persons', [
      'setEditing'
    ]),
    save () {
      this.saving = true
      this.savePerson()
        .catch(() => {
          notifications.error(this.SR_StateMessages.SaveError)
        })
        .then(() => {
          notifications.success(this.SR_StateMessages.SaveSuccess)
          this.stopEditing()
          this.saving = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
