import { render, staticRenderFns } from "./CommonDetails.vue?vue&type=template&id=1e8e5128&scoped=true&"
import script from "./CommonDetails.vue?vue&type=script&lang=js&"
export * from "./CommonDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e8e5128",
  null
  
)

export default component.exports