<template>
  <div>
    <ProductNames v-bind="{part, products}">
      <IxRes>frs.actions.seeding.product</IxRes>:
    </ProductNames>

    <ShowDetails>
      <template #label>
        <IxRes>frs.actions.seeding.PlantsPerSqm</IxRes>:
      </template>

      {{ Math.round(part.parameters.plantsPerSqm) }} pro {{ $i18n.translateUnit('m²') }}
    </ShowDetails>

    <ShowDetails v-if="isCorn">
      <template #label>
        <IxRes>frs.actions.seeding.RowDistance</IxRes>:
      </template>

      {{ part.parameters.rowDistance }} {{ $i18n.translateUnit('cm') }}
    </ShowDetails>

    <ShowDetails v-if="isCorn">
      <template #label>
        <IxRes>frs.actions.seeding.RowSeparation</IxRes>:
      </template>

      {{ part.parameters.rowSeparation }} {{ $i18n.translateUnit('cm') }}
    </ShowDetails>

    <ShowDetails v-if="!isCorn">
      <template #label>
        <IxRes>frs.actions.seeding.ThousandSeedWeight</IxRes>:
      </template>

      {{ part.parameters.thousandSeedWeight }} {{ $i18n.translateUnit('g') }}
    </ShowDetails>

    <ShowDetails v-if="!isCorn">
      <template #label>
        <IxRes>frs.actions.seeding.GerminationCapacity</IxRes>:
      </template>

      {{ part.parameters.germinationCapacity }} {{ $i18n.translateUnit('%') }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>frs.actions.seeding.Emergence</IxRes>:
      </template>

      {{ part.parameters.emergence }} {{ $i18n.translateUnit('%') }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>frs.actions.seeding.coating</IxRes>:
      </template>

      {{ part.parameters.coating }}
    </ShowDetails>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {isMaize} from '@helpers/products'

import ShowDetails from './ShowDetails'
import ProductNames from './ProductNames'

export default {
  components: {
    ShowDetails,
    ProductNames
  },
  props: {
    part: Object,
    products: Object
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      fieldData: state => state.data.field
    }),
    isCorn () {
      const maizeStatus = this.part.productQuantities.map(({productId}) => isMaize(this.products[productId]))

      if (maizeStatus.some(x => x) && maizeStatus.some(x => !x)) {
        throw new Error('mixed corn/non-corn products in one seeding action')
      }

      return maizeStatus
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
