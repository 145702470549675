var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SharedSummary',{attrs:{"meta-data":_vm.metaData},scopedSlots:_vm._u([{key:"images",fn:function(){return [_c('ZoneMapDetailsPanel',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('IxRes',[_vm._v("frs.zoneMaps.details.headers.imageSearchOptions")])]},proxy:true}])},[_c('div',{class:_vm.$style.options},[_c('IxRes',{attrs:{"context":{
            buffer: _vm.$i18n.format(_vm.metaData.imageSearchSummary.options.cloudStatisticsBuffer || 0, 'f2') + 'm',
            cloudTolerance: _vm.$i18n.format(_vm.metaData.imageSearchSummary.options.cloudCoverageThreshold, 'percent'),
            minNdvi: _vm.$i18n.format(_vm.minNdvi, 'f2'),
            maxNdvi: _vm.$i18n.format(_vm.maxNdvi, 'f2'),
            minNdviL2aOnly: _vm.$i18n.format(_vm.minNdviL2aOnly, 'f2'),
            maxNdviL2aOnly: _vm.$i18n.format(_vm.maxNdviL2aOnly, 'f2')
          }}},[_vm._v("\n          frs.zoneMaps.details.imageSearchOptionsDescription\n        ")]),_c('IxRes',{attrs:{"context":{
            targetPercentage: _vm.$i18n.format(_vm.metaData.imageSearchSummary.options.targetPercentage, 'percent'),
            stDevWeight: _vm.$i18n.format(_vm.metaData.imageSearchSummary.options.standardDeviationPrioritizationWeight, 'f2')
          }}},[_vm._v("\n          frs.zoneMaps.details.fallingEdgeOptionsDescription\n        ")])],1)]),_c('ZoneMapDetailsPanel',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('IxRes',[_vm._v("frs.zoneMaps.details.headers.imageSearchResults")])]},proxy:true}])},[_c('VueSelectize',{attrs:{"ordered":"","options":_vm.metaData.imageSearchSummary.cultivationSummaries,"fields":_vm.selectizeFields},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('IxRes',[_vm._v("frs.zoneMap.details.labels.cultivation")])]},proxy:true}]),model:{value:(_vm.selectedCultivationSummary),callback:function ($$v) {_vm.selectedCultivationSummary=$$v},expression:"selectedCultivationSummary"}}),_c('CultivationSummary',{attrs:{"summary":_vm.selectedCultivationSummary}})],1)]},proxy:true},{key:"availableimages",fn:function(ref){
          var image = ref.image;
return [_c('SatelliteImageReferenceWithStats',{attrs:{"image":image,"statistics":_vm.stDevLookup[((image.recordingDate) + "_" + (image.dataSource))]}})]}},{key:"stdevmean",fn:function(){return [_c('span',[_c('IxRes',{attrs:{"context":{
          stDevMean: _vm.$i18n.format(_vm.stDevMean, 'f2')
        }}},[_vm._v("\n        frs.zoneMap.details.image.standardDeviationMean\n      ")])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }