<template>
  <div class="form-container">
    <div class="input">
      <TextInput v-model="name" placeholder="Name" />
    </div>
    <div class="date">
      <VueDateTimePicker v-model="date" iso placeholder="Datum" />
    </div>
    <div class="input">
      <FrsCropUsagePicker v-model="cropUsageId">
        <template #label>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationSelection</IxRes>
        </template>
      </FrsCropUsagePicker>
    </div>
    <div class="key-selector">
      <VueSelectize
        v-model="timestampKey"
        :options="keyOptions"
        label="Zeitstempel" allow-empty
      />
    </div>
    <div class="key-selector">
      <VueSelectize
        v-model="valueKey"
        :options="keyOptions"
        label="Value" allow-empty
      />
    </div>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'

import TextInput from '@components/forms/TextInput'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import FrsCropUsagePicker from '@frs/components/base/FrsCropUsagePicker'
import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    TextInput,
    VueDateTimePicker,
    FrsCropUsagePicker,
    VueSelectize
  },
  props: {
    measurementKeys: Array
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/telemetry/dataImport', [
      'name',
      'date',
      'cropUsageId',
      'valueKey',
      'timestampKey'
    ]),
    keyOptions () {
      return this.measurementKeys.map(key => ({text: key, value: key}))
    }
  }
}
</script>

<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
}
.input, .date, .input {
  margin-bottom: 4px;
}
</style>
