<template lang="html">
  <div class="zone-map-import-settings">
    <h3>
      <IxRes>frs.mapManagement.zoneMapImport.headers.main</IxRes>
    </h3>

    <TextInput v-model="name" name="name" required>
      <template #label>
        <IxRes>frs.mapManagement.zoneMapImport.labels.name</IxRes>
      </template>
    </TextInput>

    <VueDateTimePicker
      v-model="generatedAt" name="generatedAt"
      format="L LT" iso
      required
    >
      <template #label>
        <IxRes>frs.mapManagement.zoneMapImport.labels.createdAt</IxRes>
      </template>
    </VueDateTimePicker>

    <VueSelectize
      v-model="idColumn" name="idColumn"
      :options="availableColumns"
      required allow-empty
    >
      <template #label>
        <IxRes>frs.mapManagement.zoneMapImport.labels.idColumn</IxRes>
      </template>
    </VueSelectize>

    <FrsZoneMapSelection
      v-model="originalZoneMapId"
      :required="false"
      :field-id="fieldId"
      show-on-map
      hide-label
      show-inactive-maps
    >
      <template #label>
        <IxRes>frs.mapManagement.zoneMapImport.labels.originalZoneMap</IxRes>
      </template>
    </FrsZoneMapSelection>
    <HelpBox>
      <IxRes>frs.mapManagement.zoneMapImport.description.originalZoneMap</IxRes>
    </HelpBox>
    <HelpBox v-if="errorFeatures.length" type="danger">
      <template>
        <IxRes>frs.mapManagement.zoneMapImport.labels.geometryError</IxRes>
        <li v-for="errorFeature in errorFeatures" :key="errorMessage(errorFeature)">
          {{ errorMessage(errorFeature) }}
        </li>
      </template>
    </HelpBox>

    <IxVectorLayer :features="errorFeatures" :vector-style="errorStyle" :z-index="1" />
    <IxVectorLayer :features="previewFeatures" :vector-style="vectorStyle" auto-focus />

    <div class="buttons">
      <IxButton large cancel @click="onCancel" />
      <IxButton
        large save
        :disabled="!!errorFeatures.length"
        @click="onSave"
      >
        <IxRes>frs.mapManagement.zoneMapImport.buttons.finishImport</IxRes>
      </IxButton>
    </div>

    <FrsLoadingIndicator :loading="saving" />
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import {toWkt} from '@helpers/openlayers/features'

import {mapFormFields} from '@helpers/vuex'
import {findIntersections, findKinks} from '@helpers/geojson/errors'
import {zonemap, error} from '@frs/map-styles'
import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'
import TextInput from '@components/forms/TextInput'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import VueSelectize from '@components/VueSelectize'
import IxVectorLayer from '@components/map/IxVectorLayer'

import FrsZoneMapSelection from '@frs/components/base/FrsZoneMapSelection'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'

import FormPartMixin from '@components/forms/FormPartMixin'
import HelpBox from '@components/help/HelpBox'

export default {
  components: {
    HelpBox,
    IxButton,
    TextInput,
    VueDateTimePicker,
    VueSelectize,
    IxVectorLayer,
    FrsLoadingIndicator,
    FrsZoneMapSelection
  },
  mixins: [
    FormPartMixin,
    MirrorRouteMixin
  ],
  data () {
    return {
      saving: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement/zoneMapImport', [
      'features'
    ]),
    ...mapGetters('fieldRecordSystem/mapManagement/zoneMapImport', [
      'availableColumns'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    ...mapFormFields('fieldRecordSystem/mapManagement/zoneMapImport', [
      'name',
      'generatedAt',
      'idColumn',
      'originalZoneMapId'
    ]),
    previewFeatures () {
      const makeNumeric = value => isNaN(value) ? 1 : value

      const zoneCount = Math.max(...this.features.map(x => makeNumeric(x.properties[this.idColumn])))

      return this.features.map((x, i) => ({
        ...x,
        properties: {
          ...x.properties,
          zone: makeNumeric(x.properties[this.idColumn]),
          zoneCount
        }
      }))
    },
    errorFeatures () {
      return this.previewFeatures.map((feature, i) => {
        const kinks = findKinks([feature])
        const intersections = findIntersections([feature])

        return kinks.concat(intersections)
      }).reduce((acc, cur) => acc.concat(cur), [])
    },
    vectorStyle () {
      return zonemap
    },
    errorStyle () {
      return error
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement/zoneMapImport', [
      'finishImport'
    ]),
    ...mapMutations('fieldRecordSystem', [
      'setRightView'
    ]),
    ...mapMutations('dataLoading', [
      'invalidate'
    ]),
    errorMessage (feature) {
      return toWkt(feature)
    },
    onCancel () {
      /* setting the default right view and commenting out the router push is a temporary fix to enable navigation from this component which was broken.
         once the navigation to this component is handled by routing, setting the right view should be replaced by the router push
         */
      // this.$router.push({name: this.createParallelRoute('mapManagement'), params: {...this.$route.params}})
      this.setRightView('default')
    },
    async onSave () {
      this.validate()
      await this.$nextTick()

      if (this.state !== 'success') {
        // TODO notification
        return
      }

      this.saving = true

      try {
        await this.finishImport()
        // needs to be called to enable reload of zonemaps after import
        this.invalidate('zoneMaps.forField')
        notifications.success(this.$i18n.translate('frs.zoneMapImport.notifications.success'))
      } catch (error) {
        console.error(error)
        notifications.error(error.response.data.failure)
      } finally {
        this.saving = false
        /* setting the default right view and commenting out the router push is a temporary fix to enable navigation from this component which was broken.
         once the navigation to this component is handled by routing, setting the right view should be replaced by the router push
         */
        // this.$router.push({name: this.createParallelRoute('mapManagement'), params: {...this.$route.params}})
        this.setRightView('default')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zone-map-import-settings {
  position: relative;
}
.buttons {
  & >* {
    margin-right: 8px;
  }
}
</style>
