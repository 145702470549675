<template>
  <VueSelectize
    v-model="modelProxy"
    :name="name"
    :options="options"
    :fields="fieldConfig"
    :required="required"
    :disabled="disabled"
    :allow-empty="!options.length"
  >
    <slot slot="label" name="label" />
  </VueSelectize>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

import {modelProxy} from '@helpers/vuex'

import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    VueSelectize
  },
  props: {
    value: String, // unitShortcut
    label: String,
    required: Boolean,
    disabled: Boolean,
    filter: Function,
    name: String
  },
  computed: {
    modelProxy,
    ...mapState('masterData', [
      'units'
    ]),
    ...mapGetters('masterData', [
      'unitArray'
    ]),
    availableUnits () {
      return this.filter ? this.unitArray.filter(this.filter) : this.unitArray
    },
    options () {
      return this.availableUnits.map(x => x.shortcut)
    },
    fieldConfig () {
      return {
        label: shortcut => this.units[shortcut].name
      }
    }
  },
  watch: {
    availableUnits: {
      immediate: true,
      handler (units) {
        if (!units.find(x => x.shortcut === this.value)) {
          this.modelProxy = this.options[0] || null
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
