<template lang="html">
  <div>
    <p>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Action</IxRes>: *
    </p>
    <div class="action">
      <VueSelectize
        v-model="type"
        name="type"
        :options="typeOptions"
        :fields="typeFields"
        required allow-empty
      />
      <template v-if="procedureOptions.length > 0">
        <p v-if="type === 'protection'">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ProtectionProcedure</IxRes>: *
        </p>
        <p v-else>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Procedure</IxRes>: *
        </p>
        <VueSelectize
          v-model="procedure"
          name="procedure"
          :options="procedureOptions"
          :fields="procedureFields"
          required
        />
      </template>
    </div>
  </div>
</template>

<script>
import {sortBy} from 'lodash'

import {mapFormFields} from '@helpers/vuex'
import {types, procedures} from 'src/js/store/modules/field-record-system/action/action-hierarchy'
import PermissionMixin from '@mixins/PermissionMixin'

import VueSelectize from '@components/VueSelectize'

import {getTypeKey, getProcedureKey} from '@frs/components/action/translation-key-helper'

export default {
  components: {
    VueSelectize
  },
  mixins: [
    PermissionMixin
  ],
  computed: {
    ...mapFormFields('fieldRecordSystem/action/editBuffer', [
      'type',
      'procedure'
    ]),
    typeOptions () {
      return sortBy(types(), this.translateType)
    },
    procedureOptions () {
      if (this.type) {
        return sortBy(procedures(this.type), this.translateProcedure)
      } else {
        return []
      }
    },
    typeFields () {
      return {
        text: this.translateType
      }
    },
    procedureFields () {
      return {
        text: this.translateProcedure
      }
    }
  },
  methods: {
    translateType (type) {
      return this.$i18n.translate(`${getTypeKey({type})}`)
    },
    translateProcedure (procedure) {
      return this.$i18n.translate(`${getProcedureKey({type: this.type, procedure})}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.action {
  width: 35%;
}
</style>
