<template lang="html">
  <div class="planning-wizard">
    <WizardHeader />
    <component
      :is="component" :key="component"
      class="components"
      :name="step"
    />
    <WizardFooter  v-bind="{getValidity}" />

    <ActionPreviewMapContent v-if="step !== 'start' || id" />
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

import WizardHeader from './widgets/WizardHeader'
import WizardFooter from './widgets/WizardFooter'
import BasicSettings from './pages/BasicSettings'
import CombinationOverview from './pages/CombinationOverview'
import TypeSelection from './pages/TypeSelection'
import ActionParameters from './pages/ActionParameters'
import FinalSettings from './pages/FinalSettings'
import CombinationSummary from './pages/CombinationSummary'
import ActionStatus from './pages/ActionStatus'
import InvalidStep from './pages/InvalidStep'
import ActionPreviewMapContent from './ActionPreviewMapContent'

// import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    WizardHeader,
    WizardFooter,
    BasicSettings,
    CombinationOverview,
    TypeSelection,
    ActionParameters,
    FinalSettings,
    CombinationSummary,
    ActionStatus,
    InvalidStep,
    ActionPreviewMapContent
  },
  // mixins: [
  //   FormPartMixin
  // ],
  computed: {
    ...mapState('fieldRecordSystem/action', {
      step: state => state.ui.planningWizard.step,
      id: state => state.editBuffer.id
    }),
    component () {
      switch (this.step) {
      case 'start': return 'BasicSettings'
      case 'combinationStart': return 'CombinationOverview'
      case 'type': return 'TypeSelection'
      case 'parameters': return 'ActionParameters'
      case 'misc': return 'FinalSettings'
      case 'status': return 'ActionStatus'
      case 'combinationSummary': return 'CombinationSummary'
      default: return 'InvalidStep'
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem', [
      'setWorkflow'
    ]),
    async getValidity () {
      // TODO support proper validation in all wizard forms, adding it was out of scope for the story this was found in 
      return true

      // this.validate()

      // await this.$nextTick()

      // return this.state === 'success'
    }
  },
  mounted () {
    this.setWorkflow({'fieldSelection': true})
  }
}
</script>

<style lang="scss" scoped>
.components {
  margin-top: 12px;
  margin-bottom: 10px;
}
</style>
