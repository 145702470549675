import kinks from '@turf/kinks'
import intersect from '@turf/line-intersect'

export const excludePoints = feature => !feature.geometry.type.endsWith('Point')

export const findKinks = features => {
  return features.filter(excludePoints).reduce((all, feature) => {
    return all.concat(kinks(feature).features)
  }, [])
}

export function findIntersections (features) {
  features = features.filter(excludePoints)

  const intersections = []

  for (let i = 0; i < features.length; i++) {
    for (let k = i + 1; k < features.length; k++) {
      // the turf method 'intersect' between polygons does not work with self-intersecting geometries
      // however 'line-intersect' is able to use polygons and linestrings
      // https://github.com/Turfjs/turf/issues/297
      const result = intersect(features[i].geometry, features[k].geometry)
      result.features.forEach(feature => intersections.push(feature))
    }
  }

  return intersections
}

export function findErrors (features) {
  const kinks = findKinks(features)
  const intersections = findIntersections(features)

  return kinks.concat(intersections)
}
