<template lang="html">
  <div class="header">
    <h4 v-if="$slots.title || title">
      <slot name="title">
        {{ title ? title.toString() : '' }}
      </slot>
    </h4>
    <h3 v-if="$slots.subTitle || subTitle">
      <slot name="subTitle">
        {{ subTitle ? subTitle.toString() : '' }}
      </slot>
    </h3>
    <span><slot v-if="$slots.description || description" name="description">{{ description ? description.toString() : '' }}</slot></span>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
    description: String
  }
}
</script>

<style lang="scss" scoped>
.header {
  h3 {
    margin: 0 0 3px 0;
  }
  h4 {
    margin: 0 0 3px 0;
  }

  padding: 0 0 5px 0;
  margin: 0 0 5px 0;
  border-bottom: 1px solid #cbcbcb;
}
</style>
