import { render, staticRenderFns } from "./HarvestGrassLandDetails.vue?vue&type=template&id=01562bfc&scoped=true&"
import script from "./HarvestGrassLandDetails.vue?vue&type=script&lang=js&"
export * from "./HarvestGrassLandDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01562bfc",
  null
  
)

export default component.exports