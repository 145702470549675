<template>
  <div>
    <h3>
      <IxRes>Areas.MaizeSowing.SR_MaizeSowing.CommonSettings_header</IxRes>
    </h3>

    <CommonSettings />

    <template v-if="zoneMap && productId">
      <h3>
        <IxRes>Areas.MaizeSowing.SR_MaizeSowing.ZoneMapSettings_header</IxRes>
      </h3>

      <div class="zonemap-settings">
        <div class="specific-settings">
          <FrsHeterogenityDisplay :zone-map="zoneMap" />

          <label>
            <IxRes>Areas.MaizeSowing.SR_MaizeSowing.Mode_header</IxRes>
          </label>

          <SimpleSwitch v-if="automaticSupported" v-model="automatic">
            <IxRes>Areas.MaizeSowing.SR_MaizeSowing.ModeAutomatic</IxRes>
          </SimpleSwitch>

          <SimpleSwitch v-model="ignoreLimits" class="ignore-limits">
            <IxRes>Areas.MaizeSowing.SR_MaizeSowing.IgnoreLimits_label</IxRes>
          </SimpleSwitch>

          <AutomaticSettings v-if="automaticSupported && automatic" />
          <ManualSettings v-else />
        </div>
        <div class="zone-values">
          <FrsZoneValueInput
            v-if="zoneMap && productId" v-model="zoneAmounts"
            name="zoneValueInput"
            :zone-map="zoneMap"
            :unit="unit"
          />
        </div>
      </div>
      <br>

      <div class="table-responsive">
        <table class="table table-bordered table-condensed">
          <tbody>
            <tr>
              <th>
                <IxRes>Areas.MaizeSowing.SR_MaizeSowing.TotalNumberOfGrains</IxRes>:
              </th>
              <td>
                <p v-if="zonesResult">
                  {{ $i18n.format(Math.ceil(zonesResult)) }}
                </p>
              </td>
            </tr>
            <tr>
              <th>
                <IxRes>Areas.MaizeSowing.SR_MaizeSowing.PackageUnits</IxRes> (à 50.000 <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Grains</IxRes>):
              </th>
              <td>
                <p v-if="zonesResultToPackages">
                  {{ $i18n.format(Math.ceil(zonesResultToPackages)) }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <div class="buttons">
      <IxButton
        save large
        @click="onCreateApplicationMap"
      />
    </div>
    <FrsLoadingIndicator saving :requests="['save-maize-sowing-map', 'applicationMapInfos.forField']" />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {mapResources, mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import SimpleSwitch from '@components/forms/SimpleSwitch'
import IxButton from '@components/IxButton'
import FrsZoneValueInput from '@frs/components/base/FrsZoneValueInput'
import FrsHeterogenityDisplay from '@frs/components/base/FrsHeterogenityDisplay'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import CommonSettings from './CommonSettings'
import AutomaticSettings from './AutomaticSettings'
import ManualSettings from './ManualSettings'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    SimpleSwitch,
    CommonSettings,
    AutomaticSettings,
    ManualSettings,
    IxButton,
    FrsZoneValueInput,
    FrsHeterogenityDisplay,
    FrsLoadingIndicator
  },
  mixins: [
    FormPartMixin
  ],
  data () {
    return {
      palette: 'relative'
    }
  },
  computed: {
    ...mapResources([
      '@ms.SR_MaizeSowing'
    ]),
    ...mapFormFields('fieldRecordSystem/maizeSowing', [
      'automatic',
      'ignoreLimits',
      'parameters.zoneAmounts',
      'parameters.zoneMapId',
      'parameters.productId',
      'parameters.unit'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    ...mapGetters('fieldRecordSystem/maizeSowing', [
      'zoneMap',
      'limits',
      'automaticSupported',
      'calculatedZoneAmounts'
    ]),
    zoneMapIds () {
      return [this.zoneMapId]
    },
    fieldIdByZoneMapId () {
      return {[this.zoneMapId]: this.fieldId}
    },
    zonesResult () {
      if (this.zoneAmounts && this.zoneMap) {
        const zoneIds = Object.keys(this.zoneAmounts)
        const zones = this.zoneMap.zones

        let result = 0
        zoneIds.map(zoneId => {
          const val = this.zoneAmounts[zoneId]
          const zone = zones.find(x => x.id === zoneId)

          result += zone.area * val
        })

        return this.unit === 'K/ha' ? (result * 1.0) / 10000 : result
      }
      return 0
    },
    zonesResultToPackages () {
      if (this.zonesResult) {
        return (this.zonesResult * 1.0) / 50000
      }
      return 0
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/maizeSowing', [
      'createApplicationMap',
      'viewResultPage'
    ]),
    ...mapActions('fieldRecordSystem/applicationMaps', [
      'createApplicationMapSuccess'
    ]),
    async onCreateApplicationMap () {
      this.validate()

      await this.$nextTick()

      if (this.state !== 'success') return
      try {
        const applicationMapId = await this.createApplicationMap()

        notifications.success(this.SR_MaizeSowing.CreationSuccess)
        await this.createApplicationMapSuccess(applicationMapId)
      } catch (error) {
        console.error(error)
        let notification = this.SR_MaizeSowing.CreationError
        if (error.response.data.failure) {
          notification += '\n' + this.$i18n.translate(`frs.exceptions.${error.response.data.failure}`)
        }
        notifications.error(notification)
      } finally {
        await this.viewResultPage()
      }
    }
  },
  watch: {
    calculatedZoneAmounts (amounts) {
      this.zoneAmounts = amounts
    }
  }
}
</script>

<style lang="scss" scoped>
.zonemap-settings {
  display: flex;
  justify-content: space-between;

  .specific-settings {
    width: 50%;
  }

  .zone-values {
    width: 40%;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
</style>
