<template lang="html">
  <DlgHarvestYearHelpModal
    v-if="portalId === 'DLG'"
    v-model="flagValue"
    :current-year="userSettings.harvestYear"
  >
    <slot />

    <template #next>
      <slot name="next" />
    </template>
  </DlgHarvestYearHelpModal>
</template>

<script>
import {mapState} from 'vuex'

import {config} from 'src/js/infrastructure'

import DlgHarvestYearHelpModal from './harvest-year-help/DlgHarvestYearHelpModal'

export default {
  components: {
    DlgHarvestYearHelpModal
  },
  props: {
    flag: String
  },
  computed: {
    ...mapState('fieldRecordSystem', [
      'userSettings'
    ]),
    flagValue: {
      get () {
        return this.userSettings.ui[this.flag]
      },
      set (value) {
        this.$store.dispatch('fieldRecordSystem/setUiFlag', {flag: this.flag, value})
      }
    },
    portalId () {
      return config.portalId
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
