<template>
  <div v-if="map">
    <h4>
      <IxRes>frs.mapManagement.details.soilMaps.labels.{{ `ZoneValueLegend_${map.category}` }}</IxRes>
    </h4>
    <div v-for="(zone, idx) in zones" :key="idx" class="legend-row">
      <div class="legend-box" :style="{backgroundColor: getColor(zone.value, idx)}" />
      <div class="text">
        <label>
          <IxRes>frs.mapManagement.details.soilMaps.{{ map.category }}.zoneLegend.value</IxRes>
          {{ zone.value }}
        </label>
        <small class="area-info"> ({{ $i18n.format(zone.area, 'area') }} = {{ $i18n.format(zone.area / totalArea, 'percent') }})</small>
      </div>
    </div>

    <div v-if="!zones" class="legend-row">
      <div class="legend-box no-data" />
      <div class="text">
        <IxRes>frs.mapManagement.details.soilMaps.zoneLegend_noData</IxRes>
      </div>
    </div>
  </div>
</template>

<script>
import {colorFunctions} from '@helpers/map-color-schemes'

export default {
  props: {
    map: {
      type: Object
    }
  },
  computed: {
    totalArea () {
      return this.zones.reduce((total, zone) => total + zone.area, 0)
    },
    zones () {
      return [...this.map.zones].sort((a, b) => {
        return parseFloat(a.value) - parseFloat(b.value)
      })
    }
  },
  methods: {
    getColor (zoneValue, index) {
      return colorFunctions.phbb(zoneValue, index, this.zones.length, this.map.category)
    }
  }
}
</script>

<style lang="scss" scoped>
.legend-box {
  border: 1px black solid;
  margin-right: 6px;
  width: 40px;
  height: 20px;
}

.legend-row {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.area-info {
  margin-left: 5px;
}

.no-data {
  background-color: lightgrey;
}
</style>
