<template lang="html">
  <div class="fertilization-amount">
    <QuantityInput
      v-model="amountWithUnit" class="input"
      :unit-options="validUnits"
      :rule="x => x >= 0" required
    >
      <template #label>
        <IxRes>frs.action.planning.fertilization.amount</IxRes>:
      </template>
    </QuantityInput>

    <i class="fa fa-arrow-right fa-2x" />

    <p class="lead">
      <IxRes>frs.action.planning.fertilization.totalAmount</IxRes> {{ $i18n.format(totalAmount) }} {{ absoluteUnit || '' }}
    </p>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import QuantityInput from '@components/forms/QuantityInput'

import ParameterMixin, {mapParameters, mapSingleProductQuantity} from '../ParameterMixin'

export default {
  components: {
    QuantityInput
  },
  mixins: [
    ParameterMixin
  ],
  props: {
    action: Object,
    value: Object // TODO find out what this is, maybe a details lookup?
  },
  computed: {
    ...mapParameters([
      'isLiquid'
    ]),
    ...mapSingleProductQuantity(),
    amountWithUnit: {
      get () {
        return {amount: this.amount, unit: this.unit}
      },
      set ({amount, unit}) {
        this.emitSingleProductQuantity({amount, unit})
      }
    },
    ...mapState('fieldRecordSystem', {
      fieldData: state => state.data.field
    }),
    validUnits () {
      return this.isLiquid
        ? ['l/ha', 'm³/ha']
        : ['kg/ha', 'dt/ha']
    },
    absoluteUnit () {
      return this.unit
        ? this.unit.replace(/\/ha/, '')
        : ''
    },
    totalAmount () {
      return this.amount * this.totalSize / 10000
    },
    totalSize () {
      return this.action.fieldIds
        .map(fieldId => this.fieldData[fieldId].area.geometrical)
        .reduce((total, x) => total + x, 0)
    }
  },
  created () {
    const {amount, unit} = this
    this.emitSingleProductQuantity({amount, unit: unit || this.validUnits[0]})
  }
}
</script>

<style lang="scss" scoped>
.input {
  width: 200px;
}

.fertilization-amount {
  display: flex;
  align-items: center;

  .fa {
    margin: 10px 20px;
  }

  .lead {
    margin: 0;
  }
}
</style>
