<template>
  <div>
    <ShowDetails>
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CargoType</IxRes>:
      </template>

      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CargoType_{{ part.parameters.cargoType }}</IxRes>
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.TransportType</IxRes>:
      </template>

      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.TransportType_{{ part.parameters.internal ? 'internal' : 'external' }}</IxRes>
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.TransportType</IxRes>:
      </template>

      {{ part.parameters.amount.value }} {{ $i18n.translateUnit(part.parameters.amount.unit) }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.TransportFrom</IxRes>:
      </template>

      {{ part.parameters.origin }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.TransportTo</IxRes>:
      </template>

      {{ part.parameters.destination }}
    </ShowDetails>

    <ShowDetails>
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Distance</IxRes>:
      </template>

      {{ part.parameters.distance }} {{ $i18n.translateUnit('km') }}
    </ShowDetails>
  </div>
</template>

<script>
import ShowDetails from './ShowDetails'

export default {
  components: {
    ShowDetails
  },
  props: {
    part: Object
  }
}
</script>

<style scoped>
</style>
