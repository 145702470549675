<template lang="html">
  <div class="wizard-footer">
    <IxButton
      v-if="wizardStep === 'overview'" large
      back
      @click="cancel"
    >
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.BackToFieldOverview</IxRes>
    </IxButton>
    <IxButton
      v-else-if="wizardStep !== 'correction'" large
      cancel
      @click="cancel"
    />

    <IxButton
      v-if="wizardStep === 'stepNitrogen'" :disabled="!isValid"
      large
      next icon="arrow-circle-o-right"
      @click="next"
    >
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ nextText }}</IxRes>
    </IxButton>

    <IxButton
      v-if="wizardStep === 'stepPhosphorus'" :disabled="!isValid"
      large
      next save
      icon="arrow-circle-o-right" @click="next"
    >
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ nextText }}</IxRes>
    </IxButton>

    <IxButton
      v-else-if="wizardStep === 'result'" :disabled="!isValid"
      large
      save @click="saveNewRequirement"
    />

    <FrsLoadingIndicator :loading="loading" />
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'

import {mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    IxButton,
    FrsLoadingIndicator
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/nutrientRequirement', {
      wizardStep: state => state.ui.wizardStep,
      selectedCropId: state => state.editBuffer.mainCropId,
      catchCropId: state => state.editBuffer.catchCropId,
      previousCropId: state => state.editBuffer.previousCropId
    }),
    ...mapResources([
      'Messages.State.SR_StateMessages'
    ]),
    nextText () {
      switch (this.wizardStep) {
      case 'stepNitrogen': return 'ToPRequirement'
      case 'stepPhosphorus': return 'ToEvaluation'
      default: return ''
      }
    },
    isValid () {
      return this.selectedCropId !== null && (this.catchCropId !== null || this.previousCropId !== null)
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/nutrientRequirement', [
      'saveRequirements',
      'next',
      'cancel'
    ]),
    ...mapMutations('fieldRecordSystem/nutrientRequirement', [
      'setWizardStep'
    ]),
    saveNewRequirement () {
      this.loading = true
      this.saveRequirements()
        .then(() => {
          notifications.success(this.SR_StateMessages.SaveSuccess)
        })
        .catch(error => {
          notifications.error(this.SR_StateMessages.SaveError)
          console.error(error)
        })
        .then(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .wizard-footer {
    margin-top: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
</style>
