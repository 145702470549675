<template lang="html">
  <div>
    <HelpBox v-if="!map || !map.zones" type="danger">
      Invalid map
    </HelpBox>
    <NumericInput
      v-model="totalAmount" readonly
      :addon="absoluteUnit"
      name="total-amount"
    >
      <template slot="label">
        <IxRes>Common.SR_Common.Sum</IxRes>
        <small class="text-muted">({{ $i18n.format(fieldSize / 10000, 'f2') }} ha)</small>
      </template>
    </NumericInput>
  </div>
</template>

<script>
import NumericInput from '@components/forms/NumericInput'
import HelpBox from '@components/help/HelpBox'

export default {
  components: {
    NumericInput,
    HelpBox
  },
  props: {
    map: {
      type: Object,
      required: true
    }
  },
  computed: {
    fieldSize () {
      if (!Object.keys(this.map).length || this.map.zones === undefined) return 0

      return this.map.zones.reduce((a, b) => a + b.geographicArea, 0)
    },
    totalAmount () {
      let sum = 0

      if (!Object.keys(this.map).length || this.map.zones === undefined) return sum

      this.map.zones.forEach((zone) => {
        if (!this.convertToHectare) {
          sum += zone.geographicArea / 10000 * zone.value
        } else {
          sum += zone.geographicArea * zone.value
        }
      })

      return sum
    },
    absoluteUnit () {
      if (!Object.keys(this.map).length) return null

      return this.map.unit.replace(/\/.+$/, '')
    },
    convertToHectare () {
      if (!Object.keys(this.map).length) return false

      return this.map.unit.endsWith('m²')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
