<template lang="html">
  <button v-if="editable" class="add-node" @click="$emit('click')">
    <i class="fa fa-plus" />
  </button>
  <div v-else class="inactive-node" />
</template>

<script>
export default {
  props: {
    editable: Boolean
  }
}
</script>

<style lang="scss" scoped>
@import "./crop-rotation.scss";

button.add-node {
  @include circle($insertButtonRadius);
  font-size: $insertButtonRadius + 2;

  border: $lineWidth solid $lineColor;
  background-color: white;
  padding: 0;
  line-height: 0;

  transition: all 0.25s;

  &:hover {
    color: green;
  }
}

.inactive-node {
  @include circle(3px);
  background-color: $lineColor;
}
</style>
