<template lang="html">
  <div>
    <p>
      <IxRes>Common.SR_Common.EditBorder</IxRes>
    </p>
    <button class="btn btn-default" @click="stopEditing">
      <IxRes>Common.SR_Common.StopEditing</IxRes>
    </button>
    <button class="btn btn-success" @click="save">
      <IxRes>Common.SR_Common.Accept</IxRes>
    </button>
    <hr>
    <button class="btn btn-default" @click="setGeometryFromAssignedFields">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AcceptGeometriesFromAssignedFields</IxRes>
    </button>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import {parse} from 'wellknown'

import {mapFormFields} from '@helpers/vuex'
import {combineToMultiPolygon, splitToPolygons, geoJsonFormat} from 'src/js/helpers/openlayers/features'

import DisableDefaultMapLayersMixin from '@frs/mixins/DisableDefaultMapLayersMixin'

export default {
  mixins: [
    DisableDefaultMapLayersMixin
  ],
  computed: {
    ...mapState('fieldRecordSystem', {
      fieldData: state => state.data.field
    }),
    ...mapState('fieldRecordSystem/map', {
      fieldFeatures: state => state.layers.fields.features.map(feature => geoJsonFormat.readFeature(feature))
    }),
    ...mapFormFields('fieldRecordSystem/map', [
      'editing.activeFeatures',
      'editing.selectedIds',
      'editing.mode'
    ]),
    ...mapState('fieldRecordSystem/action/editBuffer', [
      'fieldIds'
    ]),
    fieldWkts () {
      return this.fieldIds.map(fieldId => this.fieldData[fieldId].wkt)
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/map', [
      'stopEditing'
    ]),
    ...mapMutations('fieldRecordSystem/map', [
      'recordFeatures'
    ]),
    ...mapMutations('fieldRecordSystem/action/editBuffer', [
      'setCustomGeometry'
    ]),
    setGeometryFromAssignedFields () {
      const features = splitToPolygons(this.fieldIds.map(id => ({
        type: 'Feature',
        geometry: parse(this.fieldData[id].wkt),
        properties: {
          id
        }
      })))

      this.recordFeatures()
      this.activeFeatures = features.map(feature => geoJsonFormat.writeFeatureObject(feature))
    },
    save () {
      const fieldId = this.fieldIds[0]

      this.stopEditing().then(features => {
        const multiPolygon = combineToMultiPolygon(features)
        if (multiPolygon.getGeometry().getPolygons().length) {
          const {geometry} = geoJsonFormat.writeFeatureObject(multiPolygon)
          this.setCustomGeometry({fieldId, geometry})
        } else {
          this.setCustomGeometry({fieldId, geometry: null})
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
