<template lang="html">
  <BsModal v-model="modelProxy">
    <template #title>
      <IxRes>bf.uploadResult.title.uploadResults</IxRes>
    </template>
    <HelpBox v-if="pdf" large>
      <IxRes>bf.uploadResults.hint.preferCSV</IxRes>
    </HelpBox>
    <template>
      <FileDropArea
        ref="fileDropArea" v-model="files"
        :format-name="formatName" :accept="acceptFormat"
        @input="onInput = true"
      >
        <template #label>
          <IxRes v-if="pdf">bf.uploadResults.label.csvPdf</IxRes>
          <IxRes v-else>bf.uploadResults.label.csv</IxRes>
        </template>
      </FileDropArea>
    </template>
    <template #footer>
      <IxButton
        large
        cancel
        @click="modelProxy = false"
      >
        <IxRes>bf.uploadResults.button.cancel</IxRes>
      </IxButton>
      <IxButton
        large
        save
        :disabled="!onInput"
        @click="createTicket"
      >
        <IxRes>bf.uploadResults.button.upload</IxRes>
      </IxButton>
    </template>
  </BsModal>
</template>

<script>
import {mapResources, mapFormFields, modelProxy} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import HelpBox from '@components/help/HelpBox'
import BsModal from '@components/bootstrap/BsModal'
import IxButton from '@components/IxButton'
import FileDropArea from '@components/forms/FileDropArea'

import {mapGetters, mapActions} from 'vuex'

export default {
  components: {
    HelpBox,
    BsModal,
    FileDropArea,
    IxButton
  },
  props: {
    value: Boolean,
    pdf: Boolean
  },
  data () {
    return {
      files: null,
      importedOrderName: '',
      epsg: 4326,
      getOrder: false,
      onInput: false
    }
  },
  computed: {
    modelProxy,
    ...mapResources([
      '@frs.SR_BaseFertilization'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'orgUnitName'
    ]),
    ...mapFormFields('fieldRecordSystem/basicFertilization/resultImport', [
      'order'
    ]),
    acceptFormat () {
      return this.pdf
        ? '.csv, .pdf'
        : '.csv'
    },
    formatName () {
      return this.pdf
        ? 'csv/pdf'
        : 'csv'
    }
  },
  methods: {
    ...mapActions('geometryImport', [
      'guessEpsgForShape'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization/resultImport', [
      'createYoutrackIssue'
    ]),
    createTicket () {
      let formData = new FormData()

      const fileArray = [...this.files]
      fileArray.forEach(file => {
        formData.append('files', file)
      })
      formData.append('orderId', this.order.id)
      formData.append('orgName', this.orgUnitName)

      this.createYoutrackIssue(formData)
      .then(() => {
        notifications.success(this.SR_BaseFertilization.UploadResultSuccess)
      })
      .catch(() => {
        notifications.error(this.SR_BaseFertilization.UploadResultError)
      })
      .finally(() => {
        this.modelProxy = false
      })
    }
  }
}
</script>
