<template>
  <div>
    <GradientScale :colors="colors">
      <template #startLabel>
        <IxRes :context="{maxValue: $i18n.format(map.maxValue, 'f0')}">frs.mapManagement.sensorData.gradient.labels.highYield</IxRes>
      </template>
      <template #endLabel>
        <IxRes :context="{minValue: $i18n.format(map.minValue, 'f0')}">frs.mapManagement.sensorData.gradient.labels.lowYield</IxRes>
      </template>
    </GradientScale>
  </div>
</template>

<script>
import GradientScale from '@components/GradientScale'

export default {
  components: {
    GradientScale
  },
  props: {
    map: {
      type: Object,
      required: true
    }
  },
  computed: {
    colors () {
      return ['#4169E1','#00FF7F', '#FFFF00', '#FF0000']
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
