import {mapState, mapGetters} from 'vuex'

export default {
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization', {
      simplifiedGeometries: state => state.data.simplifiedGeometries
    }),
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    fieldGeometry () {
      return this.fieldId ? this.simplifiedGeometries[this.fieldId] : null
    },
    fieldBorderFeatures () {
      return (this.fieldGeometry
        ? [{
          type: 'Feature',
          geometry: this.fieldGeometry,
          properties: {id: this.fieldId}
        }]
        : Object.keys(this.simplifiedGeometries).map(fieldId => ({
          type: 'Feature',
          geometry: this.simplifiedGeometries[fieldId],
          properties: {id: fieldId}
        })))
    }
  }
}
