export default {
  functional: true,
  props: {
    year: Number,
    position: String,
    baseHarvestYears: Array,
    interactiveHarvestYears: Array,
    harvestYears: Array,
    currentHarvestYear: Number
  },
  render (h, {props, scopedSlots, listeners}) {
    const {
      year,
      baseHarvestYears,
      interactiveHarvestYears,
      harvestYears,
      currentHarvestYear
    } = props

    const i = baseHarvestYears.indexOf(year)
    const length = baseHarvestYears.length

    return scopedSlots.default({
      year,
      // show: (i < 2 || i >= baseHarvestYears.length - 2),
      show: i === 0 || i === length - 1 || (i === 1 && !harvestYears.includes(baseHarvestYears[0])) || (i === length - 2 && !harvestYears.includes(baseHarvestYears[length - 1])),
      terminal: i === 0 || i === length - 1,
      included: harvestYears.includes(year),
      interactive: interactiveHarvestYears.includes(year),
      current: year === currentHarvestYear,
      onClick: () => {
        if (listeners.toggle) listeners.toggle(year)
      },
      positionClasses: {
        'timeline-column-start': (length > 1 && i === 0) || (length > 3 && i < length / 2),
        'timeline-column-mid': length === 1 || (i > 0 && i < length - 1),
        'timeline-column-end': (length > 1 && i === length - 1) || (length > 3 && i >= length / 2)
      }
    })
  }
}
