<template lang="html">
  <div :class="$style.summary">
    <IxRes
      :context="{
        total: summary.candidateImages.length,
        cloudy: summary.candidateImages.filter(x => x.outcome === 'TooCloudy').length
      }"
    >
      frs.zoneMaps.details.cultivationImageAvailability
    </IxRes>

    <NdviLineChart :ndvi-data="ndviData" />
  </div>
</template>

<script>
import NdviLineChart from '@frs/components/map-management/details/NdviLineChart'

export default {
  components: {
    NdviLineChart
  },
  props: {
    summary: {
      type: Object,
      required: true
    }
  },
  computed: {
    ndviData () {
      const cloudFreeCandidates = this.summary.candidateImages.filter(x => x.outcome !== 'TooCloudy')
      cloudFreeCandidates.sort((a, b) => {
        return a.recordingDate.localeCompare(b.recordingDate)
      })

      return {
        cropUsageId: this.summary.cultivation.cropUsageId,
        recordingDate: this.summary.selectedImage.recordingDate,
        ndvi: this.summary.selectedImage.ndvi.mean,
        usedCandidates: cloudFreeCandidates.map(x => ({
          date: x.recordingDate,
          ndviStats: {mean: x.ndvi.mean}
        }))
      }
    }
  }
}
</script>

<style lang="scss" module>
.summary {
  display: flex;
  flex-direction: column;
}
</style>
