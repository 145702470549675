<template>
  <div>
    <HelpBox
      v-if="showHint && !isLoading"
      v-bind="icon"
      :large="largeHint"
      :type="hintType"
    >
      <slot name="hint" />
    </HelpBox>

    <slot v-else />

    <FrsLoadingIndicator v-bind="{loading, error, requests, small, large, saving}" @hide="isLoading = false">
      <slot slot="error" name="error" />
    </FrsLoadingIndicator>
  </div>
</template>

<script>
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import HelpBox from '@components/help/HelpBox'

export default {
  components: {
    HelpBox,
    FrsLoadingIndicator
  },
  props: {
    loading: Boolean,
    error: Boolean,
    requests: Array,
    small: Boolean,
    large: Boolean,
    saving: Boolean,
    showHint: Boolean,
    hintType: {
      type: String,
      default: 'warning'
    },
    icon: String,
    largeHint: Boolean
  },
  data () {
    return {
      isLoading: true
    }
  }
}
</script>

<style scoped>

</style>
