<template lang="html">
  <div>
    <div>
      <p><IxRes>DetailInformationOnThePContent</IxRes>:</p>
      <SimpleTable :columns="columns" :data="phosphorRequirement.data">
        <template #header-currentYear>
          <IxRes>CurrentYear</IxRes> {{ formatAsAbsoluteYear(0) }}
        </template>
        <template #header-secondYear>
          <IxRes>SecondYear</IxRes> {{ formatAsAbsoluteYear(1) }}
        </template>
        <template #header-thirdYear>
          <IxRes>ThirdYear</IxRes> {{ formatAsAbsoluteYear(2) }}
        </template>

        <template #label="{rowIndex}">
          <IxRes v-if="rowIndex === 0">
            ExpectedYieldLevelDtPerHa
          </IxRes>
          <IxRes v-if="rowIndex === 1">
            PRevocationKgPerHa
          </IxRes>
        </template>
      </SimpleTable>

      <strong><IxRes>AmountPRevocationCropRotation</IxRes>:</strong><p v-if="phosphorRequirement">
        {{ $i18n.format(phosphorRequirement.totalPRevocation, 'f2') }} {{ $i18n.translateUnit('kg/ha') }}
      </p>
    </div>
    <div>
      <div>
        <strong><IxRes>NutrientRequirementPFor3Years</IxRes>: </strong> <p v-if="phosphorRequirement">
          {{ $i18n.format(phosphorRequirement.pRequirementFor3Years, 'f2') }} {{ $i18n.translateUnit('kg/ha') }}
        </p>
      </div>
      <div>
        <strong><IxRes>AmountForTheEntireFieldFor3Years</IxRes>: </strong><p v-if="phosphorRequirement">
          {{ $i18n.format(phosphorRequirement.pRequirementFor3YearsForField, 'f2') }} {{ $i18n.translateUnit('kg') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {mapState} from 'vuex'

import SimpleTable from '@components/table/SimpleTable'

export default {
  translationNamespace: 'Areas.FieldRecordSystem.SR_FieldRecordSystem',
  components: {
    SimpleTable
  },
  props: {
    phosphorRequirement: Object
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      harvestYear: state => state.userSettings.harvestYear
    }),
    columns () {
      return {
        label: '',
        currentYear: '',
        secondYear: '',
        thirdYear: ''
      }
    }
  },
  methods: {
    formatAsAbsoluteYear (relativeYear) {
      const harvestYear = this.harvestYear
      const year = moment({harvestYear}).add(relativeYear, 'years')
      return `${year.clone().subtract(1, 'year').format('YY')}/${year.format('YY')}`
    }
  }
}
</script>

<style lang="css">
</style>
