<template lang="html">
  <div class="crop-entry">
    <div class="crop-body">
      <div class="crop-rotation-graph node-container">
        <div class="vertical-line" />
        <CropUsageNode :crop-usage-id="step.cropUsageId" :editable="editable" @click="$emit('click')" />
        <div class="vertical-line" />
      </div>
      <div v-if="verbose" class="crop-description">
        <strong>
          <IxRes>masterData.crops.usage.{{ step.cropUsageId }}_name</IxRes> <i v-if="crop.isLeguminous" class="fa fa-leaf leguminous" />
        </strong>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import CropUsageNode from './CropUsageNode'

export default {
  components: {
    CropUsageNode
  },
  props: {
    step: Object,
    editable: Boolean,
    verbose: Boolean
  },
  computed: {
    ...mapState('masterData', [
      'crops',
      'cropUsages'
    ]),
    crop () {
      const usage = this.cropUsages[this.step.cropUsageId]

      return this.crops[usage.cropId]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./crop-rotation.scss";

.crop-rotation-graph, .crop-entry, .crop-description {
  display: flex;
  flex-direction: column;
}

.crop-rotation-graph {
  align-items: center;
  width: $nodeRadius * 2;

  &.node-container {
    min-height: $nodeRadius * 2.5;
  }
}

.crop-body {
  display: flex;
}

.crop-description {
  margin-left: 6px;
  font-size: 1.2em;
  justify-content: center;
}

.leguminous {
  margin-left: 0.25em;
  color: rgb(0, 176, 23);
}
</style>
