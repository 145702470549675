<template>
  <div>
    <ShowDetails v-if="part.parameters.cropUsageId">
      <template #label>
        <IxRes>frs.action.details.cropUsage</IxRes>:
      </template>
      <IxRes>masterData.crops.usage.{{ part.parameters.cropUsageId }}_name</IxRes>
    </ShowDetails>

    <ShowDetails v-if="part.parameters.cropHarvestQualityId">
      <template #label>
        <IxRes>frs.action.planning.type.harvest.labels.crop.quality</IxRes>:
      </template>
      <IxRes>masterData.crops.quality.{{ part.parameters.cropHarvestQualityId }}_name</IxRes>
    </ShowDetails>

    <ShowDetails v-if="part.parameters.mainCropYield.amount">
      <template #label>
        <IxRes>actions.harvest.mainCropIncome</IxRes>:
      </template>
      {{ part.parameters.mainCropYield.amount + ' ' + part.parameters.mainCropYield.unit }}
    </ShowDetails>

    <ShowDetails v-if="part.parameters.catchCropYield.amount">
      <template #label>
        <IxRes>actions.harvest.catchCropIncome</IxRes>:
      </template>
      {{ part.parameters.catchCropYield.amount + ' ' + part.parameters.catchCropYield.unit }}
    </ShowDetails>
  </div>
</template>

<script>
import ShowDetails from './ShowDetails'

export default {
  components: {
    ShowDetails
  },
  props: {
    part: Object
  }
}
</script>

<style lang="scss" scoped>
</style>
