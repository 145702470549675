<template>
  <div class="irrigation">
    <div class="waterAmount">
      <NumericInput v-model="waterAmount" unit="mm/m²">
        <template #label>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.WaterAmount</IxRes>
        </template>
      </NumericInput>
    </div>
    <div class="water-extraction-source">
      <VueSelectize
        v-model="waterExtraction" :options="waterExtractionSources"
        allow-empty
        name="waterExtractionSource" :fields="fields"
      >
        <template #label>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.WaterExtractionSource</IxRes>
        </template>
      </VueSelectize>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {mapResources} from '@helpers/vuex'

import NumericInput from '@components/forms/NumericInput'
import VueSelectize from '@components/VueSelectize'

import ParameterMixin, {mapParameters} from './ParameterMixin'

export default {
  components: {
    NumericInput,
    VueSelectize
  },
  mixins: [
    ParameterMixin
  ],
  computed: {
    ...mapState('fieldRecordSystem', {
      waterExtractionSources: state => state.data.waterExtractionSources,
      editBuffer: state => state.action.editBuffer
    }),
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapParameters([
      'waterAmount',
      'waterExtraction'
    ]),
    fields () {
      return {
        text: waterExtractionSource => this.SR_FieldRecordSystem[`WaterExtractionSource_${waterExtractionSource}`],
        value: value => value
      }
    }
  },
  mounted () {
    if (this.editBuffer.parameters !== undefined) {
      if (this.editBuffer.parameters.waterAmount !== undefined && this.editBuffer.parameters.waterExtraction !== undefined) {
        this.emitParameterChange({waterAmount: this.editBuffer.parameters.waterAmount, waterExtraction: this.editBuffer.parameters.waterExtraction})
      }
    }
  }
}
</script>

<style scoped>
.irrigation {
  display: flex;
}
.waterAmount {
  width: 140px;
  margin-bottom: 6px;
}
.water-extraction-source {
  width: 250px;
  margin-left: 10px;
}
</style>
