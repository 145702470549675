<template lang="html">
  <BsModal v-model="modelProxy">
    <template #title>
      <IxRes>bf.orderImport.title.ImportOrder</IxRes>
    </template>
    <div style="position: relative">
      <template>
        <div class="alert alert-info">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ProjektionFileHint</IxRes>
        </div>

        <div v-if="!allSampleIdsAreValid && sampleIdPropertyExists" class="alert alert-danger">
          <h4>
            <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.BF_order_import_cancel</IxRes>
          </h4>
          <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.BF_order_import_missing_sampleId</IxRes>
        </div>

        <div v-if="hasMultiPointGeometries" class="alert alert-danger">
          <h4>
            <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.BF_order_import_cancel</IxRes>
          </h4>
          <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.BF_order_import_invalid_geometry</IxRes>:
          <a href="mailto:geometriefehler@ixmap.eu">geometriefehler@ixmap.eu</a>
        </div>

        <ShapeDropArea v-if="importedOrderName" :epsg="epsg" @load="loadFeatures" />
        <div v-else class="alert alert-warning">
          <h4>
            <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.BF_order_import_invalid_name</IxRes>
          </h4>
        </div>

        <VueSelectize
          v-model="sampleId"
          name="sample-id"
          :options="shapeFileColumns"
          allow-empty
        >
          <template #label>
            <IxRes>bf.orderImport.labels.sampleId</IxRes>
          </template>
          <template #description>
            <IxRes>bf.orderImport.description.sampleId</IxRes>
          </template>
        </VueSelectize>

        <div v-if="shapeFileFeatures.length" class="alert alert-info preview">
          <label><IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.BF_FeaturePreview</IxRes></label>
          <div>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.BF_SampleId</IxRes>:
            <ColumnMappingPreview :features="shapeFileFeatures" :selected-key="sampleId" />
          </div>
        </div>
      </template>
      <FrsLoadingIndicator :loading="importRunning" />
    </div>
    <template #footer>
      <IxButton
        large cancel
        @click="modelProxy = false"
      >
        <IxRes>bf.orderCreation.cancel</IxRes>
      </IxButton>
      <IxButton
        large save
        :disabled="!isValid"
        @click="finishImport"
      >
        <IxRes>bf.orderCreation.saveToStepFour</IxRes>
      </IxButton>
    </template>
  </BsModal>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'

import {mapResources, mapFormFields, modelProxy} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import BsModal from '@components/bootstrap/BsModal'
import ShapeDropArea from '@frs/components/geometry-editing/ShapeDropArea'
import IxButton from '@components/IxButton'
import VueSelectize from '@components/VueSelectize'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import ColumnMappingPreview from './ColumnMappingPreview'

export default {
  components: {
    BsModal,
    ShapeDropArea,
    IxButton,
    VueSelectize,
    FrsLoadingIndicator,
    ColumnMappingPreview
  },
  props: {
    value: Boolean,
    importedOrderName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      epsg: 4326,
      importRunning: false
    }
  },
  computed: {
    modelProxy,
    ...mapGetters('fieldRecordSystem/basicFertilization/orderImport', [
      'isValid',
      'validSampleIdColumn',
      'allSampleIdsAreValid',
      'sampleIdPropertyExists',
      'hasMultiPointGeometries',
      'shapeFileColumns'
    ]),
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapFormFields('fieldRecordSystem/basicFertilization/orderImport', [
      'upload.columnMapping.sampleId',
      'upload.shapeFileFeatures'
    ])
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/orderImport', [
      'importOrder'
    ]),
    ...mapMutations('fieldRecordSystem/basicFertilization/orderImport', [
      'reset'
    ]),
    async finishImport () {
      this.importRunning = true

      try {
        await this.importOrder({features: this.shapeFileFeatures, name: this.importedOrderName})

        notifications.success(this.SR_FieldRecordSystem.ImportOrderSuccess)
      } catch (error) {
        console.error(error)
        notifications.error(this.SR_FieldRecordSystem.ImportOrderError)
      } finally {
        this.importRunning = false
      }
    },
    loadFeatures (features) {
      this.shapeFileFeatures = features
    }
  },
  beforeDestroy () {
    this.reset()
  }
}
</script>

<style lang="scss" scoped>
.preview {
  display: flex;
  flex-direction: column;
}
.footer {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
