<template lang="html">
  <div class="cultivation" style="position: relative;">
    <DlgHarvestYearHelpIntegration flag="frs.cultivation.hasSeenFirstTimeHint">
      <IxRes>frs.cultivationPlanning.dlgCertIntegration.buttons.showHarvestYearHelp</IxRes>

      <template #next>
        <IxRes>frs.cultivationPlanning.dlgCertIntegration.buttons.createCultivationNow</IxRes>
      </template>
    </DlgHarvestYearHelpIntegration>

    <div class="form">
      <CommonSettingsForm />

      <component
        :is="subFormComponent"
        name="component"
        :available-mixtures="selectableMixtures"
        @mixtureView="$emit('mixtureView')"
      />
      <br>

      <template v-if="id || !planningIsFromOrgUnit">
        <div class="row">
          <IxButton large @click="startEditingGeometry">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EditCultivationGeometry</IxRes>
          </IxButton>
        </div>
        <br>
      </template>

      <div class="row">
        <IxButton large icon="trash" @click="cancelModalVisible = true">
          <IxRes>Common.SR_Common.Cancel</IxRes>
        </IxButton>

        <IxButton
          large submit
          :disabled="!isValid"
          class="pull-right" @click="saveIfValid"
        >
          <IxRes v-if="isNew">Areas.FieldRecordSystem.SR_FieldRecordSystem.AddCultivation</IxRes>
          <IxRes v-else>Common.SR_Common.SaveChanges</IxRes>
        </IxButton>
      </div>
    </div>

    <ConfirmationModal
      v-model="cancelModalVisible"
      @confirm="onConfirmCancellation" @cancel="cancelModalVisible = false"
    >
      <template #title>
        <IxRes>Common.SR_Common.Cancel</IxRes>
      </template>
      <h3>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CancelEditText</IxRes>
      </h3>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CancelEditHint</IxRes>
    </ConfirmationModal>

    <IxVectorLayer
      :features="features"
      :vector-style="cultivationStyle"
      :z-index="1"
      layer-id="cultivationPreview"
      auto-focus
    />
    <!-- `borderSegments` and `style` are part of `FieldBorderMixin` -->
    <IxVectorLayer
      :features="borderSegments"
      :vector-style="style"
      :z-index="2"
      layer-id="fieldBorder"
      auto-focus
    />
    <FrsLoadingIndicator :loading="saving" />
  </div>
</template>

<script>
import {orderBy} from 'lodash'
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import parse from 'wellknown'

import {mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'
import {cultivation as cultivationStyle} from '@frs/map-styles'

import BsModal from '@components/bootstrap/BsModal'
import ConfirmationModal from '@components/modals/ConfirmationModal'
import IxButton from '@components/IxButton'
import IxVectorLayer from '@components/map/IxVectorLayer'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import DlgHarvestYearHelpIntegration from '@frs/components/dlg-cert-integration/DlgHarvestYearHelpIntegration'

import CommonSettingsForm from './planning/CommonSettingsForm'
import MainCropForm from './planning/MainCropForm'
import CatchCropForm from './planning/CatchCropForm'
import EcoPriorityForm from './planning/EcoPriorityForm'

import FormPartMixin from '@components/forms/FormPartMixin'
import FieldBorderMixin from './planning/FieldBorderMixin'

export default {
  components: {
    BsModal,
    IxButton,
    IxVectorLayer,
    ConfirmationModal,
    CommonSettingsForm,
    DlgHarvestYearHelpIntegration,
    FrsLoadingIndicator
  },
  mixins: [
    FormPartMixin,
    FieldBorderMixin
  ],
  data () {
    return {
      cancelModalVisible: false,
      saving: false
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/cultivationPlanning/editBuffer', [
      'ecoTypeId',
      'cropUsageId',
      'geometry'
    ]),
    ...mapState('masterData', [
      'cropUsages'
    ]),
    ...mapState('fieldRecordSystem', {
      crops: state => state.data.crops
    }),
    ...mapState('fieldRecordSystem/navigation', {
      fieldId: state => state.location.fieldId,
      orgUnitId: state => state.location.orgUnitId
    }),
    ...mapState('fieldRecordSystem/cultivationPlanning', {
      cultivation: state => state.editBuffer,
      ecoTypes: state => state.data.ecoTypes,
      loadedCultivations: state => state.data.selection.orgUnit,
      availableMixtures: state => state.data.mixtures
    }),
    ...mapState('fieldRecordSystem/cultivationPlanning/editBuffer', {
      id: state => state.id,
      type: state => state.type
    }),
    ...mapGetters('fieldRecordSystem', [
      'harvestYear'
    ]),
    features () {
      if (this.geometry) {
        return this.geometry.map(wkt => ({
          geometry: parse(wkt),
          type: 'Feature',
          properties: {
            id: this.id,
            type: this.type,
            ecoTypeId: this.ecoTypeId,
            cropUsage: this.cropUsages[this.cropUsageId]
          }
        }))
      } else {
        return []
      }
    },
    cultivationStyle () {
      return cultivationStyle
    },
    isNew () {
      return !this.cultivation.id
    },
    subFormComponent () {
      switch (this.type) {
      case 'main': return MainCropForm
      case 'secondary': return MainCropForm
      case 'eco': return EcoPriorityForm
      case 'catch': return CatchCropForm
      default: throw new Error(`invalid cultivation type '${this.type}'`)
      }
    },
    initialCultivation () {
      return this.cultivation.id ? Object.assign({}, this.cultivation, this.loadedCultivations[this.cultivation.id]) : {}
    },
    hasChanges () {
      if (!this.cultivation.id) return true

      const c = this.cultivation
      const i = this.initialCultivation

      return !(c.type === i.type &&
        c.subsidies.ecoPriorityArea === i.subsidies.ecoPriorityArea &&
        c.subsidies.other === i.subsidies.other &&
        c.geometry === i.geometry &&
        c.cropUsageId === i.cropUsageId &&
        JSON.stringify(c.mixtureComponents) === JSON.stringify(i.mixtureComponents) &&
        c.ecoTypeId === i.ecoTypeId)
    },
    state () {
      return this.combineStates({
        inferred: this.inferredState,
        other: this.hasChanges ? 'success' : undefined
      })
    },
    isValid () {
      if (this.state !== 'success') {
        return false
      }

      return this.geometry.length > 0 && (((this.type === 'main' || this.type === 'secondary') && this.cultivation.cropUsageId) || (this.type === 'eco' && this.cultivation.ecoTypeId) || (this.type === 'catch'))
    },
    planningIsFromOrgUnit () {
      return !this.fieldId
    },
    sortedAvailableMixtures () {
      return orderBy(this.availableMixtures, ['productNames'])
    },
    selectableMixtures () {
      if (!this.cultivation.id) return this.sortedAvailableMixtures
      const currentComponents = this.loadedCultivations[this.cultivation.id].mixtureComponents
      return currentComponents && currentComponents.length > 0
        ? [{components: currentComponents, productNames: 'Aktueller Anbau', current: true}].concat(this.sortedAvailableMixtures)
        : this.sortedAvailableMixtures
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/cultivationPlanning', [
      'saveCultivation',
      'displayCultivationOverview',
      'launchCultivationPlanning'
    ]),
    ...mapMutations('fieldRecordSystem/cultivationPlanning/editBuffer', [
      'reset'
    ]),
    async saveIfValid () {
      this.validate()
      await this.$nextTick()

      if (this.isValid) {
        return this.save()
      }
    },
    async save () {
      this.saving = true

      try {
        await this.saveCultivation()

        const notification = this.$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationSaved')
        notifications.success(notification)

        this.routeToCultivationOverview()
      } catch (error) {
        const notification = this.$i18n.translate('Messages.State.SR_StateMessages.SaveError')
        notifications.error(notification)
        console.error(error)
      }

      this.saving = false
    },
    onConfirmCancellation () {
      this.cancelModalVisible = false
      this.$nextTick(() => {
        this.routeToCultivationOverview()
      })
    },
    startEditingGeometry () {
      this.$store.dispatch('fieldRecordSystem/map/startExpertMode', this.features ? this.features : [])
    },
    routeToCultivationOverview () {
      const isCultivationSet = this.cultivation.id !== '' && this.cultivation.id !== undefined
      if (isCultivationSet) {
        this.$router.push({name: 'details', params: {year: this.harvestYear, orgUnitId: this.orgUnitId, cultivationId: this.cultivation.id}})
      } else {
        this.$router.push({name: 'cultivations', params: {year: this.harvestYear, orgUnitId: this.orgUnitId}})
      }
    }
  },
  watch: {
    type (type) {
      this.ecoTypeId = type === 'eco' ? this.ecoTypes[0].name : null
    }
  }
}
</script>

<style lang="scss" scoped>
.cultivation-selection {
  width: 70%;
}

.cultivation {
  height: 800px;
}

.picker {
  width: 80%;
}

.date-range-time-picker {
  ::v-deep .input-group {
    // width: 75%;
  }
}

.row {
  margin-left: 0;
  margin-right: 0;
}
.harvestYearModalFooter {
  display: flex;
  justify-content: flex-end;
}
</style>
