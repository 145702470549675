<template lang="html">
  <div class="yield-planning-container">
    <Header>
      <template #subtitle>
        <IxRes v-if="onlyPhSelected">bf.applicationMaps.applicationMap.phOnly</IxRes>
        <IxRes v-else>bf.applicationMaps.applicationMap</IxRes>
      </template>
      <template #hint>
        <IxRes v-if="onlyPhSelected">bf.applicationMaps.hint.phOnly</IxRes>
        <IxRes v-else>bf.applicationMaps.hint</IxRes>
      </template>
      <template v-if="selectedFieldId" #field>
        <IxRes>bf.yieldPlanning.fieldName</IxRes>: {{ fieldName }}
      </template>
    </Header>

    <IxButton large back @click="setStep('basicSettings')">
      <IxRes>bf.yieldPlanning.backToFieldSelection</IxRes>
    </IxButton>

    <div class="hints">
      <HelpBox>
        <IxRes>bf.yieldPlanning.selectedNutrients</IxRes>
        {{ selectedNutrients }}
      </HelpBox>
      <HelpBox
        v-if="phAndNutrientsSelected"
      >
        <IxRes>bf.applicationMaps.help.phCalculationInfo</IxRes>
      </HelpBox>
      <HelpBox
        v-if="onlyPhSelected"
      >
        <IxRes>bf.applicationMaps.help.onlyPhSelected</IxRes>
      </HelpBox>
    </div>
    <div v-if="selectedFieldId" class="planning">
      <template v-if="phAndNutrientsSelected || onlyNutrientsSelected">
        <YearsSelection :years-count="3" />

        <FrsZoneMapSelection
          v-model="zoneMapId" name="zoneMapSelection"
          :field-id="selectedFieldId"
          hide-label
          :required="false"
          show-on-map
        >
          <template #label>
            <IxRes>bf.yieldPlanning.zonemap</IxRes>
          </template>
        </FrsZoneMapSelection>

        <IxButton
          v-if="!onlyPhSelected"
          large icon="pencil"
          :disabled="!zoneMapId"
          @click="adjustHeterogenity"
        >
          <IxRes>bf.yieldPlanning.adjustHeterogenity</IxRes>
        </IxButton>

        <YearForm
          v-for="(plannedYield, i) in plannedYields"
          :key="i" :year="i"
          :show-harvest-year="plannedYields.length > 1"
          :show-average-yield="!onlyPhSelected"
          :value="plannedYield"
          @input="setPlannedYieldData({year: i, plannedYield: $event})"
        />
      </template>

      <div class="start-button">
        <IxButton
          large colored
          icon="chevron-circle-right"
          @click="startCreation"
        >
          <IxRes>bf.yieldPlanning.startCalculation</IxRes>
        </IxButton>
      </div>
    </div>
    <template v-else>
      <HelpBox>
        <IxRes>bf.applicationMapPreview.hints.fieldSelection</IxRes>
      </HelpBox>
    </template>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'

import {mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import Header from './widgets/Header'
import YearsSelection from './widgets/YearsSelection'
import YearForm from './widgets/YearForm'

import FrsZoneMapSelection from '@frs/components/base/FrsZoneMapSelection'
import HelpBox from '@components/help/HelpBox'
import IxButton from '@components/IxButton'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    Header,
    FrsZoneMapSelection,
    HelpBox,
    IxButton,
    YearsSelection,
    YearForm
  },
  mixins: [
    FormPartMixin
  ],
  computed: {
    ...mapFormFields('fieldRecordSystem/basicFertilization/applicationMaps', [
      'creationParameters.zoneMapId',
      'creationParameters.plannedYields'
    ]),
    ...mapState('fieldRecordSystem/basicFertilization/applicationMaps', {
      selectedFieldId: state => state.selectedFieldId
    }),
    ...mapGetters('fieldRecordSystem/navigation', [
      'entityLookup'
    ]),
    ...mapGetters('fieldRecordSystem/basicFertilization/applicationMaps', [
      'mapsToCreate',
      'onlyPhSelected',
      'phAndNutrientsSelected',
      'onlyNutrientsSelected'
    ]),
    ...mapState('fieldRecordSystem/mapManagement', {
      zoneMapLookup: state => state.data.zoneMap.mapLookup
    }),
    zoneMap () {
      return this.zoneMapLookup[this.zoneMapId]
    },
    fieldName () {
      return this.entityLookup[this.selectedFieldId].name
    },
    selectedNutrients () {
      return this.mapsToCreate.map(type => this.$i18n.translate(`bf.labels.nutrient_${type}`)).join(', ')
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/applicationMaps', [
      'adjustHeterogenity',
      'initializeHeterogenity',
      'createApplicationMap'
    ]),
    ...mapMutations('fieldRecordSystem/basicFertilization/applicationMaps', [
      'setPlannedYieldData',
      'setStep'
    ]),
    async startCreation () {
      this.validate()

      await this.$nextTick()

      if (this.state === 'error') {
        notifications.error(this.$i18n.translate('bf.applicationMaps.notifications.calculationValidationError'))
        return
      }

      try {
        notifications.info(this.$i18n.translate('bf.applicationMaps.notifications.calculationStartedHint'))
        await this.createApplicationMap()
        notifications.success(this.$i18n.translate('bf.applicationMaps.notifications.calculationStarted'))
      } catch (error) {
        console.error(error)

        const body = error.response.data
        if (!(body && body.error)) {
          notifications.error(this.$i18n.translate('bf.applicationMaps.notifications.calculationError'))
          return
        }

        const bodyError = body.error.toString()

        switch (body.error) {
        case 'missingMeasurement': {
          notifications.criticalError(this.$i18n.translate(`bf.applicationMaps.notifications.${bodyError}Error`,
            {nutrient: body.nutrient, point: body.point, measurementType: body.measurementType}))
          break
        }
        case 'invalidProbingMethod': {
          notifications.criticalError(this.$i18n.translate(`bf.applicationMaps.notifications.${bodyError}Error`,
            {nutrient: body.nutrient, probingMethods: body.probingMethods}))
          break
        }
        case 'noValidMeasurements': {
          notifications.criticalError(this.$i18n.translate(`bf.applicationMaps.notifications.${bodyError}Error`,
            {nutrient: body.nutrient}))
          break
        }
        }
      }
    }
  },
  watch: {
    zoneMap: {
      deep: true,
      handler: 'initializeHeterogenity'
    }
  }
}
</script>

<style lang="scss" scoped>
.ph-calculation-info {
  margin-top: 15px;
}
.start-button {
  margin-top: 10px;
}

.planning {
  display: flex;
  flex-direction: column;
}

.hints {
  margin-top: 5px;

  ::v-deep .alert {
    margin-bottom: 5px;
    padding: 5px;
  }
}
</style>
