<template lang="html">
  <div>
    <FrsHeader>
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ActionWizard_Combination_title</IxRes>
      </template>
      <template #subTitle>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ subtitle }}</IxRes>
      </template>
      <template #description>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.FormRequiredHint</IxRes>
      </template>
    </FrsHeader>
    <IxButton
      v-if="canGoBack" large
      back
      @click="back"
    >
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ backText }}</IxRes>
    </IxButton>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import IxButton from '@components/IxButton'
import FrsHeader from '@frs/components/base/FrsHeader'

export default {
  components: {
    IxButton,
    FrsHeader
  },
  computed: {
    ...mapState('fieldRecordSystem/action', {
      step: state => state.ui.planningWizard.step,
      parameterStep: state => state.ui.planningWizard.parameterStep
    }),
    canGoBack () {
      return this.step !== 'start'
    },
    subtitle () {
      return this.resourceKeyTemplate('subtitle')
    },
    backText () {
      return this.resourceKeyTemplate('backText')
    }
  },
  methods: {
    resourceKeyTemplate (key) {
      if (this.step === 'parameters') {
        return `ActionWizard_${this.step}_${this.parameterStep}_${key}`
      } else if (this.step === 'misc') {
        return `ActionWizard_Combination_${this.step}`
      } else {
        return `ActionWizard_${this.step}_${key}`
      }
    },
    ...mapActions('fieldRecordSystem/action', [
      'back'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
