export const wheatTypes = [
  {
    value: 1.0,
    name: 'StandDensity'
  },
  {
    value: 1.2,
    name: 'Compensation'
  },
  {
    value: 0.8,
    name: 'SingleEar'
  },
  {
    value: 0.65,
    name: 'Hybrid'
  }
]

export const wheatDateOfSeeds = [
  {
    value: 0.7,
    name: 'Early'
  },
  {
    value: 0.85,
    name: 'MiddleEarly'
  },
  {
    value: 1.0,
    name: 'Normal'
  },
  {
    value: 1.2,
    name: 'MiddleLate'
  },
  {
    value: 1.4,
    name: 'Late'
  }
]
