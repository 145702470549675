<template>
  <div>
    <div class="yield-difference-calculation">
      <div>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementCurrentHarvestYear</IxRes>: <strong>{{ harvestYear | format }}</strong>
        <VueInfo class="info" placement="top">
          <template #description>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SelectedHarvestYearInfo</IxRes>
          </template>
        </VueInfo>
      </div>
      <div class="soil-type">
        <p>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SoilSubType</IxRes>:
          <IxRes v-if="!typeOfSoil">Areas.FieldRecordSystem.SR_FieldRecordSystem.NotAvailable</IxRes>
          <template v-else>
            {{ typeOfSoil }}
          </template>
        </p>
      </div>

      <div class="main-crop-selection">
        <FertilizationCropSelector v-model="mainCrop" />
      </div>

      <div class="crop-details">
        <p>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementIncomeLevelFertilizingRegulation</IxRes>
        </p>
        <p v-if="mainCrop">
          {{ mainCrop.expectedYield }} {{ $i18n.translateUnit('dt/ha') }}
        </p>
        <p v-else>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoCropSelection</IxRes>
        </p>
      </div>
      <div class="crop-details">
        <p>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutritionalRequirements</IxRes>:
        </p>
        <p v-if="mainCrop">
          {{ mainCrop.nitrogenRequirement }} {{ $i18n.translateUnit('kg N/ha') }}
        </p>
        <p v-else>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoCropSelection</IxRes>
        </p>
      </div>

      <label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementAverageIncomeLevelThreeYears</IxRes>
      </label>
      <FormBlock :number="supplementsAndDiscountsForYieldDifference" unit="kg/ha">
        <div class="input-last-nutrient">
          <NumericInput
            v-model="averageYieldLast3Years" required
            class="input"
            unit="kg/ha"
          />
        </div>
      </FormBlock>

      <div class="yield-difference">
        <p>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementYieldDifference</IxRes>:
        </p>
        <p> {{ yieldDifference }} {{ $i18n.translateUnit('dt/ha') }}</p>
      </div>
    </div>
    <FrsHeader>
      <template #subTitle>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AdditionAndRevocation</IxRes>
      </template>
    </FrsHeader>

    <div class="input-n-amount">
      <FormBlock sign="-" :number="nitrogenActual" unit="kg/ha">
        <NumericInput
          v-model="nitrogenActual"
          :readonly="isNitrogenMinManuel"
          class="input"
          unit="kg/ha"
        >
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementUsableNAmountInSoil</IxRes>
          </template>
        </NumericInput>
      </FormBlock>
      <BsCheckbox v-model="nitrogenMinManual">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ChangeActualNmin</IxRes>
        <VueInfo class="info" placement="top">
          <template #description>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SoilCoverTypeInfo</IxRes>
          </template>
        </VueInfo>
      </BsCheckbox>
    </div>

    <div class="humusContentBS">
      <label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementHumusAmount</IxRes>: *
      </label>
      <FormBlock unit="kg/ha" sign="-" :number="humusIsLow === 1 ? 0 : 20">
        <div class="radio-btns">
          <BsRadio v-model="humusIsLow" class="bs-radio" :value="1">
            &lt; 4 %
          </BsRadio>
          <BsRadio v-model="humusIsLow" class="text-cover-enabled" :value="2">
            &ge; 4 %
          </BsRadio>
        </div>
      </FormBlock>
    </div>

    <div class="input-n-requirement">
      <label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementCompleteAmountFertilizorLastYear</IxRes>
      </label>
      <FormBlock :number="nitrogenFertilizationPreviousYear * 0.1" unit="kg N/ha" sign="-">
        <NumericInput v-model="nitrogenFertilizationPreviousYear" class="input" addon="kg N/ha" />
      </FormBlock>
    </div>

    <div class="crop-selection">
      <FormBlock sign="-" unit="kg/ha" :number="getCatchOrPreviousCropAmount">
        <LabelAndMessage required>
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementSelectionLastAndCatchCrop</IxRes>
          </template>
          <CropSelector />
        </LabelAndMessage>
      </FormBlock>
    </div>

    <BsCheckbox v-model="isCovered">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementCoverType</IxRes>
      <VueInfo class="info" placement="top">
        <template #description>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SoilCoverTypeInfo</IxRes>
        </template>
      </VueInfo>
    </BsCheckbox>
    <div class="box-container">
      <div class="cover-type-box">
        <FormBlock :number="additionalRequirementFromCover" unit="kg/ha">
          <NumericInput
            v-model="additionalRequirementFromCover"
            class="input-cover"
            :readonly="isCoveredTrue" :rule="between(0, 20)"
            unit="kg/ha"
          >
            <template #label>
              <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Addition</IxRes>
            </template>
          </NumericInput>
        </FormBlock>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import moment from 'moment'

import {mapFormFields} from '@helpers/vuex'

import BsCheckbox from '@components/bootstrap/BsCheckbox'
import BsRadio from '@components/bootstrap/BsRadio'
import FormBlock from './widgets/FormBlock'
import NumericInput from '@components/forms/NumericInput'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import VueInfo from '@components/help/VueInfo'

import CropSelector from './widgets/CropSelector'

import FrsHeader from '@frs/components/base/FrsHeader'
import FertilizationCropSelector from '@frs/components/nutrient-requirement/FertilizationCropSelector'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

export default {
  components: {
    NumericInput,
    CropSelector,
    BsRadio,
    BsCheckbox,
    VueInfo,
    LabelAndMessage,
    FrsHeader,
    FormBlock,
    FertilizationCropSelector
  },
  filters: {
    format (year) {
      const yearAsMoment = moment({year})
      return `${yearAsMoment.clone().subtract(1, 'year').format('YY')}/${yearAsMoment.format('YY')}`
    }
  },
  mixins: [
    StandardRulesMixin
  ],
  data () {
    return {
      humusIsLow: 1,
      nitrogenMinManual: false
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/nutrientRequirement/editBuffer', {
      'mainCrop': {
        read (value, state) {
          return this.crops.find(c => c.name === state.mainCropId) || null
        },
        mutation: 'setMainCropDetails'
      }
    }),
    ...mapFormFields('fieldRecordSystem/nutrientRequirement/editBuffer', [
      'isCovered',
      'additionalRequirementFromCover',
      'nitrogenActual',
      'nitrogenFertilizationPreviousYear',
      'averageYieldLast3Years',
      'supplementsAndDiscountsForYieldDifference',
      'catchCrop',
      'previousCrop'
    ]),
    ...mapState('fieldRecordSystem', {
      crops: state => state.nutrientRequirement.data.editing.crops,
      fieldData: state => state.data.field
    }),
    ...mapState('fieldRecordSystem/nutrientRequirement/editBuffer', {
      fieldId: state => state.fieldId,
      typeOfSoil: state => state.typeOfSoil,
      harvestYear: state => state.harvestYear,
      yieldDifference: state => state.yieldDifference
    }),
    mainCropSelected () {
      return !!this.mainCrop
    },
    isCoveredTrue () {
      return this.isCovered ? null : 'readonly'
    },
    isNitrogenMinManuel () {
      return this.nitrogenMinManual ? null : 'readonly'
    },
    getCatchOrPreviousCropAmount () {
      if (this.catchCrop) {
        return this.catchCrop.amount
      }
      if (this.previousCrop) {
        return this.previousCrop.amount
      }
      return 0
    },
    nitrogenMeasurementFromField () {
      const {nutrients} = this.fieldData[this.fieldId]

      return nutrients.nitrogen
        ? nutrients.nitrogen.value
        : null
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/nutrientRequirement/editBuffer', [
      'set',
      'setNHumusContentIsLow'
    ]),
    updateNitrogenActualFromField () {
      this.nitrogenActual = this.nitrogenMeasurementFromField || 0
    }
  },
  watch: {
    humusIsLow (value) {
      this.setNHumusContentIsLow(value === 1)
    },
    isCovered (value) {
      if (value === false) {
        this.additionalRequirementFromCover = 0
      }
    },
    nitrogenMinManual: 'updateNitrogenActualFromField'
  },
  mounted () {
    this.updateNitrogenActualFromField()
  }
}
</script>

<style lang="scss" scoped>
.info {
  transform: scale(0.7);
}

.input-last-nutrient, .input-n-amount, .input-n-requirement {
  ::v-deep .input-field {
  width: 70%;
  }
}

.input-last-nutrient {
  display: flex;
  justify-content: space-between;
}

.box-container {
  display: flex;
  flex-direction: row;
  p {
    padding-top: 5px;
  }
}

.cover-type-box {
  margin-left: 40px;

  ::v-deep .input-field {
  width: 70%;
  }
}

.crop-selection {
  margin-top: 10px;
}
.main-crop-selection, .humusContentBS {
  margin-top: 5px;
}

.crop-details, .yield-difference {
  margin-top: 5px;

  display: flex;
  p {
    margin-right: 5px;
  }
}

.soil-type {
  display: flex;
  p {
    margin-right: 5px;
  }
}
.radio-btns {
  display: flex;
  margin-top: -10px;
  .bs-radio {
    margin-right: 10px;
  }
}
</style>
