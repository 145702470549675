<template>
  <div class="checkbox-cell-container" @click.stop.prevent="toggleSelection">
    <FontAwesomeIcon
      class="checkbox-cell-icon"
      :icon="state ? checkboxIcons.checked : checkboxIcons.unchecked"
    />
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faCheckSquare, faSquare} from '@fortawesome/free-regular-svg-icons'

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    row: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/action/editBuffer', [
      'selectedCultivationIds',
      'remainingFieldSelected'
    ]),
    state () {
      return this.row.id
        ? this.selectedCultivationIds[this.row.id] === this.row.fieldId
        : this.remainingFieldSelected[this.row.fieldId]
    },
    checkboxIcons () {
      return {
        checked: faCheckSquare,
        unchecked: faSquare
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/action/editBuffer', [
      'toggleCultivationId'
    ]),
    toggleSelection () {
      this.toggleCultivationId({fieldId: this.row.fieldId, cultivationId: this.row.id})
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-cell-container {
  cursor: pointer;
}
.checkbox-cell-icon {
  margin: 0;
  padding: 0;
}
</style>
