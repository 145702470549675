<template>
  <div class="action-footer-container">
    <IxButton icon="save" :disabled="disabled" @click="$emit('home')">
      <IxRes>bf.actionFooter.saveAndHome</IxRes>
    </IxButton>

    <IxButton
      icon="arrow-circle-right"
      :disabled="disabled"
      class="save-button"
      @click="$emit('next')"
    >
      <slot name="saveNextStep" />
    </IxButton>
  </div>
</template>

<script>
import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton
  },
  props: {
    disabled: Boolean
  }
}
</script>

<style scoped lang="scss">
.action-footer-container {
  display: flex;
  justify-content: space-between;

  .save-button {
    color: white;
    background: #43c634;

    &:hover {
      background: #39a32b;
    }
  }
}
</style>
