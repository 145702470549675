<template lang="html">
  <div class="zone-map-details-panel">
    <h4>
      <slot name="header" />
    </h4>

    <slot />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.zone-map-details-panel {
  border: 1px solid lightgray;
  padding: 0.5em;

  &:not(:last-child) {
    margin-bottom: 0.5em;
  }

  h4 {
    margin-top: 0;
  }
}
</style>
