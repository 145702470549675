<template lang="html">
  <div class="algorithm-parameters">
    <div class="algorithm-parameter-container">
      <CommonParameters />

      <div v-if="algorithm">
        <h3>
          <IxRes>frs.applicationMaps.headers.algorithmParameters</IxRes>
        </h3>
      </div>

      <component :is="component" v-if="algorithm" :key="algorithmObject.algorithmGroup" />
    </div>

    <div class="button-container">
      <IxButton cancel large @click="cancelCreation" />
      <IxButton save large @click="onRunAlgorithm">
        <IxRes>frs.applicationMaps.preview</IxRes>
      </IxButton>
    </div>

    <FrsLoadingIndicator :requests="['frs.applicationMaps.calculate']" />
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import CommonParameters from './CommonParameters'
import WimexMaize from './algorithms/WimexMaize'
import WimexWinterWheat from './algorithms/WimexWinterWheat'
import WimexUnderRootFertilization from './algorithms/WimexUnderRootFertilization'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    IxButton,
    FrsLoadingIndicator,
    CommonParameters
  },
  mixins: [
    FormPartMixin
  ],
  computed: {
    ...mapState('fieldRecordSystem/applicationMaps', {
      algorithm: state => state.parameters.algorithm
    }),
    ...mapState('masterData', [
      'productAlgorithms'
    ]),
    ...mapResources([
      '@sam.SR_SimpleApplicationMaps'
    ]),
    algorithmObject () {
      return this.productAlgorithms[this.algorithm]
    },
    component () {
      switch (this.algorithmObject.algorithmGroup) {
      case 'wimexMaize': return WimexMaize
      case 'wimexWinterWheat': return WimexWinterWheat
      case 'wimexUnderRootFertilization': return WimexUnderRootFertilization
      default: return null
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/applicationMaps', [
      'cancelCreation',
      'runAlgorithm'
    ]),
    async onRunAlgorithm () {
      this.validate()

      await this.$nextTick()

      if (this.state !== 'success') return

      try {
        await this.runAlgorithm()
      } catch (error) {
        console.error(error)
        notifications.error(this.SR_SimpleApplicationMaps.CreationError)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.algorithm-parameters {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.algorithm-parameter-container {
  flex: 1;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  min-height: 0;
}
.button-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
</style>
