<template lang="html">
  <header class="year-header">
    <IxRes :context="{year: year - 1}">
      frs.dlgCertIntegration.modals.harvestYearHelp.diagram.labels.harvestYear
    </IxRes>
  </header>
</template>

<script>
export default {
  props: {
    year: Number
  }
}
</script>

<style lang="scss" scoped>
@use './shared';

.year-header {
  @include shared.large-text;
}
</style>
