<template lang="html">
  <div class="entity-import-preview">
    <h3>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EntityImportColumnSelectionHeader</IxRes>
    </h3>

    <div v-if="showImportSimplificationHint" class="alert alert-info">
      <IxRes :resource="SR_FieldRecordSystem">EntityImportSimplificationHint</IxRes>
    </div>

    <EntityImportColumnMappingDropdown column-id="fieldName" label="EntityImportColumnLabelField" />
    <EntityImportColumnMappingDropdown column-id="orgName" label="EntityImportColumnLabelOrg" />
    <EntityImportColumnMappingDropdown column-id="subOrgName" label="EntityImportColumnLabelSubOrg" />

    <h3>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EntityImportSettingsHeader</IxRes>
    </h3>

    <VueSelectize v-model="colorProperty" :options="columns" allow-empty>
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EntityImportColorSelectionLabel</IxRes>
      </template>
    </VueSelectize>

    <div class="buttons">
      <IxButton cancel large @click="cancel" />
      <IxButton
        :disabled="!hasAnythingSelected" save
        large
        @click="save"
      />
    </div>

    <IxVectorLayer
      :features="features"
      :vector-style="style"
      :z-index="1"
      layer-id="importPreview"
      auto-focus
    />

    <ClickInteraction
      :hover-style="hoverStyle"
      :layer-filter="layer => layer.get('id') === 'importPreview'"
      @click="onFeatureClick"
    />

    <FrsLoadingIndicator :loading="loading" />
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'

import {notifications} from 'src/js/infrastructure'
import {mapResources} from '@helpers/vuex'
import {entityImportPreview, entityImportPreviewHover} from '@frs/map-styles'

// import {segmentAB} from './styles'
import ClickInteraction from '@components/map/interactions/ClickInteraction'

import IxButton from '@components/IxButton'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import VueSelectize from '@components/VueSelectize'
import IxVectorLayer from '@components/map/IxVectorLayer'

import EntityImportColumnMappingDropdown from './EntityImportColumnMappingDropdown'

import DisableDefaultMapInteractionsMixin from '@frs/mixins/DisableDefaultMapInteractionsMixin'

export default {
  components: {
    IxButton,
    FrsLoadingIndicator,
    VueSelectize,
    IxVectorLayer,
    EntityImportColumnMappingDropdown,
    ClickInteraction
  },
  mixins: [
    DisableDefaultMapInteractionsMixin
  ],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/entityImport', [
      'geojson',
      'columnMapping',
      'selectedIds',
      'showImportSimplificationHint'
    ]),
    ...mapGetters('fieldRecordSystem/entityImport', [
      'fields',
      'columns'
    ]),
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    colorProperty: {
      get () {
        return this.columnMapping.colorProperty
      },
      set (column) {
        this.updateColumnMapping({id: 'colorProperty', column})
      }
    },
    features () {
      return this.fields.map(field => {
        const {feature, ...rest} = field
        const selected = this.selectedIds.includes(this.geojson.features.indexOf(feature))
        const properties = {...feature.properties, ...rest, colorProperty: this.colorProperty, selected}
        return {...feature, properties}
      })
    },
    hasAnythingSelected () {
      return this.selectedIds.length > 0
    },
    style () {
      return entityImportPreview
    },
    hoverStyle () {
      return entityImportPreviewHover
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/entityImport', [
      'cancel',
      'startImport',
      'toggleNode'
    ]),
    ...mapMutations('fieldRecordSystem/entityImport', [
      'updateColumnMapping'
    ]),
    ...mapActions('fieldRecordSystem/map', [
      'focus'
    ]),
    ...mapMutations('fieldRecordSystem/map', [
      'pushVectorStyle',
      'popVectorStyle'
    ]),
    save () {
      this.loading = true
      this.startImport()
        .then(() => {
          notifications.success(this.SR_FieldRecordSystem.EntityImportSaveSuccess)
        })
        .catch(error => {
          console.error(error)
          notifications.error(this.SR_FieldRecordSystem.EntityImportSaveError)
        })
        .then(() => {
          this.loading = false
        })
    },
    onFeatureClick ({feature}) {
      if (feature) {
        this.toggleNode(this.fields.find(entity => entity.id === feature.get('id')))
      }
    }
  },
  mounted () {
    this.focus(this.features)
  }
}
</script>

<style lang="scss" scoped>
.entity-import-preview {
  position: relative;
}

.buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
</style>
