<template lang="html">
  <div>
    <div v-if="useCustomProduct">
      <p>
        <IxRes>Areas.FieldRecordSystem.SR_Action.FertilizerContentsFormHint</IxRes>
      </p>
      <p>
        <strong>
          <IxRes>frs.action.planning.fertilization.{{ procedure }}.labels.customProductNutrientContentDisplay</IxRes>
        </strong>
      </p>
      <p>
        <FertilizerContentsDisplay :value="value" />
      </p>
      <p>
        <IxButton
          v-if="productId === null"
          add
          large
          @click="createOrEditCustomProduct"
        >
          <IxRes>frs.action.planning.fertilization.addCustomProduct</IxRes>
        </IxButton>
        <IxButton
          v-else
          edit
          large
          @click="createOrEditCustomProduct"
        >
          <IxRes>frs.action.planning.fertilization.editCustomProduct</IxRes>
        </IxButton>
      </p>
    </div>
    <div v-else>
      <div class="product-selection">
        <div class="product-selection-dropdown">
          <b>
            <IxRes>Areas.FieldRecordSystem.SR_Action.Product</IxRes>
          </b>
          <FrsProductPicker v-model="productId" type="fertilizer" :available-product-filter="availableProductFilter" />
          <br>
        </div>
        <div v-if="isLiquid && procedure === 'organic' && productId" class="dry-substrate-hint">
          <IxRes :context="{0: $i18n.format(product.drySubstrateContent * 100)}">frs.action.planning.fertilization.organic.drySubstrateHint</IxRes>
        </div>
      </div>

      <label>
        <IxRes>frs.action.planning.fertilization.{{ procedure }}.labels.productNutrientContentDisplay</IxRes>:
      </label>
      <FertilizerContentsDisplay :value="value" />
    </div>

    <CustomProductModal
      v-model="showCustomProductModal"
      :is-new="productId === null"
      :procedure="procedure"
      :saving="saving"
      @save="saveCustomProduct"
    />
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'

import {modelProxy} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'
import FrsProductPicker from '@frs/components/base/FrsProductPicker'

import FertilizerContentsDisplay from './FertilizerContentsDisplay'
import CustomProductModal from './CustomProductModal'

import ParameterMixin, {mapParameters, mapSingleProductQuantity} from '../ParameterMixin'

export default {
  components: {
    IxButton,
    FertilizerContentsDisplay,
    CustomProductModal,
    FrsProductPicker
  },
  mixins: [
    ParameterMixin
  ],
  props: {
    procedure: String,
    value: Object
  },
  data () {
    return {
      showCustomProductModal: false,
      saving: false
    }
  },
  computed: {
    modelProxy,
    ...mapSingleProductQuantity(),
    ...mapGetters('fieldRecordSystem', [
      'orgUnitId'
    ]),
    ...mapState('masterData', [
      'customProducts'
    ]),
    ...mapGetters('masterData', [
      'products'
    ]),
    ...mapParameters([
      'isLiquid',
      'useCustomProduct',
      'fertilizerNutrientContents',
      'fertilizerData'
    ]),
    availableProductFilter () {
      return product => product.isOrganic === (this.procedure === 'organic')
    },
    product () {
      return this.products[this.productId] || null
    }
  },
  methods: {
    ...mapActions('masterData/management', [
      'select',
      'save'
    ]),
    ...mapMutations('masterData/management', [
      'setSelectedProductGroup',
      'createNewProduct',
      'setIsOrganic'
    ]),
    ...mapMutations('masterData', [
      'setCustomProducts'
    ]),
    createOrEditCustomProduct () {
      if (!this.productId) {
        this.setSelectedProductGroup('fertilizer')
        this.createNewProduct({source: 'action-planning', orgUnitId: this.orgUnitId})

        this.setIsOrganic(this.procedure === 'organic')

        this.showCustomProductModal = true
      } else {
        this.select(this.productId)
          .then(() => {
            this.showCustomProductModal = true
          })
      }
    },
    saveCustomProduct () {
      this.saving = true
      this.save()
      .then(product => {
        const products = {
          ...(this.customProducts[this.orgUnitId] || {}),
          [product.id]: product
        }

        this.setCustomProducts({
          orgUnitId: this.orgUnitId,
          products
        })

        this.showCustomProductModal = false
        this.productId = product.id
        if (this.productId === product.id) {
          this.updateCustomProduct(product.id)
        }
        notifications.success(this.$i18n.translate('frs.action.planning.fertilization.notifications.success.customFertilizerCreated'))
      })
      .catch(() => {
        notifications.error(this.$i18n.translate('frs.action.planning.fertilization.notifications.error.customFertilizerCreated'))
      })
      .finally(() => {
        this.saving = false
      })
    },
    updateCustomProduct (productId) {
      if (!productId) {
        this.fertilizerNutrientContents = {
          nitrogen: null,
          phosphorus: null,
          magnesium: null,
          potassium: null,
          sulphur: null,
          nitrate: null,
          ammonium: null,
          nitrogenAmide: null
        }
      } else {
        const {
          nitrogen,
          phosphorus,
          potassium,
          magnesium,
          sulphur,
          nitrate,
          ammonium,
          nitrogenAmide,
          density
        } = this.products[productId]

        const fertilizerData = {...this.fertilizerData, density}
        const fertilizerNutrientContents = {nitrogen, phosphorus, potassium, magnesium, sulphur, nitrate, ammonium, nitrogenAmide}

        this.emitParameterChange({
          fertilizerData,
          fertilizerNutrientContents
        })
      }
    }
  },
  watch: {
    productId (productId) {
      this.updateCustomProduct(productId)
    }
  }
}
</script>

<style lang="scss" scoped>
.product-selection {
  display: flex;
  align-items: flex-end;

  .product-selection-dropdown {
    width: 40%;
  }

  .dry-substrate-hint {
    margin-left: 10px;
    margin-bottom: 12px;
  }
}
</style>
