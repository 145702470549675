<template lang="html">
  <div class="wizard-footer">
    <div>
      <IxButton large cancel @click="cancelEditing" />
      <IxButton
        v-if="finished" large
        save
        @click="save"
      >
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SaveAction</IxRes>
      </IxButton>
      <IxButton
        v-else :disabled="isNextButtonDisabled"
        large
        next @click="tryNext"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'

import {mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton
  },
  props: {
    state: String,
    getValidity: Function
  },
  computed: {
    ...mapResources([
      'Messages.State.SR_StateMessages'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    ...mapGetters('fieldRecordSystem/action', [
      'finished'
    ]),
    ...mapState('fieldRecordSystem/action', {
      step: state => state.ui.planningWizard.step
    }),
    ...mapState('fieldRecordSystem/action/editBuffer', [
      'fieldIds',
      'timestamps',
      'type'
    ]),
    isNextButtonDisabled () {
      if (this.step === 'start') {
        const startDate = this.timestamps.planned.start
        const startEnd = this.timestamps.planned.end

        return !(this.fieldIds.length > 0 && startDate !== null && startEnd !== null)
      } else if (this.step === 'type') {
        return !this.type
      }

      return false
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/action', [
      'cancelEditing',
      'cancelNewAction',
      'saveAction',
      'next'
    ]),
    async tryNext () {
      const isValid = await this.getValidity()
      
      if (!isValid) return

      await this.next()
    },
    async save () {
      try {
        this.$store.commit('fieldRecordSystem/setDataLoading', true)

        await this.saveAction()
        notifications.success(this.SR_StateMessages.SaveSuccess)
      } catch (error) {
        notifications.error(this.SR_StateMessages.SaveError)
        console.error(error)
      } finally {
        this.$store.commit('fieldRecordSystem/setDataLoading', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
