<template lang="html">
  <SimpleSwitch v-model="isOxide">
    <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.OxideForm</IxRes>
  </SimpleSwitch>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

import SimpleSwitch from '@components/forms/SimpleSwitch'

export default {
  components: {
    SimpleSwitch
  },
  props: {
    nutrient: String
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', {
      columnMapping: state => state.upload.columnMapping
    }),
    isOxide: {
      get () {
        return this.columnMapping[`${this.nutrient}IsOxide`]
      },
      set (value) {
        this.setIsOxide({nutrient: this.nutrient, value})
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/basicFertilization/resultImport', [
      'setIsOxide'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
