<template lang="html">
  <div class="info-overlay">
    <slot />
  </div>
</template>

<script>
import OverlayMixin from '../mixins/OverlayMixin'

export default {
  mixins: [
    OverlayMixin
  ]
}
</script>

<style lang="scss" scoped>
.info-overlay {
  background-color: rgba(211,211,211, 1);
  border: 3px solid gray;
  border-radius: 5px;
  padding: 6px;
}
.info-overlay:after {
  content: '';
  display: block;
  position: absolute;
  top: 98%;
  left: 10px;
  width: 0;
  height: 0;
  border-color: rgba(211,211,211, 1) transparent transparent transparent ;
  border-style: solid;
  border-width: 10px;
}
</style>
