<template>
  <div>
    <ShowDetails v-if="part.parameters.cropUsageId">
      <template #label>
        <IxRes>frs.action.details.cropUsage</IxRes>:
      </template>
      <IxRes>masterData.crops.usage.{{ part.parameters.cropUsageId }}_name</IxRes>
    </ShowDetails>

    <ShowDetails v-if="part.parameters.cropHarvestQualityId">
      <template #label>
        <IxRes>frs.action.planning.type.harvest.labels.crop.quality</IxRes>:
      </template>
      <IxRes>masterData.crops.quality.{{ part.parameters.cropHarvestQualityId }}_name</IxRes>
    </ShowDetails>

    <ShowDetails v-if="part.parameters.cutNumber">
      <template #label>
        <IxRes>actions.harvest.CuttingNumber</IxRes>:
      </template>
      {{ part.parameters.cutNumber }}
    </ShowDetails>

    <ShowDetails v-if="part.parameters.cuttingTime">
      <template #label>
        <IxRes>actions.harvest.CuttingTime</IxRes>:
      </template>
      {{ translation[part.parameters.cuttingTime] }}
    </ShowDetails>

    <ShowDetails v-if="part.parameters.grassYield.amount">
      <template #label>
        <IxRes>actions.harvest.Income</IxRes>:
      </template>
      {{ part.parameters.grassYield.amount + ' ' + part.parameters.grassYield.unit }}
    </ShowDetails>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import ShowDetails from './ShowDetails'

export default {
  components: {
    ShowDetails
  },
  props: {
    part: Object
  },
  computed: {
    ...mapState('i18n', {
      translation: state => state.translations['actions.harvest']
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
