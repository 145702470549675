<template lang="html">
  <div class="winter-wheat-parameters">
    <div class="input-row">
      <VueSelectize
        v-model="type"
        name="type"
        :placeholder="SR_Placeholders.ComboboxSeedType"
        :fields="typeFields"
        :options="typeOptions"
        required
        allow-empty
      >
        <template #label>
          <IxRes>frs.applicationMaps.parameters.labels.type</IxRes>
        </template>
      </VueSelectize>
      <VueSelectize
        v-model="dateOfSeed"
        name="dateOfSeed"
        :fields="dateOfSeedFields"
        :options="dateOfSeedOptions"
        required
        allow-empty
      >
        <template #label>
          <IxRes>frs.applicationMaps.parameters.labels.dateOfSeed</IxRes>
        </template>
      </VueSelectize>
    </div>

    <div class="input-row">
      <NumericInput v-model="thousandKernelWeight" name="thousandKernelWeight" required>
        <template #label>
          <IxRes>frs.applicationMaps.parameters.labels.thousandKernelWeight</IxRes>
        </template>
      </NumericInput>

      <NumericInput
        v-model="germinationCapacity"
        name="germinationCapacity"
        percent percent-not-normalized
        required
      >
        <template #label>
          <IxRes>frs.applicationMaps.parameters.labels.germinationCapacity</IxRes>
        </template>
      </NumericInput>
    </div>

    <div class="input-row">
      <NumericInput
        v-model="germination"
        name="germination"
        :rule="between(0, 400)"
        required
      >
        <template #label>
          <IxRes>frs.applicationMaps.parameters.labels.germination</IxRes>
        </template>
      </NumericInput>
    </div>

    <CollapsibleSection v-if="zoneMap" v-model="showZoneInput">
      <template #header>
        <IxRes>frs.applicationMaps.parameters.additionalZoneValues</IxRes>
      </template>
      <div class="zone-values">
        <ZoneInput
          v-model="additionalZoneValues"
          :zone-map="zoneMap"
        >
          <template #label>
            <IxRes>frs.applicationMaps.parameters.additionalZoneValues</IxRes>
          </template>

          <template #default="{zone, value, onInput}">
            <div class="input-row">
              <VueSelectize
                :value="value && value.waterAvailability !== undefined ? value.waterAvailability : null" :options="waterAvailabilityOptions"
                :name="`waterAvailability-input-${zone.id}`" class="water-availability"
                :fields="waterAvailabilityFields"
                allow-empty
                required
                @input="x => onInput({...defaultAdditionalZoneValues, ...value, waterAvailability: x})"
              >
                <template #label>
                  <IxRes>frs.mapManagement.zoneMapAdditionalValues.labels.waterAvailability</IxRes>
                </template>
              </VueSelectize>

              <NumericInput
                :value="value && value.acreRatio !== undefined ? value.acreRatio : null"
                :name="`acreRatio-input-${zone.id}`"
                :rule="between(0, 106)"
                required
                @input="x => onInput({...defaultAdditionalZoneValues, ...value, acreRatio: x})"
              >
                <template #label>
                  <IxRes>frs.mapManagement.zoneMapAdditionalValues.labels.acreRatio</IxRes>
                </template>
              </NumericInput>

              <NumericInput
                :value="value && value.pwc !== undefined ? value.pwc : null"
                :name="`pwc-input-${zone.id}`"
                :rule="between(60, 420)"
                required
                @input="x => onInput({...defaultAdditionalZoneValues, ...value, pwc: x})"
              >
                <template #label>
                  <IxRes>frs.mapManagement.zoneMapAdditionalValues.labels.pwc</IxRes>
                </template>
              </NumericInput>
            </div>
          </template>
        </ZoneInput>
      </div>
    </CollapsibleSection>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'
import {waterAvailabilities} from 'src/js/data/zone-map-additional-value-enums'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'
import NumericInput from '@components/forms/NumericInput'
import VueSelectize from '@components/VueSelectize'
import ZoneInput from '@components/forms/ZoneInput'
import CollapsibleSection from '@components/CollapsibleSection'

import {wheatTypes, wheatDateOfSeeds} from './parameter-enums'

export default {
  components: {
    NumericInput,
    ZoneInput,
    VueSelectize,
    CollapsibleSection
  },
  mixins: [
    StandardRulesMixin
  ],
  data () {
    return {
      showZoneInput: true
    }
  },
  computed: {
    ...mapResources([
      'Controls.Placeholders.SR_Placeholders',
      'Controls.Placeholders.SR_SeedExpert',
      'Common.SR_Common',
      'masterData'
    ]),
    ...mapResources({
      parameterTranslations: 'frs.applicationMaps.parameters'
    }),
    ...mapFormFields('fieldRecordSystem/applicationMaps', [
      'parameters.type',
      'parameters.dateOfSeed',
      'parameters.thousandKernelWeight',
      'parameters.germinationCapacity',
      'parameters.germination',
      'parameters.additionalZoneValues'
    ]),
    ...mapState('fieldRecordSystem/applicationMaps', {
      zoneMapId: state => state.parameters.zoneMapId
    }),
    ...mapState('fieldRecordSystem/mapManagement', {
      zoneMapLookup: state => state.data.zoneMap.mapLookup
    }),
    zoneMap () {
      return this.zoneMapLookup[this.zoneMapId]
    },
    typeFields () {
      return {
        text: value => this.parameterTranslations[`wheatType_${wheatTypes.find(x => x.value === value).name}`]
      }
    },
    dateOfSeedFields () {
      return {
        text: value => this.parameterTranslations[`dateOfSeed_${wheatDateOfSeeds.find(x => x.value === value).name}`]
      }
    },
    waterAvailabilityFields () {
      return {
        text: value => this.parameterTranslations[`waterAvailability_${waterAvailabilities.find(x => x.value === value).name}`]
      }
    },
    typeOptions () {
      return wheatTypes.map(x => x.value)
    },
    dateOfSeedOptions () {
      return wheatDateOfSeeds.map(x => x.value)
    },
    waterAvailabilityOptions () {
      return waterAvailabilities.map(x => x.value)
    },
    defaultAdditionalZoneValues () {
      return {
        waterAvailability: null,
        acreRatio: null,
        pwc: null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.winter-wheat-parameters {
  display: flex;
  flex-direction: column;

  .input-row {
    display: flex;

    > * {
      flex: 1;
    }

    > :not(:first-child) {
      margin-left: 0.5em;
    }
  }
}

.zone-values ::v-deep .input-row {
  label.control-label {
    font-size: 0.8em;
    color: gray;
  }

  .water-availability {
    flex: 2;
  }
}
</style>
