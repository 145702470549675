<template lang="html">
  <div class="calculation-container">
    <div class="box">
      {{ internalSign }} {{ Math.abs(number) }} {{ $i18n.translateUnit(unit) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: Number,
    unit: String,
    sign: String
  },
  computed: {
    internalSign () {
      if (this.sign) {
        return this.sign
      }
      if (this.number < 0) {
        return '-'
      } else {
        return '+'
      }
    }
  }
}
</script>

<style lang="scss">
.calculation-container {
  display: flex;
  height: 34px;
}

.box {
  padding: 2px 4px 2px 10px;
  background-color: #b6d7a8;
  border: 1px solid green;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

}

</style>
