<template lang="html">
  <div class="crop-rotation-management">
    <template v-if="orgUnitId && orgUnitId === root.id">
      <p class="lead">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AllCropRotations</IxRes>
      </p>
      <ul v-if="cropRotations.length" class="list-group">
        <li v-for="rotation in cropRotations" :key="rotation.id" class="flex-item">
          <button
            v-if="selectedCropRotation" class="edit-button btn btn-default"
            :class="{'active': rotation.id === selectedCropRotation.id}"
            @click="edit(rotation)"
          >
            <i class="fa fa-pencil" :class="{'text-success': rotation.id === selectedCropRotation.id}" />
          </button>
          <CropRotationDisplay :rotation="rotation" />
        </li>
      </ul>
      <div v-else class="alert alert-info">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoCropRotationDefined</IxRes>
      </div>
      <hr>
      <CropRotationEditor
        v-if="selectedCropRotation" v-model="selectedCropRotation"
        :crops="crops"
        editable verbose
      />
      <div v-if="selectedCropRotation" class="btn-group">
        <IxButton
          v-if="selectedCropRotation.steps.length" large
          add
          @click="add"
        >
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AddNewCropRotation</IxRes>
        </IxButton>
        <IxButton
          v-if="selectedCropRotation.id" large
          save
          @click="update"
        >
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SaveCropRotation</IxRes>
        </IxButton>
        <IxButton
          v-if="selectedCropRotation.id" large
          remove
          @click="remove"
        >
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.RemoveCropRotation</IxRes>
        </IxButton>
      </div>
      <hr>
    </template>
    <p class="lead">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AssignCropRotation</IxRes>
    </p>
    <template v-if="cropRotations.length">
      <div>
        <ul class="list-group">
          <li
            v-for="(rotation, i) in cropRotations" :key="i"
            class="flex-item"
            style="font-size: 1.4em;"
          >
            <BsCheckbox v-model="selectedCropRotations" class="rotation-checkbox" :value="rotation" />
            <CropRotationDisplay :rotation="rotation" />
          </li>
        </ul>
        <IxButton large save @click="assign">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SaveAssignment</IxRes>
        </IxButton>
      </div>
    </template>
    <template v-else>
      <div class="alert alert-info">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoCropRotationDefined</IxRes> ({{ root.name }})
      </div>
    </template>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

import {mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import BsCheckbox from '@components/bootstrap/BsCheckbox'
import IxButton from '@components/IxButton'

import CropRotationEditor from './CropRotationEditor'
import CropRotationDisplay from './CropRotationDisplay'

import ReloadDataOnOrgUnitChange from '@frs/mixins/ReloadDataOnOrgUnitChange'

export default {
  components: {
    CropRotationEditor,
    CropRotationDisplay,
    BsCheckbox,
    IxButton
  },
  mixins: [
    ReloadDataOnOrgUnitChange
  ],
  data () {
    return {
      selectedCropRotation: null,
      selectedCropRotations: []
    }
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem',
      'Messages.State.SR_StateMessages'
    ]),
    ...mapState('fieldRecordSystem', {
      assignedCropRotations: state => state.cropRotations.company
    }),
    ...mapGetters('fieldRecordSystem', [
      'orgUnitId',
      'root'
    ]),
    cropRotations () {
      return this.$store.state.fieldRecordSystem.cropRotations.root
    },
    crops () {
      return this.$store.state.masterData.cropUsages
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'addCropRotation',
      'deleteCropRotation',
      'updateCropRotation',
      'reloadAssignedCropRotations',
      'reloadCropRotations',
      'saveAssignedCropRotations'
    ]),
    add () {
      this.addCropRotation(Object.assign({}, this.selectedCropRotation))
        .then(() => {
          notifications.success(this.SR_StateMessages.SaveSuccess)
        })
        .catch(error => {
          notifications.error(this.SR_StateMessages.SaveError)
          console.error(error)
        })
      this.selectedCropRotation.name = ''
      this.selectedCropRotation.steps = []
      this.$delete(this.selectedCropRotation, 'id')
    },
    edit (rotation) {
      this.selectedCropRotation = Object.assign({}, rotation)
    },
    remove () {
      const rotation = Object.assign({}, this.selectedCropRotation)
      this.deleteCropRotation({id: rotation.id})
      this.selectedCropRotation.name = ''
      this.selectedCropRotation.steps = []
      this.$delete(this.selectedCropRotation, 'id')
    },
    update () {
      this.updateCropRotation(Object.assign({}, this.selectedCropRotation))
        .then(() => {
          notifications.success(this.SR_StateMessages.SaveSuccess)
        })
        .catch(error => {
          notifications.error(this.SR_StateMessages.SaveError)
          console.error(error)
        })

      // update local state to preserve selection status
      const index = this.selectedCropRotations.findIndex(c => c.id === this.selectedCropRotation.id)
      if (index !== -1) {
        this.selectedCropRotations.splice(index, 1)
        this.selectedCropRotations.push(JSON.parse(JSON.stringify(this.selectedCropRotation)))
      }
      this.selectedCropRotation.name = ''
      this.selectedCropRotation.steps = []
      this.$delete(this.selectedCropRotation, 'id')
    },
    assign () {
      this.saveAssignedCropRotations(this.selectedCropRotations)
        .then(() => {
          notifications.success(this.SR_FieldRecordSystem.SavedCropTypesAssignment)
        })
        .catch(error => {
          notifications.error(this.SR_StateMessages.SaveError)
          console.error(error)
        })
    }
  },
  reloadData () {
    return this.reloadAssignedCropRotations()
      .then(() => {
        this.selectedCropRotations = this.assignedCropRotations
        this.selectedCropRotation = {name: '', steps: []}
      })
  }
}
</script>

<style lang="scss" scoped>
.crop-rotation-management {
  padding: 5px;
  hr {width: 100%;}
}
.edit-button {
  margin-right: 1rem;
}
.edit-button, .rotation-checkbox {
  margin-top: 16px;
}
li.flex-item {
  display: flex;
  align-items: center;
}
</style>
