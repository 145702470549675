<template lang="html">
  <div>
    <NavigationHeader @back="startOrderPlanning">
      <template #step>
        <IxRes>bf.orderExport.headerTitle</IxRes>
      </template>
      <template #back>
        <IxRes>bf.orderExport.button.backToStepTwo</IxRes>
      </template>
    </NavigationHeader>

    <div v-if="orders.length" class="order-export-actions">
      <VueSelectize
        v-model="order" :options="orderOptions"
        :fields="orderFields"
        allow-empty :placeholder="SR_BaseFertilization.PleaseChooseOrder"
      >
        <template #label>
          <IxRes>bf.orderExport.label.orderSelection</IxRes>
        </template>
      </VueSelectize>
      <div class="download-buttons">
        <IxButton icon="download" :disabled="!selectedOrder" @click="downloadAsCsv">
          <IxRes>bf.orderExport.button.downloadAsCSV</IxRes>
        </IxButton>
        <IxButton icon="download" :disabled="!selectedOrder" @click="onDownloadAsShape">
          <IxRes>bf.orderExport.button.downloadAsShape</IxRes>
        </IxButton>
      </div>
      <a
        v-if="csv" ref="anchor"
        data-ajax="false"
        class="hidden" :href="downloadUrl"
        :download="csv.name + '.csv'" type="text/csv"
        target="_blank"
      />
      <a
        ref="anchor_shape" style="display: none"
        type="application/zip"
        target="_blank" data-ajax="false"
      />
    </div>

    <div v-else class="alert alert-info">
      <IxRes>Areas.FieldRecordSystem.SR_BaseFertilization.NoOpenOrdersAvailable</IxRes>
    </div>

    <IxVectorLayer
      :features="fieldBorderFeatures"
      :vector-style="styles.border"
      :z-index="1"
      layer-id="fieldBorder"
      auto-focus
    />
    <IxVectorLayer
      :features="features"
      :vector-style="styles.point"
      :z-index="2"
      layer-id="points"
    />
    <FrsLoadingIndicator :requests="['bf.order.export']" />
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'

import {mapFormFields, mapResources} from '@helpers/vuex'
import {fieldBorder, planningPoint} from '@frs/map-styles'
import {notifications} from 'src/js/infrastructure'

import parse from 'wellknown'

import VueSelectize from '@components/VueSelectize'
import IxButton from '@components/IxButton'
import IxVectorLayer from '@components/map/IxVectorLayer'

import NavigationHeader from '@frs/components/basic-fertilization/widgets/NavigationHeader'
import FieldBorderMixin from './mixins/FieldBorderMixin'
import StandardRulesMixin from '@components/forms/StandardRulesMixin'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    VueSelectize,
    IxButton,
    NavigationHeader,
    IxVectorLayer,
    FrsLoadingIndicator
  },
  mixins: [
    FieldBorderMixin,
    StandardRulesMixin
  ],
  computed: {
    ...mapResources([
      '@frs.SR_BaseFertilization'
    ]),
    ...mapState('fieldRecordSystem/basicFertilization/orderExport', {
      orders: state => state.openOrders
    }),
    ...mapFormFields('fieldRecordSystem/basicFertilization/orderExport', [
      'selectedOrder',
      'optionalSampleIdKey',
      'optionalFieldNameKey'
    ]),
    ...mapGetters('fieldRecordSystem/basicFertilization/orderExport', [
      'csv'
    ]),
    order: {
      get () {
        if (!this.selectedOrder) return null
        const order = this.ordersWithProbingSites.find(order => order.id === this.selectedOrder.id)
        return order ? order.id : null
      },
      set (id) {
        this.selectedOrder = this.ordersWithProbingSites.find(order => order.id === id)
      }
    },
    orderOptions () {
      return this.ordersWithProbingSites.map(order => order.id)
    },
    ordersWithProbingSites () {
      return this.orders.filter(order => order.geometries.length)
    },
    orderFields () {
      return {
        text: id => this.ordersWithProbingSites.find(x => x.id === id).name
      }
    },
    downloadUrl () {
      return this.csv
        ? window.URL.createObjectURL(new Blob([this.csv.body], {type: 'text/csv'}))
        : null
    },
    features () {
      return this.selectedOrder ? this.selectedOrder.geometries.map(geometry => ({
        geometry: parse(geometry.geometry),
        type: 'Feature',
        properties: {
          id: geometry.id,
          results: geometry.results,
          sampleId: geometry.sampleId,
          fieldName: geometry.fieldName,
          isActive: true
        }
      })) : []
    },
    styles () {
      return {
        border: fieldBorder,
        point: planningPoint
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/orderPlanning', [
      'startOrderPlanning'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization/orderExport', [
      'downloadAsShape'
    ]),
    async onDownloadAsShape () {
      if (!this.selectedOrder) return null
      try {
        await this.downloadAsShape()
        .then(path => {
          this.$refs.anchor_shape.href = `/Download/Temp/${path}`
          this.$refs.anchor_shape.download = path.split(/\\/)[1]
          this.$refs.anchor_shape.click()
        })
        notifications.success(this.$i18n.translate('bf.orderExport.notifications.success'))
      } catch (error) {
        console.error(error)
        notifications.error(this.$i18n.translate('bf.orderExport.notifications.error'))
      }
    },
    downloadAsCsv () {
      this.$refs.anchor.click()
    }
  },
  watch: {
    downloadUrl (newUrl, oldUrl) {
      if (oldUrl) {
        window.URL.revokeObjectURL(oldUrl)
      }
    }
  },
  beforeDestroy () {
    if (this.downloadUrl) {
      window.URL.revokeObjectURL(this.downloadUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
.order-export-actions {
  > * {
    margin-bottom: 6px;
  }
}
.download-buttons {
  display: flex;
  justify-content: space-between;
}
</style>
