<template lang="html">
  <div>
    <h3>
      <IxRes v-if="isSeeding">Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.AlgorithmPreview_header_seeding</IxRes>
      <IxRes v-else>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.AlgorithmPreview_header_fertilizer</IxRes>
    </h3>

    <TextInput v-model="name" name="name" placeholder="Optional">
      <template #label>
        <IxRes>frs.name</IxRes>
      </template>
    </TextInput>

    <h3>
      <IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.ZoneMapSettings_header</IxRes>
    </h3>

    <div class="zone-values">
      <FrsZoneValueInput
        v-model="applicationValues"
        name="zoneValueInput"
        :zone-map="zoneMap"
        :unit="unit"
      />
      <FrsZonesTotalSum :zone-values="applicationValues" :zone-map="zoneMap" :unit="unit" />
    </div>

    <FrsZoneMapApplicationPreviewLayer :zone-map-id="zoneMapId" :application-values="applicationValues" />

    <div class="button-container">
      <IxButton back large @click="discardPreview" />
      <IxButton save large @click="onCreateApplicationMap" />
    </div>

    <FrsLoadingIndicator :requests="['frs.applicationMaps.save']" />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'

import {mapFormFields, mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'
import TextInput from '@components/forms/TextInput'
import FrsZoneValueInput from '@frs/components/base/FrsZoneValueInput'
import FrsZoneMapApplicationPreviewLayer from '@frs/components/base/FrsZoneMapApplicationPreviewLayer'
import FrsZonesTotalSum from '@frs/components/base/FrsZonesTotalSum'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    IxButton,
    TextInput,
    FrsZoneValueInput,
    FrsZoneMapApplicationPreviewLayer,
    FrsZonesTotalSum,
    FrsLoadingIndicator
  },
  mixins: [
    FormPartMixin
  ],
  props: {
    isSeeding: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/applicationMaps', [
      'parameters.name',
      'parameters.applicationValues'
    ]),
    ...mapState('fieldRecordSystem/applicationMaps', {
      zoneMapId: state => state.parameters.zoneMapId,
      unit: state => state.parameters.unit
    }),
    ...mapState('fieldRecordSystem/mapManagement', {
      zoneMapLookup: state => state.data.zoneMap.mapLookup
    }),
    ...mapResources([
      '@sam.SR_SimpleApplicationMaps'
    ]),
    zoneMap () {
      return this.zoneMapLookup[this.zoneMapId]
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/applicationMaps', [
      'createApplicationMap',
      'viewResultPage',
      'createApplicationMapSuccess'
    ]),
    ...mapMutations('fieldRecordSystem/applicationMaps', [
      'discardPreview'
    ]),
    async onCreateApplicationMap () {
      this.validate()

      await this.$nextTick()

      if (this.state !== 'success') return

      try {
        const applicationMapId = await this.createApplicationMap()
        notifications.success(this.SR_SimpleApplicationMaps.CreationSuccess)
        this.createApplicationMapSuccess(applicationMapId)
      } catch (data) {
        const {error, zoneGroupId, zoneName} = data.response.data
        let notification = this.SR_SimpleApplicationMaps.CreationError
        console.error(`${notification}. Reason: ${error}, zoneMapId: ${this.zoneMapId}, zoneGroupId: ${zoneGroupId}, zoneName: ${zoneName}`)

        if (data.response.data.failure) {
          notification += '\n' + this.$i18n.translate(`frs.exceptions.${data.response.data.failure}`)
        }
        notifications.error(notification)
      } finally {
        this.viewResultPage()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.button-container {
  margin-top: 10px;
}

.zone-values {
  width: 50%;
}

.button-container {
  display: flex;
  justify-content: space-between;
}
</style>
