<template lang="html">
  <div class="harvest-quality">
    <FrsCropUsagePicker
      v-model="cropUsageId"
      name="cropUsageId"
      :filter="cropUsageHasCropHarvestQualities"
      required
    >
      <template #label>
        <IxRes>frs.action.planning.type.harvest.labels.crop.usage</IxRes>
      </template>
    </FrsCropUsagePicker>
    <VueSelectize
      v-model="cropHarvestQualityId"
      :options="qualityOptions"
      :fields="fields"
      name="cropHarvestQualityId"
      required
      allow-empty
    >
      <template #label>
        <IxRes>frs.action.planning.type.harvest.labels.crop.quality</IxRes>
      </template>
    </VueSelectize>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {groupBy} from 'lodash'

import FrsCropUsagePicker from '@frs/components/base/FrsCropUsagePicker'
import VueSelectize from '@components/VueSelectize'
import ParameterMixin, {mapParameters} from '../ParameterMixin'

export default {
  components: {
    VueSelectize,
    FrsCropUsagePicker
  },
  mixins: [
    ParameterMixin
  ],
  computed: {
    ...mapGetters('masterData', [
      'cropHarvestQualityArray'
    ]),
    ...mapParameters([
      'cropUsageId',
      'cropHarvestQualityId'
    ]),
    qualities () {
      return this.cropHarvestQualityArray.filter(x => x.cropUsageId === this.cropUsageId)
    },
    qualityOptions () {
      return this.qualities.map(x => x.id)
    },
    fields () {
      return {
        text: id => this.$i18n.translate(`masterData.crops.quality.${id}_name`)
      }
    },
    harvestQualityIdsByCropUsageId () {
      return groupBy(this.cropHarvestQualityArray, quality => quality.cropUsageId)
    }
  },
  methods: {
    cropUsageHasCropHarvestQualities (cropUsage) {
      return this.harvestQualityIdsByCropUsageId[cropUsage.id]
    }
  },
  watch: {
    cropUsageId () {
      this.cropHarvestQualityId = null
    },
    cropHarvestQualityId: {
      immediate: true,
      handler () {
        //  referential integrity is not ensured by db as parameters are saved as a json in db
        if (this.cropHarvestQualityId && !this.qualityOptions.includes(this.cropHarvestQualityId)) {
          this.cropHarvestQualityId = null
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.harvest-quality {
  display: flex;
  flex-direction: column;

  > * {
    width: 50%;
  }

  > :last-child {
    margin-top: 16px;
  }
}
</style>
