<template>
  <div>
    <VueSelectize
      v-model="selectedOption"
      allow-empty
      ordered
      :options="ndviOptions"
      :fields="ndviFields"
    >
      <template #label>
        <IxRes>frs.zoneMap.labels.ndviMean</IxRes>
      </template>
    </VueSelectize>
    <div v-if="ndviOptions.length">
      <NdviLineChart v-if="selectedOption" :ndvi-data="selectedOption" />
    </div>
  </div>
</template>

<script>
import VueSelectize from '@components/VueSelectize'
import NdviLineChart from './NdviLineChart'
import moment from 'moment'

export default {
  components: {
    NdviLineChart,
    VueSelectize
  },
  props: {
    ndvi: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedOption: null
    }
  },
  computed: {
    ndviOptions () {
      if (this.ndvi.every(x => x.cropUsageId !== null)) {
        return this.ndvi.map(x => {
          const year = moment(x.recordingDate).year()

          return {
            ...x,
            year,
            id: `${year}-${x.cropUsageId}`
          }
        })
      } else {
        return []
      }
    },
    ndviFields () {
      return {
        text: x => this.$i18n.translate(`masterData.crops.usage.${x.cropUsageId}_name`),
        value: x => x.id,
        label: x => x.year
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
