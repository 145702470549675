<template lang="html">
  <div class="osb">
    <IxButton
      large
      @click="openModal = true"
    >
      <img width="50px" src="@images/Partner/osb.png">
    </IxButton>
    <OsbModal v-if="openModal" v-model="openModal" :application-map-ids="applicationMapIds" />
  </div>
</template>

<script>
import OsbModal from './OsbModal'

import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton,
    OsbModal
  },
  props: {
    applicationMapIds: Array
  },
  data () {
    return {
      openModal: false
    }
  }
}
</script>
