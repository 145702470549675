<template lang="html">
  <ListCard show-details>
    <template slot="title">
      <span class="name" :title="`${index + 1}. `">
        <p>{{ index + 1 }}. <IxRes>Common.SR_Common.Amount</IxRes>: {{ correction.amount }} {{ $i18n.translateUnit('kg/ha') }}</p>
      </span>
    </template>
    <template slot="addon">
      <QuickActions>
        <QuickActionIcon icon="edit" :title="SR_Common.Edit" @click="edit(correction)" />
        <QuickActionIcon icon="trash" :title="SR_Common.Delete" @click="removeCorrection()" />
      </QuickActions>
    </template>
    <div class="item">
      <div class="details">
        <p>
          <IxRes>Common.SR_Common.Comment</IxRes>: {{ correction.note }}
        </p>
      </div>
    </div>
  </ListCard>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'

import {mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import QuickActions from '@components/card/QuickActions'
import QuickActionIcon from '@components/card/QuickActionIcon'
import ListCard from '@components/card/ListCard'

export default {
  components: {
    ListCard,
    QuickActions,
    QuickActionIcon
  },
  props: {
    correction: Object,
    index: Number
  },
  computed: {
    ...mapResources([
      'Common.SR_Common',
      'Messages.State.SR_StateMessages'
    ])
  },
  methods: {
    ...mapActions('fieldRecordSystem/nutrientRequirement', [
      'deleteCorrection'
    ]),
    ...mapMutations('fieldRecordSystem/nutrientRequirement', [
      'editCorrection',
      'setWizardStep'
    ]),
    edit (correction) {
      this.editCorrection(correction)
      this.setWizardStep('correction')
    },
    removeCorrection () {
      this.deleteCorrection(this.correction)
        .then(() => {
          notifications.success(this.SR_StateMessages.DeleteSuccess)
          this.$store.commit('fieldRecordSystem/setDataLoading', false)
        })
        .catch(() => {
          notifications.error(this.SR_StateMessages.DeleteError)
          this.$store.commit('fieldRecordSystem/setDataLoading', false)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .item, .amount {
    display: flex;
  }
  .index {
    width: 4%;
  }
  .amount {
    p {
      margin-right: 5px;
    }
  }
  .edit-correction {
    margin-right: 5px;
  }
</style>
