<template lang="html">
  <BsModal v-model="modelProxy">
    <template #title>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.FieldGeometrySaveModalTitle</IxRes>
    </template>

    <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.FieldGeometrySaveModalHint</IxRes>

    <FieldEditingCultivationSelection v-model="selectedCultivations" :field-feature="fieldFeature" :cultivations="futureCultivations" />

    <div slot="footer" class="footer-content">
      <IxButton cancel large @click="$emit('input', false)" />
      <IxButton save large @click="$emit('confirm', selectedCultivations)" />
    </div>
  </BsModal>
</template>

<script>
import {mapState} from 'vuex'

import {modelProxy} from '@helpers/vuex'
import {combineToMultiPolygon, geoJsonFormat} from '@helpers/openlayers/features'

import IxButton from '@components/IxButton'
import BsModal from '@components/bootstrap/BsModal'

import FieldEditingCultivationSelection from './FieldEditingCultivationSelection'

export default {
  components: {
    IxButton,
    FieldEditingCultivationSelection,
    BsModal
  },
  props: {
    value: Boolean
  },
  data () {
    return {
      selectedCultivations: []
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/map', {
      features: state => state.editing.activeFeatures.map(feature => geoJsonFormat.readFeature(feature))
    }),
    ...mapState('fieldRecordSystem', {
      fieldData: state => state.data.field
    }),
    ...mapState('fieldRecordSystem/navigation', {
      fieldId: state => state.location.fieldId
    }),
    futureCultivations () {
      return this.fieldData[this.fieldId].futureCultivations
    },
    fieldFeature () {
      if (!this.value) return null

      const polygons = this.features.filter(f => f.getGeometry().getType() === 'Polygon')
      const geojson = polygons.length
        ? geoJsonFormat.writeFeatureObject(combineToMultiPolygon(polygons))
        : null
      return geojson
    },
    modelProxy
  }
}
</script>

<style lang="scss" scoped>
.footer-content {
  display: flex;
  justify-content: space-between;
}
</style>
