<template lang="html">
  <div class="crop-rotation-editor" :class="{editable}">
    <TextInput v-model="rotation.name" class="text-input" label="Name" />
    <div class="loop-top" />
    <div class="loop-middle">
      <div class="loop-back" />
      <div class="crop-rotation-main">
        <div class="crop-rotation-graph">
          <GraphTerminator type="start" direction="vertical" />
        </div>
        <div v-for="(step, index) in rotation.steps" :key="index">
          <div class="crop-rotation-graph">
            <MidpointNode :editable="editable" @click="showFor(index)" />
          </div>

          <CropRotationEditorStep
            :step="step"
            :editable="editable"
            :verbose="verbose"
            @click="removeStep(index)"
          />
        </div>
        <div class="end-row">
          <div class="crop-rotation-graph">
            <MidpointNode :editable="editable" @click="showFor(rotation.steps.length)" />
            <GraphTerminator type="end" direction="vertical" />
          </div>
          <span v-if="!rotation.steps.length" class="no-value-hint">
            <i class="fa fa-arrow-left" /> <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.PleaseClickToAddCrop</IxRes>
          </span>
        </div>
      </div>
    </div>
    <div class="loop-bottom" />
    <BsModal v-if="editable" v-model="showModal">
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AddNewCrop</IxRes>
      </template>
      <div style="max-height: 80vh; overflow-y: auto">
        <FrsCropUsageSelectionGrid @input="addCropUsage" />
      </div>
      <!-- <vue-selectize :options="options" v-model="selectedCrop"></vue-selectize>
      <button slot="footer" class="btn btn-success" @click="add">Add</button> -->
    </BsModal>
  </div>
</template>

<script>
import {getCropUsageTranslations} from '@frs/helpers/crops'

import BsModal from '@components/bootstrap/BsModal'
import FrsCropUsageSelectionGrid from '@frs/components/base/FrsCropUsageSelectionGrid'
import TextInput from '@components/forms/TextInput'

import MidpointNode from './MidpointNode'
import GraphTerminator from './GraphTerminator'
import CropRotationEditorStep from './CropRotationEditorStep'

export default {
  components: {
    TextInput,
    FrsCropUsageSelectionGrid,
    BsModal,
    MidpointNode,
    GraphTerminator,
    CropRotationEditorStep
  },
  model: {
    prop: 'rotation'
  },
  props: {
    rotation: Object,
    editable: Boolean,
    verbose: Boolean
  },
  data () {
    return {
      showModal: false,
      selectedCropUsage: null, // TODO add default
      selectedIndex: 0
    }
  },
  computed: {
    cropUsageTranslations () {
      return this.rotation.steps.map(step => getCropUsageTranslations(step.cropUsageId))
    }
  },
  methods: {
    showFor (index) {
      this.selectedIndex = index
      this.showModal = true
    },
    addCropUsage (cropUsageId) {
      this.showModal = false

      const index = this.selectedIndex

      if (index < 0 || index > this.rotation.steps.length) {
        throw new Error('invalid index')
      }
      const steps = [...this.rotation.steps]
      steps.splice(index, 0, {cropUsageId, index})
      this.$emit('input', Object.assign({}, this.rotation, {steps}))
    },
    removeStep (index) {
      if (index < 0 || index >= this.rotation.steps.length) {
        throw new Error('invalid index')
      }
      const steps = [...this.rotation.steps]
      steps.splice(index, 1)
      this.$emit('input', Object.assign({}, this.rotation, {steps}))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./crop-rotation.scss";

input {
  margin-bottom: 1rem;
}
.loop-middle, .end-row {
  display: flex;
}
.crop-rotation-editor {
  margin: 10px;
  .text-input {margin-bottom: 10px;}
}
.crop-rotation-editor, .crop-rotation-main, .crop-rotation-graph {
  display: flex;
  flex-direction: column;
}

// .loop-back {
//   align-self: stretch;
//
//   width: $lineWidth;
//   margin-right: $nodeRadius;
//   background-color: $lineColor;
// }

.crop-rotation-graph {
  align-items: center;
  width: $nodeRadius * 2;
}

.end-row .no-value-hint {
  align-self: flex-start;
}
</style>
