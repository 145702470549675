<template>
  <VueSelectize
    class="crop-selector"
    :options="crops"
    :fields="{text: 'name', value: 'name'}"
    :value="value"
    required
    allow-empty
    :placeholder="SR_FieldRecordSystem.SelectPlaceholder"
    @input="onCropSelected"
  >
    <template #label>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Crop</IxRes>
    </template>
  </VueSelectize>
</template>

<script>
import {mapResources} from '@helpers/vuex'
import {mapState} from 'vuex'

import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    VueSelectize
  },
  props: {
    value: {}
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState('fieldRecordSystem', {
      crops: state => state.nutrientRequirement.data.editing.crops
    })
  },
  methods: {
    onCropSelected (cropName) {
      this.$emit('input', cropName)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
