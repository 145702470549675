<template lang="html">
  <BsModal v-model="showModal">
    <TextInput v-model="name" required name="machineName">
      <template #label>
        <IxRes>Areas.LanePlanning.SR_LanePlanning.Machine</IxRes>:
      </template>
    </TextInput>
    <VueSelectize
      v-model="group"
      allow-empty
      :options="machineGroupOptions"
      :fields="machineGroupFields"
      required
      :placeholder="SR_FieldRecordSystem.SelectMachineGroupPlaceholder" sort-field="text"
      name="machineGroups"
    >
      <template #label>
        <IxRes>Common.SR_Common.Group</IxRes>:
      </template>
    </VueSelectize>
    <TextInput v-model="vin" name="vin">
      <template #label>
        <IxRes>Areas.VehicleFleet.SR_VehicleFleet.VehicleIdentificationNumber</IxRes>:
      </template>
    </TextInput>

    <NumericInput v-model="workingWidth" name="workingWidth">
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.WorkingWidthInMeter</IxRes>:
      </template>
    </NumericInput>
    <NumericInput v-model="turningCircle" name="turningCircle">
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.TurningCircleInMeter</IxRes>:
      </template>
    </NumericInput>

    <IxButton
      slot="footer" large
      save
      :disabled="!isValid" @click="onSave"
    >
      <IxRes>Common.SR_Common.Save</IxRes>
    </IxButton>
    <IxButton
      slot="footer" large
      cancel
      @click="stopEditing"
    >
      <IxRes>Common.SR_Common.Cancel</IxRes>
    </IxButton>

    <FrsLoadingIndicator :loading="saving" />
    <slot slot="title" name="title" />
  </BsModal>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import FormPartMixin from '@components/forms/FormPartMixin'

import BsModal from '@components/bootstrap/BsModal'
import VueSelectize from '@components/VueSelectize'
import IxButton from '@components/IxButton'
import NumericInput from '@components/forms/NumericInput'
import TextInput from '@components/forms/TextInput'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    BsModal,
    VueSelectize,
    IxButton,
    NumericInput,
    TextInput,
    FrsLoadingIndicator
  },
  mixins: [FormPartMixin],
  props: [
    'title'
  ],
  data () {
    return {
      saving: false
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/machines/editBuffer', [
      'id',
      'vin',
      'name',
      'workingWidth',
      'turningCircle',
      'group'
    ]),
    ...mapResources([
      '@frs.SR_FieldRecordSystem',
      'Messages.State.SR_StateMessages'
    ]),
    ...mapState('fieldRecordSystem/machines', {
      editing: state => state.ui.editing,
      machineGroups: state => state.data.machineGroups
    }),
    machineGroupFields () {
      return {
        text: name => this.SR_FieldRecordSystem[`VehicleType_${name}`]
      }
    },
    machineGroupOptions () {
      const sortedMachineGroups = [...this.machineGroups].sort()

      return sortedMachineGroups.map(x => x.name)
    },
    showModal: {
      get () {
        return this.editing
      },
      set (value) {
        this.setEditing(value)
      }
    },
    isValid () {
      if (this.state !== 'success') {
        return false
      }
      return true
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/machines', [
      'stopEditing',
      'saveMachine'
    ]),
    ...mapMutations('fieldRecordSystem/machines', [
      'setEditing'
    ]),
    onSave () {
      this.saving = true

      this.saveMachine()
        .then(() => {
          this.stopEditing()
          notifications.success(this.SR_StateMessages.SaveSuccess)
        })
        .catch((error) => {
          if (error.response.data.error === 'duplicateVIN') {
            notifications.error(this.SR_StateMessages.duplicateVin)
            console.error(`The machine with vin ${this.vin} is already in use.`)
          } else {
            notifications.error(this.SR_StateMessages.SaveError)
          }
        })
        .then(() => {
          this.saving = false
        })
    },
    localizedTypeOption (type) {
      const key = `VehicleType_${type}`

      return {
        value: type,
        name: this.SR_FieldRecordSystem[key]
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
