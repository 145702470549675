<template lang="html">
  <IxVectorLayer
    :features="actionFeatures"
    :vector-style="actionStyle"
    layer-id="actions"
  />
</template>

<script>
import {action} from '@frs/map-styles'

import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  components: {
    IxVectorLayer
  },
  props: {
    actions: {
      type: Array,
      required: true
    }
  },
  computed: {
    actionFeatures () {
      if (!this.actions.length) return []

      return this.actions.map(({id, type, geometry, fieldId}) => ({
        geometry,
        type: 'Feature',
        properties: {
          uid: id,
          fieldId,
          type
        }
      }))
    },
    actionStyle () {
      return action
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
