import { render, staticRenderFns } from "./ResultsTableReadOnly.vue?vue&type=template&id=5557d764&scoped=true&lang=html&"
import script from "./ResultsTableReadOnly.vue?vue&type=script&lang=js&"
export * from "./ResultsTableReadOnly.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5557d764",
  null
  
)

export default component.exports