<template>
  <div class="card-container" @click="$emit('action')">
    <h4 class="title">
      <slot name="title" />
    </h4>
    <p v-if="isCollapsed" class="description">
      <slot />
    </p>
    <div class="content">
      <slot name="content" />
    </div>
    <div class="footer-container">
      <p class="status">
        <slot name="status" />
      </p>
      <a>
        <slot name="action" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isCollapsed: Boolean
  }
}
</script>

<style lang="scss" scoped>
h4, p, a {
  padding: 0;
  margin: 0;
}
a:hover {
  text-decoration: none;
}
h4 {
  font-size: 1.1em;
}
.card-container {
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 4px;
  padding: 8px;
  border: 1px solid lightgrey;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.075);
  &:hover {
    border-color: #a0d9ef;
    background-color: #f9f9f9;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}
.title {
  display: flex;
  margin-bottom: 4px;
}
.description {
  margin-bottom: 3px;
  font-size: 1em;
  color: darkgray;
}
.footer-container {
  display: flex;
  justify-content: space-between;
}
.status {
  font-size: 0.9em;
  padding-top: 3px;
}
</style>
