<template lang="html">
  <SharedSummary :meta-data="metaData">
    <template #images>
      <div :class="$style.images">
        <SatelliteImageReference
          v-for="(image, i) in metaData.parameters.selectedImages" :key="i"
          :image="image"
        />
      </div>
    </template>
    <template #availableimages="{image}">
      <SatelliteImageReference :image="image" />
    </template>
  </SharedSummary>
</template>

<script>
import SharedSummary from './components/SharedSummary'
import SatelliteImageReference from './components/SatelliteImageReference'

export default {
  components: {
    SharedSummary,
    SatelliteImageReference
  },
  props: {
    metaData: Object
  }
}
</script>

<style lang="scss" module>
.images {
  display: flex;
  flex-direction: column;
}
</style>
