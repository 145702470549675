<template>
  <span>
    <span>{{ $i18n.format(value.start, 'date') }}</span>
    <span v-if="!isSingleDate">- {{ value.end ? $i18n.format(value.end, 'date') : '?' }}</span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    isSingleDate () {
      return this.value.start === this.value.end
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
