<template lang="html">
  <div class="marker-container" @click="onClick">
    <div class="marker" :class="{included, interactive, small: !terminal}" />
  </div>
</template>

<script>
export default {
  props: {
    included: Boolean,
    interactive: Boolean,
    terminal: Boolean,
    onClick: Function
  }
}
</script>

<style lang="scss" scoped>
.marker-container {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: mid-start / mid-end
}

.marker {
  border-radius: 50%;
  width: 15px;
  height: 15px;

  border: 2px solid rgb(89, 89, 89);
  background-color: rgb(89, 89, 89);

  transition: background-color 0.2s, transform 0.2;

  &.interactive {
    cursor: pointer;
    border-color: rgb(91, 137, 255);
    background-color: white;

    &:hover {
      transform: scale(1.2);
    }

    &.included {
      background-color: rgb(91, 137, 255);
    }
  }
}

.small {
  width: 9px;
  height: 9px;
}
</style>
