<template lang="html">
  <div class="transport">
    <div>
      <span>
        <strong>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CargoType</IxRes>: *
        </strong>
      </span>
      <VueSelectize v-model="selectedCargoType" :options="cargoTypeOptions" allow-empty />
    </div>
    <div class="section">
      <p>
        <strong>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.TransportType</IxRes>: *
        </strong>
      </p>
      <SimpleSwitch v-model="internal">
        <template #offLabel>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.TransportType_external</IxRes>
        </template>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.TransportType_internal</IxRes>
      </SimpleSwitch>
    </div>
    <div class="section">
      <strong>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.TransportOriginLabel</IxRes>:
      </strong>
      <TextInput v-model="origin" />
      <strong>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.TransportDestinationLabel</IxRes>:
      </strong>
      <TextInput v-model="destination" />
    </div>
    <div class="section">
      <strong>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Distance</IxRes>:
      </strong>
      <NumericInput v-model="distance" class="distance-input" unit="km" />
    </div>
  </div>
</template>

<script>
import {mapResources} from '@helpers/vuex'

import VueSelectize from '@components/VueSelectize'
import TextInput from '@components/forms/TextInput'
import SimpleSwitch from '@components/forms/SimpleSwitch'
import NumericInput from '@components/forms/NumericInput'

import ParameterMixin, {mapParameters} from './ParameterMixin'

export default {
  components: {
    VueSelectize,
    TextInput,
    SimpleSwitch,
    NumericInput
  },
  mixins: [
    ParameterMixin
  ],
  computed: {
    fieldId () {
      return Object.keys(this.value)[0]
    },
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    cargoTypeOptions () {
      return ['general', 'bulk', 'liquid', 'hazardous'].map(value => ({
        text: this.SR_FieldRecordSystem[`CargoType_${value}`],
        value
      }))
    },
    ...mapParameters([
      'internal',
      'origin',
      'destination',
      'cargoType',
      'distance'
    ]),
    selectedCargoType: {
      get () {
        return this.cargoTypeOptions.find(x => x.value === this.cargoType)
      },
      set (option) {
        this.emitParameterChange({cargoType: option.value})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.transport {
  margin-top: 20px;
  margin-bottom: 6px;
}
.section {
  margin-top: 25px;

  .distance-input {
    width: 40%;
  }
}
</style>
