<template>
  <label>
    <IxRes :context="{0: fullName}">Areas.FieldRecordSystem.SR_Action.MultiFieldNutrientValuesHeader</IxRes>:
  </label>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

export default {
  props: {
    action: Object,
    fieldId: String
  },
  computed: {
    ...mapGetters('fieldRecordSystem', [
      'entityNameLookup'
    ]),
    ...mapState('fieldRecordSystem', {
      fieldDataLookup: state => state.data.field
    }),
    name () {
      return this.entityNameLookup[this.fieldId]
    },
    code () {
      return this.fieldCodeLookup[this.fieldId]
    },
    fullName () {
      return this.code ? `${this.name} (${this.code})` : this.name
    },
    fieldCodeLookup () {
      return this.action.fieldIds.reduce((lookup, id) => {
        lookup[id] = null
        lookup[id] = this.fieldDataLookup[id].code
        return lookup
      }, [])
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
