<template>
  <div class="form-container">
    <NavigationHeader hide-back-button>
      <IxRes slot="step">bf.orderCreation.headerTitle</IxRes>
    </NavigationHeader>

    <TextInput
      v-model="name" class="name-input"
      name="orderName"
      required
    >
      <template slot="label">
        <IxRes>bf.orderCreation.label.orderName</IxRes>:
      </template>
    </TextInput>

    <div class="choice-divider">
      <hr>
      <IxRes>bf.orderCreation.labels.creationChoicePrefix</IxRes>
      <hr>
    </div>

    <HelpBox>
      <IxRes>bf.orderCreation.hints.import</IxRes>
    </HelpBox>

    <IxButton
      icon="upload"
      :disabled="!name"
      @click="showOrderImportModal = true"
    >
      <IxRes>bf.orderCreation.button.import</IxRes>
    </IxButton>

    <div class="choice-divider">
      <hr>
      <IxRes>bf.orderCreation.labels.creationChoice</IxRes>
      <hr>
    </div>

    <HelpBox>
      <IxRes>bf.orderCreation.hints.fieldSelectionDescription</IxRes>
    </HelpBox>

    <FrsFieldSelectionInfo class="field-selection-info" :field-ids="selectedFieldIds">
      <template #title>
        <IxRes>bf.orderCreation.labels.fieldSelection</IxRes>
      </template>
      <template #description>
        <IxRes>bf.orderCreation.hints.fieldSelection</IxRes>
      </template>
    </FrsFieldSelectionInfo>

    <br>

    <ActionFooter :disabled="disableButton" @home="save(false)" @next="next(true)">
      <template slot="saveNextStep">
        <IxRes>bf.orderCreation.saveToStepTwo</IxRes>
      </template>
    </ActionFooter>

    <OrderImportModal
      :value="showOrderImportModal"
      :imported-order-name="name"
      @input="showOrderImportModal = false"
      @success="goToStepFour = true"
    />
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {mapFormFields, mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import ActionFooter from '@frs/components/basic-fertilization/widgets/ActionFooter'
import NavigationHeader from '@frs/components/basic-fertilization/widgets/NavigationHeader'
import OrderImportModal from '@frs/components/basic-fertilization/order-import/OrderImportModal'

import HelpBox from '@components/help/HelpBox'
import IxButton from '@components/IxButton'
import TextInput from '@components/forms/TextInput'
import FrsFieldSelectionInfo from '@frs/components/base/FrsFieldSelectionInfo'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    ActionFooter,
    FrsFieldSelectionInfo,
    NavigationHeader,
    HelpBox,
    IxButton,
    OrderImportModal,
    TextInput
  },
  mixins: [
    FormPartMixin
  ],
  data () {
    return {
      showOrderImportModal: false
    }
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState('fieldRecordSystem/basicFertilization/orderCreation', {
      selectedFieldIds: state => state.order.selectedFieldIds
    }),
    ...mapFormFields('fieldRecordSystem/basicFertilization/orderCreation', [
      'order.name'
    ]),
    disableButton () {
      return this.selectedFieldIds.length === 0
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/basicFertilization/orderCreation', [
      'saveOrder'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization/resultImport', [
      'startManualEntry'
    ]),
    next (next) {
      this.save(next)
    },
    async save (next) {
      this.validate()

      await this.$nextTick()

      if (this.state === 'success' && this.selectedFieldIds.length > 0) {
        this.saveOrder(next)
        .then(() => {
          notifications.success(this.SR_FieldRecordSystem.saveSamplingOrderSuccess)
        })
        .catch(error => {
          console.error(error)
          notifications.error(this.SR_FieldRecordSystem.saveSamplingOrderError)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form-container {
  display: flex;
  flex-direction: column;
  padding: 0.25em;

  .field-selection-info {
    margin: 16px 0 0 0;
  }
  .description {
    width: 90%;
    margin-bottom: 16px;
  }
  .upload-button {
    width: 45%;
  }
}

.choice-divider {
  display: flex;
  align-items: center;

  font-size: 1.5em;
  text-transform: uppercase;
  margin: 0.5em 0;

  hr {
    border-color: darkgray;
    flex: 1;
    margin: 0 0.25em;
  }
}
</style>
