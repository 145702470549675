<template>
  <div>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.zoneMap</IxRes>
      </template>
      <div>{{ metaData.zoneMap.name }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.product</IxRes>
      </template>
      <div>{{ product.name }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.cultivation</IxRes>
      </template>
      <div v-if="cultivation">
        <span>{{ cultivation.label }}</span>
        &nbsp;
        <span>({{ cultivation.dateTimeRange.start | formatDate }} - {{ cultivation.dateTimeRange.end | formatDate }})</span>
      </div>
      <div v-else>
        <IxRes>masterData.crops.usage.noInfo</IxRes>
      </div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.unit</IxRes>
      </template>
      <div>{{ applicationMap.unit }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.applicationMaps.labels.algorithmSelection</IxRes>
      </template>
      <div>{{ metaData.algorithmName }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.detailsFertAssist.labels.temperature</IxRes>
      </template>
      <div>{{ metaData.parameters.currentTemperature }}</div>
    </LabelAndMessage>

    <FrsLoadingIndicator :requests="['mapManagement.cultivation.loadDetails']" />
  </div>
</template>

<script>
import moment from 'moment'

import {mapGetters} from 'vuex'
import {smarterGet} from '@helpers/vuex/data-loading'
import {cultivationToName} from '@frs/components/cultivation-overview/helpers'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    FrsLoadingIndicator,
    LabelAndMessage
  },
  filters: {
    formatDate (isoDate) {
      return moment(isoDate).format('DD.MM.YY')
    }
  },
  props: {
    applicationMap: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      cultivation: null
    }
  },
  computed: {
    ...mapGetters('masterData', [
      'products'
    ]),
    metaData () {
      return this.applicationMap.metaData
    },
    product () {
      return this.products[this.applicationMap.productId]
    }
  },
  created () {
    if (this.applicationMap.cultivationId) {
      smarterGet('/api/v2/org/cultivations/{cultivationId}/details', {
        id: 'mapManagement.cultivation.loadDetails',
        inputs: {
          cultivationId: () => this.applicationMap.cultivationId
        },
        onResult: details => {
          this.cultivation = {
            dateTimeRange: details.dateTimeRange,
            label: cultivationToName(details)
          }
        }
      })
    }
  }
}
</script>
