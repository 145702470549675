<template>
  <div>
    <div class="harvest-year">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementCurrentHarvestYear</IxRes>: <strong>{{ harvestYear | format }}</strong>
      <VueInfo class="info" placement="top">
        <template #description>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SelectedHarvestYearInfo</IxRes>
        </template>
      </VueInfo>
    </div>

    <div class="crop-type">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NutrientRequirementMainCrop</IxRes>:
      <strong v-if="mainCropName">{{ mainCropName }}</strong>
      <IxRes v-else>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoCultivation</IxRes>
      <VueInfo class="info" placement="top">
        <template #description>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SelectedHarvestMainCropInfo</IxRes>
        </template>
      </VueInfo>
    </div>

    <div v-for="(plan, i) in phosphorusPlans" :key="i">
      <div v-if="i > 0" class="container-years">
        <p class="text-addititonal-years">
          <strong>{{ i + 1 }}<IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AdditionalYear</IxRes>{{ harvestYear + i | format }})</strong>
        </p>
        <IxButton
          large class="delete-button"
          icon="minus-square"
          @click="removeYear(i)"
        >
          <IxRes v-if="phosphorusPlans.length === 2 && i === 1">Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteSecondYear</IxRes>
          <IxRes v-if="phosphorusPlans.length === 3 && i === 1">Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteSecondAndThirdYear</IxRes>
          <IxRes v-if="phosphorusPlans.length === 3 && i === 2">Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteThirdYear</IxRes>
        </IxButton>
      </div>
      <PhosphorusRequirementDetails
        :average-yield-last-three-years="averageYieldLast3Years"
        :current-yield="expectedYield"
        :year="i"
        :value="plan"
        :crops="crops"
        :show-crop-selection="i > 0"
        @input="updateYear({i, plan: $event})"
        @phosphorusContent="updatePhosphorusContent({plan: $event})"
        @partialPhosphorusPlan="setPartialPPlan({i, partialPhosphorusPlan: $event})"
      />
      <hr>
    </div>

    <div class="add-year">
      <IxButton
        v-if="phosphorusPlans.length < 3 && phosphorusPlans.length === 1" large
        icon="plus-square"
        @click="addYear"
      >
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AddSecondYear</IxRes>
      </IxButton>
    </div>

    <div>
      <IxButton
        v-if="phosphorusPlans.length < 3 && phosphorusPlans.length === 2" large
        icon="plus-square"
        @click="addYear"
      >
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AddThirdYear</IxRes>
      </IxButton>
    </div>
    <br>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import moment from 'moment'

import {mapFormFields} from '@helpers/vuex'

import IxButton from '@components/IxButton'
import VueInfo from '@components/help/VueInfo'

import PhosphorusRequirementDetails from './widgets/PhosphorusRequirementDetails'

export default {
  components: {
    PhosphorusRequirementDetails,
    IxButton,
    VueInfo
  },
  filters: {
    format (year) {
      const yearAsMoment = moment({year})
      return `${yearAsMoment.clone().subtract(1, 'year').format('YY')}/${yearAsMoment.format('YY')}`
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/nutrientRequirement/editBuffer', [
      'averageYieldLast3Years'
    ]),
    ...mapState('fieldRecordSystem/nutrientRequirement', {
      crops: state => state.data.editing.crops,
      mainCropId: state => state.editBuffer.mainCropId,
      harvestYear: state => state.editBuffer.harvestYear,
      phosphorusPlans: state => state.editBuffer.phosphorusPlans,
      expectedYield: state => state.editBuffer.expectedYield
    }),
    mainCropName () {
      if (this.mainCropId) {
        return (this.crops.find(x => x.name === this.mainCropId)).name
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/nutrientRequirement/editBuffer', [
      'addYear',
      'removeYear',
      'updateYear',
      'updatePhosphorusContent',
      'setPartialPPlan'
    ])
  }
}
</script>

<style lang="scss" scoped>
.container-years {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.add-year {
  margin-top: 5px;
}
</style>
