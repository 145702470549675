<template lang="html">
  <canvas ref="canvas" />
</template>

<script>
import {mapState, mapMutations} from 'vuex'

import Chart from 'chart.js'
import {take, sum} from 'lodash'
import moment from 'moment'

export default {
  props: [
    'climateData',
    'category'
  ],
  computed: {
    ...mapState('fieldRecordSystem/fieldDetails', {
      climateCategories: state => state.data.climateCategories
    }),
    ...mapMutations('fieldRecordSystem/fieldDetails', [
      'resetClimateData'
    ]),
    unit () {
      return this.climateCategories[this.category].unit
    },
    currentClimateData () {
      return this.climateData.currentData
    },
    averageClimateData () {
      return this.climateData.averageData
    },
    currentYearLabel () {
      if (this.currentClimateData.length > 0) {
        return moment(this.currentClimateData[0].date).year()
      } return this.$i18n.translate('frs.weatherDetails.labels.noDataForCurrentYear')
    },
    averageYearLabel () {
      const startYear = moment(this.climateData.startDateOfAverageData).year()
      const endYear = moment(this.climateData.endDateOfAverageData).year()
      return this.$i18n.translate('frs.weatherDetails.labels.averageTimespan', {from: startYear, to: endYear})
    },
    chartInit () {
      let datasets = []
      let currentDataset = {
        label: this.currentYearLabel,
        borderColor: '#ffa500',
        data: []
      }
      let averageDataset = {
        label: this.averageYearLabel,
        borderColor: '#0000ff',
        data: []
      }
      if (this.category === 'evapotranspiration' || this.category === 'climaticWaterBalance' || this.category === 'heatSummation' || this.category === 'precipitation') {
        currentDataset.data = this.currentClimateData.map(data => data[this.category]).map((dummy, i, data) => this.$i18n.parse(this.$i18n.format(sum(take(data, i + 1)), 'f2'), 'number'))
        averageDataset.data = this.averageClimateData.map(data => data[this.category]).map((dummy, i, data) => this.$i18n.parse(this.$i18n.format(sum(take(data, i + 1)), 'f2'), 'number'))
      } else {
        currentDataset.data = this.currentClimateData.map(data => this.$i18n.parse(this.$i18n.format(data[this.category], 'f2')), 'number')
        averageDataset.data = this.averageClimateData.map(data => this.$i18n.parse(this.$i18n.format(data[this.category], 'f2')), 'number')
      }

      datasets = [currentDataset, averageDataset]

      return {
        type: 'line',
        data: {
          labels: moment.months(),
          datasets
        },
        options: {
          scales: {
            xAxes: [{
              ticks: {
                autoSkip: false,
                maxRotation: 45,
                minRotation: 45
              }
            }],
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: this.$i18n.translateUnit(this.unit),
                fontStyle: 'bold'
              }
            }]
          }
        }
      }
    }
  },
  watch: {
    category () {
      this.chart.destroy()
      this.chart = new Chart(this.$refs.canvas, this.chartInit)
    }
  },
  beforeUpdate () {
    this.chart.unbindEvents()
  },
  updated () {
    this.chart.bindEvents()
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.destroy()
    }
  },
  mounted () {
    this.chart = new Chart(this.$refs.canvas, this.chartInit)
  }
}
</script>

<style lang="css" scoped>
</style>
