import { render, staticRenderFns } from "./CommonSettingsForm.vue?vue&type=template&id=24451d9c&scoped=true&lang=html&"
import script from "./CommonSettingsForm.vue?vue&type=script&lang=js&"
export * from "./CommonSettingsForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24451d9c",
  null
  
)

export default component.exports