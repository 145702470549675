<template>
  <div v-if="map">
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.detail.soilMaps.metaData.fileFormatTerminal</IxRes>:
      </template>
      <div>{{ map.metaData.fileFormat }}</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.detail.soilMaps.metaData.alignmentInDrivingDirection</IxRes>:
      </template>
      <div>{{ map.metaData.alignInDrivingDirection ? $i18n.translate('frs.mapManagement.detail.soilMaps.metaData.alignInDrivingDirection.align') : $i18n.translate('frs.mapManagement.detail.soilMaps.metaData.alignInDrivingDirection.noAlign') }}</div>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.detail.soilMaps.metaData.spreaderWorkingWidth</IxRes>:
      </template>
      <div>{{ map.metaData.spreaderWorkingWidth }} m</div>
    </LabelAndMessage>
    <LabelAndMessage v-if="map.metaData.alignInDrivingDirection && map.metaData.fileFormat === 'IsoXML'">
      <template #label>
        <IxRes>frs.mapManagement.detail.soilMaps.metaData.rasterLength</IxRes>:
      </template>
      <div>{{ map.metaData.rasterLength }} m</div>
    </LabelAndMessage>
    <LabelAndMessage v-if="map.metaData.requiredNutrientMapName">
      <template #label>
        <IxRes>frs.mapManagement.detail.soilMaps.metaData.requiredNutrientMapName</IxRes>:
      </template>
      <div>{{ map.metaData.requiredNutrientMapName }} m</div>
    </LabelAndMessage>
  </div>
</template>

<script>
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
export default {
  components: {
    LabelAndMessage
  },
  props: {
    map: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
