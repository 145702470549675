<template lang="html">
  <FrsFeatureAvatar>
    <polygon
      v-for="(polygon, index) in cultivationPolygons" :key="`cultivation-${index}`"
      :fill="cultivationColor"
      :points="polygon"
    />
    <polygon
      v-for="(polygon, index) in borderPolygons" :key="`border-white-${index}`"
      class="border"
      :class="{animate}"
      :points="polygon"
    />
    <polygon
      v-for="(polygon, index) in borderPolygons" :key="`border-black-${index}`"
      class="border2"
      :class="{animate}"
      :points="polygon"
    />
  </FrsFeatureAvatar>
</template>

<script>
import {mapState} from 'vuex'
import bbox from '@turf/bbox'

import {toSvgPolygons, fromWkt} from '@helpers/geojson'
import {cultivationToColor} from '@frs/helpers/colors'

import FrsFeatureAvatar from '@frs/components/base/FrsFeatureAvatar'

export default {
  components: {
    FrsFeatureAvatar
  },
  props: {
    fieldFeature: Object,
    cultivation: Object
  },
  data () {
    return {
      animate: false
    }
  },
  computed: {
    ...mapState('masterData', {
      cropUsages: state => state.cropUsages
    }),
    bbox () {
      return bbox({type: 'FeatureCollection',
        features: [
          {type: 'Feature', geometry: this.cultivationGeometry},
          this.fieldFeature
        ].filter(x => x)})
    },
    cultivationColor () {
      return cultivationToColor(this.cultivation, this.cropUsages[this.cultivation.cropUsageId])
    },
    cultivationGeometry () {
      return fromWkt(this.cultivation.geometry)
    },
    cultivationPolygons () {
      return toSvgPolygons(this.cultivationGeometry, this.bbox)
    },
    borderPolygons () {
      return toSvgPolygons(this.fieldFeature, this.bbox)
    }
  },
  mounted () {
    this.animate = true
  }
}
</script>

<style lang="scss" scoped>
.border {
  fill: transparent;
  stroke: #ffffff;
  stroke-width: 3;
  stroke-dasharray: 10 10;

  &.animate {
    animation: dash 2s linear infinite;
  }
}
.border2 {
  fill: transparent;
  stroke: #000000;
  stroke-width: 3;
  stroke-dasharray: 10 10;
  stroke-dashoffset: 10;

  &.animate {
    animation: dash2 2s linear infinite;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 20;
  }
}

@keyframes dash2 {
  0% {
    stroke-dashoffset: 10;
  }
  100% {
    stroke-dashoffset: 30;
  }
}
</style>
