<template lang="html">
  <SidebarLoadingWrapper :loading="loadingData">
    <div>
      <LegacyGrid
        v-model="selectedRows"
        selection="single"
        :data="machineRows"
        :title="SR_FieldRecordSystem.Machines"
        :columns="machineColumns"
        add-button edit-button
        delete-button paging
        @add="addNewMachine" @edit="editMachine"
        @delete="showDeleteMachineModal = !showDeleteMachineModal"
      />
      <ContentShareActions v-if="$can('Content.Sharing.Vehicle')" content-type="machines" @all="shareAllMachineToChilds" />
      <MachineModal>
        <template #title>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EditMachine</IxRes>
        </template>
      </MachineModal>
      <ConfirmationModal v-model="showDeleteMachineModal" @confirm="removeSelectedMachine">
        <template #title>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteMachine</IxRes>
        </template>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteSelectedMachineQuestion</IxRes>
      </ConfirmationModal>
    </div>
  </SidebarLoadingWrapper>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import SidebarLoadingWrapper from '@frs/components/SidebarLoadingWrapper'
import LegacyGrid from '@components/grid/LegacyGrid'
import ConfirmationModal from '@components/modals/ConfirmationModal'
import MachineModal from './MachineModal'
import ContentShareActions from '../content-sharing/ContentShareActions'

import ReloadDataOnOrgUnitChange from '@frs/mixins/ReloadDataOnOrgUnitChange'
import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    SidebarLoadingWrapper,
    LegacyGrid,
    MachineModal,
    ConfirmationModal,
    ContentShareActions
  },
  mixins: [
    ReloadDataOnOrgUnitChange,
    PermissionMixin
  ],
  data () {
    return {
      showDeleteMachineModal: false,
      selectedRows: [],
      title: 'test'
    }
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem',
      'Common.SR_Common',
      'Messages.State.SR_StateMessages'
    ]),
    ...mapState('fieldRecordSystem/machines', {
      machines: state => state.data.machines,
      machineGroups: state => state.data.machineGroups
    }),
    machineRows () {
      return this.ownedNotArchivedMachines.map(x => ({...x, group: this.getGroupName(x.group)}))
    },
    machineColumns () {
      return [
        {localizedTitle: this.SR_Common.Title, typeName: 'String', propertyName: 'name'},
        {localizedTitle: this.SR_Common.Group, typeName: 'String', propertyName: 'group'},
        {localizedTitle: this.SR_FieldRecordSystem.WorkingWidthInMeter, typeName: 'Number', propertyName: 'workingWidth'},
        {localizedTitle: this.SR_FieldRecordSystem.TurningCircleInMeter, typeName: 'Number', propertyName: 'turningCircle'}
      ]
    },
    ownedNotArchivedMachines () {
      return this.machines.filter(x => x.orgUnitId === this.$route.params.orgUnitId && x.isArchived === false)
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/machines', [
      'startEditing',
      'loadMachines',
      'loadMachineGroups',
      'removeMachine',
      'shareMachineToChilds'
    ]),
    addNewMachine () {
      this.startEditing()
      this.title = this.SR_FieldRecordSystem.AddMachine
    },
    editMachine () {
      this.title = this.SR_FieldRecordSystem.EditMachine
      this.startEditing(this.ownedNotArchivedMachines.find(x => x.id === this.selectedRows[0].id))
    },
    getGroupName (name) {
      const key = `VehicleType_${name}`

      return name ? this.SR_FieldRecordSystem[key] : ''
    },
    removeSelectedMachine () {
      this.showDeleteMachineModal = false
      this.removeMachine(this.selectedRows[0])
        .catch(() => {
          notifications.error(this.SR_StateMessages.MachineNotDeleted)
        })
        .then(() => {
          notifications.success(this.SR_StateMessages.MachineDeleted)
        })
    },
    shareAllMachineToChilds () {
      this.shareMachineToChilds()
        .then(() => {
          notifications.success(this.SR_StateMessages.MachineShared)
        })
        .catch(() => {
          notifications.error(this.SR_StateMessages.MachineNotShared)
        })
    }
  },
  reloadData () {
    return Promise.all([
      this.loadMachines(),
      this.loadMachineGroups()
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
