<template>
  <div class="harvest-form-container">
    <span class="item-container">
      <label>
        <IxRes>actions.harvest.CuttingNumber</IxRes>: *
      </label>
      <NumericInput v-model="cutNumber" class="cut-input" />
    </span>

    <span class="item-container">
      <label>
        <IxRes>actions.harvest.CuttingTime</IxRes>: *
      </label>
      <div class="radio-container">
        <BsRadio v-model="cuttingTime" inline value="early"><IxRes>actions.harvest.early</IxRes></BsRadio>
        <BsRadio v-model="cuttingTime" inline value="middle"><IxRes>actions.harvest.middle</IxRes></BsRadio>
        <BsRadio v-model="cuttingTime" inline value="late"><IxRes>actions.harvest.late</IxRes></BsRadio>
      </div>
    </span>

    <span class="item-container">
      <label>
        <IxRes>actions.harvest.Income</IxRes>: *
      </label>
      <QuantityInput v-model="grassYield" class="income-input" :unit-options="validUnits" />
    </span>
  </div>
</template>

<script>
import ParameterMixin, {mapParameters} from '../ParameterMixin'

import BsRadio from '@components/bootstrap/BsRadio'
import NumericInput from '@components/forms/NumericInput'
import QuantityInput from '@components/forms/QuantityInput'

export default {
  components: {
    BsRadio,
    NumericInput,
    QuantityInput
  },
  mixins: [
    ParameterMixin
  ],
  computed: {
    ...mapParameters([
      'cutNumber',
      'cuttingTime',
      'grassYield'
    ]),
    validUnits () {
      return ['dt/ha']
    }
  }
}
</script>

<style scoped>
.harvest-form-container {
  display: flex;
  flex-direction: column;
}
.item-container {
  margin-bottom: 16px;
}
.radio-container {
  display: flex;
  flex-direction: column;
}
.cut-input {
  width: 12%;
}
.income-input {
  width: 23%;
}
</style>
