<template lang="html">
  <div>
    <ZoneMapsLayer :zone-maps="[updatedMap]" :palette="zoneMapPalette" />

    <ClickInteraction
      :hover-style="hoverStyle"
      @click="onClick"
    />

    <ZoneEditingOverlay
      v-if="selectedPart"
      :zone-map="map" :palette="zoneMapPalette"
      :position="overlayPosition" centered
      @select="merge"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {fromPairs, mapValues, flatten} from 'lodash'
import {parse, stringify} from 'wellknown'
import {center} from '@turf/turf'
import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import Fill from 'ol/style/Fill'

import ZoneMapsLayer from '@components/map/layers/ZoneMapsLayer'
import ClickInteraction from '@components/map/interactions/ClickInteraction'

import ZoneEditingOverlay from './ZoneEditingOverlay'

import DisableDefaultMapLayersMixin from '@frs/mixins/DisableDefaultMapLayersMixin'

export default {
  components: {
    ZoneMapsLayer,
    ClickInteraction,
    ZoneEditingOverlay
  },
  mixins: [
    DisableDefaultMapLayersMixin
  ],
  props: {
    value: Object, // {[zoneId]: [geoJsonGeometry,...]}
    map: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectedPart: null
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      zoneMapPalette: state => state.ui.zoneMapPalette
    }),
    separatedOriginalGeometries () {
      return fromPairs(this.map.zones.map(zone => [zone.id, this.splitMultiPolygon(parse(zone.geometry))]))
    },
    valueOrOriginal () {
      return this.value || this.separatedOriginalGeometries
    },
    separatedUpdatedZones () {
      return mapValues(this.valueOrOriginal, (geometries, zoneId) => {
        const zone = this.map.zones.find(x => x.id === zoneId)

        return geometries.map((geometry, i) => ({
          ...zone,
          id: `${zoneId}_${i}`,
          geometry: stringify(geometry)
        }))
      })
    },
    updatedMap () {
      return {
        ...this.map,
        zones: flatten(Object.values(this.separatedUpdatedZones))
      }
    },
    hoverStyle () {
      return new Style({
        stroke: new Stroke({
          color: 'rgb(255, 255, 255)',
          width: 3
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.1)'
        })
      })
    },
    originZoneId () {
      return Object.keys(this.valueOrOriginal)
        .find(zoneId => this.valueOrOriginal[zoneId].includes(this.selectedPart))
    },
    overlayPosition () {
      if (!this.selectedPart) return null

      return center(this.selectedPart).geometry.coordinates
    }
  },
  methods: {
    onClick ({feature}) {
      if (!feature) {
        this.selectedPart = null
        return
      }

      const partZoneId = feature.get('zoneId')
      const [zoneId, partIndexString] = /(.+)_(\d+)$/.exec(partZoneId).slice(1)

      const partIndex = parseInt(partIndexString)

      this.selectedPart = this.valueOrOriginal[zoneId]
        .find((zonePart, index) => index === partIndex)
    },
    splitMultiPolygon (multiPolygonGeometry) {
      return multiPolygonGeometry.coordinates.map(coordinates => ({
        type: 'Polygon',
        coordinates
      }))
    },
    merge (targetZoneId) {
      this.$emit('input', {
        ...(this.valueOrOriginal),
        [this.originZoneId]: this.valueOrOriginal[this.originZoneId].filter(x => x !== this.selectedPart),
        [targetZoneId]: this.valueOrOriginal[targetZoneId].concat(this.selectedPart)
      })

      this.selectedPart = null
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
