<template>
  <div class="company-data-container">
    <div class="company-header">
      <h3>
        <IxRes v-if="isEditCompanyComponent">frs.company.data.editCompany</IxRes>
        <IxRes v-else>frs.company.data.createOrganization</IxRes>
      </h3>
      <h5>
        <IxRes>frs.forms.mandatoryInputFields</IxRes>
      </h5>
    </div>
    <div class="company-data-form item">
      <TextInput v-model="orgUnitName" name="orgUnitName">
        <template #label>
          <IxRes>frs.common.name</IxRes>: *
        </template>
      </TextInput>
      <div class="company-switch item">
        <SimpleSwitch v-model="shouldHaveCompanyData" name="shouldHaveCompanyData">
          <template #offLabel>
            <IxRes>frs.common.orgUnit</IxRes>
          </template>
          <IxRes>frs.common.company</IxRes>
        </SimpleSwitch>
        <VueInfo class="info" placement="left">
          <template #description>
            <IxRes>frs.company.CompanyHelp</IxRes>
          </template>
        </VueInfo>
      </div>
      <template v-if="shouldHaveCompanyData">
        <TextInput v-model="name" class="item" name="name">
          <template #label>
            <IxRes>frs.company.data.companyName</IxRes>: *
          </template>
        </TextInput>
        <div class="address-input-container item">
          <TextInput
            v-model="address1"
            class="item-address"
            required
            name="address1"
          >
            <template #label>
              <IxRes>frs.company.data.address</IxRes>:
            </template>
          </TextInput>
          <TextInput
            v-model="address2"
            class="item-address"
            required
            name="address2"
          >
            <template #label>
              <IxRes>frs.company.data.zipCode</IxRes>:
            </template>
          </TextInput>
          <TextInput
            v-model="address3"
            class="item-address"
            required
            name="address3"
          >
            <template #label>
              <IxRes>frs.company.data.city</IxRes>:
            </template>
          </TextInput>
          <TextInput
            v-model="phoneNumber"
            class="item"
            required
            name="phoneNumber"
          >
            <template #label>
              <IxRes>frs.company.data.phoneNumber</IxRes>:
            </template>
          </TextInput>
          <TextInput
            v-model="email"
            class="item"
            required
            name="email"
          >
            <template #label>
              <IxRes>frs.company.data.email</IxRes>:
            </template>
          </TextInput>
        </div>
        <SimpleSwitch v-model="isSupplementarySourceOfIncome" name="isSupplementarySourceOfIncome">
          <template #offLabel>
            <IxRes>frs.common.mainAcquisition</IxRes>
          </template>
          <IxRes>frs.common.sideline</IxRes>
        </SimpleSwitch>
        <VueSelectize
          v-model="legalForm" :options="legalForms"
          class="item" :multiple="false"
          sort-field="text" allow-empty
          name="legalForm"
        >
          <template #label>
            <IxRes>frs.company.data.legalForm</IxRes>:
          </template>
        </VueSelectize>
        <VueSelectize
          v-model="companyForm" :options="companyForms"
          class="item" :multiple="false"
          sort-field="text" allow-empty
          name="companyForm"
        >
          <template #label>
            <IxRes>frs.company.data.companyForm</IxRes>:
          </template>
        </VueSelectize>
        <HelpBox v-if="companyForm">
          <IxRes>frs.company.data.{{ companyForm }}</IxRes>
        </HelpBox>
        <SimpleSwitch v-model="isOperatedEcologically" name="isOperatedEcologically">
          <template #offLabel>
            <IxRes>frs.common.conventional</IxRes>
          </template>
          <IxRes>frs.common.ecologically</IxRes>
        </SimpleSwitch>
        <TextInput v-model="invekos" class="item" name="ivekos">
          <template #label>
            <IxRes>frs.company.data.invekos</IxRes>:
          </template>
        </TextInput>
        <TextInput v-model="tin" class="item" name="tin">
          <template #label>
            <IxRes>frs.company.data.taxIndentificationNumber</IxRes>:
          </template>
        </TextInput>
      </template>
      <div class="button-container item">
        <IxButton
          large save
          :disabled="!isValid"
          @click="saveWithPossibleDelete"
        >
          <IxRes>frs.common.save</IxRes>
        </IxButton>
        <IxButton large cancel @click="cancel">
          <IxRes>frs.common.cancel</IxRes>
        </IxButton>
      </div>
    </div>
    <FrsLoadingIndicator :loading="saving" />
    <ConfirmationModal
      v-model="cancelModalVisible"
      @confirm="confirmCancel"
      @cancel="refuseCancel"
    >
      <template #title>
        <IxRes>frs.common.cancel</IxRes>
      </template>
      <h3>
        <IxRes>frs.common.cancelEditText</IxRes>
      </h3>
      <IxRes>frs.common.cancelEditHint</IxRes>
    </ConfirmationModal>
    <ConfirmationModal
      v-model="deleteModalVisible"
      @confirm="confirmDelete"
      @cancel="refuseDelete"
    >
      <IxRes slot="title">frs.company.deleteCompanyData</IxRes>
      <p v-if="orgUnitId">
        <IxRes>frs.company.deleteCompanyDataQuestion</IxRes>
      </p>
      <div><b> {{ orgUnitName }}</b></div>
    </ConfirmationModal>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import VueSelectize from '@components/VueSelectize'
import IxButton from '@components/IxButton'
import ConfirmationModal from '@components/modals/ConfirmationModal'
import VueInfo from '@components/help/VueInfo'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import SimpleSwitch from '@components/forms/SimpleSwitch'
import TextInput from '@components/forms/TextInput'
import HelpBox from '@components/help/HelpBox'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    VueSelectize,
    IxButton,
    ConfirmationModal,
    VueInfo,
    SimpleSwitch,
    TextInput,
    FrsLoadingIndicator,
    HelpBox
  },
  mixins: [
    FormPartMixin
  ],
  data () {
    return {
      cancelModalVisible: false,
      deleteModalVisible: false,
      saving: false
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/companyData/editBuffer', [
      'orgUnitName',
      'shouldHaveCompanyData'
    ]),
    ...mapFormFields('fieldRecordSystem/companyData/editBuffer', [
      'companyData.companyForm',
      'companyData.legalForm',
      'companyData.tin',
      'companyData.invekos',
      'companyData.name',
      'companyData.address1',
      'companyData.address2',
      'companyData.address3',
      'companyData.isOperatedEcologically',
      'companyData.isSupplementarySourceOfIncome',
      'companyData.email',
      'companyData.phoneNumber'
    ]),
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState('fieldRecordSystem', {
      orgUnitData: state => state.data.orgUnits.data,
      legalForms: state => state.data.legalForms.map(x => x.name),
      companyForms: state => state.data.companyForms.map(x => x.name),
      companyData: state => state.companyData.editBuffer.companyData,
      companyId: state => state.companyData.editBuffer.id,
      view: state => state.ui.view.right
    }),
    ...mapState('fieldRecordSystem/navigation', {
      orgUnitId: state => state.location.orgUnitId
    }),
    isNew () {
      return this.companyId === null
    },
    hasCompanyData () {
      return Boolean(!this.isNew && this.orgUnitId !== null && this.orgUnitData[this.orgUnitId].companyData)
    },
    isValid () {
      return !!this.orgUnitName
    },
    isEditCompanyComponent () {
      return this.view === 'companyData'
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/companyData', [
      'saveCompany',
      'stopEditing'
    ]),
    async save () {
      this.validate()

      await this.$nextTick()

      if (this.state !== 'success') return

      this.saving = true

      this.saveCompany()
        .then(orgUnitId => {
          if (orgUnitId !== this.$route.params.orgUnitId) {
            this.$router.push({name: 'orgDashboard', params: {orgUnitId}})
          }
          notifications.success(this.SR_FieldRecordSystem.CompanyDataChangesSuccess)
        })
        .catch(error => {
          console.error(error)
          notifications.error(this.SR_FieldRecordSystem.CompanyDataChangesError)
        })
        .then(() => {
          this.saving = false
        })
    },
    saveWithPossibleDelete () {
      if (this.hasCompanyData && !this.shouldHaveCompanyData) {
        this.deleteModalVisible = true
      } else {
        this.save()
      }
    },
    cancel () {
      this.cancelModalVisible = true
    },
    confirmCancel () {
      this.cancelModalVisible = false
      this.$nextTick(() => {
        this.stopEditing()
      }) // give modal time to close
    },
    refuseCancel () {
      this.cancelModalVisible = false
    },
    confirmDelete () {
      this.save()
    },
    refuseDelete () {
      this.deleteModalVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.company-data-container, .company-header, .company-data-form , .address-input-container{
  display: flex;
  flex-direction: column;
}
.item {
  margin: 4px 0;
}
.item-address {
  margin: 1px 0;
}
.company-switch {
  display: flex;
  .info {
    margin-left: 4px;
    margin-bottom: 4px;
  }
}
.button-container {
  display: flex;
  justify-content: space-between;
}
</style>
