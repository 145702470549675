<template lang="html">
  <div class="tag-container">
    <div v-if="tags.length === 0" class="label label-custom">
      {{ placeholder }}
    </div>
    <div
      v-for="tag in tags" :key="tag.id"
      class="label label-default tag"
      :class="large ? 'large' : ' '"
    >
      <div class="tag-element">
        <div>{{ tag.label }}</div>
        <div v-if="button" class="label label-default edit-button" @click="$emit('click', tag)">
          <i class="fa" :class="[`fa-${button}`]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tags: Array,
    placeholder: String,
    button: String,
    large: Boolean
  }
}
</script>

<style lang="scss" scoped>
.tag {
  margin-right: 0.25rem;
  margin-top: 0.25rem;
}

.tag-element {
  display: flex;
  align-items: center;
}

.label-custom {
  color: #AAA;
  background-color: #EEE;
}

.edit-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 2px;

  i {
    margin-bottom: -2px;
  }

  &:hover {
    background-color: #AAA;
  }
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
}

.large {font-size: 100%; font-weight: normal;}
</style>
