<template lang="html">
  <div v-if="dateTimeRange" class="date" :class="{compact}">
    <span>{{ $i18n.format(dateTimeRange.start, 'date') }} - </span>
    <span>{{ $i18n.format(dateTimeRange.end, 'date') }}</span>
  </div>
</template>

<script>
export default {
  props: {
    dateTimeRange: {
      type: Object,
      default: null
    },
    compact: Boolean
  }
}
</script>

<style lang="scss" scoped>
.compact.date {
  display: flex;
  flex-direction: column;
  font-size: 0.7em;
  justify-content: space-between;

  > span {
    margin: -0.1em;
  }
}
</style>
