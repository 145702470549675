<template lang="html">
  <span
    class="variant-divider"
    :class="{
      'left-border': !hideLeftBorder,
      'right-border': !hideRightBorder
    }"
  >
    <IxRes>frs.dlgCertIntegration.modals.harvestYearHelp.diagram.labels.divider</IxRes>
  </span>
</template>

<script>
export default {
  props: {
    hideLeftBorder: Boolean,
    hideRightBorder: Boolean
  }
}
</script>

<style lang="scss" scoped>
@use './shared';

.variant-divider {
  @include shared.small-text;
  @include shared.centered;

  padding: 0 shared.$small-padding;

  &.left-border {
    border-left: shared.$border;
  }
  &.right-border {
    border-right: shared.$border;
  }
}
</style>
