<template lang="html">
  <div>
    <div class="row">
      <div class="col-md-9">
        <VueSelectize
          v-model="ecoTypeId" required
          :options="ecoTypeOptions"
          sort-field="text"
          name="ecoTypeId"
        >
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EcoType</IxRes>:
          </template>
        </VueSelectize>
      </div>

      <div class="col-md-3 greening-factor">
        <LabelAndMessage>
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.GreeningFactor</IxRes>
          </template>
          <input
            type="text" class="form-control"
            :value="ecoTypeId.greeningFactor"
            disabled
            name="greeningFactor"
          >
        </LabelAndMessage>
      </div>
    </div>

    <div v-if="ecoTypeId.value === 'Leguminosen'" class="form-group crop-display">
      <FrsCropUsagePicker v-model="cropUsageId" required name="cropUsageId">
        <template #label>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationSelection</IxRes>
        </template>
      </FrsCropUsagePicker>
    </div>

    <MixtureSelection
      v-if="ecoTypeId.value === 'ZwischenfruechteGruendecke'"
      v-model="mixtureComponents"
      :available-mixtures="availableMixtures"
      @remove="mixtureComponents = null"
    >
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CatchCropMixture</IxRes>
      </template>
    </MixtureSelection>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'

import VueSelectize from '@components/VueSelectize'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import FrsCropUsagePicker from '@frs/components/base/FrsCropUsagePicker'

import MixtureSelection from './mixture/MixtureSelection'

export default {
  components: {
    VueSelectize,
    LabelAndMessage,
    FrsCropUsagePicker,
    MixtureSelection
  },
  props: {
    availableMixtures: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/cultivationPlanning/editBuffer', [
      'mixtureComponents'
    ]),
    ...mapFormFields('fieldRecordSystem/cultivationPlanning/editBuffer', {
      'ecoTypeId': {
        read (value) {
          return this.ecoTypeOptions.find(option => option.value === value)
        },
        write: option => option.value
      }
    }),
    ...mapFormFields('fieldRecordSystem/cultivationPlanning/editBuffer', [
      'cropUsageId'
    ]),
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState('fieldRecordSystem/cultivationPlanning', {
      ecoTypes: state => state.data.ecoTypes
    }),
    ecoTypeOptions () {
      return this.ecoTypes.map(type => ({
        value: type.name,
        text: this.SR_FieldRecordSystem[`EcoTypes_${type.name}`],
        label: this.$i18n.format(type.greeningFactor, 'f1')
      }))
    }
  },
  methods: {
    editMixture () {
      this.$store.commit('fieldRecordSystem/setRightView', 'mixtureManagement')
    }
  }
}
</script>

<style lang="css">
.greening-factor {
  padding-left: 10px;
}
</style>
