<template>
  <div class="form-container">
    <div class="input">
      <TextInput v-model="name" placeholder="Name" />
    </div>
    <div class="date">
      <VueDateTimePicker v-model="date" iso placeholder="Datum" />
    </div>
    <div class="picker">
      <FrsProductPicker
        v-model="productId" type="fertilizer"
        legacy
        label="Product"
      />
    </div>
    <FrsUnitPicker
      v-model="unit"
    >
      <template #label>
        <IxRes>applicationMaps.algorithmicCreation.labels.unit</IxRes>
      </template>
    </FrsUnitPicker>
    <div class="key-selector">
      <VueSelectize
        v-model="timestampKey"
        :options="keyOptions"
        label="Zeitstempel" allow-empty
      />
    </div>
    <div class="key-selector">
      <VueSelectize
        v-model="valueKey"
        :options="keyOptions"
        label="Value" allow-empty
      />
    </div>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'

import TextInput from '@components/forms/TextInput'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import FrsProductPicker from '@frs/components/base/FrsProductPicker'
import VueSelectize from '@components/VueSelectize'
import FrsUnitPicker from '@frs/components/base/FrsUnitPicker'

export default {
  components: {
    TextInput,
    VueDateTimePicker,
    FrsProductPicker,
    VueSelectize,
    FrsUnitPicker
  },
  props: {
    measurementKeys: Array
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/telemetry/dataImport', [
      'name',
      'date',
      'productId',
      'valueKey',
      'timestampKey',
      'unit'
    ]),
    keyOptions () {
      return this.measurementKeys.map(key => ({text: key, value: key}))
    }
  }
}
</script>

<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
}
.input, .date, .input {
  margin-bottom: 4px;
}
</style>
