<template lang="html">
  <div class="alert" :class="[`alert-${autoClass}`]">
    <div class="text">
      <slot />
    </div>
    <div class="icon-container">
      <FontAwesomeIcon class="help-icon fa icon" :icon="autoIcon" />
    </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faCalendar, faCheck} from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    isDone: Boolean,
    icon: Object
  },
  computed: {
    autoIcon () {
      return this.icon || (this.isDone ? faCheck : faCalendar)
    },
    autoClass () {
      return this.isDone ? 'success' : 'info'
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  margin-bottom: 0.5em;

  .icon-container {
    border: 1px solid;
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;

    .help-icon {
      font-size: 1.5em;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
  }
}
</style>
