<template lang="html">
  <ProductQuantityEditor
    v-model="modelProxy"
    v-bind="{entityLookup, products, recommendedIds, previouslyUsedProducts, availableProductFilter}"
  />
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {smarterGet} from '@helpers/vuex/data-loading'
import {modelProxy} from '@helpers/vuex'

import ProductQuantityEditor from '@components/product-quantity-editor/ProductQuantityEditor'

export default {
  components: {
    ProductQuantityEditor
  },
  props: {
    value: Array, // [{productId: string, amount: number, unit: string}, ...more]
    availableProductFilter: Function
  },
  data () {
    return {
      previouslyUsedProducts: []
    }
  },
  computed: {
    modelProxy,
    ...mapGetters('fieldRecordSystem/navigation', [
      'entityLookup'
    ]),
    ...mapGetters('masterData', [
      'products'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'orgUnitId'
    ]),
    ...mapState('masterData', {
      recommendedIds: state => state.portalSettings.recommended.products
    })
  },
  created () {
    this.$store.dispatch('masterData/reloadCrops')
    smarterGet('/api/v2/master-data/products/{orgUnitId}', {
      id: 'productPicker.lastUsed.productIds',
      inputs: {
        orgUnitId: () => this.orgUnitId
      },
      onResult: (previouslyUsedProducts) => {
        this.previouslyUsedProducts = previouslyUsedProducts
      }
    })
  }
}
</script>

<style lang="scss" module>
</style>
