<template>
  <div class="input-group">
    <div class="section">
      <div class="row">
        <div class="col-md-4">
          <NumericInput
            v-model="bbch" integer
            :rule="x => x >= 0 && x <= 99"
            name="bbch"
          >
            <template #label>
              <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.BBCHStage</IxRes>
            </template>
          </NumericInput>
        </div>
      </div>
    </div>
    <div class="section">
      <FrsProductQuantityEditor v-model="protectionProductQuantities" />
    </div>
    <div class="section">
      <div class="row">
        <div class="col-md-6">
          <NumericInput
            v-model="waterAmount"
            unit="ha"
            name="waterAmount"
          >
            <template #label>
              <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ProtectionWaterAmount</IxRes>
            </template>
          </NumericInput>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {filterCompleted} from '@components/product-quantity-editor/buffer-handling'

import NumericInput from '@components/forms/NumericInput'

import FormPartMixin from '@components/forms/FormPartMixin'
import ParameterMixin, {mapParameters} from '../ParameterMixin'
import FrsProductQuantityEditor from '@frs/components/base/FrsProductQuantityEditor'

const productIdWater = '462e02db-a1ec-475f-b6a5-108ebd5f4b0e'

export default {
  components: {
    FrsProductQuantityEditor,
    NumericInput
  },
  mixins: [
    FormPartMixin,
    ParameterMixin
  ],
  props: {
    name: String
  },
  data () {
    return {
      // NOTE temporary flags
      overrideChecks: true,
      waterAmountBuffer: {
        productId: productIdWater,
        unit: 'l/ha',
        amount: null
      }
    }
  },
  computed: {
    ...mapParameters([
      'bbch'
    ]),
    ...mapGetters('masterData', ['products']),
    currentCrop () {
      return {} // TODO has to be retrieved from cultivations at some point, what about multiple fields?
    },
    availableProtectionProductsFilter () {
      return product => product.type === 'protection' && !product.isArchived
    },
    productFields () {
      return {
        text: id => this.products[id].name
      }
    },
    productQuantities () {
      const fieldId = Object.keys(this.value)[0]
      return this.value[fieldId].productQuantities
    },
    protectionProductQuantities: {
      get () {
        return this.productQuantities.filter(x => x.productId !== productIdWater)
      },
      set (quantities) {
        this.emitProductQuantities(quantities.concat(filterCompleted([this.waterAmountBuffer])))
      }
    },
    waterAmount: {
      get () {
        return this.waterAmountBuffer.amount
      },
      set (amount) {
        this.waterAmountBuffer = Object.assign({...this.waterAmountBuffer}, {amount})
        this.emitProductQuantities(this.protectionProductQuantities.concat(filterCompleted([this.waterAmountBuffer])))
      }
    }
  },
  created () {
    const waterQuantity = this.productQuantities.find(x => x.productId === productIdWater)
    if (waterQuantity) {
      this.waterAmountBuffer = waterQuantity
    }
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  width: 60%;
}
.section {
  margin-top: 10px;
}
.product {
  padding-left: 0;
}
.alert {
  color: red;
}
</style>
