<template lang="html">
  <div class="actions-for-field-container">
    <FrsLoadingIndicator :loading="loadingData" />

    <div class="title-container">
      <h4>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.RelevantActionsFor</IxRes>
        {{ fieldName }}
      </h4>
      <div class="subtext">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.RelevantActionDescription</IxRes>
      </div>
    </div>

    <div v-if="!(startedActions.length + plannedActions.length)" class="title-container">
      <div class="subtext">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoRelevantActions</IxRes>
      </div>
    </div>

    <template v-if="!loadingData">
      <ActionCard
        v-for="action in startedActions.concat(plannedActions)" :key="action.id"
        class="action-card"
        v-bind="{action, products, personnel, machines}"
        @click.native="showActionDetails(action.id)"
      />

      <IxCollapsePanel v-model="finishedActionsCollapsed" border>
        <template #title>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.FinishedActions</IxRes>
          ({{ finishedActions.length }})
        </template>
        <template #expand-text>
          <IxRes>Common.SR_Common.Expand</IxRes>
        </template>
        <template #collapse-text>
          <IxRes>Common.SR_Common.Collapse</IxRes>
        </template>

        <div v-if="!finishedActions.length" class="title-container">
          <div class="subtext expand-element">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoFinishedActionsYet</IxRes>
          </div>
        </div>

        <ActionCard
          v-for="action in finishedActions" :key="action.id"
          class="action-card"
          v-bind="{action, products, personnel, machines}"
          @click.native="showActionDetails(action.id)"
        />
      </IxCollapsePanel>
    </template>

    <div class="title-container">
      <h4>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.RequiredResourcesForFutureActions</IxRes>
      </h4>
      <div v-if="!futureResources.length" class="subtext">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoResourcesNeeded</IxRes>
      </div>
    </div>

    <ProductQuantityCard v-for="resource in futureResources" :key="resource.id" :data="resource" />

    <ActionMapLayer
      :actions="actions"
    />
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import {orderBy, flatten, groupBy} from 'lodash'

import IxCollapsePanel from '@components/ix/IxCollapsePanel'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import ActionCard from '@frs/components/action/ActionCard'
import ProductQuantityCard from './ProductQuantityCard'
import ActionMapLayer from './ActionMapLayer'

import makeReloadDataMixin from '@frs/mixins/make-reload-data-mixin'

export default {
  components: {
    ActionCard,
    ProductQuantityCard,
    ActionMapLayer,
    IxCollapsePanel,
    FrsLoadingIndicator
  },
  mixins: [
    makeReloadDataMixin([
      state => state.fieldRecordSystem.navigation.location.fieldId,
      state => state.fieldRecordSystem.userSettings.harvestYear
    ])
  ],
  data () {
    return {
      finishedActionsCollapsed: true
    }
  },
  computed: {
    ...mapGetters('masterData', [
      'products'
    ]),
    ...mapState('fieldRecordSystem', {
      machines: state => state.machines.data.machines,
      personnel: state => state.persons.data.persons
    }),
    ...mapState('fieldRecordSystem/action', {
      actions: state => state.data.actions.forField
    }),
    ...mapState('fieldRecordSystem', {
      fields: state => state.data.field
    }),
    ...mapGetters('fieldRecordSystem', [
      'fieldName'
    ]),
    startedActions () {
      return orderBy(this.actions.filter(x => x.timestamps.actual.start && !x.timestamps.actual.end), 'timestamps.actual.start', 'asc')
    },
    finishedActions () {
      return orderBy(this.actions.filter(x => x.timestamps.actual.end), 'timestamps.actual.start', 'desc')
    },
    plannedActions () {
      return orderBy(this.actions.filter(x => !x.timestamps.actual.start && !x.timestamps.actual.end), 'timestamps.planned.start', 'asc')
    },
    futureResources () {
      let temp = []

      const futureActions = this.plannedActions // this.actions.filter(x => x.status === 'ActionStatusPlanned')
      const futureResources = futureActions.map((x) => [{date: x.timestamps.planned.start, resources: x.planningParts.flatMap(part => part.productQuantities)}])
      const flattenedResources = flatten(futureResources)

      flattenedResources.forEach(x => x.resources.forEach(productQuantity => temp.push({
        name: this.products[productQuantity.productId].name,
        date: x.date,
        amount: productQuantity.amount,
        unit: productQuantity.unit
      })))

      const result = groupBy(temp, 'name')

      return Object.values(result)
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/action', [
      'loadActionsForCurrentField',
      'showActionDetails'
    ]),
    ...mapActions('fieldRecordSystem/machines', [
      'loadMachines'
    ]),
    ...mapActions('fieldRecordSystem/persons', [
      'loadPersons'
    ])
  },
  async reloadData () {
    return Promise.all([
      this.loadMachines(),
      this.loadPersons(),
      this.loadActionsForCurrentField()
    ])
  }
}
</script>

<style lang="scss" scoped>
  span {
    padding: 0;
  }
  h4 {
    margin-bottom: 4px;
    padding-bottom: 0;
  }
  .actions-for-field-container {
    display: flex;
    flex-direction: column;

    .action-card {
      cursor: pointer;
    }

    .title-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;

      .subtext {
        font-size: 0.9em;
        color: darkgrey;
      }

      .expand-element {
        margin-left: 6px;
      }
    }
  }
</style>
