<template>
  <div v-if="map">
    <SimpleTable
      v-for="(validation, index) in map.metaData.validation" :key="index"
      :columns="{name: {title: 'Name', sortable: true}, value: {title: 'Wert'}}"
      :data="Object.keys(validation).map(x => ({name: x, value: validation[x]}))"
    />
  </div>
</template>

<script>
import SimpleTable from '@components/table/SimpleTable'

export default {
  components: {
    SimpleTable
  },
  props: {
    map: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
