<template lang="html">
  <div>
    <div class="machines">
      <p>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Machines</IxRes>:
      </p>
      <VueSelectize
        v-model="machines" :fields="fields"
        name="machines"
        :options="selectableMachines"
        :allow-empty="true" :placeholder="SR_Placeholders.SelectMachine"
        multiple
      />
    </div>
    <div class="persons">
      <p>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Personnel</IxRes>:
      </p>
      <VueSelectize
        v-model="personnel" :fields="fields"
        name="personnel"
        :options="selectablePersonnel"
        :allow-empty="true" :placeholder="SR_Placeholders.SelectPerson"
        multiple
      />
    </div>
    <p>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Notes</IxRes>:
    </p>
    <textarea
      ref="notes" class="note-area"
      rows="4"
      :value="notes" @blur="notes = $refs.notes.value"
    />
  </div>
</template>

<script>
import {intersectionBy} from 'lodash'

import {mapState, mapGetters} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'

import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    VueSelectize
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/action/editBuffer', {
      'machines': {
        read (value) {
          return this.selectableMachines.filter(m => value.includes(m.id))
        },
        write: value => value.map(m => m.id),
        mutation: 'setMachineIds'
      },
      'personnel': {
        read (value) {
          return this.selectablePersonnel.filter(p => value.includes(p.id))
        },
        write: value => value.map(p => p.id),
        mutation: 'setPersonnelIds'
      }
    }),
    ...mapFormFields('fieldRecordSystem/action/editBuffer', [
      'notes'
    ]),
    ...mapResources([
      'Controls.Placeholders.SR_Placeholders'
    ]),
    ...mapState('fieldRecordSystem', {
      machineOptions: state => state.machines.data.machines,
      personOptions: state => state.persons.data.persons,
      selectedActionId: state => state.action.editBuffer.id,
      actionsForOrg: state => state.action.data.actions.forOrgUnit,
      actionsForField: state => state.action.data.actions.forField
    }),
    ...mapGetters('fieldRecordSystem', [
      'entityNameLookup',
      'orgUnitId'
    ]),
    selectedAction () {
      return this.actionsForOrg.find(x => x.id === this.selectedActionId) ||
          this.actionsForField.find(x => x.id === this.selectedActionId) ||
          null
    },
    selectableMachines () {
      const included = this.selectedAction
        ? intersectionBy(this.machineOptions.filter(x => x.isArchived || x.isRetracted), this.selectedAction.machines.map(x => ({id: x})), 'id')
        : []

      const visible = this.machineOptions.filter(x => !x.isArchived && !x.isRetracted)

      return included.concat(visible).map(x => {
        return {
          ...x,
          group: x.group,
          label: this.statusInfo(x)
        }
      })
    },
    selectablePersonnel () {
      const included = this.selectedAction
        ? intersectionBy(this.personOptions.filter(x => x.isArchived || x.isRetracted), this.selectedAction.personnel.map(x => ({id: x})), 'id')
        : []

      const visible = this.personOptions.filter(x => !x.isArchived && !x.isRetracted)

      return included.concat(visible).map(x => {
        return {
          ...x,
          group: x.group,
          label: this.statusInfo(x)
        }
      })
    },
    fields () {
      return {
        text: state => state.name,
        value: state => state.id
      }
    }
  },
  methods: {
    statusInfo (value) {
      let status = this.orgUnitId !== value.orgUnitId
        ? this.entityNameLookup[value.orgUnitId]
        : ''

      if (value.isArchived) {
        if (status.length) {
          status += ' - '
        }
        status += this.$i18n.translate('actions.labels.archived')
      } else if (value.isRetracted) {
        if (status.length) {
          status += ' - '
        }
        status += this.$i18n.translate('actions.labels.retracted')
      }
      return status
    }
  }
}
</script>

<style lang="scss" scoped>
.sort {
  display: flex;
  justify-content: space-between;
}
.note-area {
  width: 100%;
  min-width: 100%;
  min-height: 80px;
}
.machines, .persons {
  width: 55%;
}
</style>
