<template>
  <div class="details-container">
    <component :is="detailsComponent" v-if="detailsComponent" :application-map="applicationMap" />
  </div>
</template>

<script>

import DetailsBasicFertilization from './DetailsBasicFertilization'
import DetailsFertAssist from './DetailsFertAssist'
import DetailsMaizeSowing from './DetailsMaizeSowing'
import DetailsSeedExpert from './DetailsSeedExpert'
import DetailsSimpleApplicationMap from './DetailsSimpleApplicationMap'

export default {
  props: {
    applicationMap: Object
  },
  computed: {
    detailsComponent () {
      if (!this.applicationMap) {
        return null
      }

      switch (this.applicationMap.category) {
      case 'BasicFertilization_Magnesium':
      case 'BasicFertilization_Phosphorus':
      case 'BasicFertilization_Potassium':
      case 'BasicFertilization_pH':
        return DetailsBasicFertilization

      case 'FertAssist_ApplicationMap':
        return DetailsFertAssist

      case 'SeedExpert_ApplicationMap':
        return DetailsSeedExpert

      case 'MaizeSowingApplicationMap':
        return DetailsMaizeSowing

      case 'SimpleApplicationMap':
      case 'SimpleApplicationMapByZones':
      case 'SimpleApplicationMapByHeterogenity':
        return DetailsSimpleApplicationMap

      default:
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.details-container {
  display: flex;
  flex-direction: column;
}
</style>
