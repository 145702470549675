<template lang="html">
  <div class="item">
    <div class="front-spacing">
      <h4>{{ name }}</h4>
    </div>
    <template>
      <div v-for="(item, index) in data" :key="index" class="date-row">
        <span class="item-key">
          {{ $i18n.format(item.date, 'date') }}:
        </span>
        <span v-if="item.amount" class="item-value">
          {{ $i18n.format(item.amount, 'f2') }} {{ unit }}
        </span>
        <span v-else class="item-value">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.UnknownQuantity</IxRes>
        </span>
      </div>
    </template>
    <div class="result-row">
      <span class="item-key">
        <strong>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.FutureResourceSum</IxRes>:
        </strong>
      </span>
      <span class="item-value">
        <strong>
          {{ sum }} {{ unit }}
        </strong>
      </span>
    </div>
  </div>
</template>

<script>
import {sumBy, groupBy} from 'lodash'
import moment from 'moment'

export default {
  props: {
    data: null
  },
  computed: {
    name () {
      const name = Object.keys((groupBy(this.data, 'name')))
      return name[0]
    },
    sum () {
      const sum = sumBy(this.data, 'amount')
      return this.$i18n.format(sum, 'f2')
    },
    unit () {
      const unit = Object.keys((groupBy(this.data, 'unit')))
      return unit[0]
    }
  },
  methods: {
    formattedDate (date) {
      return moment(date).format('L')
    }
  }
}
</script>

<style scoped>
.item {
  border: solid 1px #dcdcdc;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 2px 8px 4px 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.075);
  margin: 4px;
}
.item:hover {
  border: solid 1px #a0d9ef;
}
.date-row, .result-row {
  display: flex;
}
.item-key {
  width: 30%;
}
.item-value {
  width: 60%
}
</style>
