<template lang="html">
  <div>
    <SidebarLoadingWrapper :loading="loadingData">
      <ActionGrids
        :title="$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.ActionOverview')"
        :actions="actions"
        @selected="onSelectedActions"
        @add="addNewAction"
        @edit="editActions"
        @delete="deleteActions"
      />
    </SidebarLoadingWrapper>

    <ActionMapLayer
      :actions="actions"
    />
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {notifications} from 'src/js/infrastructure'

import SidebarLoadingWrapper from '@frs/components/SidebarLoadingWrapper'
import ActionMapLayer from '@frs/components/action/ActionMapLayer'

import ActionGrids from './ActionGrids'

import makeReloadDataMixin from '@frs/mixins/make-reload-data-mixin'

export default {
  components: {
    ActionGrids,
    ActionMapLayer,
    SidebarLoadingWrapper
  },
  mixins: [
    makeReloadDataMixin([
      state => state.fieldRecordSystem.navigation.location.orgUnitId,
      state => state.fieldRecordSystem.userSettings.harvestYear,
    ])
  ],
  data() {
    return {
      selectedActions: [],
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/action', {
      actions: state => state.data.actions.forOrgUnit
    }),
    ...mapState('fieldRecordSystem', {
      fields: state => state.data.field
    }),
  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'loadDataForActions'
    ]),
    ...mapActions('fieldRecordSystem/action', [
      'startEditingAction',
      'removeActions',
    ]),
    deleteActions(actions) {
      this.removeActions(actions)
        .then(() => {
          notifications.success(this.$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.ActionsDeleted'))
        })
        .then(this.loadDataForActions)
    },
    onSelectedActions(actions) {
      if (!this.actions) {
        throw new Error(
          "[ActionOverviewMixin] 'actions' property has to be supplied by the component using this mixin"
        )
      }
      this.selectedActions = actions
    },
    addNewAction() {
      this.startEditingAction({
        navigationSource: {
          saveTarget: 'actionOverview',
          cancelTarget: 'actionOverview',
        },
      })
    },
    editActions(actions) {
      if (actions.length !== 1) {
        notifications.warning(this.$i18n.translate('Common.SR_Common.PleaseSelectExactlyOneRow'))
      } else {
        this.startEditingAction({
          actionId: actions[0].id,
          navigationSource: {
            saveTarget: 'actionOverview',
            cancelTarget: 'actionOverview',
          },
        })
      }
    }
  },
  // hook for makeReloadDataMixin
  async reloadData () {
    await this.loadDataForActions()
  }
}
</script>

<style lang="scss" scoped>

</style>
