<template>
  <ListCard :show-details="!collapsed">
    <template #title>
      {{ typeNames }}
    </template>
    <div slot="subtitle" class="subtitle">
      <div class="resource-item" :title="isDone ? $i18n.translate('frs.action.details.status.completed') : $i18n.translate('frs.action.details.status.planned')">
        <FontAwesomeIcon :icon="isDone ? icons.completed : icons.planned" />
        <div>
          <DateRange :value="isDone ? action.timestamps.actual : action.timestamps.planned"></DateRange>
        </div>
      </div>
      <div>
        {{area}}
      </div>
      <div v-if="action.usesExperimentalFinanceIntegration">
        <FontAwesomeIcon :icon="icons.finance" />
      </div>
    </div>
    <template #addon>
      <div class="addon">
        <IxCollapsePanel v-model="collapsed" />
      </div>
    </template>

    <hr class="separator">

    <div class="collapsable-container">
      <div class="text-ellipsed">
        {{ procedureNames }}
      </div>
      <div v-if="action.personnel.length" :title="personnelNames" class="resource-item">
        <FontAwesomeIcon :icon="icons.personnel" />
        <div class="text-ellipsed">
          {{ personnelNames }}
        </div>
      </div>
      <div v-if="action.machines.length" :title="machineNames" class="resource-item">
        <FontAwesomeIcon :icon="icons.tractor" />
        <div class="text-ellipsed">
          {{ machineNames }}
        </div>
      </div>
      <div v-if="productQuantities.length" class="product-quantity-info">
        <SimpleTable :columns="columns" :data="productQuantities">
          <template #header-name>
            <IxRes>frs.action.details.quantity.productName</IxRes>
          </template>
          <template #header-amount>
            <IxRes>frs.action.details.quantity.amount</IxRes>
          </template>
          <template #header-unit>
            <IxRes>frs.action.details.quantity.unit</IxRes>
          </template>

          <template #name="{value}">
            {{value}}
          </template>
          <template #amount="{value}">
            {{ value }}
          </template>
          <template #unit="{value}">
            {{value}}
          </template>
        </SimpleTable>
      </div>
    </div>
  </ListCard>
</template>

<script>
import {flattenDeep} from 'lodash'

import {computeSize} from '@helpers/openlayers/computation'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faCalendar, faCalendarCheck} from '@fortawesome/free-regular-svg-icons'
import {faTractor, faUserFriends, faBox, faFileInvoiceDollar} from '@fortawesome/free-solid-svg-icons'

import SimpleTable from '@components/table/SimpleTable'
import ListCard from '@components/card/ListCard'
import IxCollapsePanel from '@components/ix/IxCollapsePanel'
import DateRange from '@frs/components/action/planning/wizard/widgets/DateRange'

import {getTypeKey, getProcedureKey} from '@frs/components/action/translation-key-helper'

export default {
  components: {
    FontAwesomeIcon,
    SimpleTable,
    ListCard,
    IxCollapsePanel,
    DateRange
  },
  props: {
    action: {
      type: Object,
      required: true
    },
    products: {
      type: Object,
      required: true
    },
    personnel: {
      type: Array,
      required: true
    },
    machines: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      collapsed: true
    }
  },
  computed: {
    personnelNames () {
      return this.action.personnel.map(x => this.personnel.find(person => person.id === x).name).join(', ')
    },
    machineNames () {
      return this.action.machines.map(x => this.machines.find(machine => machine.id === x).name).join(', ')
    },
    productQuantities () {
      const quantities = this.action.planningParts.map(part => {
        return part.productQuantities.map(quantity => {
          return {
            name: this.products[quantity.productId].name,
            amount: this.$i18n.format(quantity.amount, 'f2'),
            unit: quantity.unit,
            isLot: quantity.isLot
          }
        })
      })
      return flattenDeep(quantities)
    },
    typeNames () {
      return this.action.planningParts.map(part => this.$i18n.translate(`${getTypeKey(part)}`)).join(', ')
    },
    procedureNames () {
      return this.action.planningParts.filter(part => part.procedure).map(part => this.$i18n.translate(`${getProcedureKey(part)}`)).join(', ')
    },
    area () {
      return this.$i18n.format(computeSize(this.action.geometry), 'area')
    },
    isDone () {
      return !!this.action.timestamps.actual.end
    },
    icons () {
      return {
        tractor: faTractor,
        personnel: faUserFriends,
        resource: faBox,
        finance: faFileInvoiceDollar,
        completed: faCalendarCheck,
        planned: faCalendar
      }
    },
    columns () {
      return {
        name: {type: 'string', sortable: true},
        amount: 'number',
        unit: 'string'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.addon {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
}

.subtitle {
  display: flex;
  > * {
    margin-right: 0.8em;
  }
}

.resource-item {
  display: flex;
  align-items: center;
  > * {
    margin-right: .4em;
  }
}

.separator {
  margin: 6px 0 3px 0;
  border-top: 1px solid #eee;
}

.text-ellipsed {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.collapsable-container {
  display: flex;
  flex-direction: column;

  .product-quantity-info {
    margin-top: .4em;
  }
}
</style>
