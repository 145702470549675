<template>
  <div>
    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.zoneMap</IxRes>
      </template>
      <div>{{ zoneMapName }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.product</IxRes>
      </template>
      <div>{{ product.name }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.nutrient</IxRes>
      </template>
      <div>
        <IxRes>frs.mapManagement.labels.nutrient_{{ nutrient }}</IxRes>
      </div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.nutrientContent</IxRes>
      </template>
      <div>{{ $i18n.format(nutrientContent) }}{{ $i18n.translateUnit('%') }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.probingMethod</IxRes>
      </template>
      <div>{{ metaData.probingMethod }}</div>
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>frs.mapManagement.labels.desiredNumberOfClasses</IxRes>
      </template>
      <div>{{ metaData.desiredNumberOfClasses }}</div>
    </LabelAndMessage>

    <h4>
      <IxRes>frs.mapManagement.labels.plannedYields</IxRes>
    </h4>
    <DetailsBasicFertilizationPlannedYield v-for="plannedYield in metaData.plannedYields" :key="plannedYield.year" :planned-yield="plannedYield" />

    <div v-if="metaData.previousFertilization">
      <h4>
        <IxRes>frs.mapManagement.labels.previousFertilization</IxRes>
      </h4>
      <LabelAndMessage>
        <template #label>
          <IxRes>frs.mapManagement.labels.product</IxRes>
        </template>
        <div>{{ previousFertilizationProduct.name }}</div>
      </LabelAndMessage>
      <LabelAndMessage>
        <template #label>
          <IxRes>frs.mapManagement.labels.quantity</IxRes>
        </template>
        <div>{{ metaData.previousFertilization.quantity }} {{ $i18n.translateUnit('kg/ha') }}</div>
      </LabelAndMessage>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import {get} from 'lodash'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

import DetailsBasicFertilizationPlannedYield from './DetailsBasicFertilizationPlannedYield'

export default {
  components: {
    LabelAndMessage,
    DetailsBasicFertilizationPlannedYield
  },
  props: {
    applicationMap: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('masterData', [
      'products'
    ]),
    nutrient () {
      const legacyNutrientMapping = {
        'BasicFertilization_Phosphorus': 'phosphorus',
        'BasicFertilization_Magnesium': 'magnesium',
        'BasicFertilization_pH': 'lime',
        'BasicFertilization_Potassium': 'potassium'
      }

      return legacyNutrientMapping[this.applicationMap.category]
    },
    nutrientContent () {
      return this.product[this.nutrient]
    },
    product () {
      return this.products[this.applicationMap.productId]
    },
    previousFertilizationProduct () {
      return this.products[this.metaData.previousFertilization.productId]
    },
    metaData () {
      return this.applicationMap.metaData
    },
    zoneMapName () {
      return get(this.metaData, 'zoneMap.name', 'N/A')
    }
  }
}
</script>

<style scoped>

</style>
